import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IHeaderMainTemplate } from "../../../models";
import { DebtsVehicleRegisterOrganism } from "../../organisms";
import { DebtsBasePage } from "./base/debts-base.page";

export const DebtsVehicleRegisterPage: React.FC = () => {
  const { t } = useTranslation();

  const header = useMemo(() => {
    return {
      Title: t("TitleVehicleRegister"),
      hideReturnButton: true,
      otherPage: true,
    } as IHeaderMainTemplate;
    // eslint-disable-next-line
  }, []);

  return (
    <DebtsBasePage step={1} header={header}>
      <DebtsVehicleRegisterOrganism />
    </DebtsBasePage>
  );
};
