/* eslint-disable no-useless-concat */
import {
  ButtonPrimary,
  CoxIcon,
  CreditcardCompanyEnum,
  Img,
  NumberHelper,
} from "c4u-web-components";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { paths } from "../../../../constants";
import {
  DebtsStatusPaymentEnum,
  IVehicleDebtDetailModel,
} from "../../../../models";
import {
  AmericanExpressLogo,
  CoxHeaderLogo,
  DtHeaderLogo,
  EloLogo,
  ExpressHeaderLogo,
  HipercardLogo,
  KbbHeaderLogo,
  MastercardLogo,
  VisaLogo,
  ZapayHeader,
} from "../../../assets";
import {
  AdvicePaymentDebts,
  AdvicePaymentDebtsColor,
  BoxCardInspection,
  CarCirle,
  Content,
  ContentProof,
  DebtLine,
  DebtLineMore,
  Details,
  DivContent,
  DivIconCox,
  DivStatus,
  FooterButtons,
  FooterInfoPayment,
  HeaderTicket,
  Label,
  LabelNormal,
  LabelNormalValue,
  LabelPrint,
  LabelTitle,
  LabelTitleBlue,
  LinePart,
  LineTitle,
  ModalBody,
  ModalHeader,
  ShareItem,
  StatusBarLateral,
  StatusFlyer,
  TagCreditCardInfo,
  TitleDebt,
  TitleDebtMore,
  TotalValueTitle,
  Value,
  ValueDebt,
  ValueDebtMore,
  WarnningContentPayment,
  Wrapper,
  WrapperDetail,
  WrapperDetails,
  WrapperInspection,
} from "./card-status-debt.molecute.style";

interface IProps {
  id: number;
  status: DebtsStatusPaymentEnum;
  plate: string;
  token: string;
  protocol: string;
  uf: string;
  debtsNumber: number;
  debtsValue: number;
  total: number;
  debts: IVehicleDebtDetailModel[];
  date: Date;
  creditcard: string;
  creditcardFlag?: CreditcardCompanyEnum;
  installmentPlan: number;
  installmentValue: number;
  showPaymentWarning?: boolean;
  onOpenDetails?: (id: number) => void;
  onShare: (id: number, token: string, func: () => void) => void;
  onClickContinue?: () => void;
}

export interface IStatusHistoryProps {
  date: Date;
  status: number;
}

export const CardStatusDebtMolecule: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => setProps(parameters), [parameters]);

  const [seeMore, setSeemore] = useState(false);
  const [showTicket, setShowTicket] = useState(false);

  const componentRef = useRef(null);
  const handlePrint = () => {
    window.open("/#" + paths.debtsProof(props.token, "true"));
  };

  useEffect(() => {
    if (seeMore && parameters.onOpenDetails) parameters.onOpenDetails(props.id);
    // eslint-disable-next-line
  }, [seeMore]);

  const getIconStatus = (status: DebtsStatusPaymentEnum) => {
    switch (status) {
      case DebtsStatusPaymentEnum.Refused:
        return "danger";
      case DebtsStatusPaymentEnum.Pending:
      case DebtsStatusPaymentEnum.Created:
        return "warning";
      case DebtsStatusPaymentEnum.Paied:
      case DebtsStatusPaymentEnum.Settled:
        return "approved";

      default:
        return "";
    }
  };

  const getStatusPaymentName = (status: DebtsStatusPaymentEnum) => {
    switch (status) {
      case DebtsStatusPaymentEnum.Refused:
        return t("Refused");
      case DebtsStatusPaymentEnum.Pending:
        return t("In Analysis");
      case DebtsStatusPaymentEnum.Created:
        return t("To be completed");
      case DebtsStatusPaymentEnum.Paied:
      case DebtsStatusPaymentEnum.Settled:
        return t("Approved");
      default:
        return "";
    }
  };

  const formatValue = (value: number) => {
    return `${t("$")} ${NumberHelper.toFormatString(value, 2, "--")}`;
  };

  const toCreditCardName = () => {
    switch (props.creditcardFlag) {
      case CreditcardCompanyEnum.Mastercard:
        return "Master Card";
      case CreditcardCompanyEnum.Visa:
        return "Visa";
      case CreditcardCompanyEnum.AmericanExpress:
        return "American Express";
      case CreditcardCompanyEnum.Elo:
        return "Elo";
      case CreditcardCompanyEnum.Hipercard:
        return "Hipercard";
      default:
        return "";
    }
  };

  return (
    <>
      <BoxCardInspection>
        <StatusFlyer status={props.status}>
          <CoxIcon name={getIconStatus(props.status)} />
          <span className={"mx-3"}>{t("Payment")}</span>
          <b>{getStatusPaymentName(props.status)}</b>
          <div>
            <CoxIcon name={"shadow-corner"} />
          </div>
        </StatusFlyer>

        <Wrapper>
          <DivStatus>
            <StatusBarLateral>&nbsp;</StatusBarLateral>
          </DivStatus>
          <DivContent>
            <Content>
              <Row>
                <Col lg={"1"}>
                  <WrapperInspection>
                    <CarCirle>
                      <CoxIcon name={"car"} />
                    </CarCirle>
                  </WrapperInspection>
                </Col>
                <Col lg={"11"}>
                  <Row className={"mb-4"}>
                    <Col sm={"2"}>
                      <Label>{t("Request code")}</Label>
                      <Value>{props.protocol}</Value>
                    </Col>
                    <Col sm={"2"}>
                      <Label>{t("Plate")}</Label>
                      <Value>{props.plate}</Value>
                    </Col>
                  </Row>
                  <Row className={"mt-2"}>
                    <Col sm={"2"}>
                      <Label>{t("Part")}</Label>
                      <Value>Detran-{props.uf}</Value>
                    </Col>
                    <Col sm={"2"}>
                      <Label>{t("Debts")}</Label>
                      <Value>{props.debtsNumber}</Value>
                    </Col>
                    <Col sm={"3"}>
                      <Label>{t("Debts value")}</Label>
                      <Value>{formatValue(props.debtsValue)}</Value>
                    </Col>
                    <Col sm={"3"}>
                      <Label>{t("Total value")}</Label>
                      <Value>{formatValue(props.total)}</Value>
                    </Col>
                    {!!props.onClickContinue && (
                      <Col sm={"2"}>
                        <ButtonPrimary onClick={props.onClickContinue}>
                          {t("Continue")}
                        </ButtonPrimary>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              <WrapperDetails>
                <div
                  className={"d-flex align-items-center see-more"}
                  onClick={() => setSeemore(!seeMore)}
                >
                  <Details close={seeMore} className={"d-none d-md-block"}>
                    {t("More details")}
                  </Details>
                  <CoxIcon
                    name={!seeMore ? "arrow-circle" : "arrow-circle-closed"}
                  />
                </div>
                {(props.status === DebtsStatusPaymentEnum.Paied ||
                  props.status === DebtsStatusPaymentEnum.Settled) && (
                  <ShareItem
                    className={"d-flex align-items-center"}
                    onClick={() => setShowTicket(true)}
                  >
                    <CoxIcon name={"proof"} />
                    <Details close={false} className={"d-none d-md-block ml-2"}>
                      {t("See proof")}
                    </Details>
                  </ShareItem>
                )}
              </WrapperDetails>

              {seeMore && (
                <>
                  <hr />
                  <Row>
                    <Col>
                      <WrapperDetail>
                        <span>{t("Debts")}</span>
                        {props.showPaymentWarning && (
                          <>
                            <AdvicePaymentDebts>
                              <DivIconCox>
                                <CoxIcon name={"atention"} />
                              </DivIconCox>
                              <AdvicePaymentDebtsColor>
                                {t("WarningSettledItems")}
                              </AdvicePaymentDebtsColor>
                            </AdvicePaymentDebts>
                          </>
                        )}
                        {props.debts.map((m, i) => (
                          <DebtLineMore key={`debts-details-${props.id}-${i}`}>
                            <Row>
                              <Col sm={9}>
                                <TitleDebtMore>{m.title}</TitleDebtMore>
                              </Col>
                              <Col sm={3}>
                                <ValueDebtMore>
                                  {formatValue(m.value)}
                                </ValueDebtMore>
                              </Col>
                            </Row>
                          </DebtLineMore>
                        ))}
                        <FooterInfoPayment>
                          <Row>
                            <Col>
                              <span>{t("Date")}</span>
                              <p>
                                {format(
                                  props.date,
                                  `dd/MM/yyyy '${t("at")}' HH:mm`
                                )}
                              </p>
                            </Col>
                            <Col>
                              <span>{t("Creditcard")}</span>
                              <p>
                                {!props.creditcard
                                  ? "-"
                                  : `${toCreditCardName()} - ${t("Ending")} ${
                                      props.creditcard
                                    }`}
                              </p>
                            </Col>
                            <Col>
                              <span>{t("Payment way")}</span>
                              <p>{`${props.installmentPlan}x ${formatValue(
                                props.installmentValue
                              )}`}</p>
                            </Col>
                            <Col>
                              <span>{t("TOTAL interest")}</span>
                              <p>{formatValue(props.total)}</p>
                            </Col>
                          </Row>
                        </FooterInfoPayment>
                      </WrapperDetail>
                    </Col>
                  </Row>
                </>
              )}
            </Content>
          </DivContent>
        </Wrapper>

        <Modal
          show={showTicket}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowTicket(false)}
        >
          <ModalHeader closeButton>
            <Row className={"auto"}>
              <Col>
                <Img src={CoxHeaderLogo} />
              </Col>
              <Col>
                <Img src={KbbHeaderLogo} />
              </Col>
              <Col>
                <Img src={ExpressHeaderLogo} />
              </Col>
              <Col>
                <Img src={DtHeaderLogo} />
              </Col>
              <Col>
                <LinePart />
              </Col>
              <Col>
                <Img src={ZapayHeader} />
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <WrapperDetail ref={componentRef}>
              <HeaderTicket>
                <Row>
                  <Col className={"mb-4"}>
                    <h5>{t("Proof of payment")}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <h6>{t("Request code")}</h6>
                    <h4>{props.protocol}</h4>
                  </Col>
                  <Col xs>
                    <h6>{t("Plate")}</h6>
                    <h4>{props.plate}</h4>
                  </Col>
                  <Col xs>
                    <h6>{t("Payment date")}</h6>
                    <h4>
                      {" "}
                      {format(props.date, `dd/MM/yyyy '${t("at")}' HH:mm`)}
                    </h4>
                  </Col>
                </Row>
              </HeaderTicket>
              <ContentProof>
                <LineTitle>
                  <Row className={"mt-3"}>
                    <Col>
                      <span>{t("Debts")}</span>
                    </Col>
                    <Col className={"text-right"}>
                      <span>{t("Values")}</span>
                    </Col>
                  </Row>
                  <hr />
                </LineTitle>
                {props.debts.map((m, i) => (
                  <DebtLine key={`debts-details-modal-${props.id}-${i}`}>
                    <Row>
                      <Col sm={9}>
                        <TitleDebt>{m.title}</TitleDebt>
                      </Col>
                      <Col sm={3}>
                        <ValueDebt>{formatValue(m.value)}</ValueDebt>
                      </Col>
                    </Row>
                  </DebtLine>
                ))}
                <DebtLine className={"py-3"}>
                  <Row>
                    <Col className={"text-right"}>
                      <LabelNormal>{t("Subtotal")}</LabelNormal>
                    </Col>
                    <Col className={"text-right"} md={"2"}>
                      <LabelNormalValue>
                        {formatValue(props.debtsValue)}
                      </LabelNormalValue>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"text-right"}>
                      <LabelNormal>{t("Interest")}</LabelNormal>
                    </Col>
                    <Col className={"text-right"} md={"2"}>
                      <LabelNormalValue>
                        {formatValue(props.total - props.debtsValue)}
                      </LabelNormalValue>
                    </Col>
                  </Row>
                </DebtLine>
                <div className={"py-3"}>
                  <Row>
                    <Col className={"text-right"}>
                      <LabelTitle>{t("Total")}</LabelTitle>
                    </Col>
                    <Col className={"text-right"} md={"auto"}>
                      <TotalValueTitle>
                        {formatValue(props.total)}
                      </TotalValueTitle>
                    </Col>
                  </Row>
                </div>
              </ContentProof>

              <TagCreditCardInfo>
                <Row>
                  <Col>
                    <LabelNormal className={"mr-2"}>
                      {t("Creditcard")}
                    </LabelNormal>
                    <div className={"mr-2"}>
                      {props.creditcardFlag ===
                        CreditcardCompanyEnum.Mastercard && (
                        <Img src={MastercardLogo} />
                      )}
                      {props.creditcardFlag === CreditcardCompanyEnum.Visa && (
                        <Img src={VisaLogo} />
                      )}
                      {props.creditcardFlag ===
                        CreditcardCompanyEnum.AmericanExpress && (
                        <Img src={AmericanExpressLogo} />
                      )}
                      {props.creditcardFlag === CreditcardCompanyEnum.Elo && (
                        <Img src={EloLogo} />
                      )}
                      {props.creditcardFlag ===
                        CreditcardCompanyEnum.Hipercard && (
                        <Img src={HipercardLogo} />
                      )}
                    </div>
                    <LabelTitleBlue>{`${t("Ending")} ${
                      props.creditcard
                    }`}</LabelTitleBlue>
                  </Col>
                  <Col className={"justify-content-end"}>
                    <LabelNormal className={"mr-2"}>
                      {t("Payment type")}
                    </LabelNormal>
                    <LabelTitleBlue>{`${props.installmentPlan}x ${formatValue(
                      props.installmentValue
                    )}`}</LabelTitleBlue>
                  </Col>
                </Row>
              </TagCreditCardInfo>

              <WarnningContentPayment>
                {t("WarningProofZapay")}
              </WarnningContentPayment>
            </WrapperDetail>

            <FooterButtons>
              <Row>
                <Col>
                  <LabelPrint onClick={handlePrint}>
                    <CoxIcon name={"print"} />{" "}
                    <span className={"ml-1"}>{t("To print")}</span>
                  </LabelPrint>
                </Col>
                <Col className={"text-right"}>
                  {/* <div className={"d-flex"}> */}
                  {/* <ButtonSecondary
                      sizex={"md"}
                      onClick={handlePrint}
                      className={"mr-4"}
                    >
                      {t("Download PDF")}
                    </ButtonSecondary> 
                    <ButtonPrimary sizex={"md"} onClick={handleClickShare}>
                      {t("Share")}
                    </ButtonPrimary>
                   </div> */}
                </Col>
              </Row>
            </FooterButtons>
          </ModalBody>
        </Modal>
      </BoxCardInspection>
    </>
  );
};
