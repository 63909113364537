import {
  ICreatePasswordlessRequest,
  StorageConstant,
  TopPasswordlessKbbAtom,
} from "c4u-web-components";
import { parseISO } from "date-fns";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../constants";
import { useQuery, useSessionContext } from "../../../hooks";
import { IPasswordlessParams } from "../../../models";
import { FeedbackModal, TopMainTemplate } from "../../molecules";

export const PasswordlessTemplate: FunctionComponent = (props) => {
  const {
    usernamePawordlessContext,
    setUsernamePawordlessContext,
    genericModalMsg,
    genericErrorModal,
    hideGenericErrorModal,
    genericWarningModal,
    hideGenericWarningModal,
  } = useSessionContext();

  const { t } = useTranslation();

  const { replace, location } = useHistory();

  const { typeJorney } = useParams<IPasswordlessParams>();

  const { IsDebtProduct, IsInspectionProduct, IsTrackerProduct } = useQuery();

  useEffect(() => {
    if (typeJorney !== Definition.Passwordless && IsDebtProduct())
      replace(paths.debtsVehicle());
    if (typeJorney !== Definition.Passwordless && IsInspectionProduct())
      replace(paths.inspectionPartner());
    if (typeJorney !== Definition.Passwordless && IsTrackerProduct())
      replace(paths.trackerVehicleSelection(0));

    if (
      typeJorney === Definition.Passwordless &&
      location.pathname !== paths.debtsRegister(typeJorney) &&
      location.pathname !== paths.inspectionRegister(typeJorney) &&
      location.pathname !== paths.trackerRegister(typeJorney)
    ) {
      const dateSession = sessionStorage.getItem(
        StorageConstant.PasswordlessExpire
      );
      if (!dateSession || parseISO(dateSession) < new Date()) {
        if (IsDebtProduct()) replace(paths.debtsRegister(typeJorney));
        if (IsInspectionProduct())
          replace(paths.inspectionRegister(typeJorney));
        if (IsTrackerProduct()) replace(paths.trackerRegister(typeJorney));
      }
    }
  }, [
    typeJorney,
    replace,
    location,
    IsDebtProduct,
    IsInspectionProduct,
    IsTrackerProduct,
  ]);

  const userPasswordlessData = useMemo<ICreatePasswordlessRequest>(() => {
    const storage = sessionStorage.getItem(
      StorageConstant.PasswordlessEmailCellphone
    );
    if (storage) return JSON.parse(storage);
  }, []);

  useEffect(() => {
    if (!usernamePawordlessContext.email && userPasswordlessData)
      setUsernamePawordlessContext(userPasswordlessData);
  }, [
    usernamePawordlessContext,
    userPasswordlessData,
    setUsernamePawordlessContext,
  ]);

  return (
    <>
      <TopPasswordlessKbbAtom />
      <Container>
        <TopMainTemplate />
        {props.children}
      </Container>
      <FeedbackModal
        type={"error"}
        show={genericErrorModal}
        onHide={hideGenericErrorModal}
        title={t("Error")}
        content={genericModalMsg ?? t("GenericErrorMsg")}
      />
      <FeedbackModal
        type={"warning"}
        show={genericWarningModal}
        onHide={hideGenericWarningModal}
        title={t("Warning")}
        content={genericModalMsg ?? t("GenericWarningMsg")}
      />
    </>
  );
};
