import { ActiveItemTypeConstant } from "c4u-web-components";
import React from "react";
import { AuthTemplate } from "..";

interface IProps {
  redirectUri: string;
}

export const FenautoAuth: React.FC<IProps> = (props) => {
  return (
    <AuthTemplate
      redirectUri={props.redirectUri}
      activeMenu={ActiveItemTypeConstant.FENAUTO}
    >
      {props.children}
    </AuthTemplate>
  );
};
