import { TransferToUserModalAtom } from "c4u-web-components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useC4u, useProductsDebts, useSessionContext } from "../../../hooks";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { FeedbackModal } from "../../molecules";
import { DebtPaymentOrganism } from "../../organisms";
import { DebtsBasePage } from "./base/debts-base.page";

export const VehicleDebtsPaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, typeJorney } = useParams<IPasswordlessParams>();

  const { getListMyUsers } = useC4u();
  const { putTransferDebts } = useProductsDebts();
  const { coxUserContext } = useSessionContext();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [showChooseUserModal, setShowChooseUserModal] = useState(false);

  const header = useMemo(() => {
    if (id)
      return {
        Title: t("TitleVehicleRegister"),
        BackTo: paths.debtsPenalties(id, typeJorney),
        otherPage: true,
      } as IHeaderMainTemplate;
  }, [id, t, typeJorney]);

  useEffect(() => {
    if (coxUserContext?.isCorporate) setShowChooseUserModal(true);
  }, [coxUserContext?.isCorporate]);

  useEffect(() => {
    if (showErrorModal || showSuccessModal) setShowChooseUserModal(false);
  }, [showErrorModal, showSuccessModal]);

  return (
    <>
      {header && (
        <DebtsBasePage step={3} header={header}>
          <DebtPaymentOrganism />
        </DebtsBasePage>
      )}
      <TransferToUserModalAtom
        entryId={Number(id)}
        show={showChooseUserModal}
        onSubmitCallback={putTransferDebts}
        getUsersPagedListCallback={getListMyUsers}
        onSucessCallBack={() => setShowSuccessModal(true)}
        setShowErrorModal={setShowErrorModal}
        setErrorMsg={setErrorMsg}
        translate={t}
      />
      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => window.location.reload()}
        title={t("A problem found")}
        content={errorMsg ?? t("GenericErrorMsg")}
      />
      <FeedbackModal
        type={"success"}
        show={showSuccessModal}
        onHide={() => history.push(paths.debtsList(typeJorney))}
        title={t("Everything all right")}
        content={t("Debts payment request forwarded successfully")}
      />
    </>
  );
};
