import { useAuth0 } from "@auth0/auth0-react";
import {
  CoxIcon,
  CreditcardCompanyEnum,
  DateHelper,
  IPagedResponseBaseModel,
  PaginationControlMolecule,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import { parse, parseISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../../constants";
import {
  useDebtsContext,
  useProductsDebts,
  useSessionContext,
} from "../../../../hooks";
import {
  DebtsStatusPaymentEnum,
  DebtsVehicleModel,
  IPasswordlessParams,
  IShareProofRequest,
  PaymentCreditCardInfoModel,
  ShareProofRequest,
} from "../../../../models";
import {
  CardStatusDebtMolecule,
  FormDebtsListFilterMolecule,
  ModalPaymentProofShareMolecule,
} from "../../../molecules";
import { IFormDebtsListFilter } from "../../../molecules/debts/form-debts-list-filter/form-debts-list-filter.molecule";
import {
  DebtsListOrganismWrapper,
  FilterLabel,
} from "./debts-list.organism.style";

export const DebtsListOrganism: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation();
  const history = useHistory();

  const { getAllDebits, checkOrder, shareProof } = useProductsDebts();
  const {
    debtShareContext,
    setDebtShareContext,
    setPaymentContext,
    setDebtsContext,
    clearDebtsContexts,
  } = useDebtsContext();
  const {
    showGenericSuccessModal,
    showGenericErrorModal,
    usernamePawordlessContext,
  } = useSessionContext();

  const [debtsRegistered, setDebtsRegistered] = useState<DebtsVehicleModel[]>();
  const [debtsRegisteredAll, setDebtsRegisteredAll] =
    useState<DebtsVehicleModel[]>();
  const [debtsSettled, setDebtsSettled] = useState<
    {
      id: number;
      show: boolean;
    }[]
  >();
  const [paginationData, setPaginationData] =
    useState<IPagedResponseBaseModel>();

  const { typeJorney } = useParams<IPasswordlessParams>();

  const recordsPerPage = useMemo(() => 10, []);

  useEffect(() => {
    if (isAuthenticated || typeJorney === Definition.Passwordless) {
      clearDebtsContexts();
      setDebtsRegistered(undefined);
      getAllDebits(1, 200)
        .then(({ data }) => {
          setDebtsRegisteredAll(data);
          setDebtsSettled(
            data.map((data) => {
              return {
                id: data.id,
                show: false,
              };
            })
          );
        })
        .catch((c) => {
          setDebtsRegistered([]);
        });
    }
    // eslint-disable-next-line
  }, [isAuthenticated, typeJorney]);

  const initialValues = {
    plate: "",
    status: "",
    dateEnd: "",
    dateStart: "",
  } as IFormDebtsListFilter;

  const [filterValues, setFilterValues] =
    useState<IFormDebtsListFilter>(initialValues);

  const handleFilter = async (
    values: IFormDebtsListFilter,
    pageNumber?: number
  ) => {
    if (debtsRegisteredAll) {
      setDebtsRegistered(undefined);
      if (!pageNumber) setPaginationData(undefined);

      let debts = [...debtsRegisteredAll];
      if (values.dateEnd && !!values.dateStart) {
        const start = DateHelper.toDateHourFixed(
          parse(values.dateStart ?? "", "dd/MM/yyyy", new Date())
        );
        const end = DateHelper.toDateHourFixed(
          parse(values.dateEnd ?? "", "dd/MM/yyyy", new Date())
        );

        debts = debts.filter(
          (f) =>
            DateHelper.toDateHourFixed(parseISO(f.insertDate.toString())) >=
              start &&
            DateHelper.toDateHourFixed(parseISO(f.insertDate.toString())) <= end
        );
      }

      if (values.status) {
        debts = debts.filter(
          (f) =>
            Number(values.status) ===
            (f.status === DebtsStatusPaymentEnum.Settled
              ? DebtsStatusPaymentEnum.Paied
              : f.status)
        );
      }

      if (values.plate) {
        debts = debts.filter(
          (f) => f.plate.toLowerCase() === values.plate.toLowerCase()
        );
      }

      setTimeout(() => {
        setDebtsRegistered(
          debts.slice(
            ((pageNumber ?? 1) - 1) * recordsPerPage,
            (pageNumber ?? 1) * recordsPerPage
          )
        );
        setPaginationData({
          currentPage: pageNumber ?? 1,
          totalPages: Math.ceil(debts.length / recordsPerPage),
          recordsPerPage: recordsPerPage,
          totalRecords: debts.length,
        });
      }, 300);
    }
  };

  useEffect(() => {
    if (debtsRegisteredAll !== undefined)
      handleFilter({
        plate: "",
        status: "",
        dateEnd: "",
        dateStart: "",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtsRegisteredAll]);

  const [filter, setFilter] = useState(false);

  const handleOpenDetails = async (id: number) => {
    if (debtsSettled) {
      const order = await checkOrder(id);
      const orders = debtsSettled.filter((f) => f.id !== id);

      setDebtsSettled([
        ...orders,
        {
          id: id,
          show: order.statusPayment === DebtsStatusPaymentEnum.Paied,
        },
      ]);
    }
  };

  const handleSubmitShareForm = async (email: string) => {
    try {
      const link = `${window.location.origin}/#${paths.debtsProof(
        debtShareContext?.token ?? "",
        "false"
      )}`;

      const share = {
        email: email,
        vehicleDebtId: debtShareContext?.id,
        link: link,
      } as IShareProofRequest;

      await shareProof(new ShareProofRequest(share));
      setDebtShareContext(undefined);
      showGenericSuccessModal();
    } catch (error) {
      showGenericErrorModal();
    }
  };

  const handleShareClick = (id: number, token: string, func: () => void) => {
    setDebtShareContext({
      id: id,
      token: token,
      close: func,
    });
  };

  const toEnum = (flag: string) => {
    return flag as CreditcardCompanyEnum;
  };

  return (
    <DebtsListOrganismWrapper>
      <Row className={"mb-3"}>
        <Col>
          <FilterLabel
            onClick={() => {
              if (filter) {
                setFilterValues(initialValues);
                handleFilter(initialValues, 1);
              }
              setFilter(!filter);
            }}
          >
            {t("Filter by")}{" "}
            <CoxIcon name={!filter ? "arrow-circle" : "arrow-circle-closed"} />
          </FilterLabel>
        </Col>
      </Row>
      {filter && (
        <Row className={"mb-4"}>
          <Col>
            <FormDebtsListFilterMolecule
              onSubmit={handleFilter}
              setFilterValues={setFilterValues}
              initialValues={initialValues}
              currentValues={filterValues}
            />
          </Col>
        </Row>
      )}
      {debtsRegistered === undefined ? (
        <SpinnerCenteredAtom classNames="my-5" />
      ) : debtsRegistered.length === 0 ? (
        <div>{t("No results found")}</div>
      ) : (
        debtsRegistered?.map((m, i) => (
          <Row key={`debts-card-${i}`}>
            <Col>
              <CardStatusDebtMolecule
                id={m.id}
                token={m.token}
                protocol={m.debitsProtocol}
                plate={m.plate}
                uf={m.detranUf}
                debtsNumber={m.vehicleDebtDetails.length}
                debtsValue={m.totalDebts}
                total={m.totalDebtsInstallments}
                status={m.status}
                debts={m.vehicleDebtDetails}
                date={parseISO((m.paymentDate ?? m.insertDate).toString())}
                creditcard={m.finalCreditCard}
                creditcardFlag={toEnum(m.flagCreditCard)}
                installmentPlan={m.installmentPlan}
                installmentValue={m.installmentValue}
                showPaymentWarning={
                  debtsSettled?.find((f) => f.id === m.id)?.show
                }
                onOpenDetails={handleOpenDetails}
                onShare={handleShareClick}
                onClickContinue={
                  m.status !== DebtsStatusPaymentEnum.Created
                    ? undefined
                    : () => {
                        setDebtsContext(m);
                        setPaymentContext({
                          parcelable: m.installmentPlan,
                          email: usernamePawordlessContext.email,
                          phone: usernamePawordlessContext.cellphone,
                          name: usernamePawordlessContext.name,
                        } as PaymentCreditCardInfoModel);

                        history.push(paths.debtsPayment(m.id, typeJorney));
                      }
                }
              />
            </Col>
          </Row>
        ))
      )}

      {paginationData && paginationData.totalPages > 1 && (
        <div className="default-align">
          <PaginationControlMolecule
            translator={t}
            paginationData={paginationData}
            setPageNumber={(pageNumber) =>
              handleFilter(filterValues, pageNumber)
            }
          />
        </div>
      )}

      <ModalPaymentProofShareMolecule
        show={!!debtShareContext}
        onHide={() => setDebtShareContext(undefined)}
        onBackButton={debtShareContext?.close}
        onSubmit={handleSubmitShareForm}
      />
    </DebtsListOrganismWrapper>
  );
};
