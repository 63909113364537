import { MsgValidation, YupHelper } from "c4u-web-components";
import { addYears, parse } from "date-fns";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  IDefaultsProps,
  IDiverDataFormMoleculeProps,
} from "./driver-data-form.molecule";

export const DriverDataFormMoleculeValidation = (props: IDefaultsProps) => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "betweenDatesPtBr",
    YupHelper.betweenDatesPtBr
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );

  return Yup.object<IDiverDataFormMoleculeProps>({
    vehicleUse: Yup.string(),
    document: Yup.string()
      .required(t(MsgValidation.Required))
      .isCpfCnpjValid(t(MsgValidation.Invalid)),
    name: Yup.string()
      .required(t(MsgValidation.Required))
      .test("complete-name", t(MsgValidation.Invalid), function (value) {
        const words = value?.split(" ")?.length;
        return !!(words && words > 1);
      }),
    driversBetween18and26: Yup.string().required(t(MsgValidation.Required)),
    storedClosedGarage: Yup.string().required(t(MsgValidation.Required)),
    email: Yup.string()
      .required(t(MsgValidation.Required))
      .email(t(MsgValidation.Invalid)),
    cellphone: Yup.string()
      .required(t(MsgValidation.Required))
      .test("Cellphone", t(MsgValidation.Invalid), (value) => {
        return value?.replace(/[^\d]/g, "").length === 11;
      }),
    birthdate: Yup.string().when("document", {
      is: (v) => YupHelper.cpfValid(v),
      then: Yup.string()
        .nullable()
        .required(t(MsgValidation.Required))
        .test("date-valid-driver", t(MsgValidation.Invalid), function (value) {
          const dateValue = parse(
            value ?? "".toString(),
            "dd/MM/yyyy",
            new Date()
          );
          if (this.parent.licenseTime === "2_anos") {
            const date = addYears(new Date(), -18);
            return date > dateValue;
          } else {
            const date = addYears(new Date(), -20);
            return date > dateValue;
          }
        }),
      otherwise: Yup.string().notRequired(),
    }),
    gender: Yup.string().when("document", {
      is: (v) => YupHelper.cpfValid(v),
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
    maritalStatus: Yup.string().when("document", {
      is: (v) => YupHelper.cpfValid(v),
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
    legalVehicleOwn: Yup.string().when("vehicleUse", {
      is: (v) => [props.taxi, props.appDriver].includes(v),
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
    vehicleUseToWork: Yup.string().when("vehicleUse", {
      is: (v) => ![props.taxi, props.appDriver, props.comercial].includes(v),
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
    storedClosedGarageAtHome: Yup.string().when("storedClosedGarage", {
      is: (v) => v === props.yes,
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
    storedClosedGarageAtWork: Yup.string().when(
      ["storedClosedGarage", "vehicleUseToWork"],
      {
        is: (storedClosedGarage, vehicleUseToWork) =>
          storedClosedGarage === props.yes && vehicleUseToWork === props.yes,
        then: Yup.string().nullable().required(t(MsgValidation.Required)),
        otherwise: Yup.string().notRequired(),
      }
    ),
    storedClosedGarageAtSchool: Yup.string().when("storedClosedGarage", {
      is: (v) => v === props.yes,
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
    mainDriver: Yup.string().when("storedClosedGarage", {
      is: (v) => YupHelper.cpfValid(v),
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),

    driverPersonalDocument: Yup.string()
      .when("document", {
        is: (v) => YupHelper.cnpjValid(v),
        then: Yup.string().nullable().required(t(MsgValidation.Required)),
        otherwise: Yup.string().notRequired(),
      })
      .when("mainDriver", {
        is: (v) => v === props.no,
        then: Yup.string()
          .nullable()
          .required(t(MsgValidation.Required))
          .isCpfCnpjValid(t(MsgValidation.Invalid)),
        otherwise: Yup.string().notRequired(),
      }),
    driverName: Yup.string()
      .when("document", {
        is: (v) => YupHelper.cnpjValid(v),
        then: Yup.string()
          .nullable()
          .required(t(MsgValidation.Required))
          .test("complete-name", t(MsgValidation.Invalid), function (value) {
            const words = value?.split(" ")?.length;
            return !!(words && words > 1);
          }),
        otherwise: Yup.string().notRequired(),
      })
      .when("mainDriver", {
        is: (v) => v === props.no,
        then: Yup.string()
          .nullable()
          .required(t(MsgValidation.Required))
          .test("complete-name", t(MsgValidation.Invalid), function (value) {
            const words = value?.split(" ")?.length;
            return !!(words && words > 1);
          }),
        otherwise: Yup.string().notRequired(),
      }),
    driverBirthdate: Yup.string()
      .when("document", {
        is: (v) => YupHelper.cnpjValid(v),
        then: Yup.string().nullable().required(t(MsgValidation.Required)),
        otherwise: Yup.string().notRequired(),
      })
      .when("mainDriver", {
        is: (v) => v === props.no,
        then: Yup.string()
          .nullable()
          .required(t(MsgValidation.Required))
          .test(
            "date-valid-driver",
            t(MsgValidation.Invalid),
            function (value) {
              const dateValue = parse(
                value ?? "".toString(),
                "dd/MM/yyyy",
                new Date()
              );
              if (this.parent.licenseTime === "2_anos") {
                const date = addYears(new Date(), -18);
                return date > dateValue;
              } else {
                const date = addYears(new Date(), -20);
                return date > dateValue;
              }
            }
          ),
        otherwise: Yup.string().notRequired(),
      }),
    driverGender: Yup.string()
      .when("document", {
        is: (v) => YupHelper.cnpjValid(v),
        then: Yup.string().nullable().required(t(MsgValidation.Required)),
        otherwise: Yup.string().notRequired(),
      })
      .when("mainDriver", {
        is: (v) => v === props.no,
        then: Yup.string().nullable().required(t(MsgValidation.Required)),
        otherwise: Yup.string().notRequired(),
      }),
    driverMaritalStatus: Yup.string()
      .when("document", {
        is: (v) => YupHelper.cnpjValid(v),
        then: Yup.string().nullable().required(t(MsgValidation.Required)),
        otherwise: Yup.string().notRequired(),
      })
      .when("mainDriver", {
        is: (v) => v === props.no,
        then: Yup.string().nullable().required(t(MsgValidation.Required)),
        otherwise: Yup.string().notRequired(),
      }),
    driverLink: Yup.string()
      .when("document", {
        is: (v) => YupHelper.cnpjValid(v),
        then: Yup.string().nullable().required(t(MsgValidation.Required)),
        otherwise: Yup.string().notRequired(),
      })
      .when("mainDriver", {
        is: (v) => v === props.no,
        then: Yup.string().nullable().required(t(MsgValidation.Required)),
        otherwise: Yup.string().notRequired(),
      }),
    licenseTime: Yup.string().nullable().required(t(MsgValidation.Required)),
  });
};
