import React from "react";
import { useTranslation } from "react-i18next";
import {
  DescriptionInfo,
  IconCircle,
  TitleInfo,
  WrapperDataControl,
} from "./checkout-vehicle-info.molecule.style";
import { CoxIcon } from "c4u-web-components";
import { Col, Row } from "react-bootstrap";

interface IProps {
  plate: string;
  carName: string;
}

export const CheckoutVehicleInfoMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <WrapperDataControl>
        <Row>
          <Col xs={"auto"} className={"pr-0"}>
            <IconCircle>
              <CoxIcon name={"car-front"} fillColor={"#003468"} />
            </IconCircle>
          </Col>
          <Col xs>
            <Row>
              <Col>
                <TitleInfo>{t("Vehicle registered")}</TitleInfo>
              </Col>
            </Row>
            <Row className={"mb-2"}>
              <Col>
                <DescriptionInfo>{props.carName}</DescriptionInfo>
              </Col>
            </Row>
            <Row>
              <Col>
                <TitleInfo>{t("Plate")}</TitleInfo>
              </Col>
            </Row>
            <Row>
              <Col>
                <DescriptionInfo>{props.plate}</DescriptionInfo>
              </Col>
            </Row>
          </Col>
        </Row>
      </WrapperDataControl>
    </>
  );
};
