import { useContext } from "react";
import { DebtsContext } from "../../contexts";
import {
  DebtsVehicleModel,
  InstallmentsModel,
  PaymentCreditCardInfoModel,
  VehicleAllDebtsModel,
} from "../../models";

export interface IDebtsContextProps {
  debtsContext: DebtsVehicleModel | null | undefined;
  setDebtsContext: React.Dispatch<
    React.SetStateAction<DebtsVehicleModel | null | undefined>
  >;
  debtShareContext:
    | { id: number; token: string; close: () => void }
    | undefined;
  setDebtShareContext: React.Dispatch<
    React.SetStateAction<
      { id: number; token: string; close: () => void } | undefined
    >
  >;
  paymentContext: PaymentCreditCardInfoModel | undefined;
  setPaymentContext: React.Dispatch<
    React.SetStateAction<PaymentCreditCardInfoModel | undefined>
  >;
  plotsContext: InstallmentsModel[] | undefined;
  setPlotsContext: React.Dispatch<
    React.SetStateAction<InstallmentsModel[] | undefined>
  >;
  vehicleDebtsContext: VehicleAllDebtsModel;
  setVehicleDebtsContext: React.Dispatch<
    React.SetStateAction<VehicleAllDebtsModel | undefined>
  >;
  clearDebtsContexts: () => void;
}

export const useDebtsContext = () => {
  const context = useContext<IDebtsContextProps>(DebtsContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
