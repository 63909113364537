import React from "react";
import { Modal } from "react-bootstrap";
import {
  ModalBody,
  Title,
  Content,
  ModalHeader,
  ContentModalMsg,
} from "./report-list-modal.molecule.style";

interface IProps {
  show: boolean;
  onHide: () => void;
  onClickButton?: () => void;
  title: string;
  textButton?: string;
  content?: string;
}

export const ReportListModalMolecule: React.FC<IProps> = (props) => {

  return (
    <>
      <Modal
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.onHide}
      >
        <ModalHeader closeButton></ModalHeader>
        <ModalBody>
          <Title>{props.title}</Title>
          <Content>
            {props.children ? (
              props.children
            ) : (
              <ContentModalMsg>{props.content}</ContentModalMsg>
            )}
          </Content>
        </ModalBody>
      </Modal>
    </>
  );
};
