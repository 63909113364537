import styled from "styled-components";

const TooltipWrapper = styled.div`
  display: flex;
  align-items: baseline;
  div {
    margin-left: 4px;
  }
`;

const SelectVehicleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ContentLoader = styled.div`
  display: flex;
  gap: 10px;

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const SelectVehicleDescription = styled.div`
  font-size: 13px;
  color: ${(props) => props.theme.colors.BlueNavy};
  font-family: Helvetica;
  line-height: 28px;
  font-weight: bold;
`;

const ButtonStyled = styled.button`
  border: none;
  background: inherit;
  margin-bottom: 10px;
  margin: 0px;
  padding: 0px;

  font-family: Helvetica;
  font-size: 16px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #0799f8;
`;

const VehicleVersionsDiv = styled.div`
  display: flex;
  align-items: baseline;
  margin: 12px 0;

  input,
  label {
    cursor: pointer;
  }
`;

export {
  TooltipWrapper,
  SelectVehicleDescription,
  ButtonStyled,
  SelectVehicleDiv,
  ContentLoader,
  VehicleVersionsDiv,
};
