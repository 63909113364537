import { NumberHelper } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BoxCheck,
  BoxDebit,
  BoxDebitDescription,
  BoxDebitInformation,
  BoxDebitValue,
  DebitInformation,
  DebitName,
  RowWrapperDebit,
  WrapperBoxDebit,
} from "./debit-detail.molecule.style";

interface IProps {
  id: string;
  checked: boolean;
  disableCheck?: boolean;
  name: string;
  description: string;
  value: number;
  status: string;
  onCheck?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const DebitDetail: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => setProps(parameters), [parameters]);

  return (
    <RowWrapperDebit>
      <Col>
        <WrapperBoxDebit>
          <Row>
            <Col sm={"1"} className={"pr-0"}>
              <BoxCheck>
                <Form.Check
                  value={props.id}
                  checked={props.checked}
                  onChange={parameters.onCheck}
                  disabled={props.disableCheck}
                  id={props.id}
                />
              </BoxCheck>
            </Col>
            <Col sm={'7'}>
              <BoxDebitDescription>
                <DebitName>{props.name}</DebitName>
                <DebitInformation>{props.description}</DebitInformation>
              </BoxDebitDescription>
            </Col>
            <Col sm={4}>
              <BoxDebit>
                <BoxDebitValue>{`${t("$")} ${NumberHelper.toFormatString(
                  props.value,
                  2,
                  "--"
                )}`}</BoxDebitValue>
                <BoxDebitInformation>{props.status}</BoxDebitInformation>
              </BoxDebit>
            </Col>
          </Row>
        </WrapperBoxDebit>
      </Col>
    </RowWrapperDebit>
  );
};
