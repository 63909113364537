import { MsgValidation, TypesHelper, YupHelper } from "c4u-web-components";
import { differenceInDays } from "date-fns";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IFormDebtsListFilter } from "./form-debts-list-filter.molecule";

export const FormDebtsListFilterValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDatePtBrValid",
    YupHelper.isDatePtBrValid
  );

  const msgDtInvalid = t(MsgValidation.Date);

  return Yup.object<IFormDebtsListFilter>({
    dateStart: Yup.string()
      .notRequired()
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, msgDtInvalid)
      .test("date-valid", t("Invalid period"), function (value) {
        const date = TypesHelper.toDateNull(value);

        const dt = this.resolve(Yup.ref("dateEnd"));
        const dateEnd = TypesHelper.toDateNull(dt);

        if (!dateEnd) return true;

        if (date) {
          const days = differenceInDays(dateEnd, date);
          return days >= 0;
        }

        return false;
      }),
    dateEnd: Yup.string()
      .notRequired()
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, msgDtInvalid)
      .test("date-valid", t("Invalid period"), function (value) {
        const dateEnd = TypesHelper.toDateNull(value);

        const dt = this.resolve(Yup.ref("dateStart"));
        const date = TypesHelper.toDateNull(dt);

        if (!dateEnd) return true;

        if (date) {
          const days = differenceInDays(dateEnd, date);
          return days >= 0;
        }

        return false;
      }),
    plate: Yup.string(),
    status: Yup.string(),
  });
};
