import React, { useEffect, useState } from "react";
import {
  FormikControlAtom as FormikControlAtomComponents,
  FormikControlAtomProps,
  theme,
} from "c4u-web-components";
import { useTranslation } from "react-i18next";
import { useTrackerContext } from "../../../hooks";

interface IProps extends Omit<FormikControlAtomProps, "translate"> {}

export const FormikControlIturanAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { originInsuranceContext } = useTrackerContext();

  const [Color, setColor] = useState<string>();

  const { children, disabled, ...FormikProps } = props;

  useEffect(() => {
    if (originInsuranceContext) {
      if (
        props.formik.values[props.property] &&
        !["checkbox", "radio"].includes(props.type ?? "")
      ) {
        setColor("highlight-empty");
      } else {
        if (
          !props.formik.initialValues[props.property] &&
          !["checkbox", "radio"].includes(props.type ?? "")
        ) {
          setColor("highlight-empty-no");
        } else {
          setColor("");
        }
      }
    }
  }, [originInsuranceContext, props]);

  return (
    <FormikControlAtomComponents
      translate={t}
      disabled={props.disabled}
      {...FormikProps}
      className={Color}
      autoComplete={props.property}
      borderColor={theme.colors.DarkGrayOpacity}
      borderColorError={theme.colors.Validation.Invalid}
    >
      {children}
    </FormikControlAtomComponents>
  );
};
