import styled from "styled-components";

const PowerBIEmbedDiv = styled.div`
  div {
    width: 1140px;
    height: 541.25px;
  }
`;

export { PowerBIEmbedDiv };
