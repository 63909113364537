import styled from "styled-components";

export const WrapperBuyerInformationPage = styled.div`
  max-width: 643px;
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  margin-right: calc(33% - 40px);

  gap: 65px;
`;

export const WrapperBuyerVehiclesCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;
