import React from "react";
import { Modal } from "react-bootstrap";
import {
  ModalBody,
  Title,
  Icon,
  Content,
  ModalHeader,
  ContentModalMsg,
  WrapperButtonsConfirm,
} from "./feedback-modal.molecule.style";
import { useTranslation } from "react-i18next";
import { ButtonSecondary, ButtonPrimary, CoxIcon } from "c4u-web-components";

interface IProps {
  type: "success" | "error" | "warning" | "confirm";
  show: boolean;
  onHide: () => void;
  onClickButton?: () => void;
  title: string;
  textButton?: string;
  content?: string | React.ReactElement;
  loading?: boolean;
  sizex?: "sm" | "md" | "lg";
  sizey?: "thin" | "normal";
}

export const FeedbackModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.onHide}
      >
        <ModalHeader closeButton></ModalHeader>
        <ModalBody>
          {props.type !== "confirm" && (
            <Icon>
              {props.type === "success" && (
                <CoxIcon name="checked" width={32} height={32} />
              )}
              {props.type === "error" && (
                <CoxIcon name="danger-color" width={32} height={32} />
              )}
              {props.type === "warning" && (
                <CoxIcon name="warning-color" width={32} height={32} />
              )}
            </Icon>
          )}
          <Title>{props.title}</Title>
          <Content>
            {props.children ? (
              props.children
            ) : (
              <ContentModalMsg>{props.content}</ContentModalMsg>
            )}
          </Content>
          <div className={"text-center"}>
            {props.type === "success" && (
              <ButtonPrimary
                onClick={
                  props.onClickButton ? props.onClickButton : props.onHide
                }
                sizex={props.sizex ?? "md"}
                sizey={props.sizey ?? "normal"}
              >
                {props.textButton ? props.textButton : t("OK")}
              </ButtonPrimary>
            )}
            {props.type === "error" && (
              <ButtonSecondary
                onClick={
                  props.onClickButton ? props.onClickButton : props.onHide
                }
                sizex={props.sizex ?? "md"}
                sizey={props.sizey ?? "normal"}
              >
                {props.textButton ? props.textButton : t("OK")}
              </ButtonSecondary>
            )}
            {props.type === "warning" && (
              <ButtonSecondary
                onClick={
                  props.onClickButton ? props.onClickButton : props.onHide
                }
                sizex={props.sizex ?? "md"}
                sizey={props.sizey ?? "normal"}
              >
                {props.textButton ? props.textButton : t("OK")}
              </ButtonSecondary>
            )}
            {props.type === "confirm" && (
              <WrapperButtonsConfirm>
                <ButtonSecondary
                  onClick={props.onHide}
                  sizex={props.sizex ?? "md"}
                  sizey={props.sizey ?? "normal"}
                  disabled={!!props.loading}
                >
                  {t("Cancel")}
                </ButtonSecondary>
                <ButtonPrimary
                  onClick={
                    props.onClickButton ? props.onClickButton : props.onHide
                  }
                  sizex={props.sizex ?? "md"}
                  sizey={props.sizey ?? "normal"}
                  loading={!!props.loading}
                >
                  {props.textButton ? props.textButton : t("OK")}
                </ButtonPrimary>
              </WrapperButtonsConfirm>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
