import { TypesHelper } from "c4u-web-components";

export interface IShareProofRequest {
  email?: string;
  link?: string;
  vehicleDebtId?: number;
}

export class ShareProofRequest {
  email: string | null;
  link: string | null;
  vehicleDebtId: number | null;

  constructor({ email, link, vehicleDebtId }: IShareProofRequest) {
    this.email = TypesHelper.toStringNull(email);
    this.link = TypesHelper.toStringNull(link);
    this.vehicleDebtId = TypesHelper.toNumberNull(vehicleDebtId);
  }
}
