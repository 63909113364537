import axios from "axios";

const ManheinTokenHttp = axios.create({
  baseURL: process.env.REACT_APP_API_MANHEIN_URL,
  timeout: 600000,
});

const manheimTokenClientConfig = {
  connection: process.env.REACT_APP_AUTH0_MANHEIM_CONNECTION,
  domain: process.env.REACT_APP_AUTH0_MANHEIM_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_MANHEIM_CLIENTID,
  audience: process.env.REACT_APP_AUTH0_MANHEIM_AUDIENCE,
};

export { ManheinTokenHttp, manheimTokenClientConfig };
