import { Img, VehicleDebtOriginEnum } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  useDebtsContext,
  useProductsDebts,
  useSessionContext,
} from "../../../../hooks";
import {
  DebtsPaymentRequest,
  DebtsStatusPaymentEnum,
  IDebtsPaymentRequest,
  InstallmentsModel,
  IPasswordlessParams,
} from "../../../../models";
import { Zapay } from "../../../assets";
import {
  DebitConfirmationPaymentMolecule,
  FeedbackModal,
} from "./../../../molecules";
import {
  TitlePageConfirmation,
  DescriptionPageConfirmation,
  InPartner,
} from "./debt-confirmation.organism.style";

export const DebtConfirmationOrganism: React.FC = () => {
  const { t } = useTranslation();

  const { paymentContext, plotsContext, debtsContext, vehicleDebtsContext } =
    useDebtsContext();

  const {
    handleFormikError,
    showGenericWarningModal,
    showGenericErrorModal,
    riskifiedIdContext,
    handleEventGTM,
  } = useSessionContext();

  const { payDebts } = useProductsDebts();
  const { id, typeJorney } = useParams<IPasswordlessParams>();
  const history = useHistory();

  const [plot, setPlot] = useState<InstallmentsModel>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [finishingLoading, setFinishingLoading] = useState(false);
  const [debts, setDebts] =
    useState<{ description: string; value: number }[]>();

  const setErrors = useCallback(
    (erros: any) => {
      try {
        let msg = "";
        Object.keys(erros).forEach((prop) => {
          const value = t("Property") + " " + prop + ": " + erros[prop];
          msg = msg + value + "\n";
        });
        showGenericWarningModal(msg);
      } catch (error) {
        showGenericErrorModal();
      }
    },
    [showGenericErrorModal, showGenericWarningModal, t]
  );

  useEffect(() => {
    if (plotsContext) {
      setPlot(
        plotsContext.find((f) => f.installments === paymentContext?.parcelable)
      );
    }
    // eslint-disable-next-line
  }, [plotsContext]);

  useEffect(() => {
    if (vehicleDebtsContext && debtsContext) {
      const debtsFilter = vehicleDebtsContext.debts.filter((f) =>
        debtsContext.vehicleDebtDetails.map((m) => m.debtId).includes(f.id)
      );

      setDebts(
        debtsFilter.map((m) => {
          return {
            description: `${m.title}`,
            value: m.amount,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [plotsContext, debtsContext]);

  useEffect(() => {
    handleEventGTM({
      event: "begin_checkout",
      ecommerce: {
        value: `${debtsContext?.totalDebtsInstallments}`,
        currency: "BRL",
        items: {
          item_name: debtsContext?.vehicleDebtDetails.map((item) => item.title),
          item_id: debtsContext?.vehicleDebtDetails.map((item) => item.debtId),
          price: debtsContext?.totalDebtsInstallments,
          currency: "BRL",
          affiliation: "Débitos",
          item_category: "Documentação",
          item_list_name: "Confirmação",
          quantity: 1,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtsContext]);

  const onFinish = useCallback(async () => {
    try {
      if (!debtsContext || !paymentContext) return;

      const request = {
        id: debtsContext.id,
        email: paymentContext.email,
        phone: paymentContext.phone,
        name: paymentContext.name,
        document: paymentContext.personalDocument,
        installmentValue: plot?.amount,
        payment: {
          protocol: vehicleDebtsContext.protocol,
          state: debtsContext.detranUf,
          installmentPlan: paymentContext.parcelable,
          ids: debtsContext.vehicleDebtDetails.map((m) => m.debtId),
          clientDetails: {
            cartToken: riskifiedIdContext,
          },
          card: {
            brand: paymentContext.flag,
            cvv: paymentContext.cvv,
            expirationDate: `${paymentContext.creditCardmonthValidate}${
              paymentContext.creditCardyearValidate?.slice(2, 4) ?? ""
            }`,

            holder: paymentContext.creditCardName,
            number: paymentContext.creditCardNumber,
            billingAddress: {
              address: paymentContext.street,
              city: paymentContext.city,
              neighborhood: paymentContext.neighborhood,
              number: paymentContext.number,
              zipCode: paymentContext.zipCode,
            },
          },
        },
      } as IDebtsPaymentRequest;

      const response = await payDebts(new DebtsPaymentRequest(request));
      handleEventGTM({
        event: "purchase",
        ecommerce: {
          transaction_id: `${debtsContext.id}`,
          value: `${debtsContext?.totalDebtsInstallments}`,
          tax: `${
            debtsContext?.totalDebtsInstallments - debtsContext.totalDebts
          }`,
          currency: "BRL",
          items: {
            item_name: debtsContext?.vehicleDebtDetails.map(
              (item) => item.title
            ),
            item_id: debtsContext?.vehicleDebtDetails.map(
              (item) => item.debtId
            ),
            price: debtsContext?.totalDebtsInstallments,
            currency: "BRL",
            affiliation: "Débitos",
            item_category: "Documentação",
            item_list_name: "Confirmação",
            quantity: 1,
          },
        },
      });
      if (response.status === DebtsStatusPaymentEnum.Paied) {
        setShowSuccessModal(true);
        history.push(paths.debtsList(typeJorney));
      } else if (response.status === DebtsStatusPaymentEnum.Pending) {
        setShowPendingModal(true);
        history.push(paths.debtsList(typeJorney));
      } else {
        showGenericWarningModal(t("AdvicePaymentRefused"));
        history.push(paths.debtsPayment(id, typeJorney));
      }
    } catch (error) {
      const msg = handleFormikError(error, setErrors);
      if (msg) showGenericWarningModal(msg);
      history.push(paths.debtsPayment(id, typeJorney));
    } finally {
      setFinishingLoading(false);
    }
  }, [
    debtsContext,
    handleEventGTM,
    handleFormikError,
    history,
    id,
    payDebts,
    paymentContext,
    plot?.amount,
    riskifiedIdContext,
    setErrors,
    showGenericWarningModal,
    t,
    typeJorney,
    vehicleDebtsContext.protocol,
  ]);

  useEffect(() => {
    if (finishingLoading) onFinish();
    // eslint-disable-next-line
  }, [finishingLoading]);

  useEffect(() => {
    if (!debtsContext) history.replace(paths.debtsPenalties(id, typeJorney));
    // eslint-disable-next-line
  }, [debtsContext]);

  return (
    <>
      <FeedbackModal
        show={showPendingModal}
        onHide={() => setShowPendingModal(false)}
        title={
          debtsContext?.origin === VehicleDebtOriginEnum.Renave
            ? t("RenaveProcessedPayment")
            : t("Congratulations! Your payment is being processed")
        }
        type={"success"}
        content={t("AdvicePaymentPending")}
        textButton={"OK"}
        onClickButton={() => setShowPendingModal(false)}
      />
      <FeedbackModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        title={t("Congratulations! Your payment has been approved")}
        type={"success"}
        content={t("AdvicePaymentApproved")}
        textButton={"OK"}
        onClickButton={() => setShowSuccessModal(false)}
      />
      {!!debtsContext && (
        <>
          <TitlePageConfirmation>{t("Confirmation")}</TitlePageConfirmation>
          <DescriptionPageConfirmation>
            {t("WarningTerms")}
          </DescriptionPageConfirmation>
          <DebitConfirmationPaymentMolecule
            plate={debtsContext?.plate}
            renavam={debtsContext?.renavam}
            totalDebts={debtsContext?.totalDebts}
            date={new Date()}
            plot={paymentContext?.parcelable ?? 0}
            plotValue={plot?.amount ?? 0}
            zapayTaxe={plot?.fee ?? 0}
            totalValue={plot?.totalAmount ?? 0}
            debts={debts ?? []}
            onFinishing={() => setFinishingLoading(true)}
            loading={finishingLoading}
          />
          <InPartner>
            <span>{t("in partner with")}</span>
            <Img className={"ml-1"} src={Zapay} />
          </InPartner>
        </>
      )}
    </>
  );
};
