import { useAuth0 } from "@auth0/auth0-react";
import { AxiosInstance } from "axios";
import { StorageConstant } from "c4u-web-components";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../..";
import { Definition, paths } from "../../../../constants";
import {
  ConflictRequestError,
  GeneralRequestError,
  IPasswordlessParams,
  RequestError,
} from "../../../../models";

export const useRequest = (
  http: AxiosInstance,
  path: string,
  headersParam: any = null
) => {
  const { getAccessTokenSilently } = useAuth0();

  const { replace } = useHistory();

  const { typeJorney } = useParams<IPasswordlessParams>();

  const { IsDebtProduct, IsInspectionProduct, IsTrackerProduct } = useQuery();

  const getAccessToken = useCallback(async () => {
    if (typeJorney === Definition.Passwordless) {
      return sessionStorage.getItem(StorageConstant.PasswordlessToken);
    } else {
      for (let i = 0; i < 10; i++) {
        try {
          return await getAccessTokenSilently({
            audience: "https://coxpravoce.com.br",
            scope: "kbb products",
          });
        } catch (error) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    }

    return "";
  }, [getAccessTokenSilently, typeJorney]);

  const buildUrl = (url: string) => {
    return url ? `${path}/${url}` : path;
  };

  const buildHeaders = async (customHeaders = {}) => {
    const token = await getAccessToken();
    const headers = headersParam
      ? headersParam
      : {
          Authorization: `Bearer ${token}`,
          "Accept-Language": "pt-BR,pt;q=0.9",
          "Content-Type": "application/json",
          "Accept": "application/json",
        };

    return { ...headers, ...customHeaders };
  };

  const handleErrors = (e: any): void => {
    if (e?.response && e.response?.status === 412) {
      throw new RequestError(e.response.data);
    } else if (e?.response && e.response?.status === 409) {
      throw new ConflictRequestError(e.response.data);
    } else if (
      e?.response?.status === 401 &&
      typeJorney === Definition.Passwordless
    ) {
      if (IsDebtProduct()) replace(paths.debtsRegister(typeJorney));
      if (IsInspectionProduct()) replace(paths.inspectionRegister(typeJorney));
      if (IsTrackerProduct()) replace(paths.trackerRegister(typeJorney));
    } else {
      console.log("General error", e);
      throw new GeneralRequestError(e);
    }
  };

  const callApi = async (
    url: string,
    method: string,
    config: any,
    data: any = null
  ) => {
    const requestConfig = config;
    requestConfig.url = buildUrl(url);
    requestConfig.headers = await buildHeaders(config.headers);
    requestConfig.data = data;
    requestConfig.method = method;
    try {
      return await http.request(requestConfig);
    } catch (e) {
      handleErrors(e);
    }
  };

  return {
    get: async (url: string, config = {}): Promise<any> =>
      callApi(url, "GET", config),
    delete: async (url: string, config = {}): Promise<any> =>
      callApi(url, "DELETE", config),
    put: async (url: string, data: any, config = {}): Promise<any> =>
      callApi(url, "PUT", config, data),
    post: async (url: string, data: any, config = {}): Promise<any> =>
      callApi(url, "POST", config, data),
  };
};
