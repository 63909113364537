import { Row } from "react-bootstrap";
import styled from "styled-components";

const RowContent = styled(Row)`
  @media (max-width: 992px) {
    margin: 0 -15px 0 -15px;
  }
  margin: 0 -15px 0 86px;
`;

const DivButtonAdvance = styled.div`
  text-align: right;
  margin: 32px 0;
`;

const CetOnCombo = styled.div`
  position: relative;
  span {
    text-transform: uppercase;
    position: absolute;
    font-size: 10px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.Dark};
    top: 12px;
    right: 28px;
    height: 16px;
  }
`;

const DivLoadingPaymentPage = styled.div`
  color: ${(props) => props.theme.colors.BlueNavy};
  margin-top: 10px;
  .title {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: ${(props) => props.theme.colors.BlueNavy};
    margin: 10px auto;
  }
  .description {
    color: ${(props) => props.theme.colors.Dark};
    max-width: 600px;
    margin: auto;
    text-align: center;
  }
`;

export { RowContent, DivButtonAdvance, CetOnCombo, DivLoadingPaymentPage };
