import { useAuth0 } from "@auth0/auth0-react";
import {
  ButtonPrimary,
  ButtonSecondary,
  ITypeAheadFilterBy,
  NumberHelper,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import { parseISO } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../../constants";
import {
  useDebtsContext,
  useProductsDebts,
  useSessionContext,
} from "../../../../hooks";
import {
  DebtModel,
  DebtsRegisterRequest,
  DebtsVehicleModel,
  IDebtsRegisterRequest,
  InstallmentsModel,
  IPasswordlessParams,
  IVehicleDebtDetailModel,
  PaymentCreditCardInfoModel,
} from "../../../../models";
import { DebitDetail, DebitPlots } from "../../../molecules";
import {
  DescriptionPage,
  DivLoading,
  TitlePage,
  TotalDebitsSelected,
} from "./vehicle-debts.organism.style";

interface IProps {
  filterBy?: ITypeAheadFilterBy;
}

export const VehicleDebtsOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, typeJorney } = useParams<IPasswordlessParams>();

  const { isAuthenticated } = useAuth0();
  const { getVehicleDebits, getInstallments, getDebit, saveDebts } =
    useProductsDebts();

  const {
    handleFormikError,
    showGenericWarningModal,
    showGenericErrorModal,
    usernamePawordlessContext,
    handleEventGTM,
  } = useSessionContext();

  const {
    debtsContext,
    setDebtsContext,
    setPaymentContext,
    setPlotsContext,
    setVehicleDebtsContext,
  } = useDebtsContext();

  const [installments, setInstallments] = useState<InstallmentsModel[]>();
  const [vehicleDebts, setVehicleDebts] = useState<DebtModel[]>();
  const [protocolaVehicleDebts, setProtocolVehicleDebts] = useState<string>();
  const [idsDebts, setIdsDebts] = useState<string[]>([]);
  const [idsDebtsDisabled, setIdsDebtsDisabled] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [plot, setPlot] = useState<number>();
  const [msgError, setMsgError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadDebits = useCallback(() => {
    getVehicleDebits(Number(id))
      .then((t) => {
        if (t.debts) {
          setVehicleDebts(t.debts);
          setVehicleDebtsContext(t);
          setProtocolVehicleDebts(t.protocol);
        } else {
          setVehicleDebts([]);
          setInstallments([]);
        }
      })
      .catch((e) => {
        setVehicleDebts([]);
        setInstallments([]);
        const msg = handleFormikError(e, undefined, undefined, false);
        if (msg) setMsgError(msg);
        else setMsgError(t("DetranNotAvaiable"));
      });
  }, [getVehicleDebits, handleFormikError, id, setVehicleDebtsContext, t]);

  useEffect(() => {
    if (isAuthenticated || typeJorney === Definition.Passwordless) loadDebits();
    // eslint-disable-next-line
  }, [isAuthenticated, typeJorney]);

  useEffect(() => {
    if (
      !!id &&
      !debtsContext &&
      (isAuthenticated || typeJorney === Definition.Passwordless)
    ) {
      getDebit(Number(id)).then((t) => {
        setDebtsContext(t);
      });
    }
    // eslint-disable-next-line
  }, [id, debtsContext, isAuthenticated, typeJorney]);

  useEffect(() => {
    if (protocolaVehicleDebts)
      if (idsDebts.length > 0)
        getInstallments(protocolaVehicleDebts, idsDebts)
          .then((t) => {
            setInstallments(t);
          })
          .catch((c) => {
            const msg = handleFormikError(c);
            if (msg?.startsWith("Existem débitos")) setInstallments(undefined);
            else setInstallments([]);
          });
      else setInstallments([]);
    setPlot(undefined);
    // eslint-disable-next-line
  }, [protocolaVehicleDebts, idsDebts]);

  const handleCheckPlot = useCallback((event: any) => {
    if (event.target.checked) {
      setPlot(Number(event.target.value));
    } else {
      setPlot(undefined);
    }
  }, []);

  const handleCheck = useCallback(
    (event?: any) => {
      const debit = vehicleDebts?.find((f) => f.id === event.target.value);

      handleEventGTM({
        event: "select_item",
        ecommerce: {
          items: {
            item_name: debit?.title,
            item_id: debit?.id,
            price: debit?.amount,
            currency: "BRL",
            affiliation: "Débitos",
            item_category: "Documentação",
            item_list_name: "Débitos",
            index: [debit],
            quantity: [debit],
          },
        },
      });

      const dependsIds = debit?.dependsOn ?? [];

      const distinctIds = debit?.distinct ?? [];

      if (event.target.checked) {
        let ids = [...idsDebts, event.target.value];
        dependsIds?.forEach((f) => ids.push(f));
        setIdsDebts(ids);

        const idsDisabled = [...idsDebtsDisabled, ...dependsIds];
        setIdsDebtsDisabled([...idsDisabled, ...distinctIds]);
      } else {
        let itemsToRemove = idsDebtsDisabled.filter(
          (f) => !dependsIds.includes(f)
        );

        distinctIds.forEach((f) => {
          const index = itemsToRemove.findIndex((i) => i === f);
          if (index >= 0) {
            itemsToRemove = [
              ...itemsToRemove.slice(0, index),
              ...itemsToRemove.slice(index + 1),
            ];
          }
        });
        setIdsDebtsDisabled(itemsToRemove);

        setIdsDebts(
          idsDebts.filter(
            (f) =>
              f !== event.target.value &&
              (!dependsIds || !dependsIds.includes(f))
          )
        );
      }
    },
    [handleEventGTM, idsDebts, idsDebtsDisabled, vehicleDebts]
  );

  useEffect(() => {
    const sum = vehicleDebts
      ?.filter((f) => idsDebts.includes(f.id))
      ?.reduce((sum, current) => sum + (current?.amount ?? 0), 0);
    setTotal(sum ?? 0);
    // eslint-disable-next-line
  }, [idsDebts]);

  const plots = useMemo(
    () =>
      installments?.map((m) => {
        return {
          plot: m.installments,
          value: m.amount,
          total: m.totalAmount,
        };
      }) ?? undefined,
    [installments]
  );

  const setErrors = useCallback(
    (erros: any) => {
      try {
        let msg = "";
        Object.keys(erros).forEach((prop) => {
          const value = t("Property") + " " + prop + ": " + erros[prop];
          msg = msg + value + "\n";
        });
        showGenericWarningModal(msg);
      } catch (error) {
        showGenericErrorModal();
      }
    },
    [showGenericErrorModal, showGenericWarningModal, t]
  );

  const handleContinue = useCallback(async () => {
    setLoading(true);
    try {
      const debtsI = vehicleDebts
        ?.filter((f) => idsDebts.includes(f.id))
        .map((m) => {
          return {
            debtId: m.id,
            description: m.description,
            title: m.title,
            value: m.amount,
          };
        }) as IVehicleDebtDetailModel[];

      const response = await saveDebts(
        new DebtsRegisterRequest({
          debits: debtsI,
          totalDebtsInstallments: plots?.find((f) => f.plot === plot)?.total,
          totalDebts: total,
          id: debtsContext?.id,
          installmentPlan: plot,
          installmentValue: plots?.find((f) => f.plot === plot)?.value,
          debitsProtocol: protocolaVehicleDebts,
        } as IDebtsRegisterRequest)
      );

      setDebtsContext({
        ...debtsContext,
        installmentPlan: plot,
        vehicleDebtDetails: debtsI,
        totalDebts: response.totalDebts,
        totalDebtsInstallments: response.totalDebtsInstallments,
      } as DebtsVehicleModel);

      setPaymentContext({
        parcelable: plot,
        email: usernamePawordlessContext.email,
        phone: usernamePawordlessContext.cellphone,
        name: usernamePawordlessContext.name,
      } as PaymentCreditCardInfoModel);

      setPlotsContext(installments);

      history.push(paths.debtsPayment(id, typeJorney));
    } catch (e) {
      const msg = handleFormikError(e, setErrors);
      if (msg) showGenericWarningModal(msg);
    } finally {
      setLoading(false);
    }
  }, [
    debtsContext,
    handleFormikError,
    history,
    id,
    idsDebts,
    installments,
    plot,
    plots,
    protocolaVehicleDebts,
    saveDebts,
    setDebtsContext,
    setErrors,
    setPaymentContext,
    setPlotsContext,
    showGenericWarningModal,
    total,
    typeJorney,
    usernamePawordlessContext,
    vehicleDebts,
  ]);

  const getStatusDue = useCallback(
    (dueDate: Date | undefined) => {
      if (!dueDate) return " ";

      try {
        return parseISO(dueDate.toString()) > new Date()
          ? t("Open")
          : t("Overdue");
      } catch (error) {
        return " ";
      }
    },
    [t]
  );
  useEffect(() => {
    if (vehicleDebts !== undefined) {
      handleEventGTM({
        event: "view_item_list",
        ecommerce: {
          items: {
            item_name: vehicleDebts.map((i) => i.title),
            item_id: vehicleDebts.map((i) => i.id),
            price: vehicleDebts.map((i) => i.amount),
            currency: "BRL",
            affiliation: "Débitos",
            item_category: "Documentação",
          },
        },
      });
    }
  }, [handleEventGTM, vehicleDebts]);

  return (
    <>
      <Row>
        <Col>
          <TitlePage>{t("DebitsPageTitle")}</TitlePage>
          <DescriptionPage>{t("DebitsPageDescripton")}</DescriptionPage>
        </Col>
      </Row>
      {!vehicleDebts ? (
        <DivLoading>
          <SpinnerCenteredAtom />
          <div className={"title"}>{t("Wait")}</div>
          <div className={"description"}>{t("WarningWaitingForDetran")}</div>
        </DivLoading>
      ) : (
        <Row>
          <Col lg={8}>
            <>
              {vehicleDebts.length === 0 ? (
                <div>
                  <div
                    className={
                      "h-100 d-flex align-items-center text-center my-4"
                    }
                  >
                    {msgError ?? t("No debts found")}
                  </div>
                  <div className={"text-center"}>
                    {!!msgError && (
                      <ButtonSecondary
                        sizex={"md"}
                        onClick={() => history.push(paths.debtsVehicle())}
                      >
                        {t("Try again")}
                      </ButtonSecondary>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  {vehicleDebts.map((m, i) => (
                    <DebitDetail
                      key={`DebitDetail${i}`}
                      onCheck={handleCheck}
                      id={m.id}
                      checked={!!idsDebts?.includes(m.id)}
                      disableCheck={!!idsDebtsDisabled?.includes(m.id)}
                      name={m.title}
                      description={m.description}
                      value={m.amount}
                      status={getStatusDue(m.dueDate)}
                    />
                  ))}
                  <TotalDebitsSelected>
                    {t("Total selected")}{" "}
                    <b>
                      {t("$")} {NumberHelper.toFormatString(total, 2, "--")}
                    </b>
                  </TotalDebitsSelected>
                </>
              )}
            </>
          </Col>
          <Col lg={4}>
            {debtsContext && (
              <>
                <DebitPlots
                  plate={debtsContext?.plate}
                  renavam={debtsContext?.renavam}
                  plots={plots}
                  onCheck={handleCheckPlot}
                  plotSelected={plot}
                />
                <div>
                  <ButtonPrimary
                    disabled={!plot}
                    onClick={handleContinue}
                    loading={loading}
                  >
                    {t("Proceed to payment")}
                  </ButtonPrimary>
                </div>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
