import { MsgValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IHomeInstallationAddress } from "../../../../models";

export const InstallationAddressFormMoleculeValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isPhoneValid",
    YupHelper.isPhoneValid
  );

  return Yup.object<IHomeInstallationAddress>({
    name: Yup.string().required(t(MsgValidation.Required)),
    phone: Yup.string().required(t(MsgValidation.Required)).isPhoneValid(t("Invalid Phone")),
    zipCode: Yup.string().required(t(MsgValidation.Required)),
    street: Yup.string().required(t(MsgValidation.Required)),
    number: Yup.string().required(t(MsgValidation.Required)),
    complement: Yup.string().notRequired(),
    district: Yup.string().required(t(MsgValidation.Required)),
    city: Yup.string().required(t(MsgValidation.Required)),
    state: Yup.string().required(t(MsgValidation.Required)),
    referencePoint: Yup.string().notRequired(),
  });
};
