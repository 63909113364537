import { Img, CreditcardCompanyEnum, NumberHelper } from "c4u-web-components";
import { format } from "date-fns";
import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { IVehicleDebtDetailModel } from "../../../../models";
import {
  AmericanExpressLogo,
  CoxHeaderLogo,
  CoxLogo,
  DtHeaderLogo,
  EloLogo,
  ExpressHeaderLogo,
  HipercardLogo,
  KbbHeaderLogo,
  MastercardLogo,
  VisaLogo,
  ZapayHeader,
} from "../../../assets";
import {
  DebtLine,
  TitleDebt,
  ValueDebt,
  WrapperDetail,
  Header,
  Body,
  LinePart,
  HeaderTicket,
  ContentProof,
  LineTitle,
  TotalValueTitle,
  LabelTitle,
  LabelNormal,
  LabelNormalValue,
  TagCreditCardInfo,
  LabelTitleBlue,
  WarnningContentPayment,
  FooterProofPrint,
} from "./print-proof-payment.molecute.style";

interface IProps {
  id: number;
  plate: string;
  protocol: string;
  debtsValue: number;
  total: number;
  debts: IVehicleDebtDetailModel[];
  date: Date;
  creditcard: string;
  installmentPlan: number;
  installmentValue: number;
  print: boolean;
  creditcardFlag?: CreditcardCompanyEnum;
}

export interface IStatusHistoryProps {
  date: Date;
  status: number;
}

export const PrintProofPaymentMolecute: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (props.print && handlePrint) handlePrint();
  }, [props.print, handlePrint]);

  const formatValue = (value: number) => {
    return `${t("$")} ${NumberHelper.toFormatString(value, 2, "--")}`;
  };

  return (
    <WrapperDetail ref={componentRef}>
      <Header>
        <Row className={"auto"}>
          <Col>
            <Img src={CoxHeaderLogo} />
          </Col>
          <Col>
            <Img src={KbbHeaderLogo} />
          </Col>
          <Col>
            <Img src={ExpressHeaderLogo} />
          </Col>
          <Col>
            <Img src={DtHeaderLogo} />
          </Col>
          <Col>
            <LinePart />
          </Col>
          <Col>
            <Img src={ZapayHeader} />
          </Col>
        </Row>
      </Header>
      <Body>
        <HeaderTicket>
          <Row>
            <Col className={"mb-4"}>
              <h5>{t("Proof of payment")}</h5>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <h6>{t("Request code")}</h6>
              <h4>{props.protocol}</h4>
            </Col>
            <Col xs>
              <h6>{t("Plate")}</h6>
              <h4>{props.plate}</h4>
            </Col>
            <Col xs>
              <h6>{t("Payment date")}</h6>
              <h4> {format(props.date, `dd/MM/yyyy '${t("at")}' HH:mm`)}</h4>
            </Col>
          </Row>
        </HeaderTicket>
        <hr />
        <ContentProof>
          <LineTitle>
            <Row className={"mt-3"}>
              <Col>
                <span>{t("Debts")}</span>
              </Col>
              <Col className={"text-right"}>
                <span>{t("Values")}</span>
              </Col>
            </Row>
            <hr />
          </LineTitle>
          {props.debts.map((m) => (
            <DebtLine>
              <Row>
                <Col xs={9}>
                  <TitleDebt>{m.title}</TitleDebt>
                </Col>
                <Col xs={3}>
                  <ValueDebt>{formatValue(m.value)}</ValueDebt>
                </Col>
              </Row>
            </DebtLine>
          ))}
          <DebtLine className={"py-3"}>
            <Row>
              <Col xs className={"text-right"}>
                <LabelNormal>{t("Subtotal")}</LabelNormal>
              </Col>
              <Col className={"text-right"} xs={"2"}>
                <LabelNormalValue>
                  {formatValue(props.debtsValue)}
                </LabelNormalValue>
              </Col>
            </Row>
            <Row>
              <Col xs className={"text-right"}>
                <LabelNormal>{t("Interest")}</LabelNormal>
              </Col>
              <Col className={"text-right"} xs={"2"}>
                <LabelNormalValue>
                  {formatValue(props.total - props.debtsValue)}
                </LabelNormalValue>
              </Col>
            </Row>
          </DebtLine>
          <div className={"py-3"}>
            <Row>
              <Col xs className={"text-right"}>
                <LabelTitle>{t("Total")}</LabelTitle>
              </Col>
              <Col className={"text-right"} xs={"auto"}>
                <TotalValueTitle>{formatValue(props.total)}</TotalValueTitle>
              </Col>
            </Row>
          </div>
        </ContentProof>

        <TagCreditCardInfo>
          <Row>
            <Col>
              <LabelNormal className={"mr-2"}>{t("Creditcard")}</LabelNormal>
              <div className={"mr-2"}>
                {props.creditcardFlag === CreditcardCompanyEnum.Mastercard && (
                  <Img src={MastercardLogo} />
                )}
                {props.creditcardFlag === CreditcardCompanyEnum.Visa && (
                  <Img src={VisaLogo} />
                )}
                {props.creditcardFlag ===
                  CreditcardCompanyEnum.AmericanExpress && (
                  <Img src={AmericanExpressLogo} />
                )}
                {props.creditcardFlag === CreditcardCompanyEnum.Elo && (
                  <Img src={EloLogo} />
                )}
                {props.creditcardFlag === CreditcardCompanyEnum.Hipercard && (
                  <Img src={HipercardLogo} />
                )}
              </div>
              <LabelTitleBlue>{`${t("Ending")} - ${
                props.creditcard
              }`}</LabelTitleBlue>
            </Col>
            <Col className={"justify-content-end"}>
              <LabelNormal className={"mr-2"}>{t("Payment type")}</LabelNormal>
              <LabelTitleBlue>{`${props.installmentPlan}x ${formatValue(
                props.installmentValue
              )}`}</LabelTitleBlue>
            </Col>
          </Row>
        </TagCreditCardInfo>

        <WarnningContentPayment>
          {t("WarningProofZapay")}
        </WarnningContentPayment>

        <FooterProofPrint>
          <Img src={CoxLogo} />
        </FooterProofPrint>
      </Body>
    </WrapperDetail>
  );
};
