import { TypesHelper } from "c4u-web-components";
import {
  CreateInspectionRequest,
  ICreateInspectionRequest,
} from "./create-inspection.request";

export interface IPaymentInspectionRequest extends ICreateInspectionRequest {
  birthdate?: string;
  name?: string;
  email?: string;
  document?: string;
  phoneNumber?: string;
  zipCode?: string;
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  creditCardNumber?: string;
  creditCardName?: string;
  creditCardMonthValid?: string;
  creditCardYearValid?: string;
  creditCardCvv?: string;
}

export class PaymentInspectionRequest extends CreateInspectionRequest {
  birthdate: string | null;
  name: string | null;
  email: string | null;
  document: string | null;
  phoneNumber: string | null;
  zipCode: string | null;
  street: string | null;
  number: string | null;
  complement: string | null;
  neighborhood: string | null;
  city: string | null;
  state: string | null;
  creditCardNumber: string | null;
  creditCardName: string | null;
  creditCardMonthValid: string | null;
  creditCardYearValid: string | null;
  creditCardCvv: string | null;

  constructor({
    birthdate,
    name,
    email,
    document,
    phoneNumber,
    zipCode,
    street,
    number,
    complement,
    neighborhood,
    city,
    state,
    creditCardNumber,
    creditCardName,
    creditCardMonthValid,
    creditCardYearValid,
    creditCardCvv,
    ...baseParams
  }: IPaymentInspectionRequest) {
    super(baseParams);

    this.birthdate = TypesHelper.toStringNull(birthdate);
    this.name = TypesHelper.toStringNull(name);
    this.email = TypesHelper.toStringNull(email);
    this.document = TypesHelper.toStringNull(document?.replace(/[^\d]/g, ""));
    this.phoneNumber = TypesHelper.toStringNull(phoneNumber);
    this.zipCode = TypesHelper.toStringNull(zipCode);
    this.street = TypesHelper.toStringNull(street);
    this.number = TypesHelper.toStringNull(number);
    this.complement = TypesHelper.toStringNull(complement);
    this.neighborhood = TypesHelper.toStringNull(neighborhood);
    this.city = TypesHelper.toStringNull(city);
    this.state = TypesHelper.toStringNull(state);
    this.creditCardNumber = TypesHelper.toStringNull(creditCardNumber);
    this.creditCardName = TypesHelper.toStringNull(creditCardName);
    this.creditCardMonthValid = TypesHelper.toStringNull(creditCardMonthValid);
    this.creditCardYearValid = TypesHelper.toStringNull(creditCardYearValid);
    this.creditCardCvv = TypesHelper.toStringNull(creditCardCvv);
  }
}
