import { Auth0Provider } from "@auth0/auth0-react";
import React, { FunctionComponent, useCallback } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { paths } from "../../../constants";
import {
  useInspectionContext,
  useSessionContext,
  useTrackerContext,
} from "../../../hooks";
import { FeedbackModal, TopMainTemplate } from "../../molecules";
import { AppContainerWithMenu } from "../base.template.style";
import { TrackerBaseTemplate } from "./tracker-base.template";
import { TrackerAuth } from "./tracker.auth";

export const TrackerTemplate: FunctionComponent = (props) => {
  const { t } = useTranslation();

  const {
    genericModalMsg,
    genericErrorModal,
    hideGenericErrorModal,
    genericWarningModal,
    hideGenericWarningModal,
  } = useSessionContext();
  const { setVehicleContext } = useInspectionContext();
  const { setTrackerDataContext, setOriginInsuranceContext } =
    useTrackerContext();

  const redirectUri = `${
    window.location.origin
  }/#${paths.trackerVehicleSelection(0)}`;

  const clearTrackerContexts = useCallback(() => {
    setTrackerDataContext(undefined);
    setVehicleContext(undefined);
    setOriginInsuranceContext(false);
  }, [setOriginInsuranceContext, setTrackerDataContext, setVehicleContext]);

  return (
    <>
      <AppContainerWithMenu>
        <Container>
          <TopMainTemplate hideBottomRule={true} />
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ""}
            scope={process.env.REACT_APP_AUTH0_SCOPE ?? ""}
            redirectUri={redirectUri}
          >
            <TrackerAuth
              redirectUri={redirectUri}
              onSelectDealershipSuccess={clearTrackerContexts}
            >
              <TrackerBaseTemplate>{props.children}</TrackerBaseTemplate>
            </TrackerAuth>
          </Auth0Provider>
        </Container>
        <FeedbackModal
          type={"error"}
          show={genericErrorModal}
          onHide={hideGenericErrorModal}
          title={t("Error")}
          content={genericModalMsg ?? t("GenericErrorMsg")}
        />
        <FeedbackModal
          type={"warning"}
          show={genericWarningModal}
          onHide={hideGenericWarningModal}
          title={t("Warning")}
          content={genericModalMsg ?? t("GenericWarningMsg")}
        />
      </AppContainerWithMenu>
    </>
  );
};
