import React, { useEffect, useState } from "react";
import { SideStepper } from "../..";

interface IMenuStepperProps {
  currentStep: number;
  items: string[];
}

export const MenuStepper: React.FC<IMenuStepperProps> = (props) => {

  const [step, setStep] = useState(props.currentStep);

  useEffect(() => setStep(props.currentStep), [props.currentStep]);

  return (
    <>
      <SideStepper step={step} items={props.items} />
    </>
  );
};
