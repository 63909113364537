import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SupervisaoLogo } from "./../../../assets";
import {
  useInspectionContext,
  useManhein,
  useSessionContext,
} from "../../../../hooks";
import { FeedbackModal } from "../../../molecules";
import {
  HelpText,
  WrapperContentStep,
} from "./inspection-register.organism.style";
import {
  MaskHelper,
  NumberHelper,
  CompanyInspectionChoose,
  InspectionSchedule,
  InspectionUnit,
  InspectionCompanyEnum,
  InspectionPlan,
} from "c4u-web-components";
import * as Scroll from "react-scroll";
import { Definition, paths } from "../../../../constants";
import { conformToMask } from "react-text-mask-legacy";
import { IPasswordlessParams } from "../../../../models";

export const InspectionRegister: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { typeJorney } = useParams<IPasswordlessParams>();

  const { getPlans, getUnits } = useManhein();

  const { inspectionContext, setInspectionContext } = useInspectionContext();
  const { handleEventGTM } = useSessionContext();

  const [showModal, setShowModal] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [loadingUnits, setLoadingUnits] = useState(false);

  const [plansLocal, setPlansLocal] = useState<InspectionPlan[]>();
  const [unitsLocal, setUnitsLocal] = useState<InspectionUnit[]>();

  const [idUnitSelected, setIdUnitSelected] = useState(
    inspectionContext?.unitSchedule?.idUnit
  );
  const [idUnitScheduled, setIdUnitScheduled] = useState<string>();
  const [firstLoad, setFirstLoad] = useState(true);

  const scroll = Scroll.animateScroll;
  const propsScroll = useMemo(() => {
    return {
      duration: 1500,
      delay: 100,
      smooth: true,
    };
  }, []);

  const handleSelectPlan = useCallback(
    (id: string) => {
      const selectedIndex = inspectionContext.plans?.findIndex(
        (plan) => plan.id === id
      );
      let inspection = { ...inspectionContext };
      inspection.inspectionPlan = inspectionContext?.plans?.find(
        (f) => f.id === id
      );
      scroll.scrollToBottom(propsScroll);
      setInspectionContext(inspection);
      handleEventGTM({
        event: "select_item",
        ecommerce: {
          items: {
            item_name: inspection.inspectionPlan?.name,
            item_id: inspection.inspectionPlan?.id,
            price: inspection.inspectionPlan?.value,
            currency: "BRL",
            affiliation: "SuperVisão",
            item_category: "Vistoria",
            item_list_name: "Parceiro",
            index: selectedIndex,
            quantity: 1,
          },
        },
      });
    },
    [
      handleEventGTM,
      inspectionContext,
      propsScroll,
      scroll,
      setInspectionContext,
    ]
  );

  const handleSelectInspectionCompany = useCallback(
    (id: InspectionCompanyEnum) => {
      let inspection = { ...inspectionContext };
      inspection.inspectionCompany = undefined;
      setInspectionContext(inspection);
      scroll.scrollToBottom(propsScroll);
    },
    [inspectionContext, propsScroll, scroll, setInspectionContext]
  );

  const handleChoose = useCallback((id: string) => {
    setIdUnitScheduled(undefined);
    setIdUnitSelected(id);
  }, []);

  const handleSchedule = useCallback(
    (id: string, date: Date, hour: string) => {
      let inspection = { ...inspectionContext };
      inspection.dateSchedule = date;
      inspection.hourSchedule = hour;
      inspection.unitSchedule = inspectionContext?.units?.find(
        (f) => f.idUnit === id
      );
      setIdUnitScheduled(id);
      setInspectionContext(inspection);
    },
    [inspectionContext, setInspectionContext]
  );

  const handleConfirmSchedule = useCallback((id: string) => {
    setSubmiting(true);
  }, []);

  const getPlansAsync = useCallback(
    async (id: InspectionCompanyEnum) => {
      try {
        const plansAsync = await getPlans(
          id,
          typeJorney === Definition.Passwordless
        );
        setPlansLocal(plansAsync);
      } catch (error) {
        setPlansLocal([]);
      }
    },
    [getPlans, typeJorney]
  );

  useEffect(() => {
    scroll.scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  useEffect(() => {
    if (inspectionContext?.inspectionCompany !== undefined) {
      if (!inspectionContext?.plans)
        getPlansAsync(inspectionContext.inspectionCompany);
    } else {
      setInspectionContext({
        ...inspectionContext,
        inspectionCompany: InspectionCompanyEnum.Supervisao,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionContext?.inspectionCompany]);

  useEffect(() => {
    if (submiting) {
      history.push(paths.inspectionVehicle(typeJorney));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submiting]);

  useEffect(() => {
    if (plansLocal !== undefined) {
      handleEventGTM({
        event: "view_item_list",
        ecommerce: {
          items: {
            item_name: plansLocal?.map((item) => item.name),
            item_id: plansLocal?.map((item) => item.id),
            price: plansLocal?.map((item) => item.value),
            currency: "BRL",
            affiliation: "SuperVisão",
            item_category: "Vistoria",
          },
        },
      });
    }
  }, [handleEventGTM, plansLocal]);

  const [offset, setOffset] = useState<number>(
    inspectionContext?.offsetPlans ?? 0
  );

  const [zipCode, setZipCode] = useState<string | undefined>(
    inspectionContext?.zipcodePlans
  );

  const validZipCode = useMemo(() => {
    setInspectionContext({ ...inspectionContext, zipcodePlans: zipCode });
    return !!zipCode?.match(/\d{5}-\d{3}/);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  useEffect(() => {
    if (loadingUnits) {
      getUnits(
        inspectionContext?.inspectionCompany ??
          InspectionCompanyEnum.Supervisao,
        NumberHelper.toNumber(zipCode),
        3,
        offset
      )
        .then((u) => {
          const unitsHandled = u?.map((m) => {
            const phones = m.phones
              .map(
                (phone) =>
                  conformToMask(phone ?? "", MaskHelper.Phone, {
                    guide: false,
                  }).conformedValue
              )
              .filter((f) => !!f);
            return { ...m, phones: phones } as InspectionUnit;
          });

          const newOffset = offset + unitsHandled?.length;
          setOffset(newOffset);
          inspectionContext.offsetPlans = newOffset;
          if (inspectionContext?.units) {
            const unitItems = [...inspectionContext.units];
            unitsHandled.forEach((f) => {
              unitItems.push(f);
            });
            setUnitsLocal(unitItems);
          } else {
            setUnitsLocal(unitsHandled);
          }
          scroll.scrollToBottom(propsScroll);
        })
        .catch(() => setUnitsLocal([]))
        .finally(() => setLoadingUnits(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUnits]);

  const handleMoreClick = useCallback(() => {
    setLoadingUnits(true);
  }, []);

  const handleGoClick = useCallback(() => {
    setInspectionContext({
      ...inspectionContext,
      offsetPlans: 0,
      units: undefined,
    });
    setLoadingUnits(true);
    setOffset(0);
  }, [inspectionContext, setInspectionContext]);

  useEffect(() => {
    if (validZipCode && !inspectionContext?.units?.length)
      setLoadingUnits(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      setInspectionContext({ ...inspectionContext, plans: plansLocal });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansLocal]);

  useEffect(() => {
    if (!firstLoad) {
      setInspectionContext({ ...inspectionContext, units: unitsLocal });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitsLocal]);

  useEffect(() => {
    // this effect must be the last one
    setFirstLoad(false);
  }, []);

  return (
    <>
      <FeedbackModal
        type={"success"}
        show={showModal}
        onHide={() => setShowModal(false)}
        title={t("TitleModalInspectionConfirmation")}
        content={t("DescriptionModalInspectionConfirmation")}
        textButton={t("TitleMyInspectionsPage")}
        onClickButton={() => history.push(paths.myInspections(typeJorney))}
      />

      <Row>
        <Col md>
          <WrapperContentStep>
            <label>{t("Inspection")}</label>
            <HelpText>{t("InspectionCompanyHelp")}</HelpText>
            <CompanyInspectionChoose
              onSelectPlan={handleSelectPlan}
              onSelectInspectionCompany={handleSelectInspectionCompany}
              plansList={inspectionContext?.plans}
              planId={inspectionContext?.inspectionPlan?.id}
              inspectionCompany={inspectionContext?.inspectionCompany}
              translate={t}
              logos={{
                SupervisaoLogo: SupervisaoLogo,
              }}
            />
          </WrapperContentStep>
        </Col>
      </Row>

      {!!inspectionContext?.inspectionPlan && (
        <Row>
          <Col md>
            <WrapperContentStep>
              <label>{t("Schedule inspection")}</label>
              <InspectionSchedule
                onChangeZipCode={(e: any) => setZipCode(e.target.value)}
                onClickMoreClick={handleMoreClick}
                onClickGo={handleGoClick}
                validZipCode={validZipCode}
                onConfirm={handleConfirmSchedule}
                onSchedule={handleSchedule}
                onChoose={handleChoose}
                loading={loadingUnits}
                unitIdChoosed={idUnitSelected}
                unitId={idUnitScheduled}
                units={inspectionContext?.units}
                zipcode={zipCode}
                limitDaysCalendar={365}
                translate={t}
              />
            </WrapperContentStep>
          </Col>
        </Row>
      )}
    </>
  );
};
