import { TypesHelper } from "c4u-web-components";
import { IVehicleDebtDetailModel } from "../..";

export interface IDebtsRegisterRequest {
  debits?: IVehicleDebtDetailModel[];
  totalDebts?: number;
  totalDebtsInstallments?: number;
  id?: number;
  installmentPlan?: number;
  installmentValue?: number;
  debitsProtocol?: string;
}

export class DebtsRegisterRequest {
  debits: DebtsRegisterPenaltiesRequest[] | null;
  totalDebts: number | null;
  totalDebtsInstallments: number | null;
  id: number | null;
  installmentPlan: number | null;
  installmentValue: number | null;
  debitsProtocol: string | null;

  constructor({
    debits,
    totalDebts,
    totalDebtsInstallments,
    id,
    installmentPlan,
    installmentValue,
    debitsProtocol,
  }: IDebtsRegisterRequest) {
    this.debits =
      debits?.map((m) => new DebtsRegisterPenaltiesRequest(m)) ?? null;
    this.totalDebts = TypesHelper.toNumberNull(totalDebts, 2);
    this.totalDebtsInstallments = TypesHelper.toNumberNull(totalDebtsInstallments, 2);
    this.id = TypesHelper.toNumberNull(id);
    this.installmentPlan = TypesHelper.toNumberNull(installmentPlan);
    this.installmentValue = TypesHelper.toNumberNull(installmentValue, 2);
    this.debitsProtocol = TypesHelper.toStringNull(debitsProtocol);
  }
}

export class DebtsRegisterPenaltiesRequest {
  title: string | null;
  description: string | null;
  value: number | null;
  debtId: string | null;

  constructor({ title, description, value, debtId }: IVehicleDebtDetailModel) {
    this.title = TypesHelper.toStringNull(title);
    this.description = TypesHelper.toStringNull(description);
    this.debtId = TypesHelper.toStringNull(debtId);
    this.value = TypesHelper.toNumberNull(value);
  }
}
