import { MsgValidation } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IForm } from "./modal-payment-proof-share.molecule";

export const ModalPaymentProofShareMoleculeValidation = () => {
  const { t } = useTranslation();

  return Yup.object<IForm>({
    email: Yup.string()
      .required(t(MsgValidation.Required))
      .email(t(MsgValidation.Email)),
  });
};
