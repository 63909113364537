import { useAuth0 } from "@auth0/auth0-react";
import {
  ButtonTertiary,
  CardInspection,
  Combo,
  DropdownDefault,
  InspectionCompanyEnum,
  InspectionPlan,
  InspectionStatusEnum,
  InspectionStatusHelper,
  IPagedResponseBaseModel,
  MaskHelper,
  PaginationControlMolecule,
  SpinnerCenteredAtom,
  StatusPayment,
  StatusReport,
  TypesHelper,
} from "c4u-web-components";
import { parseISO } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { conformToMask } from "react-text-mask-legacy";
import { Definition, paths } from "../../../../constants";
import { useInspectionContext, useProductsInspection } from "../../../../hooks";
import {
  InspectionStatus,
  IPasswordlessParams,
  Vehicle,
} from "../../../../models";
import { ReportListModalMolecule } from "../../../molecules";
import {
  ComboSort,
  ReportListing,
  ReportListingRow,
} from "./list-inspections.organism.style";

interface IListInspection {
  status: InspectionStatusEnum;
  statusHistory: InspectionStatus[];
  statusReport: StatusReport;
  statusPayment: StatusPayment;
  vehicleDescription: string;
  inspectionDate: Date;
  inspectionHour: string;
  inspectionUnit: string;
  inspectionUnitId: string;
  inspectionAddress: string;
  inspectionPhone: string;
  inspectionUnitEmails: string[];
  inspectionID: number;
  plate: string;
  renavam: string;
  inspectionCompany: InspectionCompanyEnum;
  inspectionPlan: InspectionPlan;
  kbbId: number;
  searchZipCode: string;
}

export const ListInspection: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { typeJorney } = useParams<IPasswordlessParams>();

  const { getAllInspections, getInspectionReports } = useProductsInspection();
  const { isAuthenticated } = useAuth0();

  const {
    setInspectionContext,
    setPaymentContext,
    setVehicleContext,
    setVehicleDataContext,
    setIdInspectionContext,
    clearInspectionContexts,
  } = useInspectionContext();

  const [listInspection, setListInspection] = useState<IListInspection[]>();
  const [paginationData, setPaginationData] =
    useState<IPagedResponseBaseModel>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const hourFormat = (hour: number) => {
    if (!hour) return "--";
    return `${hour.toString().padStart(2, "0")}:00`;
  };

  const phonesSeparator = useMemo(() => " / ", []);

  const getAllAdInspectionsAsync = useCallback(
    async (page: number) => {
      try {
        setListInspection(undefined);
        const { data: adInspection, ...paginationData } =
          await getAllInspections(page);
        const inspectionsList = adInspection.map((m) => {
          const phones = m.inspectionPlacePhones?.map((m) => {
            const phone = conformToMask(m ?? "", MaskHelper.Phone, {
              guide: false,
            });
            return phone.conformedValue;
          });

          const searchZipCode =
            conformToMask(m.searchZipCode ?? "", MaskHelper.Cep, {
              guide: false,
            }).conformedValue ?? "01122-001";

          return {
            status: m.status,
            statusHistory: m.inspectionStatuses,
            statusReport: InspectionStatusHelper.getReportStatus(m.status),
            statusPayment: InspectionStatusHelper.getPaymentStatus(m.status),
            vehicleDescription: m.vehicleName,
            inspectionDate: TypesHelper.toDateTimeNull(m.inspectionDate),
            inspectionHour: hourFormat(m.inspectionPeriod),
            inspectionUnit: m.inspectionPlaceName ?? "",
            inspectionUnitId: m.inspectionPlaceId,
            inspectionAddress: m.inspectionPlaceAddress ?? "",
            inspectionPhone: phones ? phones.join(phonesSeparator) : "",
            inspectionUnitEmails: m.inspectionPlaceEmails,
            inspectionID: m.id,
            plate: m.plate,
            renavam: m.renavam,
            inspectionCompany: m.partnerId,
            inspectionPlan: {
              id: m.inspectionPlanId,
              name: m.inspectionPlanName,
              description: "",
              value: m.inspectionPlanValue,
              spotlight: false,
            },
            kbbId: m.kbbId,
            searchZipCode,
          } as IListInspection;
        });
        setListInspection(inspectionsList);
        setPaginationData(paginationData);
      } catch (error) {
        setListInspection([]);
      }
    },
    [getAllInspections, phonesSeparator]
  );

  useEffect(() => {
    clearInspectionContexts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated || typeJorney === Definition.Passwordless) {
      getAllAdInspectionsAsync(pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, typeJorney, pageNumber]);

  const itensCombo = [
    { title: t("SortRecents"), value: 0 },
    { title: t("SortOldest"), value: 1 },
  ] as Combo[];

  const dateSort = (a: any, b: any, asc: boolean) => {
    const key = "inspectionDate";
    if (a && b) {
      if (parseISO(a[key]) > parseISO(b[key])) {
        return asc ? 1 : -1;
      } else if (parseISO(a[key]) < parseISO(b[key])) {
        return !asc ? 1 : -1;
      }
    }
    return 0;
  };

  const handleChange = (value: Combo) => {
    const list = [].slice.call(listInspection) as IListInspection[];
    const listOrder = list.sort((a, b) => dateSort(a, b, value.value === 0));
    setListInspection(listOrder);
  };

  const [showReportListModal, setShowReportListModal] =
    useState<boolean>(false);

  const [inspectionReports, setInspectionReports] = useState<string[] | null>();

  const getReportsAsync = useCallback(
    async (id: number) => {
      if (id) {
        try {
          const { links } = await getInspectionReports(id);
          if (!links) throw new Error();
          setInspectionReports(links);
        } catch (error) {
          setInspectionReports(null);
        }
      }
    },
    [getInspectionReports]
  );

  const getClickReportCallback = useCallback(
    (id: number) => {
      return () => {
        setShowReportListModal(true);
        getReportsAsync(id);
      };
    },
    [getReportsAsync]
  );

  const hideReportListModal = () => {
    setShowReportListModal(false);
    setTimeout(() => setInspectionReports(undefined), 300); // evita que o spinner apareça durante o fade out do modal
  };

  return (
    <>
      <ReportListModalMolecule
        show={showReportListModal}
        onHide={hideReportListModal}
        title={t("DownloadInspectionReports")}
        content={t("No inspection reports found")}
      >
        {inspectionReports === undefined ? (
          <ReportListing>
            <div className={"h-100 text-center"}>
              <div className={"m-5"}>
                <Spinner animation="border" />
              </div>
              <div>{t("Loading inspection reports")}</div>
            </div>
          </ReportListing>
        ) : (
          inspectionReports !== null &&
          inspectionReports.length > 0 && (
            <ReportListing>
              {inspectionReports.map((link, i) => (
                <ReportListingRow>
                  <ButtonTertiary
                    onClick={() => window.open(link, "_blank")}
                    sizex={"md"}
                  >
                    {`${t("Inspection report")} ${i + 1}`}
                  </ButtonTertiary>
                </ReportListingRow>
              ))}
            </ReportListing>
          )
        )}
      </ReportListModalMolecule>

      {!listInspection ? (
        <SpinnerCenteredAtom />
      ) : listInspection?.length === 0 ? (
        <div>{t("No results found")}</div>
      ) : (
        <>
          <ComboSort>
            <DropdownDefault
              selectText={t("Select")}
              data={itensCombo}
              onChange={handleChange}
            />
          </ComboSort>
          {listInspection?.map((m, i) => (
            <CardInspection
              key={i}
              statusReport={m.statusReport}
              statusPayment={m.statusPayment}
              vehicleDescription={m.vehicleDescription ?? `${t("Loading")}...`}
              inspectionDate={m.inspectionDate}
              inspectionHour={m.inspectionHour}
              inspectionUnit={m.inspectionUnit}
              inspectionAddress={m.inspectionAddress}
              inspectionPhone={m.inspectionPhone}
              statusHistory={m.statusHistory}
              status={m.status}
              translate={t}
              clickReport={getClickReportCallback(m.inspectionID)}
              onContinue={
                m.status !== InspectionStatusEnum.Registered
                  ? undefined
                  : () => {
                      setIdInspectionContext(m.inspectionID);
                      setInspectionContext({
                        id: m.inspectionID,
                        inspectionCompany: m.inspectionCompany,
                        inspectionPlan: m.inspectionPlan,
                        dateSchedule: m.inspectionDate,
                        hourSchedule: m.inspectionHour,
                        unitSchedule: {
                          idUnit: m.inspectionUnitId,
                          unitName: m.inspectionUnit,
                          address: m.inspectionAddress,
                          phones: m.inspectionPhone.split(phonesSeparator),
                          emails: m.inspectionUnitEmails,
                        },
                        status: m.status,
                        zipcodePlans: m.searchZipCode ?? "01122-001",
                      });
                      setPaymentContext(undefined);
                      setVehicleContext({ id: m.kbbId } as Vehicle);
                      setVehicleDataContext({
                        plate: m.plate,
                        renavam: m.renavam,
                      });
                      history.push(paths.inspectionPayment(typeJorney));
                    }
              }
            />
          ))}

          {paginationData && paginationData.totalPages > 1 && (
            <div className="default-align">
              <PaginationControlMolecule
                translator={t}
                paginationData={paginationData}
                setPageNumber={setPageNumber}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
