import { NumberHelper, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  useProductsTracker,
  useSessionContext,
  useTrackerContext,
} from "../../../../hooks";
import {
  IGetTrackerQuotesModel,
  IPasswordlessParams,
  ITrackerData,
  SaveTrackerQuoteRequest,
} from "../../../../models";
import { FixedCommission } from "../../../../models/apis/products/tracker/get-tracker-quotes.model";
import { FooterSubmitButtonAtom } from "../../../atoms";
import { TrackerQuoteMolecule } from "../../../molecules";
import { IQuotesModule } from "../../../molecules/tracker/tracker-quote/tracker-quote.molecule";
import { WrapperQuotes } from "./tracker-quotes.organism.style";

interface IProps {
  title?: string;
  commissions?: FixedCommission[];
}

export const TrackerQuotesOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, typeJorney } = useParams<IPasswordlessParams>();

  const { getQuotes, saveTrackerQuote } = useProductsTracker();

  const { trackerDataContext, setTrackerDataContext } = useTrackerContext();
  const { showGenericErrorModal, showGenericWarningModal, handleEventGTM } =
    useSessionContext();

  const [quotesModules, setQuotesModules] = useState<IQuotesModule[]>();
  const [msgApi] = useState<string>();
  const [planId, setPlan] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quotesApi, setQuotesApi] = useState<IGetTrackerQuotesModel>();
  const [chosenCommission, setChosenCommission] = useState<FixedCommission>();

  const submit = useCallback(async () => {
    try {
      const request = new SaveTrackerQuoteRequest({
        trackerId: Number(id),
        partnerQuoteGuid:
          quotesApi?.quoteGuid ??
          trackerDataContext?.quote?.partnerQuoteItemId?.toString(),
        partnerQuoteItemId: Number(planId),
        commission: chosenCommission?.commission ?? 0,
        date: quotesApi?.quoteDate,
        name: quotesApi?.quoteItems?.find(
          (f) => f.quoteItemId === Number(planId)
        )?.productName,
        value: chosenCommission?.monthlyPayment ?? 0,
        displacementValue: NumberHelper.toNumber(
          quotesApi?.quoteItems.find((f) => f.quoteItemId === Number(planId))
            ?.displacementValue
        ),
      });

      await saveTrackerQuote(request);

      setTrackerDataContext({
        ...trackerDataContext,
        quote: request,
      } as ITrackerData);
      history.push(paths.trackerOrder(id, typeJorney));
    } catch (error) {
      showGenericErrorModal();
    }
  }, [
    history,
    planId,
    id,
    quotesApi,
    saveTrackerQuote,
    showGenericErrorModal,
    typeJorney,
    setTrackerDataContext,
    trackerDataContext,
    chosenCommission,
  ]);

  useEffect(() => {
    if (isSubmitting) submit();
    // eslint-disable-next-line
  }, [isSubmitting]);

  useEffect(() => {
    if (!planId && trackerDataContext?.quote)
      setPlan(trackerDataContext.quote.partnerQuoteItemId?.toString());
    // eslint-disable-next-line
  }, [trackerDataContext]);

  const loadQuotes = useCallback(
    (quotesItems: IGetTrackerQuotesModel) => {
      const selectedPlan = quotesItems.quoteItems.find((f) => f.isSelected);
      setPlan(selectedPlan?.quoteItemId.toString());
      const withInsurance = quotesItems.quoteItems.filter(
        (f) => f.withInsurance
      );
      const withoutInsurance = quotesItems.quoteItems.filter(
        (f) => !f.withInsurance
      );

      const quotesModulesLocal: IQuotesModule[] = [];
      if (withInsurance?.length) {
        quotesModulesLocal.push({
          title: t("Tracker with Insurance"),
          withInsurance: true,
          quotes: withInsurance.map((m) => {
            return {
              planId: m.quoteItemId.toString(),
              spotlights: {
                title: m.productName,
                price: NumberHelper.toNumber(m.monthlyPayment),
                description: t("Monthly"),
                recommended: m.recommended,
              },
              regulars: [
                {
                  title: t("Indemnity", { PERCENT: m.indemnityPercentage }),
                  description: "",
                  has: m.indemnity,
                },
              ],
              totalLoss: {
                title: t("Total loss"),
                descriptionTheft: t("RobberyTheftFire"),
                hasTheft: m.robberyTheftFire,
                descriptionCollision: t("CollisionFlood"),
                hasCollision: m.collisionFlood,
              },
              franchise: {
                title: t("Franchise"),
                price: m.franchise,
                description: "",
              },
              listItems: [
                {
                  title: t("Differentials"),
                  list: m.leadName,
                },
              ],
              commissions: m.fixedCommissions ?? [],
            };
          }),
        });
      }

      if (withoutInsurance?.length) {
        quotesModulesLocal.push({
          title: t("Ituran Tracker"),
          withInsurance: false,
          quotes: withoutInsurance.map((m) => {
            return {
              planId: m.quoteItemId.toString(),
              spotlights: {
                title: m.productName,
                price: NumberHelper.toNumber(m.monthlyPayment),
                description: t("Monthly"),
                recommended: m.recommended,
              },
              regulars: [
                {
                  title: t("National action"),
                  description: "",
                  has: m.nationalAction,
                },
                {
                  title: t("Monthly return"),
                  description: t("In case of non-recovery"),
                  has: m.monthlyReturn,
                },
                {
                  title: t("Monitoring"),
                  description: t("Via App Ituran Digital"),
                  has: m.monitoring,
                },
              ],
              listItems: [
                {
                  title: t("Differentials"),
                  list: m.leadName,
                },
              ],
              commissions: m.fixedCommissions ?? [],
            };
          }),
        });
      }
      setQuotesModules(quotesModulesLocal);
    },
    [t]
  );

  useEffect(() => {
    if (!quotesApi && id)
      getQuotes(id)
        .then((data) => {
          if (data.priceHasChanged)
            showGenericWarningModal(t("PriceHasChangedWarning"));
          setQuotesApi(data);
        })
        .catch(() => setQuotesModules([]));
    if (quotesApi) loadQuotes(quotesApi);
    // eslint-disable-next-line
  }, [quotesApi, id]);

  useEffect(() => {
    const differentials = Array.from(
      document.getElementsByClassName("Differentials")
    );
    if (differentials?.length > 0) {
      let bigger = 0;
      for (const element of differentials) {
        bigger =
          (element as any).offsetHeight > bigger
            ? (element as any).offsetHeight
            : bigger;
      }
      for (const element of differentials) {
        (element as any).style.minHeight = `${bigger}px`;
      }
    }
  }, [quotesModules]);

  const handleGtmSelectTracker = useCallback(
    (planId: string) => {
      const selectedPlan = quotesModules?.find(
        (f) => !!f.quotes.find((f) => f.planId === planId)
      );
      if (!selectedPlan) return;

      const selectedQuotesModule = { ...selectedPlan };

      const selectedIndex = selectedQuotesModule?.quotes.findIndex(
        (plan) => plan.planId === planId
      );

      selectedQuotesModule.quotes = selectedQuotesModule?.quotes.filter(
        (f) => f.planId === planId
      );

      handleEventGTM({
        event: "select_item",
        ecommerce: {
          items: {
            item_name: selectedQuotesModule.quotes.map(
              (quote) => quote.spotlights.title
            ),
            item_id: selectedQuotesModule.quotes.map((quote) => quote.planId),
            price: chosenCommission?.monthlyPayment,
            currency: "BRL",
            affiliation: "Ituran",
            item_category: "Rastreadores",
            item_list_name: "Cotação",
            index: selectedIndex,
            quantity: 1,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chosenCommission, handleEventGTM, quotesModules]
  );

  useEffect(() => {
    const quotationsUnstructured = quotesModules?.map(
      (itemQuote) => itemQuote.quotes
    );
    if (!!quotesModules && quotesModules.length) {
      handleEventGTM({
        event: "view_item_list",
        ecommerce: {
          items: {
            item_name: quotationsUnstructured?.map((item) =>
              item.map((itemSpotlights) => itemSpotlights.spotlights.title)
            ),
            item_id: quotationsUnstructured?.map((item) =>
              item.map((itemId) => itemId.planId)
            ),
            price: quotationsUnstructured?.map((item) =>
              item.map((itemSpotlights) => itemSpotlights.spotlights.price)
            ),
            currency: "BRL",
            affiliation: "Ituran",
            item_category: "Rastreadores",
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleEventGTM, quotesModules]);

  useEffect(() => {
    if (chosenCommission && planId) handleGtmSelectTracker(planId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCommission]);

  return (
    <>
      <WrapperQuotes>
        <Row className={"scroll"}>
          {quotesModules === undefined ? (
            <SpinnerCenteredAtom />
          ) : quotesModules.length === 0 ? (
            <div
              className={
                "h-100 d-flex align-items-center justify-content-center"
              }
            >
              {msgApi ?? t("NoQuotesFound")}
            </div>
          ) : (
            <Col key={`quotes`} className={"p-0 mb-4"}>
              <TrackerQuoteMolecule
                onChoose={(planId: string) => setPlan(planId)}
                chosenPlanId={planId}
                quotesModules={quotesModules}
                setChosenCommission={setChosenCommission}
              />
            </Col>
          )}
        </Row>
      </WrapperQuotes>
      <FooterSubmitButtonAtom
        onClick={() => setIsSubmitting(true)}
        loading={isSubmitting}
        disabled={!quotesModules?.length}
      >
        {t("Continue")}
      </FooterSubmitButtonAtom>
    </>
  );
};
