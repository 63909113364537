import {
  FormikControlAtom,
  InspectionUnit,
  MaskHelper,
  ZipcodeAddress,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSessionContext, useTrackerContext } from "../../../../hooks";
import { IHomeInstallationAddress } from "../../../../models";
import { FormikControlIturanAtom } from "../../../atoms";

import {
  ContentGroupBox,
  TitleContentBox,
  FlexForm,
} from "./installation-address-form.molecule.style";
import { InstallationAddressFormMoleculeValidation } from "./installation-address-form.molecule.validation";

interface IProps {
  setInstallationAddressData: (values: IHomeInstallationAddress) => void;
  initialValues?: IHomeInstallationAddress;
  getAddress?: (zipcode: string) => Promise<ZipcodeAddress>;
  idContact: string;
  idAddress: string;
  onChangeZipcode: (zipCode: string) => void;
  zipCode?: string;
}

export const InstallationAddressFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { showGenericWarningModal } = useSessionContext();
  const { setTrackerDataContext, trackerDataContext } = useTrackerContext();

  const {
    setInstallationAddressData,
    initialValues,
    getAddress,
    onChangeZipcode,
  } = props;

  const formik = useFormik<IHomeInstallationAddress>({
    initialValues: initialValues ?? ({} as IHomeInstallationAddress),
    onSubmit: () => {},
    validationSchema: InstallationAddressFormMoleculeValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const [addressZipcode, setAddressZipcode] = useState<ZipcodeAddress>();

  const getAddressByZipcode = useCallback(
    async (cep: string) => {
      onChangeZipcode(cep);
      if (getAddress) {
        try {
          const address = await getAddress(cep);
          setAddressZipcode(address);

          if (!(address as any)?.erro && address?.uf) {
            formik.setFieldValue("city", address.localidade);
            formik.setFieldValue("state", address.uf);
            formik.setFieldValue("street", address.logradouro);
            formik.setFieldValue("district", address.bairro);
            if (cep !== props.zipCode) {
              formik.setFieldValue("number", "");
              formik.setFieldValue("complement", "");
              formik.setFieldValue("referencePoint", "");
            }
          } else {
            throw new Error("Address not found");
          }
        } catch (e) {
          setAddressZipcode(undefined);
          showGenericWarningModal(t("Error fetching address"));
        }
      }
    },
    [
      formik,
      getAddress,
      onChangeZipcode,
      showGenericWarningModal,
      t,
      props.zipCode,
    ]
  );

  useEffect(
    (unitsItems?: InspectionUnit[]) => {
      if (
        formik?.values?.zipCode &&
        formik.values.zipCode.replace(/[^\d]/g, "").length === 8
      ) {
        getAddressByZipcode(formik.values.zipCode);
      } else {
        setAddressZipcode(undefined);
        setTrackerDataContext({ ...trackerDataContext, units: [] });
        formik.setFieldValue("street", "");
        formik.setFieldValue("number", "");
        formik.setFieldValue("complement", "");
        formik.setFieldValue("district", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
        formik.setFieldValue("referencePoint", "");
      }
      setTrackerDataContext({ ...trackerDataContext, units: unitsItems });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik?.values?.zipCode]
  );

  useEffect(() => {
    setInstallationAddressData(formik.values);
  }, [formik.values, setInstallationAddressData]);

  return (
    <FlexForm className={"form-default"}>
      <ContentGroupBox id={props.idContact}>
        <TitleContentBox>{t("Contact")}</TitleContentBox>
        <Row>
          <FormikControlIturanAtom
            md={4}
            formik={formik}
            property={"name"}
            label={t("Name")}
          />
          <FormikControlIturanAtom
            mask={MaskHelper.Phone}
            md={3}
            formik={formik}
            property={"phone"}
            label={t("Phone")}
          />
        </Row>
      </ContentGroupBox>

      <ContentGroupBox id={props.idAddress}>
        <TitleContentBox>{t("InstallationAddress")}</TitleContentBox>
        <Row>
          <FormikControlIturanAtom
            md={3}
            mask={MaskHelper.Cep}
            formik={formik}
            property={"zipCode"}
            label={t("Zip Code")}
          />
          <FormikControlIturanAtom
            md={4}
            formik={formik}
            property={"street"}
            label={t("Street")}
            disabled={!!addressZipcode?.logradouro}
          />
          <FormikControlIturanAtom
            md={2}
            formik={formik}
            property={"number"}
            label={t("Number")}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={" "}
          />
          <FormikControlAtom
            md={3}
            formik={formik}
            property={"complement"}
            translate={t}
            label={t("Complement")}
          />
          <FormikControlIturanAtom
            md={3}
            formik={formik}
            property={"district"}
            label={t("Neighborhood")}
            disabled={!!addressZipcode?.bairro}
          />
          <FormikControlIturanAtom
            md={3}
            formik={formik}
            property={"city"}
            label={t("City")}
            disabled={!!addressZipcode?.localidade}
          />
          <FormikControlIturanAtom
            md={2}
            formik={formik}
            property={"state"}
            label={t("State")}
            disabled={!!addressZipcode?.uf}
          />
          <FormikControlIturanAtom
            md={4}
            formik={formik}
            property={"referencePoint"}
            label={t("ReferencePoint")}
          />
        </Row>
      </ContentGroupBox>
    </FlexForm>
  );
};
