import {
  ButtonTertiary,
  CoxIcon,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  useManheinToken,
  useQuery,
  useSessionContext,
} from "../../../../hooks";
import {
  GetDeliveriesRequest,
  IGetDeliveriesResponse,
} from "../../../../models";
import {
  GenericOptionsCardMolecule,
  VehicleDeliveryCardMolecule,
} from "../../../molecules";
import {
  WrapperBuyerInformationPage,
  WrapperBuyerVehiclesCard,
} from "./buyer-information.organism.style";

import { useTranslation } from "react-i18next";
import { originHeadlineKey } from "../../../../constants";

export const ManheimBuyerInformationOrganism: React.FC = () => {
  const { coxUserContext, showGenericErrorModal } = useSessionContext();
  const { get: getOrigin } = useQuery();
  const { getDeliveriesNotComplete, getDeliveriesNotStarted } =
    useManheinToken();
  const { t } = useTranslation();

  const [vehiclesToBeCollected, setVehiclesToBeCollected] = useState<
    IGetDeliveriesResponse[]
  >([]);

  const [finishedCallAPI, setFinishedCallAPI] = useState(false);

  const redirectUserToManheimAA = () => {
    window.location.href = process.env.REACT_APP_MANHEIM_URL ?? "";
  };

  const isOriginHeadline = useMemo(() => {
    return getOrigin("origin") === originHeadlineKey;
  }, [getOrigin]);

  const getRedirectUrlToManheim = (buyerId: string) => {
    return `${process.env.REACT_APP_MANHEIM_BUYER_DELIVERY_JOURNEY_URL}${buyerId}`;
  };

  const getList = useCallback(
    async (
      getVehicles: (
        request: GetDeliveriesRequest
      ) => Promise<IGetDeliveriesResponse[]>,
      dealerShipCnpj: string
    ) => {
      try {
        const vehiclesData = await getVehicles({ cnpj: dealerShipCnpj });
        if (isOriginHeadline && vehiclesData.length === 1) {
          vehiclesData.forEach((vehicle) => {
            window.location.href = getRedirectUrlToManheim(vehicle.buyerId);
          });
          return;
        }

        if (!vehiclesData.length) {
          redirectUserToManheimAA();
          return;
        }

        setVehiclesToBeCollected(vehiclesData);
      } catch {
        showGenericErrorModal();
      } finally {
        setFinishedCallAPI(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (coxUserContext.dealershipCnpj)
      getList(
        isOriginHeadline ? getDeliveriesNotComplete : getDeliveriesNotStarted,
        coxUserContext.dealershipCnpj
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOriginHeadline, coxUserContext.dealershipCnpj]);

  return (
    <WrapperBuyerInformationPage>
      {!finishedCallAPI || vehiclesToBeCollected.length === 0 ? (
        <SpinnerCenteredAtom />
      ) : (
        <>
          <GenericOptionsCardMolecule
            icon={
              <CoxIcon
                name="error"
                fillColor="#EEA200"
                width={37}
                height={32}
              />
            }
            textHeader={
              isOriginHeadline ? t("PendingWithdrawal") : t("PendingWithdrawal")
            }
            textContent={
              isOriginHeadline
                ? t("AcessOptionOriginHeadline")
                : t("AcessOption")
            }
            buttons={
              !isOriginHeadline
                ? [
                    <ButtonTertiary
                      onClick={redirectUserToManheimAA}
                      sizex={"md"}
                    >
                      {t("SeeAds")}
                    </ButtonTertiary>,
                  ]
                : []
            }
          />

          <WrapperBuyerVehiclesCard>
            {vehiclesToBeCollected.map((vehicles) => (
              <VehicleDeliveryCardMolecule
                {...vehicles}
                redirectTo={getRedirectUrlToManheim(vehicles.buyerId)}
              />
            ))}
          </WrapperBuyerVehiclesCard>
        </>
      )}
    </WrapperBuyerInformationPage>
  );
};
