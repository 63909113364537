import styled from "styled-components";

export const WrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  max-width: 648px;
  width: 100%;

  padding: 48px 128px;

  text-align: center;

  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media (max-width: 576px) {
    padding: 48px 40px;
  }

  .text-header {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    text-align: center;

    color: ${(props) => props.theme.colors.Dark};
  }

  .text-content {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    text-align: center;
    letter-spacing: -0.15px;

    color: ${(props) => props.theme.colors.BlueNavy};
  }
`;
