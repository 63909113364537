import { ButtonPrimary, FormControlMask, MaskHelper } from "c4u-web-components";
import { useFormik } from "formik";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DebtsStatusPaymentEnum } from "../../../../models";
import { CenterControl } from "./form-debts-list-filter.molecule.style";
import { FormDebtsListFilterValidation } from "./form-debts-list-filter.validation";

interface IProps {
  onSubmit: (props: IFormDebtsListFilter) => Promise<void>;
  setFilterValues: (props: IFormDebtsListFilter) => void;
  initialValues: IFormDebtsListFilter;
  currentValues: IFormDebtsListFilter;
}

export interface IFormDebtsListFilter {
  dateStart?: string;
  dateEnd?: string;
  status?: DebtsStatusPaymentEnum | "";
  plate: string;
}

export const FormDebtsListFilterMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<IFormDebtsListFilter>({
    initialValues: props.currentValues,
    onSubmit: async (values) => {
      props.setFilterValues(values);
      await props.onSubmit(values);
    },
    validationSchema: FormDebtsListFilterValidation(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Form
      onSubmit={(e: any) => formik.handleSubmit(e)}
      className={"form-default"}
    >
      <Form.Row>
        <Form.Group as={Col} sm controlId="dateStart">
          <Form.Label>{t("Period")}</Form.Label>
          <FormControlMask
            mask={MaskHelper.Date}
            value={formik.values.dateStart}
            placeholder={t("Tab here")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.errors?.dateStart}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors?.dateStart}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm controlId="dateEnd">
          <Form.Label>{"\u2000"}</Form.Label>
          <FormControlMask
            mask={MaskHelper.Date}
            value={formik.values.dateEnd}
            placeholder={t("Tab here")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.errors?.dateEnd}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors?.dateEnd}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm controlId="status">
          <Form.Label>{t("Status")}</Form.Label>
          <Form.Control
            as={"select"}
            value={formik.values.status}
            placeholder={t("Tab here")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.errors?.status}
          >
            <option value="">{t("Select")}</option>
            <option value={DebtsStatusPaymentEnum.Created}>
              {t("Payment to be completed")}
            </option>
            <option value={DebtsStatusPaymentEnum.Pending}>
              {t("Payment in analysis")}
            </option>
            <option value={DebtsStatusPaymentEnum.Refused}>
              {t("Payment refused")}
            </option>
            <option value={DebtsStatusPaymentEnum.Paied}>
              {t("Payment approved")}
            </option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {formik.errors?.status}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm controlId="plate">
          <Form.Label>{t("Plate")}</Form.Label>
          <Form.Control
            isInvalid={!!formik.errors?.plate}
            placeholder={t("Tab here")}
            value={formik.values.plate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            maxLength={7}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors?.plate}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} sm={"auto"}>
          <CenterControl>
            <Form.Label></Form.Label>
            <ButtonPrimary
              type={"submit"}
              sizex={"sm"}
              sizey={"thin"}
              loading={formik.isSubmitting}
            >
              {t("OK")}
            </ButtonPrimary>
          </CenterControl>
        </Form.Group>

        <Form.Group as={Col} sm={"auto"} className={"text-right"}>
          <CenterControl>
            <Form.Label></Form.Label>
            <span
              onClick={() => {
                formik.resetForm(props.initialValues);
                setTimeout(() => formik.submitForm(), 300);
              }}
            >
              {t("Clean Filters")}
            </span>
          </CenterControl>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
