import { CreatePasswordlessRequest, ILoginModel } from "c4u-web-components";
import { productsHttp, useRequest } from "./config";

export const useProductsPasswordless = () => {
  const { post } = useRequest(productsHttp, "Authorization");

  const AuthorizationCreate = async (
    request: CreatePasswordlessRequest
  ): Promise<string> => {
    const { data } = await post(`Create`, request);
    return data?.authId;
  };

  const AuthorizationValidateCode = async (
    username: string,
    code: string
  ): Promise<ILoginModel> => {
    const { data } = await post(`ValidateCode`, {
      email: username,
      code: code,
    });
    return data;
  };

  return {
    AuthorizationCreate,
    AuthorizationValidateCode,
  };
};
