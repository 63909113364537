import {
  Combo,
  ITypeAheadFilterBy,
  IUser,
  NumberHelper,
  PaymentCreditCard,
  PaymentCreditCardForm,
} from "c4u-web-components";
import React, { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  useInspectionContext,
  useKbb,
  useSessionContext,
  useZipcodeAddress,
} from "../../../../hooks";
import { IPasswordlessParams } from "../../../../models";
import { CardVehicleDetails } from "../../../molecules";

interface IProps {
  filterBy?: ITypeAheadFilterBy;
  user?: IUser;
  show?: boolean;
}
export const InspectionPayment: React.FC<IProps> = (props) => {
  const { usernamePawordlessContext, handleEventGTM } = useSessionContext();

  const { GetVehicle } = useKbb();
  const {
    vehicleContext,
    setVehicleContext,
    vehicleDataContext,
    inspectionContext,
    setPaymentContext,
    paymentContext,
  } = useInspectionContext();

  const { t } = useTranslation();

  const { typeJorney } = useParams<IPasswordlessParams>();

  const { getAddressByZipcode } = useZipcodeAddress();
  const history = useHistory();

  const handleZipcode = async (zipcode: string) => {
    const address = await getAddressByZipcode(zipcode);
    return address;
  };

  const submit = (values: PaymentCreditCard) => {
    setPaymentContext(values);
    history.push(paths.inspectionConfirmation(typeJorney));
  };

  const addPaymentInfo = useCallback(
    (id: string) => {
      const selectedIndex = inspectionContext.plans?.findIndex(
        (plan) => plan.id === id
      );
      let inspection = { ...inspectionContext };
      inspection.inspectionPlan = inspectionContext?.plans?.find(
        (f) => f.id === id
      );

      handleEventGTM({
        event: "add_payment_info",
        ecommerce: {
          value: `${inspectionContext?.inspectionPlan?.value}`,
          currency: "BRL",
          payment_type: "Cartão de Crédito",
          items: {
            item_name: inspection.inspectionPlan?.name,
            item_id: inspection.inspectionPlan?.id,
            price: inspection.inspectionPlan?.value,
            currency: "BRL",
            affiliation: "SuperVisão",
            item_category: "Vistoria",
            item_list_name: "Pagamento",
            index: selectedIndex,
            quantity: 1,
          },
        },
      });
    },
    [handleEventGTM, inspectionContext]
  );

  useEffect(() => {
    if (!inspectionContext)
      history.replace(paths.inspectionPartner(typeJorney));
    // eslint-disable-next-line
  }, [inspectionContext]);

  useEffect(() => {
    if (usernamePawordlessContext)
      setPaymentContext({
        email: usernamePawordlessContext?.email ?? "",
        phone: usernamePawordlessContext?.cellphone ?? "",
      });
    else setPaymentContext({});
    // eslint-disable-next-line
  }, [usernamePawordlessContext]);

  useEffect(() => {
    if (vehicleContext?.id && !vehicleContext?.brandID)
      GetVehicle(
        vehicleContext.id,
        vehicleContext.molicarID,
        vehicleContext.year
      )
        .then((t) => setVehicleContext(t))
        .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleContext]);

  useEffect(() => {
    if (inspectionContext?.inspectionPlan?.id)
      addPaymentInfo(inspectionContext?.inspectionPlan?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <Col>
          <CardVehicleDetails
            photo={vehicleContext?.firstMediaCompleteURLLarge}
            plate={vehicleDataContext?.plate ?? ""}
            title={vehicleContext?.name}
            onClickEditVehicle={() =>
              history.push(paths.inspectionVehicle(typeJorney))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {!!paymentContext && (
            <PaymentCreditCardForm
              getAddress={handleZipcode}
              values={paymentContext}
              parcelables={[
                {
                  title: `1x R$ ${NumberHelper.toFormatString(
                    inspectionContext?.inspectionPlan?.value,
                    2
                  )}`,
                  value: 1,
                } as Combo,
              ]}
              submit={submit}
              translate={t}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
