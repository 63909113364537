import { useEffect, useRef } from "react";

export const usePolling = (  callback: () => void,  delay: number) => {
  const savedCallback = useRef(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
        if(savedCallback !== null && savedCallback.current !== null)
            savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [callback, delay]);
};
