import styled from "styled-components";

export const WrapperInstallationOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 36px 48px 48px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(168, 170, 172, 0.35);
  border-radius: 10px;
`;

export const TitleDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Helvetica};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 20px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const QuestionDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: ${(props) => props.theme.colors.Dark};
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
`;

export const InstallationRateDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;

  color: inherit;
`;

export const InstallationRateValueDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Helvetica};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  color: inherit;
`;

export const WrapperInformationDiv = styled.div`
  display: flex;
  gap: 6px;

  div {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    div:first-child {
      padding: 3px 0 0 0;
      display: flex;
      align-items: baseline;
    }
  }

  max-width: 535px;
`;

export const InstallationOptionInformationDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Helvetica};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.Dark};
`;
