import styled from "styled-components";

const WrapperDetail = styled.div``;

const DebtLine = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
  min-height: 50px;
  display: grid;
  align-items: center;
`;
const TitleDebt = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.MediumGray};
`;
const ValueDebt = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  text-align: right;
  color: ${(props) => props.theme.colors.MediumGray};
  align-items: center;
  display: grid;
  height: 100%;
  white-space: nowrap;
`;

const HeaderTicket = styled.div`
  border-radius: 0px 0px 8px 8px;
  min-height: 130px;
  padding: 30px 40px 20px 40px;
  h5 {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-weight: 700;
    size: 16px;
    line-height: 22px;
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.DTBlue};
  }
  h6 {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-weight: 400;
    size: 14px;
    padding: 0;
    margin: 0 0 5px 0;
    color: ${(props) => props.theme.colors.MediumGray};
  }
  h4 {
    color: ${(props) => props.theme.colors.DTBlue};
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-weight: 700;
    size: 24px;
    padding: 0;
    margin: 0;
  }
`;

const ContentProof = styled.div`
  padding: 7px 40px;
`;

const Header = styled(ContentProof)`
  border: none;
  border-radius: 0px 0px 8px 8px;
  background: ${(props) => props.theme.colors.DTBlue};
  z-index: 2;
  height: 60px;
  .auto {
    align-items: center;
    height: 100%;
  }
`;

const Body = styled.div`
  padding: 0;
  .col {
    margin: auto 0;
  }
`;

const LinePart = styled.div`
  width: 1px;
  height: 23px;
  border: 1px solid ${(props) => props.theme.colors.white};
`;

const LineTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  hr {
    border: 1px solid ${(props) => props.theme.colors.DTBlue};
    background-color: ${(props) => props.theme.colors.DTBlue};
    margin: 0;
  }
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.DTBlue};
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
`;

const TotalValueTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.DTBlue};
`;

const LabelTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.MediumGray};
`;

const LabelNormal = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.MediumGray};
`;

const LabelNormalValue = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.Dark};
`;

const TagCreditCardInfo = styled(ContentProof)`
  background: ${(props) => props.theme.colors.Background};
  height: 92px;
  display: grid;
  .col {
    display: flex;
  }
`;

const LabelTitleBlue = styled.div`
  color: ${(props) => props.theme.colors.DTBlue};
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const WarnningContentPayment = styled(ContentProof)`
  color: ${(props) => props.theme.colors.MediumGray};
  font-size: 10px;
  line-height: 16px;
  text-align: center;
`;

const FooterProofPrint = styled(ContentProof)`
  background: ${(props) => props.theme.colors.DarkGrayOpacity15};
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  margin-top: 20px;
  min-height: 60px;
`;

export {
  FooterProofPrint,
  DebtLine,
  TitleDebt,
  ValueDebt,
  WrapperDetail,
  Header,
  Body,
  LinePart,
  HeaderTicket,
  ContentProof,
  LineTitle,
  TotalValueTitle,
  LabelTitle,
  LabelNormal,
  LabelNormalValue,
  TagCreditCardInfo,
  LabelTitleBlue,
  WarnningContentPayment,
};
