import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { AppContainerWithMenu } from "./../base.template.style";
import { InsuranceAuth } from "./insurance.auth";

export const InsuranceTemplate: FunctionComponent = (props) => {
  return (
    <>
      <AppContainerWithMenu>
        <Container className={"pb-4"}>
          <InsuranceAuth>{props.children}</InsuranceAuth>
        </Container>
      </AppContainerWithMenu>
    </>
  );
};
