import { HttpHelper, TransferToUserRequest } from "c4u-web-components";
import {
  CreateInspectionRequest,
  InspectionModel,
  IGetInspectionPagedResponseModel,
  PaymentInspectionRequest,
} from "../../models";
import { productsHttp, useRequest } from "./config";

interface IInspecionReports {
  links: string[];
}

export const useProductsInspection = () => {
  const { get, post, put } = useRequest(productsHttp, "inspection");

  const getAllInspections = async (
    page: number,
    pageSize: number = 10
  ): Promise<IGetInspectionPagedResponseModel> => {
    const { data } = await get(
      `${HttpHelper.BuildQueryString({ page, pageSize })}`
    );
    return !!data ? data : [];
  };

  const saveInspection = async (
    request: CreateInspectionRequest
  ): Promise<{ id: number }> => {
    const { data } = await post(``, request);
    return data;
  };

  const payInspection = async (
    request: PaymentInspectionRequest
  ): Promise<InspectionModel> => {
    const { data } = await put(`payment`, request);
    return data;
  };

  const getInspectionReports = async (
    id: number
  ): Promise<IInspecionReports> => {
    const { data } = await get(`gettemplinkfile/${id}`);
    return data;
  };

  const putTransferInspection = async (
    request: TransferToUserRequest
  ): Promise<void> => {
    await put(`TransferInspection`, request);
  };

  return {
    getAllInspections,
    saveInspection,
    payInspection,
    getInspectionReports,
    putTransferInspection,
  };
};
