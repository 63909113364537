import styled from "styled-components";

interface IProps {
  active: boolean;
}

export const WrapperQuote = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.DarkGrayOpacity};
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  margin: auto;
  height: 100%;
`;

export const TitleHeader = styled.div`
  color: ${(p) => p.theme.colors.DarkBlue};
  min-height: 91px;
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  justify-content: center;
  display: flex;
  white-space: nowrap;
  padding: 0 15px;
  svg {
    margin: 0 10px 0 0;
  }
`;

export const RowQuotes = styled.div`
  min-height: 131px;
  display: flex;
  .ColQuotes:not(:last-child) {
    /* border-right: 1px solid ${(p) => p.theme.colors.DarkGrayOpacity}; */
  }
  .bgGray {
    background-color: ${(p) => p.theme.colors.Background};
  }
`;
export const ColQuotes = styled.div<IProps>`
  width: 100%;
  max-width: 184px;
  min-width: 130px;
  align-items: center;
  justify-content: center;
  display: grid;
  padding: 13px;
  :not(:last-child) {
    border-right: ${(p) =>
      p.active ? `inherit` : `1px solid ${p.theme.colors.DarkGrayOpacity}`};
  }
  border-right: ${(p) =>
    p.active ? `1px solid ${p.theme.colors.BlueNavy} !important` : `inherit`};
  border-left: ${(p) =>
    p.active ? `1px solid ${p.theme.colors.BlueNavy}` : `inherit`};
  border-top: ${(p) =>
    p.active
      ? `1px solid ${p.theme.colors.BlueNavy}`
      : `1px solid ${p.theme.colors.DarkGrayOpacity}`};
`;

export const RowButtons = styled.div`
  height: 48px;
  display: flex;
  div:not(:last-child) {
    border-right: 1px solid ${(p) => p.theme.colors.DarkGrayOpacity};
  }
`;

export const ColButtons = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  background: ${(props) =>
    props.active ? props.theme.colors.Confirm : props.theme.colors.BlueNavy};

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 10px;
  line-height: 44px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.white};
`;

export const NoCotation = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;

  display: grid;
  align-items: center;
  text-align: center;

  color: ${(p) => p.theme.colors.BlueNavy};

  min-height: 300px;
  height: calc(100% - 52px);

  padding: 70px;
`;

export const ButtonChoosePlan = styled.div<IProps>`
  color: ${(p) => (!p.active ? p.theme.colors.DarkBlue : p.theme.colors.white)};
  background-color: ${(p) =>
    !p.active ? p.theme.colors.white : p.theme.colors.DarkBlue};

  border: 1px solid ${(p) => p.theme.colors.KBBBlue};
  box-sizing: border-box;
  border-radius: 4px;

  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  svg {
    margin: 0 10px 0 0;
  }

  width: 242px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const ContentMobileQuoteButtons = styled.div`
  justify-content: center;
  display: flex;
  margin: 0 0 50px 0;
`;

export const QuotesItem = styled.div`
  .quote {
    margin: 0 auto;
    height: 100%;
  }
  @media (max-width: 575px) {
    .row:first-child {
      flex-wrap: inherit;
      .quote {
        max-width: 100%;
        flex: inherit;
      }
    }
  }
`;

export const DealershipSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;

  margin: 10px 0px;

  cursor: pointer;
`;

export const DealershipSectionTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;

  color: ${(props) => props.theme.colors.Dark};
`;
