import { CancelToken } from "axios";
import { HttpHelper, VehicleCategoriesEnum } from "c4u-web-components";
import {
  GetTrackerShopRequest,
  IGetTrackerListParams,
  IGetTrackerPagedResponseModel,
  IGetTrackerQuotesModel,
  ITrackerDetailModel,
  ITrackerModel,
  ITrackerPartnerModel,
  ITrackerShopsModel,
  SaveTrackerDriverRequest,
  SaveTrackerOrderRequest,
  SaveTrackerQuoteRequest,
  SaveTrackerScheduling,
  SaveTrackerVehicleRequest,
} from "../../models";
import { productsHttp, useRequest } from "./config";

export const useProductsTracker = () => {
  interface IResponseCar {
    fipeId: string;
    ituranId: number;
    kbbId: number;
    brand: string;
    modelYear: 0;
    manufacturerYear: number;
    version: string;
    model: string;
    cc: number;
  }

  const { get, post } = useRequest(productsHttp, "tracker");

  const saveTrackerVehicle = async (
    request: SaveTrackerVehicleRequest
  ): Promise<ITrackerModel> => {
    const { data } = await post(`vehicle`, request);
    return data;
  };

  const saveTrackerDriver = async (
    request: SaveTrackerDriverRequest
  ): Promise<ITrackerModel> => {
    const { data } = await post(`driver`, request);
    return data;
  };

  const saveTrackerQuote = async (
    request: SaveTrackerQuoteRequest
  ): Promise<ITrackerModel> => {
    const { data } = await post(`quote`, request);
    return data;
  };
  const saveTrackerOrder = async (
    request: SaveTrackerOrderRequest
  ): Promise<ITrackerPartnerModel> => {
    const { data } = await post(`getorder`, request);
    return data;
  };
  const saveTrackerScheduling = async (
    request: SaveTrackerScheduling
  ): Promise<void> => {
    const { data } = await post(`getscheduling`, request);
    return data;
  };

  const getShops = async (
    request: GetTrackerShopRequest
  ): Promise<ITrackerShopsModel[]> => {
    const { data } = await post(`getShops`, request);
    return data;
  };

  const getQuotes = async (
    id: number | string
  ): Promise<IGetTrackerQuotesModel> => {
    const { data } = await post(`getquote`, { trackerId: id } as ITrackerModel);
    return data;
  };

  const getTrackerList = async (
    params: IGetTrackerListParams,
    cancelToken?: CancelToken
  ): Promise<IGetTrackerPagedResponseModel> => {
    const { data } = await get(
      `list${HttpHelper.BuildQueryString(params)}`,
      cancelToken ? { cancelToken } : undefined
    );
    return data;
  };

  const getUpdateTrackerList = async (
    params: IGetTrackerListParams,
    cancelToken?: CancelToken
  ): Promise<IGetTrackerPagedResponseModel> => {
    const { data } = await get(
      `updateList${HttpHelper.BuildQueryString(params)}`,
      cancelToken ? { cancelToken } : undefined
    );
    return data;
  };

  const getTrackerDetail = async (id: number): Promise<ITrackerDetailModel> => {
    const { data } = await get(`getTracker/${id}`);
    return data;
  };

  const getVehicleByPlate = async (
    plate: string,
    trackerId?: string,
    vehicleCategory?: VehicleCategoriesEnum
  ): Promise<IResponseCar[]> => {
    const { data } = await get(
      `findByPlate/${plate}/${trackerId}${HttpHelper.BuildQueryString({
        vehicleCategory,
      })}`
    );

    return data;
  };

  return {
    saveTrackerVehicle,
    saveTrackerDriver,
    saveTrackerQuote,
    getShops,
    getQuotes,
    getTrackerList,
    getUpdateTrackerList,
    saveTrackerOrder,
    saveTrackerScheduling,
    getTrackerDetail,
    getVehicleByPlate,
  };
};
