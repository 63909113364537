import {
  Combo,
  DateHelper,
  InspectionSchedule,
  InspectionUnit,
  MaskHelper,
  ScrollHelper,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import { parse } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { conformToMask } from "react-text-mask-legacy";
import { paths } from "../../../../constants";
import {
  useProductsTracker,
  useTrackerContext,
  useZipcodeAddress,
} from "../../../../hooks";
import {
  GetTrackerShopRequest,
  IHomeInstallationAddress,
  IPasswordlessParams,
  TrackerInstallationOptionsEnum,
} from "../../../../models";
import {
  FeedbackModal,
  InstallationAddressFormMolecule,
  InstallationOptionMolecule,
} from "../../../molecules";
import { WrapperInstalationSchedule } from "./schedule-tracker-installation.organism.style";

export const ScheduleTrackerInstalationOrganism: React.FC = () => {
  const { t } = useTranslation();

  const params = useParams<IPasswordlessParams>();
  const history = useHistory();

  const { setTrackerDataContext, trackerDataContext } = useTrackerContext();
  const { getShops } = useProductsTracker();
  const { getAddressByZipcode } = useZipcodeAddress();

  const [zipCode, setZipCode] = useState<string>();
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [idUnitSelected, setIdUnitSelected] = useState<string>();
  const [idUnitScheduled, setIdUnitScheduled] = useState<string>();

  const [trackerInstallationOption, setTrackerInstallationOption] =
    useState<TrackerInstallationOptionsEnum>();
  const [installationAddressData, setInstallationAddressData] =
    useState<IHomeInstallationAddress>();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [installationOptionClicked, setInstallationOptionClicked] =
    useState<TrackerInstallationOptionsEnum>();

  const isHomeInstallation = useMemo(
    () => trackerInstallationOption === TrackerInstallationOptionsEnum.AtHome,
    [trackerInstallationOption]
  );

  const setUnits = useCallback(
    (unitsItems?: InspectionUnit[]) => {
      setTrackerDataContext({ ...trackerDataContext, units: unitsItems });
    },
    [setTrackerDataContext, trackerDataContext]
  );

  const loadShops = useCallback(
    async (zipCode: string) => {
      try {
        const shops = await getShops(
          new GetTrackerShopRequest({
            trackerId: Number(params.id),
            schedulingZipCode: zipCode,
            isHomeSchedule: isHomeInstallation,
          })
        );

        setUnits(
          shops.map((m) => {
            const today = new Date();
            return {
              address: `${
                conformToMask(m.shop.zipCode ?? "", MaskHelper.Cep, {
                  guide: false,
                }).conformedValue
              }, ${m.shop.street}, ${m.shop.number}, ${m.shop.neighborhood}, ${
                m.shop.city
              } / ${m.shop.state}`,
              emails: [],
              idUnit: `${m.shop.shopId}`,
              phones: [],
              unitName: `${m.shop.shopName}`,
              dates: m.schedules.map((d) => {
                const dateTime = parse(d.date, "dd/MM/yyyy", today);
                return {
                  date: DateHelper.toDateHourFixed(dateTime),
                  hour: d.times.length
                    ? d.times.map(
                        (ti) =>
                          new Combo({
                            title: `${t("at")} ${ti.time} ${t("TimeMark")}`,
                            value: ti.timeId,
                            dataCy: ti.time,
                          })
                      )
                    : dateTime > today
                    ? [
                        new Combo({
                          title: t("Scheduling unavailable"),
                          value: "",
                        }),
                      ]
                    : [new Combo({ title: t("Scheduling closed"), value: "" })],
                };
              }),
            };
          })
        );
      } catch (e) {
        setUnits([]);
      } finally {
        setLoadingUnits(false);
      }
    },
    [getShops, params, t, setUnits, isHomeInstallation]
  );

  useEffect(() => {
    if (loadingUnits && zipCode && trackerInstallationOption !== undefined) {
      loadShops(zipCode);
    }
  }, [loadingUnits, trackerInstallationOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoClick = useCallback(async () => {
    setUnits(undefined);
    setLoadingUnits(true);
  }, [setUnits]);

  const validZipCode = useMemo(
    () => /\d{5}-\d{3}/.test(zipCode ?? ""),
    [zipCode]
  );

  const handleChoose = (id: string) => {
    setIdUnitSelected(id);
    setIdUnitScheduled(undefined);
  };

  const getDateSchedule = (idUnit: string, date: Date, hour: string) => {
    const hourItem = units
      ?.find((f) => f.idUnit === idUnit)
      ?.dates?.map(
        (f) => f.hour.find((fh) => Number(fh.value) === Number(hour))?.title
      )
      ?.find((f) => f);
    const time = hourItem?.toString().replace(/[^\d]/g, "") ?? "0000";
    const dateItem = new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        Number(time.slice(0, 2)),
        Number(time.slice(2, 4))
      )
    );
    return dateItem.toISOString();
  };

  const handleScheduleClickOK = (id: string) => {
    history.push(paths.trackerCheckout(params.id, params.typeJorney));
  };

  const handleSchedule = (id: string, date: Date, hour: string) => {
    setIdUnitScheduled(id);
    setTrackerDataContext({
      ...trackerDataContext,
      scheduling: {
        periodNumber: Number(hour),
        shopId: Number(id),
        trackerId: Number(params.id),
        schedulingDate: getDateSchedule(id, date, hour),
        shopName: units?.find((f) => f.idUnit === id)?.unitName,
        shopAddress: units?.find((f) => f.idUnit === id)?.address,
        zipCode: zipCode,
        isHomeSchedule: isHomeInstallation,
        homeScheduleAddresses: installationAddressData,
      },
    });
    handleScheduleClickOK(id);
  };

  useEffect(() => {
    if (!zipCode) {
      if (trackerDataContext?.scheduling?.zipCode) {
        setIdUnitSelected(trackerDataContext.scheduling.shopId?.toString());
        setIdUnitScheduled(
          trackerDataContext.scheduling.periodNumber?.toString()
        );
        setZipCode(trackerDataContext.scheduling.zipCode);
      } else if (trackerDataContext?.order?.zipCode) {
        const cep = conformToMask(
          trackerDataContext.order?.zipCode ?? "",
          MaskHelper.Cep,
          { guide: false }
        ).conformedValue;
        if (/\d{5}-\d{3}/.test(cep)) {
          setZipCode(cep);
        }
      }
    }
  }, [trackerDataContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (validZipCode && trackerInstallationOption !== undefined) {
      if (!trackerDataContext.scheduling?.shopId) {
        setIdUnitScheduled(undefined);
        setIdUnitSelected(undefined);
      }
      handleGoClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackerInstallationOption, validZipCode, zipCode]);

  const units = useMemo(() => trackerDataContext?.units, [trackerDataContext]);

  const initialValues: IHomeInstallationAddress = useMemo(
    () => ({
      name: trackerDataContext?.driver?.name ?? "",
      phone: trackerDataContext?.driver?.cellphone ?? "",
      zipCode: zipCode ?? "",
      street: trackerDataContext?.order?.street ?? "",
      number: trackerDataContext?.order?.number ?? "",
      complement: trackerDataContext?.order?.complement ?? "",
      district: trackerDataContext?.order?.district ?? "",
      city: trackerDataContext?.order?.city ?? "",
      state: trackerDataContext?.order?.state ?? "",
      referencePoint: "",
    }),
    [trackerDataContext, zipCode]
  );

  useEffect(() => {
    if (
      trackerDataContext?.scheduling?.isHomeSchedule !== undefined &&
      trackerDataContext?.scheduling?.isHomeSchedule !== null
    ) {
      if (trackerDataContext?.scheduling?.isHomeSchedule) {
        setTrackerInstallationOption(TrackerInstallationOptionsEnum.AtHome);
      } else {
        setTrackerInstallationOption(
          TrackerInstallationOptionsEnum.AtPartnerShop
        );
      }
    }
  }, [trackerDataContext]);

  useEffect(() => {
    if (trackerDataContext?.scheduling?.isHomeSchedule !== undefined) {
      if (
        trackerInstallationOption ===
        TrackerInstallationOptionsEnum.AtPartnerShop
      ) {
        ScrollHelper.scrollToElementById(
          document,
          "installation-option-choose",
          70
        );
      } else if (
        trackerInstallationOption === TrackerInstallationOptionsEnum.AtHome
      ) {
        ScrollHelper.scrollToElementById(
          document,
          "installation-contact-form",
          70
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackerInstallationOption]);

  return (
    <>
      {trackerDataContext === undefined ? (
        <SpinnerCenteredAtom />
      ) : (
        <WrapperInstalationSchedule>
          <InstallationOptionMolecule
            trackerInstallationOption={trackerInstallationOption}
            id="installation-option-choose"
            onChooseInstallation={(
              v: TrackerInstallationOptionsEnum | undefined
            ) => {
              setShowConfirmModal(true);
              setInstallationOptionClicked(v);
            }}
            rateValue={trackerDataContext?.scheduling?.displacementValue}
          />

          {isHomeInstallation && (
            <InstallationAddressFormMolecule
              getAddress={getAddressByZipcode}
              initialValues={initialValues}
              setInstallationAddressData={setInstallationAddressData}
              idContact="installation-contact-form"
              idAddress="installation-address-form"
              onChangeZipcode={setZipCode}
              zipCode={zipCode}
            />
          )}

          {trackerInstallationOption !== undefined && (
            <InspectionSchedule
              onChangeZipCode={(e: any) => setZipCode(e.target.value)}
              onClickGo={handleGoClick}
              validZipCode={validZipCode}
              onChoose={handleChoose}
              unitIdChoosed={idUnitSelected}
              onSchedule={handleSchedule}
              onConfirm={handleScheduleClickOK}
              loading={loadingUnits}
              unitId={idUnitScheduled}
              units={units}
              zipcode={zipCode}
              translate={t}
              confirmationScheduleAdvice={t("ConfirmationSchedule")}
              description={t("DescriptionTrackerSchedule")}
              textButtonConfirm={t("Schedule installation")}
              hideZipcodeComponent={isHomeInstallation}
              fullPageWidth
            />
          )}
        </WrapperInstalationSchedule>
      )}

      <FeedbackModal
        type="confirm"
        title={t("Atention")}
        content={
          <span>
            <strong>{t("AtHomeImportantInformation")}</strong>
          </span>
        }
        show={showConfirmModal}
        onHide={() => {
          setShowConfirmModal(false);
          setInstallationOptionClicked(undefined);
        }}
        onClickButton={() => {
          setShowConfirmModal(false);
          setTrackerInstallationOption(installationOptionClicked);
        }}
      />
    </>
  );
};
