import styled from "styled-components";

const Wrapper = styled.div`
  img {
    width: 85%;
  }
`;

const CommingSoonAdvice = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */

  display: flex;
  align-items: center;
  letter-spacing: -1px;
  color: ${(props) => props.theme.colors.BlueNavy};

  position: absolute;
  width: 180px;
  top: 50%;

  @media (max-width: 992px) {
    position: relative;
    width: 100%;
    top: 0;
  }
`;

export { CommingSoonAdvice, Wrapper };
