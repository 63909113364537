import React from "react";
import { useTranslation } from "react-i18next";
import {
  Price,
  SubTitle,
  Title,
  WrapperQuoteOkNokItem,
} from "./quote-item.atom.style";

interface IProps {
  title: string;
  price?: string;
  description: string;
}

export const QuoteItemFranchiseAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const hasPrice =
    !!props.price && props.price !== "R$ 0,00" && props.price !== "-";

  return (
    <WrapperQuoteOkNokItem>
      <Title>{props.title}</Title>
      <Price className={!hasPrice ? "reduce-font" : ""}>
        {hasPrice ? props.price : t("NoFranchise")}
      </Price>
      {!!props.description && <SubTitle>{props.description}</SubTitle>}
    </WrapperQuoteOkNokItem>
  );
};
