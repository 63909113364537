import { TypesHelper } from "c4u-web-components";

export interface IDebtsVehicleRegisterRequest {
  document?: string;
  plate?: string;
  renavam?: string;
  detranUf?: string;
  passwordless?: string;
}

export class DebtsVehicleRegisterRequest {
  document: string | null;
  plate: string | null;
  renavam: string | null;
  detranUf: string | null;
  passwordless: string | null;

  constructor({
    document,
    plate,
    renavam,
    detranUf,
    passwordless,
  }: IDebtsVehicleRegisterRequest) {
    this.document = TypesHelper.toStringOnlyNumbersNull(document);
    this.plate = TypesHelper.toStringNull(plate?.replace("-", ""));
    this.renavam = TypesHelper.toStringNull(renavam);
    this.renavam = this.renavam ? this.renavam.padStart(11, "0") : this.renavam;
    this.detranUf = TypesHelper.toStringNull(detranUf);
    this.passwordless = TypesHelper.toStringNull(passwordless);
  }
}
