import { Row } from "react-bootstrap";
import styled from "styled-components";

const WrapperBoxDebit = styled.div`
  min-height: 68px;
  background-color: ${(props) => props.theme.colors.DarkGrayOpacity15};
  border-radius: 10px;
`;
const RowWrapperDebit = styled(Row)`
  margin-bottom: 20px;
`;

const BoxDebit = styled.div`
  min-height: 68px;
  height: 100%;
  max-width: 176px;
  background: ${(props) => props.theme.colors.BlueHigh};
  border-radius: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  margin: 0 0 0 auto;
`;
const BoxDebitValue = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily.Montserrat} !important;
`;
const BoxDebitInformation = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${(props) => props.theme.colors.BlueNavy};
`;

const DebitName = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: Dark};
`;

const DebitInformation = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.MediumGray};
`;

const BoxDebitDescription = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  padding: 14px 0;
  max-width: 340px;
`;
const BoxCheck = styled.div`
  display: grid;
  align-items: center;
  justify-content: end;
  padding: 14px 0 14px 15px;
`;

export {
  BoxCheck,
  WrapperBoxDebit,
  BoxDebitDescription,
  BoxDebit,
  BoxDebitValue,
  BoxDebitInformation,
  DebitName,
  DebitInformation,
  RowWrapperDebit,
};
