import { HttpHelper } from "c4u-web-components";
import { GetDeliveriesRequest, IGetDeliveriesResponse } from "../../models";
import {
  ManheinTokenHttp,
  manheimTokenClientConfig,
} from "./config/axios-instance/manhein-token-http";
import { useManheimRequest } from "./config/use-request/use-manheim-request.hook";

export const useManheinToken = () => {
  const { get } = useManheimRequest(
    ManheinTokenHttp,
    "BuyerDeliveryCpv",
    manheimTokenClientConfig
  );

  const getDeliveriesNotComplete = async (
    request: GetDeliveriesRequest
  ): Promise<IGetDeliveriesResponse[]> => {
    const { data } = await get(
      `getDeliveriesNotComplete${HttpHelper.BuildQueryString(request)}`
    );

    return data;
  };

  const getDeliveriesNotStarted = async (
    request: GetDeliveriesRequest
  ): Promise<IGetDeliveriesResponse[]> => {
    const { data } = await get(
      `getDeliveriesNotStarted${HttpHelper.BuildQueryString(request)}`
    );

    return data;
  };

  return {
    getDeliveriesNotComplete,
    getDeliveriesNotStarted,
  };
};
