import { TypesHelper } from "c4u-web-components";

export interface ISaveTrackerVehicleRequest {
  trackerId?: number;
  plate?: string;
  vin?: string;
  dealershipExit?: Date;
  gasKit?: string;
  vehicleUse?: string;
  taxFree?: boolean;
  taxFreeReason?: string;
  isTunning?: string | boolean;
  isZeroKm?: string | boolean;
  kbbId?: number;
  molicarId?: string;
  vehicleName?: string;
  overnightZipCode?: string;
  passwordless?: string;
  modelYear?: number;
}

export class SaveTrackerVehicleRequest {
  trackerId: number | null;
  plate: string | null;
  vin: string | null;
  dealershipExit: Date | null;
  gasKit: string | null;
  vehicleUse: string | null;
  taxFree: boolean | null;
  taxFreeReason: string | null;
  isTunning: boolean | null;
  isZeroKm: boolean | null;
  kbbId: number | null;
  molicarId: string | null;
  vehicleName: string | null;
  overnightZipCode: string | null;
  passwordless: string | null;
  modelYear?: number | null;
  constructor({
    trackerId,
    vin,
    plate,
    dealershipExit,
    gasKit,
    vehicleUse,
    taxFree,
    taxFreeReason,
    isTunning,
    isZeroKm,
    kbbId,
    molicarId,
    vehicleName,
    overnightZipCode,
    passwordless,
    modelYear,
  }: ISaveTrackerVehicleRequest) {
    this.trackerId = TypesHelper.toNumberNull(trackerId) || null;
    this.plate = TypesHelper.toStringNull(plate);
    this.vin = TypesHelper.toStringNull(vin);
    this.dealershipExit = TypesHelper.toDateNull(dealershipExit);
    this.gasKit = TypesHelper.toStringNull(gasKit);
    this.vehicleUse = TypesHelper.toStringNull(vehicleUse);
    this.taxFree = TypesHelper.toBooleanNull(taxFree);
    this.taxFreeReason = TypesHelper.toStringNull(taxFreeReason);
    this.isTunning = TypesHelper.toBooleanNull(isTunning);
    this.isZeroKm = TypesHelper.toBooleanNull(isZeroKm);
    this.kbbId = TypesHelper.toNumberNull(kbbId);
    this.molicarId = TypesHelper.toStringNull(molicarId);
    this.vehicleName = TypesHelper.toStringNull(vehicleName);
    this.overnightZipCode =
      TypesHelper.toStringOnlyNumbersNull(overnightZipCode);
    this.passwordless = TypesHelper.toStringNull(passwordless);
    this.modelYear = TypesHelper.toNumberNull(modelYear);
  }
}
