import { MsgValidation, RegexValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IFormDebtsVehicleRegister } from "./form-debts-vehicle-register.molecule";

export const FormDebtsVehicleRegisterValidation = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isValidByRegex",
    YupHelper.isValidByRegex
  );

  const msgRequired = t(MsgValidation.RequiredField);

  return Yup.object<IFormDebtsVehicleRegister>({
    detranUf: Yup.string().required(msgRequired),
    document: Yup.string()
      .required(msgRequired)
      .isCpfCnpjValid(t("Invalid Document")),
    plate: Yup.string().required(msgRequired),
    renavam: Yup.string()
      .required(msgRequired)
      .isValidByRegex(RegexValidation.Renavam, t("Invalid Renavam")),
  });
};
