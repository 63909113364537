import { useRequest, manheinHttp } from "./config";
import {
  InspectionCompanyEnum,
  InspectionPlan,
  InspectionUnit,
} from "c4u-web-components";

export const useManhein = () => {
  const { get } = useRequest(manheinHttp, "");

  const getPlans = async (
    inspectionCompany: InspectionCompanyEnum,
    passwordless: boolean
  ): Promise<InspectionPlan[]> => {
    const { data } = await get(
      `AdInspection/plans/${inspectionCompany}/${passwordless ? 3 : 2}`
    );
    return !!data ? data : [];
  };

  const getUnits = async (
    inspectionCompany: InspectionCompanyEnum,
    zipcode: number,
    limit: number,
    offset: number
  ): Promise<InspectionUnit[]> => {
    const { data } = await get(
      `AdInspection/units/${inspectionCompany}/${zipcode}/${limit}/${offset}`
    );
    return !!data ? data : [];
  };

  return {
    getUnits,
    getPlans,
  };
};
