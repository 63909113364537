import styled from "styled-components";

export const ContentVehicleSelection = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 36px 26px;
  margin-bottom: 18px;
  min-height: 700px;
  .text-vehicle-brand {
    margin: 18px 0 6px;
  }
  .text-vehicle-year {
    margin: 6px 0;
  }
  .text-vehicle-model {
    margin: 6px 0;
  }
  .text-vehicle-version {
    margin: 6px 0;
  }

  h2 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #1f3e74;
    letter-spacing: -0.15px;

    margin: 0 0 15px 4px;
  }
`;
