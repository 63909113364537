import React, { useEffect, useState } from "react";
import { IEmbedConfiguration } from "powerbi-client";
import { PowerBIEmbedDiv } from "../shared/power-bi.organism.style";
import "powerbi-report-authoring";
import { useProductsManagement, usePolling } from "../../../hooks";
import { PowerBIEmbed } from "powerbi-client-react";
import { SpinnerCenteredAtom } from "c4u-web-components";

export const FenautoReport: React.FC = () => {
  const { getFenauto, getKeepPowerBi } = useProductsManagement();

  const [reportConfig, setReportConfig] = useState<IEmbedConfiguration>();

  useEffect(() => {
    getFenauto().then((managementData) => {
      setReportConfig({
        type: managementData.type,
        embedUrl: managementData.embedUrl,
        tokenType: managementData.tokenType,
        accessToken: managementData.accessToken,
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  usePolling(() => {
    getKeepPowerBi();
  }, 1000 * 60 * parseInt(process.env.KEEP_POWER_BI_MINUTES ?? "2"));

  return !reportConfig ? (
    <SpinnerCenteredAtom />
  ) : (
    <PowerBIEmbedDiv>
      <PowerBIEmbed embedConfig={reportConfig} />
    </PowerBIEmbedDiv>
  );
};
