import { FormControlMask } from "c4u-web-components";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useInspectionContext } from "../../../../hooks";
import { Wrapper } from "./vehicle-data.molecule.style";

interface IProps {
  data?: {
    plate: string;
    renavam: string;
  };
}

export const VehicleData: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { vehicleContext, vehicleDataContext, setVehicleDataContext } =
    useInspectionContext();

  const handlePlate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleDataContext({
      ...vehicleDataContext,
      plate: e.target.value.toUpperCase(),
    });
  };
  const handleRenavam = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleDataContext({
      ...vehicleDataContext,
      renavam: e.target.value?.replace(/[^\d]/g, ""),
    });
  };

  return (
    <Wrapper>
      {vehicleContext && (
        <Form.Row>
          <Form.Group as={Col} xs={6} controlId="Plate">
            <Form.Label>{t("Vehicle Plate")}</Form.Label>
            <Form.Control
              value={vehicleDataContext?.plate}
              placeholder={t("Tab here")}
              onChange={handlePlate}
              maxLength={7}
            />
          </Form.Group>
          <Form.Group as={Col} xs={6} controlId="renavam">
            <Form.Label>{t("Renavam")}</Form.Label>
            <FormControlMask
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              placeholderChar={"\u2000"}
              showMask={false}
              placeholder={t("Tab here")}
              onChange={handleRenavam}
              value={vehicleDataContext?.renavam}
            />
          </Form.Group>
        </Form.Row>
      )}
    </Wrapper>
  );
};
