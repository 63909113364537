import { CoxIcon, RoundedRectButtonAtom } from "c4u-web-components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TrackerInstallationOptionsEnum } from "../../../../models";

import {
  InstallationRateDiv,
  InstallationRateValueDiv,
  OptionsWrapper,
  QuestionDiv,
  TitleDiv,
  WrapperInstallationOption,
  InstallationOptionInformationDiv,
  WrapperInformationDiv,
} from "./installation-option.molecule.style";

interface IProps {
  trackerInstallationOption: TrackerInstallationOptionsEnum | undefined;
  id?: string;
  onChooseInstallation: (v: TrackerInstallationOptionsEnum) => void;
  rateValue?: number;
}

export const InstallationOptionMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const defaultInstallationFee = "60,00";

  const rateValue = useMemo(
    () =>
      props.rateValue?.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
    [props.rateValue]
  );

  const atHomeDescription = useMemo(
    () => (
      <>
        <InstallationRateDiv>{t("InstallationRate")}</InstallationRateDiv>
        <InstallationRateValueDiv>
          R$ {rateValue ?? defaultInstallationFee}
        </InstallationRateValueDiv>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rateValue]
  );

  const atPartnerDescription = useMemo(
    () => (
      <>
        <InstallationRateDiv>{t("InstallationRate")}</InstallationRateDiv>
        <InstallationRateValueDiv>{t("Free")}</InstallationRateValueDiv>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <WrapperInstallationOption id={props.id}>
        <TitleDiv>{t("TrackerInstallationOptionTitle")}</TitleDiv>
        <QuestionDiv>{t("TrackerInstallationOptionQuestion")}</QuestionDiv>
        <OptionsWrapper>
          <RoundedRectButtonAtom
            data-cyid="athomeinstallation"
            text={t("AtHomeInstallation")}
            description={atHomeDescription}
            selected={
              props.trackerInstallationOption ===
              TrackerInstallationOptionsEnum.AtHome
            }
            onClick={() =>
              props.onChooseInstallation(TrackerInstallationOptionsEnum.AtHome)
            }
            disabled={!!props.trackerInstallationOption}
          />
          <RoundedRectButtonAtom
            data-cyid="atpatnershopinstallation"
            text={t("AtPartnerShopInstallation")}
            description={atPartnerDescription}
            selected={
              props.trackerInstallationOption ===
              TrackerInstallationOptionsEnum.AtPartnerShop
            }
            onClick={() =>
              props.onChooseInstallation(
                TrackerInstallationOptionsEnum.AtPartnerShop
              )
            }
            disabled={!!props.trackerInstallationOption}
          />
        </OptionsWrapper>

        <WrapperInformationDiv>
          <div>
            <CoxIcon name="warning-color" height={16} width={16} />
          </div>
          <InstallationOptionInformationDiv>
            {t("InstallationOptionInformation")}
          </InstallationOptionInformationDiv>
        </WrapperInformationDiv>
      </WrapperInstallationOption>
    </>
  );
};
