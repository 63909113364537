import styled from "styled-components";

const WrapperBoxConfirmation = styled.div`
  border: 1px solid rgba(168, 170, 172, 0.35);
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  min-height: 300px;
  height: 100%;
  display: grid;
`;

const BoxListValues = styled.div`
  background: ${(props) => props.theme.colors.BlueHigh};
  padding: 25px 45px;
  display: grid;
  height: 100%;
  * {
    font-family: ${(props) => props.theme.fontFamily.Montserrat} !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.white};
  }
  label {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
  }
`;

const BoxBackShoopResume = styled.div`
  background: ${(props) => props.theme.colors.white};
  display: grid;
  height: 100%;
`;
const BoxShoopResume = styled.div`
  background: ${(props) => props.theme.colors.white};
  display: grid;
  height: 100%;
  padding: 30px 50px;
  max-height: 450px;
  min-height: 270px;
`;

const BoxValueDescription = styled.div`
  display: grid;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  min-height: 36px;
  padding: 0 20px;
  .description {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: ${props => props.theme.colors.MediumGray};
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .value {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: ${props => props.theme.colors.BlueHigh};
    font-family: ${(props) => props.theme.fontFamily.Montserrat} !important;
    margin: auto;
  }
`;

const WhiteCircle = styled.div`
  border: 1px solid ${(props) => props.theme.colors.white};
  border-radius: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

const TitlePayment = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat} !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.Dark};
`;
const PaymentWay = styled.div`
  * {
    font-family: ${(props) => props.theme.fontFamily.Montserrat} !important;
    font-weight: bold;
    color: ${(props) => props.theme.colors.MediumGray};
  }
`;
const PaymentTaxe = styled.div`
  * {
    font-family: ${(props) => props.theme.fontFamily.Montserrat} !important;
    font-weight: bold;
    color: ${(props) => props.theme.colors.DarkGray};
  }
`;
const PaymentValue = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat} !important;
  background: ${(props) => props.theme.colors.BlueHigh};
  font-weight: bold;
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  line-height: 22px;
  display: grid;
  height: 100%;
  align-items: center;
  padding: 0 15px;
  border-radius: 4px;
`;
const PaymentTerms = styled.div`
  * {
    font-size: 10px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.Dark};
    text-align: center;
    margin: auto;
  }
`;

export {
  WrapperBoxConfirmation,
  BoxListValues,
  BoxShoopResume,
  WhiteCircle,
  BoxValueDescription,
  TitlePayment,
  PaymentWay,
  PaymentTaxe,
  PaymentValue,
  PaymentTerms,
  BoxBackShoopResume,
};
