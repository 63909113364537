import { IPagedResponseBaseModel } from "c4u-web-components";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import {
  useInspectionContext,
  useProductsTracker,
  useTrackerContext,
} from "../../../hooks";
import {
  IGetTrackerPagedResponseModel,
  IGetTrackerResponse,
  IHeaderMainTemplate,
  IPasswordlessParams,
} from "../../../models";
import { HeaderSearchFilterAtom } from "../../atoms";
import { TrackerMyQuotesOrganism } from "../../organisms";
import { TrackerBasePage } from "./base/tracker-base.page";

export const TrackerMyQuotesPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getTrackerList } = useProductsTracker();
  const { typeJorney } = useParams<IPasswordlessParams>();
  const { setVehicleContext } = useInspectionContext();
  const { setTrackerDataContext, setOriginInsuranceContext } =
    useTrackerContext();

  const [paginationData, setPaginationData] =
    useState<IPagedResponseBaseModel>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [trackerQuotesHistory, setTrackerQuotesHistory] =
    useState<IGetTrackerResponse[]>();

  const setTrakerHistoryData = useCallback(
    (responseData: IGetTrackerPagedResponseModel | undefined) => {
      if (responseData === undefined) {
        setTrackerQuotesHistory(undefined);
        setPaginationData(undefined);
        return;
      }
      const { data, ...paginationData } = responseData;
      setTrackerQuotesHistory(data);
      setPaginationData(paginationData);
    },
    []
  );

  const header = useMemo(() => {
    return {
      Title: t("TitleTrackerMyQuotesPage"),
      NavigationButtonAction: () => {
        setTrackerDataContext(undefined);
        setVehicleContext(undefined);
        setOriginInsuranceContext(false);
        history.push(paths.trackerVehicleSelection(0, typeJorney));
      },
      NavigationButtonText: t("GetNewQuote"),
      hideReturnButton: true,
      hideAndAlignLeft: true,
      homeList: true,
    } as IHeaderMainTemplate;
  }, [
    history,
    t,
    typeJorney,
    setTrackerDataContext,
    setVehicleContext,
    setOriginInsuranceContext,
  ]);

  return (
    <TrackerBasePage header={header}>
      <HeaderSearchFilterAtom
        getTrackerList={getTrackerList}
        setTrakerHistoryData={setTrakerHistoryData}
        disableFormSubmit={trackerQuotesHistory === undefined}
        pageNumber={pageNumber}
      />
      <TrackerMyQuotesOrganism
        quotesTraker={trackerQuotesHistory}
        setPageNumber={setPageNumber}
        paginationData={paginationData}
      />
    </TrackerBasePage>
  );
};
