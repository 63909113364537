import { CheckoutInspection, PasswordlessSendByEnum } from "c4u-web-components";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  useInspectionContext,
  useProductsInspection,
  useSessionContext,
} from "../../../../hooks";
import {
  ConflictRequestError,
  IPasswordlessParams,
  IPaymentInspectionRequest,
  PaymentInspectionRequest,
} from "../../../../models";
import { FeedbackModal } from "../../../molecules";

export const InspectionConfirmation: React.FC = () => {
  const {
    vehicleContext,
    vehicleDataContext,
    inspectionContext,
    setInspectionContext,
    idInspectionContext,
    paymentContext,
  } = useInspectionContext();

  const { t } = useTranslation();

  const { typeJorney } = useParams<IPasswordlessParams>();

  const history = useHistory();

  const { payInspection } = useProductsInspection();
  const {
    showGenericErrorModal,
    showGenericWarningModal,
    usernamePawordlessContext,
    handleEventGTM,
  } = useSessionContext();

  const [submiting, setSubmiting] = useState(false);

  const handleChangeData = () => {
    history.push(paths.inspectionPartner(typeJorney));
  };
  const handleChangeInspection = () => {
    let inspection = { ...inspectionContext };
    inspection.dateSchedule = undefined;
    inspection.hourSchedule = undefined;
    inspection.unitSchedule = undefined;
    setInspectionContext(inspection);
    history.push(paths.inspectionPartner(typeJorney));
  };

  const handleFinish = async () => {
    setSubmiting(true);
  };

  const vehicleNameFormat = `${vehicleContext?.brandName} ${vehicleContext?.modelName} ${vehicleContext?.versionName} ${vehicleContext?.hp} Cv - ${vehicleContext?.year}`;

  const [showModal, setShowModal] = useState(false);
  const submit = async () => {
    try {
      const request = new PaymentInspectionRequest({
        id: idInspectionContext,
        phoneNumber: paymentContext.phone,
        email: paymentContext.email,
        name: usernamePawordlessContext?.name,
        passwordless:
          usernamePawordlessContext.sendBy === PasswordlessSendByEnum.Sms
            ? usernamePawordlessContext.cellphone
            : usernamePawordlessContext.email,
        birthdate: paymentContext.birthDate,
        city: paymentContext.city,
        complement: paymentContext.complement,
        document: paymentContext.personalDocument,
        inspectionDate: inspectionContext?.dateSchedule,
        inspectionPeriod: inspectionContext?.hourSchedule,
        inspectionPlaceAddress: inspectionContext?.unitSchedule?.address,
        inspectionPlaceEmails: inspectionContext?.unitSchedule?.emails,
        inspectionPlaceId: inspectionContext?.unitSchedule?.idUnit,
        inspectionPlaceName: inspectionContext?.unitSchedule?.unitName,
        inspectionPlacePhones: inspectionContext?.unitSchedule?.phones,
        inspectionPlanId: inspectionContext?.inspectionPlan?.id,
        inspectionPlanName: inspectionContext?.inspectionPlan?.name,
        inspectionPlanValue: inspectionContext?.inspectionPlan?.value,
        kbbId: vehicleContext.id,
        vehicleName: vehicleNameFormat,
        neighborhood: paymentContext.neighborhood,
        number: paymentContext.number,
        partnerId: inspectionContext?.inspectionCompany,
        state: paymentContext.state,
        street: paymentContext.street,
        zipCode: paymentContext.zipCode,
        creditCardCvv: paymentContext.cvv,
        creditCardMonthValid: paymentContext.creditCardmonthValidate,
        creditCardName: paymentContext.creditCardName,
        creditCardNumber: paymentContext.creditCardNumber,
        creditCardYearValid: paymentContext.creditCardyearValidate,
        plate: vehicleDataContext.plate,
        renavam: vehicleDataContext.renavam,
      } as IPaymentInspectionRequest);

      await payInspection(request);
      const selectedIndex = inspectionContext.plans?.findIndex(
        (plan) => plan.id === inspectionContext.inspectionPlan?.id
      );
      handleEventGTM({
        event: "purchase",
        ecommerce: {
          transaction_id: `${idInspectionContext}`,
          value: `${inspectionContext?.inspectionPlan?.value}`,
          currency: "BRL",
          items: {
            item_name: inspectionContext.inspectionPlan?.name,
            item_id: inspectionContext.inspectionPlan?.id,
            price: inspectionContext.inspectionPlan?.value,
            currency: "BRL",
            affiliation: "SuperVisão",
            item_category: "Vistoria",
            item_list_name: "Confirmação",
            index: selectedIndex,
            quantity: 1,
          },
        },
      });
      setShowModal(true);
    } catch (error) {
      console.log("Error on save", error);
      if (error instanceof ConflictRequestError && error?.message)
        showGenericWarningModal(error?.message, () =>
          history.push(paths.inspectionPayment(typeJorney))
        );
      else
        showGenericErrorModal(undefined, () =>
          history.push(paths.inspectionPayment(typeJorney))
        );
    } finally {
      setSubmiting(false);
    }
  };

  const beginCheckout = useCallback(
    (id: string) => {
      const selectedIndex = inspectionContext.plans?.findIndex(
        (plan) => plan.id === id
      );
      let inspection = { ...inspectionContext };
      inspection.inspectionPlan = inspectionContext?.plans?.find(
        (f) => f.id === id
      );

      handleEventGTM({
        event: "begin_checkout",
        ecommerce: {
          value: `${inspectionContext?.inspectionPlan?.value}`,
          currency: "BRL",
          items: {
            item_name: inspection.inspectionPlan?.name,
            item_id: inspection.inspectionPlan?.id,
            price: inspection.inspectionPlan?.value,
            currency: "BRL",
            affiliation: "SuperVisão",
            item_category: "Vistoria",
            item_list_name: "Confirmação",
            index: selectedIndex,
            quantity: 1,
          },
        },
      });
    },
    [handleEventGTM, inspectionContext]
  );

  useEffect(() => {
    if (submiting) {
      submit();
    }
  }, [submiting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!inspectionContext)
      history.replace(paths.inspectionPartner(typeJorney));
    // eslint-disable-next-line
  }, [inspectionContext]);

  useEffect(() => {
    if (inspectionContext?.inspectionPlan?.id)
      beginCheckout(inspectionContext?.inspectionPlan?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FeedbackModal
        type={"success"}
        show={showModal}
        onHide={() => history.push(paths.myInspections(typeJorney))}
        title={t("TitleModalInspectionConfirmation")}
        content={t("DescriptionModalInspectionConfirmation")}
        textButton={t("TitleMyInspectionsPage")}
        onClickButton={() => history.push(paths.myInspections(typeJorney))}
      />
      <Row>
        <Col>
          <CheckoutInspection
            id={vehicleContext?.id ?? 0}
            vehicleDescription={vehicleContext?.name}
            plate={vehicleDataContext?.plate ?? ""}
            inpectionTime={`${
              inspectionContext?.dateSchedule
                ? format(
                    inspectionContext?.dateSchedule,
                    `dd '${t("of")}' MMMM '${t("of")}' yyyy`,
                    { locale: ptBR }
                  )
                : ""
            } ${t("at")} ${inspectionContext?.hourSchedule}`}
            inspectionUnit={inspectionContext?.unitSchedule?.unitName ?? ""}
            inspectionAddress={inspectionContext?.unitSchedule?.address ?? ""}
            inspectionPlan={inspectionContext?.inspectionPlan?.name ?? ""}
            inspectionValue={inspectionContext?.inspectionPlan?.value ?? 0}
            date={new Date()}
            onClickChangeData={handleChangeData}
            onClickChangeInspection={handleChangeInspection}
            onClickFinish={handleFinish}
            loading={submiting}
            translate={t}
          />
        </Col>
      </Row>
    </>
  );
};
