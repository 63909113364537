import React from "react";
import { useTranslation } from "react-i18next";

import {
  WrapperInfoControl,
  StepTitle,
  DataValue,
  NumberStep,
  GroupPriceBox,
  DescriptionGroup,
  DescriptionGroupAuxiliar,
  DescriptionGroupContent,
  TitleSuperior,
} from "./checkout-info.molecule.style";
import { Col, Row } from "react-bootstrap";
import { NumberHelper } from "c4u-web-components";
import { format } from "date-fns";

interface IProps {
  steps: (string | React.ReactNode)[];
  planName: string;
  planValue: number;
  quoteNumber: string;
  quoteDate?: Date;
}

export const CheckoutInfoMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <WrapperInfoControl>
      <Row>
        <Col>
          <StepTitle>{t("Quote summary")}</StepTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataValue>
            <b>{t("Quote number")}:</b> {props.quoteNumber}
          </DataValue>
        </Col>
      </Row>
      <Row>
        <Col>
          <DataValue>
            <b>{t("Quote date")}:</b> {props.quoteDate && format(props.quoteDate, "dd/MM/yyyy")}
          </DataValue>
        </Col>
      </Row>

      <Row className={"mt-4"}>
        <Col>
          <StepTitle>{t("Nexts steps")}:</StepTitle>
        </Col>
      </Row>
      {props.steps.map((m, i) => (
        <Row key={`RowInfo${i}`}>
          <Col xs={"auto"} className={"pr-0"}>
            <NumberStep>{i + 1}</NumberStep>
          </Col>
          <Col xs>{m}</Col>
        </Row>
      ))}

      <GroupPriceBox>
        <TitleSuperior>{props.planName}</TitleSuperior>
        <DescriptionGroup>
          <DescriptionGroupContent>
            {t("$")} {NumberHelper.toFormatString(props.planValue, 2)}
          </DescriptionGroupContent>
          <DescriptionGroupAuxiliar>{t("Monthly")}</DescriptionGroupAuxiliar>
        </DescriptionGroup>
      </GroupPriceBox>
    </WrapperInfoControl>
  );
};
