import { InsuranceCardMolecule, TrackerStepEnum } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IGetTrackerResponse } from "../../../../models";
import { LogoIturan } from "../../../assets";
import {
  BodyIconDiv,
  CardBodyIcons,
} from "./tracker-quote-card.molecule.style";

interface IProps {
  data: IGetTrackerResponse;
  viewTrackerOnClick?: () => void;
}

export const TrackerQuoteCardMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const getStatusColor = (status: TrackerStepEnum) => {
    switch (status) {
      case TrackerStepEnum.Canceled:
        return "#C4390A";
      case TrackerStepEnum.Finished:
        return "#4AA434";
      case TrackerStepEnum.Scheduled:
        return "#EEA200";
      default:
        return "#005ba8";
    }
  };

  const getStatusIconName = (status: TrackerStepEnum): string => {
    switch (status) {
      case TrackerStepEnum.Vehicle:
      case TrackerStepEnum.Driver:
      case TrackerStepEnum.Quote:
      case TrackerStepEnum.Order:
        return "approved-color";
      case TrackerStepEnum.Scheduled:
      default:
        return "warning-color";
    }
  };

  const [fillColor, setFillColor] = useState<string>("");
  const [statusIconName, setStatusIconName] = useState<string>("");

  useEffect(() => {
    setFillColor(getStatusColor(props.data.step));
    setStatusIconName(getStatusIconName(props.data.step));
  }, [props.data.step]);

  const getCompanyLogo = () => {
    return <Image width={72} height={32} src={LogoIturan} alt="Logo Ituran" />;
  };

  const getBodyIcons = () => {
    const shieldIcon = (
      <svg
        width="24"
        height="30"
        viewBox="0 0 24 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 0.333984L0 5.66732V13.6673C0 21.0673 5.12 27.9873 12 29.6673C18.88 27.9873 24 21.0673 24 13.6673V5.66732L12 0.333984ZM12 14.9873H21.3333C20.6267 20.4806 16.96 25.374 12 26.9073V15.0007H2.66667V7.40065L12 3.25398V14.9873Z"
          fill="#1F3E74"
        />
      </svg>
    );

    return (
      <CardBodyIcons className={"mt-2 mt-md-0"}>
        <BodyIconDiv onClick={props.viewTrackerOnClick}>
          {shieldIcon}
          <div>{t("ViewTracker")}</div>
        </BodyIconDiv>
      </CardBodyIcons>
    );
  };

  return (
    <InsuranceCardMolecule
      fillColor={fillColor}
      statusIconName={statusIconName}
      header={{
        companyLogoImage: getCompanyLogo(),
        quoteId: props.data.partnerQuoteItemId?.toString() ?? "",
        statusText: t(TrackerStepEnum[props.data.step]),
      }}
      body={{
        title: props.data.vehicle.vehicleName,
        subtitle: props.data.name ?? "",
        date: props.data.stepsLog?.length
          ? props.data.stepsLog[0].createdAt
          : "",
        icons: !!props.viewTrackerOnClick && getBodyIcons(),
      }}
      details={
        props.data.stepsLog && {
          showMoreText: t("ShowMore"),
          showLessText: t("ShowLess"),
          title: undefined,
          description: undefined,
          stepsLog: props.data.stepsLog.map((m) => {
            return {
              text: t(TrackerStepEnum[m.step]),
              dateIsoString: m.createdAt,
            };
          }),
        }
      }
    />
  );
};
