import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import {
  VehicleFeatures,
  IVehicleFeaturesProps,
  VehicleFeaturesMotorcycle,
  VehicleCategoriesEnum,
} from "c4u-web-components";
import { Wrapper } from "./vehicle-information.molecule.style";
import { useInspectionContext, useTrackerContext } from "../../../../hooks";

interface VehicleInformationProps {
  reduced: boolean;
  informationCarMolicar?: boolean;
  vehicleCategory: VehicleCategoriesEnum;
}

export const VehicleInformation = (
  props: VehicleInformationProps
): JSX.Element => {
  const { t } = useTranslation();

  const { vehicleContext } = useInspectionContext();
  const { vehicleCategoryContext } = useTrackerContext();

  const vehicleFeaturesProps = useMemo(() => {
    const propsComponent = {
      reduced: props.reduced,
      informationCarMolicar: props.informationCarMolicar,
      vehicle: {
        ...(vehicleContext as any),
        image: vehicleContext?.firstMediaCompleteURLMedium,
      },
      handlers: {},
      texts: {
        share: {
          titleShareVehicle: t("TitleShareVehicle"),
          titleShareVehicleSuccess: t("TitleShareVehicleSuccess"),
          subTitleShareVehicleSuccess: t("SubTitleShareVehicleSuccess"),
          subTitleShareVehicle: t("subTitleShareVehicle"),
          name: t("Name"),
          email: t("Email"),
          send: t("Send"),
        },
        icons: {
          share: t("Share"),
          print: t("Print"),
          compare: t("Compare"),
        },
        category: t("Category"),
        seats: t("Seats"),
        doors: t("Doors"),
        passengers: t("Passengers"),
        engine: t("Engine"),
        weight: t("Weight"),
        emissionsCO2: t("Emissions CO2"),
        transmission: t("Transmission"),
        absBrake: t("AbsBrake"),
        powerOf: t("Power"),
        aceleration: t("Acceleration"),
        traction: t("Traction"),
        fuel: t("Fuel"),
        consumption: t("Consumption"),
        city: t("city"),
        km_l: t("km_l"),
        km_h: t("km_h"),
        gramsByKm: t("g_km"),
        packageOptional: t("PackageOptional"),
      },
    } as IVehicleFeaturesProps;

    return propsComponent;
  }, [props.informationCarMolicar, props.reduced, t, vehicleContext]);

  return (
    <>
      {vehicleContext && (
        <Wrapper>
          {vehicleCategoryContext === VehicleCategoriesEnum.Car ? (
            <VehicleFeatures {...vehicleFeaturesProps} />
          ) : (
            <VehicleFeaturesMotorcycle {...vehicleFeaturesProps} />
          )}
        </Wrapper>
      )}
    </>
  );
};
