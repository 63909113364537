import {
  Combo,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
} from "c4u-web-components";
import { useContext } from "react";
import { TrackerContext } from "../../contexts";
import { ITrackerData } from "../../models";

export interface ITrackerContextProps {
  trackerDataContext: ITrackerData;
  setTrackerDataContext: React.Dispatch<
    React.SetStateAction<ITrackerData | undefined>
  >;
  originInsuranceContext: boolean;
  setOriginInsuranceContext: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  vehicleCategoryContext: VehicleCategoriesEnum;
  setVehicleCategoryContext: React.Dispatch<VehicleCategoriesEnum | undefined>;
  sourcePartnerContext: SourcePartnerEnum;
  setSourcePartnerContext: React.Dispatch<SourcePartnerEnum | undefined>;
  yearsCombo: Combo[];
  setYearsCombo: React.Dispatch<Combo[] | undefined>;
  vehicleYearSelected: Combo;
  setVehicleYearSelected: React.Dispatch<Combo | undefined>;
}

export const useTrackerContext = () => {
  const context = useContext<ITrackerContextProps>(TrackerContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
