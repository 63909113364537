import {
  Combo,
  CoxIcon,
  MaskHelper,
  Tooltip,
  YupHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormikControlIturanAtom } from "../../../atoms";
import {
  ContentGroupBox,
  TitleContentBox,
  TooltipWrapper,
} from "./driver-data-form.molecule.style";
import { DriverDataFormMoleculeValidation } from "./driver-data-form.molecule.validation";

interface IProps {
  initialValues?: IDiverDataFormMoleculeProps;
  vehicleUse: string;
  combo: IComboProps;
  defaults: IDefaultsProps;
  isZeroKm?: boolean;
  onSubmit: (values: IDiverDataFormMoleculeProps, errors: any) => Promise<void>;
  startSubmit: boolean;
  isSubmiting: (submit: boolean) => void;
}
interface IComboProps {
  genders: Combo[];
  maritalStatus: Combo[];
  yesNo: Combo[];
  yesNoWork: Combo[];
  yesNoSchool: Combo[];
  vehicleUse: Combo[];
  taxFreeReason: Combo[];
  linkDriverCompany: Combo[];
  linkDriverPerson: Combo[];
  linkDriverPersonSingle: Combo[];
  licenseTime: Combo[];
}
export interface IDefaultsProps {
  yes: string;
  no: string;
  none?: string;
  taxi: string;
  appDriver: string;
  comercial: string;
}

export interface IDiverDataFormMoleculeProps {
  vehicleUse: string;

  name: string;
  email: string;
  cellphone: string;
  document: string;
  birthdate?: string;
  gender?: string;
  maritalStatus?: string;
  legalVehicleOwn?: string;
  driversBetween18and26: string;
  vehicleUseToWork?: string;
  storedClosedGarage: string;
  storedClosedGarageAtHome?: string;
  storedClosedGarageAtWork?: string;
  storedClosedGarageAtSchool?: string;
  mainDriver?: string;

  driverPersonalDocument?: string;
  driverName?: string;
  driverBirthdate?: string;
  driverGender?: string;
  driverMaritalStatus?: string;
  driverLink?: string;
  licenseTime?: string;
}

export const DriverDataFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<IDiverDataFormMoleculeProps>({
    initialValues:
      props.initialValues ??
      ({ vehicleUse: props.vehicleUse } as IDiverDataFormMoleculeProps),
    onSubmit: async (values, { setErrors }) => {
      try {
        if (!showStoredClosedGarageAtWork)
          values.storedClosedGarageAtWork = taxiAppDriverComercial
            ? props.defaults.no
            : undefined;
        if (formik.values.storedClosedGarage === props.defaults.no) {
          values.storedClosedGarageAtSchool = undefined;
          values.storedClosedGarageAtHome = undefined;
        }
        if (
          formik.values.storedClosedGarageAtHome === props.defaults.no &&
          formik.values.storedClosedGarageAtWork === props.defaults.no &&
          formik.values.storedClosedGarageAtSchool === props.defaults.no
        ) {
          formik.setFieldError(
            "storedClosedGarage",
            t("Select yes to at least one of the options")
          );
          formik.setFieldError(
            "storedClosedGarageAtHome",
            t("Check the options")
          );
          formik.setFieldError(
            "storedClosedGarageAtWork",
            t("Check the options")
          );
          formik.setFieldError(
            "storedClosedGarageAtSchool",
            t("Check the options")
          );
        } else if (
          (formik.values.storedClosedGarageAtHome === props.defaults.no &&
            formik.values.storedClosedGarageAtWork === props.defaults.none &&
            formik.values.storedClosedGarageAtSchool === props.defaults.no) ||
          (formik.values.storedClosedGarageAtHome === props.defaults.no &&
            formik.values.storedClosedGarageAtWork === props.defaults.no &&
            formik.values.storedClosedGarageAtSchool === props.defaults.none)
        ) {
          formik.setFieldError(
            "storedClosedGarage",
            t("Select yes to at least one of the options")
          );
          formik.setFieldError(
            "vehicleUseToWork",
            t(
              "Vehicle used as a means of transport to work, please check the options"
            )
          );
          formik.setFieldError(
            "storedClosedGarageAtHome",
            t("Check the options")
          );
          formik.setFieldError(
            "storedClosedGarageAtWork",
            t("Check the options")
          );
          formik.setFieldError(
            "storedClosedGarageAtSchool",
            t("Check the options")
          );
        } else if (
          formik.values.vehicleUseToWork === props.defaults.yes &&
          formik.values.storedClosedGarageAtHome === props.defaults.no &&
          formik.values.storedClosedGarageAtWork === props.defaults.none &&
          formik.values.storedClosedGarageAtSchool === props.defaults.none
        ) {
          formik.setFieldError(
            "storedClosedGarage",
            t("Select yes to at least one of the options")
          );
          formik.setFieldError(
            "vehicleUseToWork",
            t(
              "Vehicle used as a means of transport to work, please check the options"
            )
          );
          formik.setFieldError(
            "storedClosedGarageAtHome",
            t("Check the options")
          );

          formik.setFieldError(
            "storedClosedGarageAtSchool",
            t("Check the options")
          );
          formik.setFieldError(
            "storedClosedGarageAtWork",
            t("Check the options")
          );
        } else {
          await props.onSubmit(values, setErrors);
        }
      } catch (error) {
        console.log("Error.DriverDataFormMolecule", error);
      }
    },
    validationSchema: DriverDataFormMoleculeValidation(props.defaults),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const isCpf = useMemo(
    () => YupHelper.cpfValid(formik.values.document),
    [formik.values.document]
  );

  const isCnpj = useMemo(
    () => formik.values.document && YupHelper.cnpjValid(formik.values.document),
    [formik.values.document]
  );

  const taxiAppDriverComercial = useMemo(
    () =>
      [
        props.defaults.taxi,
        props.defaults.appDriver,
        props.defaults.comercial,
      ].includes(formik.values.vehicleUse ?? ""),
    [formik.values.vehicleUse, props]
  );

  const showStoredClosedGarageAtWork = useMemo(() => {
    return (
      formik.values.storedClosedGarage === props.defaults.yes &&
      formik.values.vehicleUseToWork === props.defaults.yes &&
      !taxiAppDriverComercial
    );
  }, [formik, props, taxiAppDriverComercial]);

  useEffect(() => {
    if (props.initialValues?.storedClosedGarageAtSchool)
      formik.setFieldValue(
        "storedClosedGarageAtSchool",
        props.initialValues.storedClosedGarageAtSchool
      );
    else if (formik.values.storedClosedGarage === props.defaults.yes) {
      formik.setFieldValue("storedClosedGarageAtSchool", "");
    } else {
      formik.setFieldValue("storedClosedGarageAtSchool", props.defaults.none);
    }
    // eslint-disable-next-line
  }, [formik.values.storedClosedGarage, props.initialValues]);

  useEffect(() => {
    if (props.initialValues?.storedClosedGarageAtWork) {
      formik.setFieldValue(
        "storedClosedGarageAtWork",
        props.initialValues.storedClosedGarageAtWork
      );
    } else if (showStoredClosedGarageAtWork) {
      formik.setFieldValue("storedClosedGarageAtWork", "");
    } else {
      formik.setFieldValue(
        "storedClosedGarageAtWork",
        taxiAppDriverComercial ? props.defaults.no : props.defaults.none
      );
    }
    // eslint-disable-next-line
  }, [
    showStoredClosedGarageAtWork,
    props.initialValues,
    formik.values.vehicleUse,
    taxiAppDriverComercial,
  ]);

  useEffect(() => {
    if (props.startSubmit) {
      formik.submitForm();
    }
    // eslint-disable-next-line
  }, [props.startSubmit]);

  useEffect(
    () => props.isSubmiting(formik.isSubmitting),
    // eslint-disable-next-line
    [formik.isSubmitting]
  );

  useEffect(
    () => {
      if (props.initialValues) formik.setValues(props.initialValues);
    },
    // eslint-disable-next-line
    [props.initialValues]
  );

  useEffect(
    () => {
      if (taxiAppDriverComercial)
        formik.setFieldValue("vehicleUseToWork", props.defaults.yes);
    },
    // eslint-disable-next-line
    [props, formik.values.vehicleUse]
  );

  return (
    <Form
      className={"form-default"}
      onSubmit={(e: any) => formik.handleSubmit(e)}
    >
      <ContentGroupBox>
        <TitleContentBox>{t("Insured data")}</TitleContentBox>
        <Row>
          <FormikControlIturanAtom
            md={6}
            formik={formik}
            property={"name"}
            label={t("Insured Complete Name")}
          />
          <FormikControlIturanAtom
            md={6}
            formik={formik}
            property={"email"}
            label={t("Insured Email")}
          />
        </Row>
        <Row>
          <FormikControlIturanAtom
            md={3}
            mask={MaskHelper.Cellphone}
            formik={formik}
            property={"cellphone"}
            label={t("Type Cellphone")}
          />
          <FormikControlIturanAtom
            md={3}
            formik={formik}
            property={"document"}
            mask={MaskHelper.Cpf}
            label={t("Documents insured")}
          />
          {isCpf && (
            <FormikControlIturanAtom
              md={3}
              mask={MaskHelper.Date}
              formik={formik}
              property={"birthdate"}
              label={t("Birth date")}
            />
          )}
          {isCpf && (
            <FormikControlIturanAtom
              type={"select"}
              md={3}
              formik={formik}
              property={"gender"}
              data={props.combo.genders}
              label={t("Gender insured")}
            />
          )}
          {isCpf && (
            <FormikControlIturanAtom
              type={"select"}
              data={props.combo.maritalStatus}
              md={3}
              formik={formik}
              property={"maritalStatus"}
              label={t("Marital status")}
            />
          )}
        </Row>
      </ContentGroupBox>

      <ContentGroupBox>
        <TitleContentBox>{t("Profile data")}</TitleContentBox>
        <Row>
          {[props.defaults.taxi, props.defaults.appDriver].includes(
            formik.values.vehicleUse ?? ""
          ) && (
            <FormikControlIturanAtom
              type={"select"}
              data={props.combo.yesNo}
              md={6}
              formik={formik}
              property={"legalVehicleOwn"} //19
              label={t("Insured is the legal vehicle own?")}
            />
          )}
          <FormikControlIturanAtom
            type={"select"}
            data={props.combo.yesNo}
            md={6}
            formik={formik}
            property={"driversBetween18and26"} //20
            label={t("WarningBetween18and26")}
          />
          {![
            props.defaults.taxi,
            props.defaults.comercial,
            props.defaults.appDriver,
          ].includes(formik.values.vehicleUse ?? "") && (
            <FormikControlIturanAtom
              type={"select"}
              data={props.combo.yesNo}
              md={6}
              formik={formik}
              property={"vehicleUseToWork"} //21
              label={t("Do you use a vehicle as a means of transport to work?")}
            />
          )}
          <FormikControlIturanAtom
            type={"select"}
            data={props.combo.yesNo}
            md={6}
            formik={formik}
            property={"storedClosedGarage"} //22
            label={t(
              "Is the vehicle stored in a closed garage or parking lot?"
            )}
          />
          {formik.values.storedClosedGarage === props.defaults.yes && (
            <FormikControlIturanAtom
              type={"select"}
              data={props.combo.yesNo}
              md={6}
              formik={formik}
              property={"storedClosedGarageAtHome"} //23
              label={t("At home?")}
            />
          )}
          {showStoredClosedGarageAtWork && (
            <FormikControlIturanAtom
              type={"select"}
              data={props.combo.yesNoWork}
              md={6}
              formik={formik}
              property={"storedClosedGarageAtWork"} //24
              label={t("At work?")}
            />
          )}
          {formik.values.storedClosedGarage === props.defaults.yes && (
            <FormikControlIturanAtom
              type={"select"}
              data={props.combo.yesNoSchool}
              md={6}
              formik={formik}
              property={"storedClosedGarageAtSchool"} //25
              label={t("At school?")}
            />
          )}
          {isCpf && (
            <FormikControlIturanAtom
              type={"select"}
              data={props.combo.yesNo}
              md={6}
              formik={formik}
              property={"mainDriver"}
              label={
                <TooltipWrapper className={"form-label"}>
                  {t("Insured is the main driver?")}
                  <Tooltip content={t("MainDriverAdvice")}>
                    <CoxIcon name={"information"} />
                  </Tooltip>
                </TooltipWrapper>
              }
            />
          )}
        </Row>
      </ContentGroupBox>

      <ContentGroupBox>
        <TitleContentBox>{t("Main driver")}</TitleContentBox>
        <Row>
          <FormikControlIturanAtom
            type={"select"}
            data={props.combo.licenseTime}
            md={3}
            formik={formik}
            property={"licenseTime"}
            label={t("License time")}
          />
          {(isCnpj || formik.values.mainDriver === props.defaults.no) && (
            <>
              <FormikControlIturanAtom
                md={3}
                formik={formik}
                property={"driverPersonalDocument"}
                label={t("Cpf")}
                mask={MaskHelper.Cpf}
              />
              <FormikControlIturanAtom
                md={6}
                formik={formik}
                property={"driverName"}
                label={t("Name")}
              />
              <FormikControlIturanAtom
                md={3}
                formik={formik}
                property={"driverBirthdate"}
                label={t("Birth date")}
                mask={MaskHelper.Date}
              />
              <FormikControlIturanAtom
                type={"select"}
                md={3}
                formik={formik}
                property={"driverGender"}
                data={props.combo.genders}
                label={t("Driver gender")}
              />
              <FormikControlIturanAtom
                type={"select"}
                data={props.combo.maritalStatus}
                md={3}
                formik={formik}
                property={"driverMaritalStatus"}
                label={t("Marital status")}
              />
              {isCpf && (
                <FormikControlIturanAtom
                  type={"select"}
                  data={
                    formik.values.driverMaritalStatus === "casado"
                      ? props.combo.linkDriverPerson
                      : props.combo.linkDriverPersonSingle
                  }
                  md={3}
                  formik={formik}
                  property={"driverLink"}
                  label={t("Driver link")}
                />
              )}
              {isCnpj && (
                <FormikControlIturanAtom
                  type={"select"}
                  data={props.combo.linkDriverCompany}
                  md={3}
                  formik={formik}
                  property={"driverLink"}
                  label={t("Driver link")}
                />
              )}
            </>
          )}
        </Row>
      </ContentGroupBox>
      <div className={"hide"}>
        <input type={"submit"} />
      </div>
    </Form>
  );
};
