import styled from "styled-components";

const TitlePageConfirmation = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  margin-bottom: 15px;
`;
const DescriptionPageConfirmation = styled.div`
  color: ${(props) => props.theme.colors.Dark};
  margin-bottom: 20px;
`;
const InPartner = styled.div`
  color: ${(props) => props.theme.colors.Dark};
  margin-top: 10px;
  text-align: right;
`;

export { TitlePageConfirmation, DescriptionPageConfirmation, InPartner };
