import "./App.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import React, { Suspense } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "c4u-web-components";
import TagManager from "react-gtm-module";

import { Routes } from "./router";
import { InspectionProvider, DebtsProvider, TrackerProvider } from "./contexts";
import GlobalStyles from "./ui/styles";
import { SessionProvider } from "./contexts/session.context";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div>Loading...</div>}>
        <SessionProvider>
          <InspectionProvider>
            <DebtsProvider>
              <TrackerProvider>
                <Routes />
              </TrackerProvider>
            </DebtsProvider>
          </InspectionProvider>
        </SessionProvider>
      </Suspense>
      <GlobalStyles />
    </ThemeProvider>
  );
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTMID ?? "dummy",
  dataLayerName: "registration",
  dataLayer: {
    page: "init",
  },
};
if (process.env.REACT_APP_GTMID) TagManager.initialize(tagManagerArgs);

export default App;
