import styled from "styled-components";

const WrapperCardBodyRenave = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
`;

const CardDate = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;

  letter-spacing: -0.15px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

const CardBodySubtitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  white-space: nowrap;
  color: ${({ theme }) => theme.colors.Dark};
`;

export { CardBodySubtitle, CardDate, WrapperCardBodyRenave };
