import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  useProductsTracker,
  useSessionContext,
  useTrackerContext,
  useZipcodeAddress,
} from "../../../../hooks";
import {
  ISaveTrackerOrderRequest,
  ISaveTrackerOrderRequestEmergencyContact,
  ITrackerData,
  IPasswordlessParams,
  SaveTrackerOrderRequest,
  IOrderDataFormMoleculeProps,
} from "../../../../models";
import { FooterSubmitButtonAtom } from "../../../atoms";
import { OrderDataFormMolecule } from "../../../molecules";

export const DriverOrderOrganism: React.FC = () => {
  const history = useHistory();
  const { id, typeJorney } = useParams<IPasswordlessParams>();

  const [goSubmit, setGoSubmit] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { t } = useTranslation();
  const { trackerDataContext, setTrackerDataContext } = useTrackerContext();
  const { handleFormikException, showGenericErrorModal } = useSessionContext();

  const { getAddressByZipcode } = useZipcodeAddress();
  const { saveTrackerOrder } = useProductsTracker();

  const handleZipcode = async (zipcode: string) => {
    return await getAddressByZipcode(zipcode);
  };

  const mapper = (
    src: IOrderDataFormMoleculeProps
  ): ISaveTrackerOrderRequest => {
    return {
      trackerId: id,
      partnerQuoteGuid: trackerDataContext?.quote?.partnerQuoteGuid,
      partnerQuoteItemId: trackerDataContext?.quote?.partnerQuoteItemId,
      commission: trackerDataContext?.quote?.commission,
      street: src.street,
      complement: src.complement,
      neighborhood: src.district,
      zipCode: src.zipCode,
      number: src.number,
      emergencyContacts: [
        {
          name: src.name,
          phone: src.phone,
        } as ISaveTrackerOrderRequestEmergencyContact,
      ],
    } as ISaveTrackerOrderRequest;
  };

  const handleSubmit = async (
    values: IOrderDataFormMoleculeProps,
    errors: any
  ) => {
    try {
      if (
        !trackerDataContext?.quote?.partnerQuoteGuid ||
        !trackerDataContext?.quote?.partnerQuoteItemId
      )
        showGenericErrorModal(t("Quote not located"));

      setTrackerDataContext({
        ...trackerDataContext,
        order: values,
      } as ITrackerData);
      await saveTrackerOrder(new SaveTrackerOrderRequest(mapper(values)));
      history.push(paths.trackerScheduleInstall(id, typeJorney));
    } catch (e) {
      handleFormikException(e, errors, mapper);
    }
  };

  useEffect(() => {
    if (!isSubmiting && goSubmit) setGoSubmit(false);
    // eslint-disable-next-line
  }, [isSubmiting]);

  return (
    <>
      <OrderDataFormMolecule
        getAddress={handleZipcode}
        initialValues={trackerDataContext?.order}
        setIsSubmiting={setIsSubmiting}
        startSubmit={goSubmit}
        onSubmit={handleSubmit}
      />
      <FooterSubmitButtonAtom
        onClick={() => setGoSubmit(true)}
        loading={isSubmiting}
      >
        {t("Continue")}
      </FooterSubmitButtonAtom>
    </>
  );
};
