import { Combo, ScrollHelper } from "c4u-web-components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  useProductsTracker,
  useSessionContext,
  useTrackerContext,
} from "../../../../hooks";
import {
  ISaveTrackerDriverRequest,
  ITrackerData,
  IPasswordlessParams,
  SaveTrackerDriverRequest,
} from "../../../../models";
import { FooterSubmitButtonAtom } from "../../../atoms";
import { DriverDataFormMolecule } from "../../../molecules";
import { IDiverDataFormMoleculeProps } from "../../../molecules/tracker/driver-data-form/driver-data-form.molecule";

export const DriverDataOrganism: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams<IPasswordlessParams>();

  const { handleFormikException } = useSessionContext();
  const { trackerDataContext, setTrackerDataContext } = useTrackerContext();

  const [goSubmit, setGoSubmit] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { saveTrackerDriver } = useProductsTracker();

  const mapper = (
    src: IDiverDataFormMoleculeProps
  ): ISaveTrackerDriverRequest => {
    return {
      trackerId: params.id,
      vehicleUse: src.vehicleUse,
      name: src.name,
      email: src.email,
      cellphone: src.cellphone,
      document: src.document,
      birthdate: src.birthdate,
      gender: src.gender,
      maritalStatus: src.maritalStatus,
      legalVehicleOwn: src.legalVehicleOwn,
      driversBetween18and26: src.driversBetween18and26,
      vehicleUseToWork: src.vehicleUseToWork,
      storedClosedGarage: src.storedClosedGarage,
      storedClosedGarageAtHome: src.storedClosedGarageAtHome,
      storedClosedGarageAtWork: src.storedClosedGarageAtWork,
      storedClosedGarageAtSchool: src.storedClosedGarageAtSchool,
      mainDriver: src.mainDriver,
      driverPersonalDocument: src.driverPersonalDocument,
      driverName: src.driverName,
      driverBirthdate: src.driverBirthdate,
      driverGender: src.driverGender,
      driverMaritalStatus: src.driverMaritalStatus,
      driverLink: src.driverLink,
      licenseTime: src.licenseTime,
    } as ISaveTrackerDriverRequest;
  };

  const handleSubmit = async (
    values: IDiverDataFormMoleculeProps,
    errors: any
  ) => {
    try {
      const response = await saveTrackerDriver(
        new SaveTrackerDriverRequest(mapper(values))
      );
      setTrackerDataContext({
        ...trackerDataContext,
        driver: values,
      } as ITrackerData);
      history.push(paths.trackerQuotes(response.trackerId, params.typeJorney));
    } catch (e) {
      handleFormikException(e, errors, mapper);
    }
  };

  useEffect(() => {
    if (!isSubmiting && goSubmit) setGoSubmit(false);
    // eslint-disable-next-line
  }, [isSubmiting]);

  //#region combos
  const genders = useMemo(
    () => [
      new Combo({ title: t("Female"), value: "feminino" }),
      new Combo({ title: t("Male"), value: "masculino" }),
    ],
    [t]
  );

  const maritalStatus = useMemo(
    () => [
      new Combo({ title: t("Solteiro"), value: "solteiro" }),
      new Combo({
        title: t("Casado ou residente com companheiro(a)"),
        value: "casado",
      }),
      new Combo({ title: t("Viúvo"), value: "viuvo" }),
      new Combo({ title: t("Divorciado"), value: "divorciado" }),
    ],
    [t]
  );
  const yesNo = useMemo(
    () => [
      new Combo({ title: t("Yes"), value: "1" }),
      new Combo({ title: t("No"), value: "0" }),
    ],
    [t]
  );

  const yesNoWork = useMemo(
    () => [
      new Combo({ title: t("Yes"), value: "1" }),
      new Combo({ title: t("No"), value: "0" }),
      new Combo({ title: t("DontUseForJob"), value: "2" }),
    ],
    [t]
  );
  const yesNoSchool = useMemo(
    () => [
      new Combo({ title: t("Yes"), value: "1" }),
      new Combo({ title: t("No"), value: "0" }),
      new Combo({ title: t("DontUseForSchool"), value: "2" }),
    ],
    [t]
  );

  const vehicleUse = useMemo(
    () => [
      new Combo({ title: t("Private"), value: "particular" }),
      new Combo({ title: t("App driver"), value: "motorista_app" }),
      new Combo({ title: t("Comercial"), value: "comercial" }),
      new Combo({ title: t("Taxi"), value: "taxi" }),
    ],
    [t]
  );

  const taxFreeReason = useMemo(
    () => [
      new Combo({ title: t("Taxi"), value: "taxi" }),
      new Combo({ title: t("PCD"), value: "pcd" }),
      new Combo({ title: t("Others"), value: "outros" }),
    ],
    [t]
  );

  const linkDriverCompany = useMemo(
    () => [
      new Combo({ title: t("OwnCompany"), value: "socio" }),
      new Combo({ title: t("ManagerCompany"), value: "gerencia" }),
      new Combo({ title: t("EmployCompany"), value: "colaborador" }),
      new Combo({ title: t("Others"), value: "outros" }),
    ],
    [t]
  );
  const linkDriverPerson = useMemo(
    () => [
      new Combo({ title: t("MarriedMartial"), value: "conjugue" }),
      new Combo({ title: t("ChildMartial"), value: "filho" }),
      new Combo({ title: t("SiblingsMartial"), value: "irmao" }),
      new Combo({ title: t("Others"), value: "outros" }),
    ],
    [t]
  );

  const linkDriverPersonSingle = useMemo(
    () => [
      new Combo({ title: t("ChildMartial"), value: "filho" }),
      new Combo({ title: t("SiblingsMartial"), value: "irmao" }),
      new Combo({ title: t("Others"), value: "outros" }),
    ],
    [t]
  );

  const licenseTime = useMemo(
    () => [
      new Combo({ title: t("Less than 2 years"), value: "2_anos" }),
      new Combo({ title: t("More than 2 years"), value: "mais_2_anos" }),
    ],
    [t]
  );
  //#endregion

  useEffect(() => {
    ScrollHelper.jumpToTop();
  }, []);

  return (
    <>
      <DriverDataFormMolecule
        initialValues={trackerDataContext?.driver}
        vehicleUse={trackerDataContext?.vehicle?.vehicleUse ?? "particular"}
        combo={{
          genders: genders,
          maritalStatus: maritalStatus,
          yesNo: yesNo,
          yesNoWork: yesNoWork,
          yesNoSchool: yesNoSchool,
          vehicleUse: vehicleUse,
          taxFreeReason: taxFreeReason,
          linkDriverCompany: linkDriverCompany,
          linkDriverPerson: linkDriverPerson,
          linkDriverPersonSingle: linkDriverPersonSingle,
          licenseTime: licenseTime,
        }}
        defaults={{
          yes: "1",
          no: "0",
          none: "2",
          taxi: "taxi",
          appDriver: "motorista_app",
          comercial: "comercial",
        }}
        isSubmiting={(submiting) => setIsSubmiting(submiting)}
        startSubmit={goSubmit}
        onSubmit={handleSubmit}
      />
      <FooterSubmitButtonAtom
        onClick={() => setGoSubmit(true)}
        loading={isSubmiting}
      >
        {t("Continue")}
      </FooterSubmitButtonAtom>
    </>
  );
};
