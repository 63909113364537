import React, { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import { WrapperCard } from "./generic-options-card.molecule.style";

interface IProps {
  icon: ReactNode;
  textHeader: string;
  textContent: string;
  buttons?: ReactNode[];
}

export const GenericOptionsCardMolecule: React.FC<IProps> = (props) => {
  return (
    <WrapperCard>
      <Row>
        <Col>{props.icon}</Col>
      </Row>
      <Row>
        <Col className="text-header">{props.textHeader}</Col>
      </Row>
      <Row>
        <Col className="text-content">{props.textContent}</Col>
      </Row>
      <Row>
        <Col>{props?.buttons?.map((buttons) => buttons)}</Col>
      </Row>
    </WrapperCard>
  );
};
