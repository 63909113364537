import { TypesHelper } from "c4u-web-components";

export interface ICreateInspectionRequest {
  id?: number;
  partnerId?: number;
  kbbId?: number;
  passwordless?: string;
  vehicleName?: string;
  inspectionPlanId?: string;
  inspectionPlanName?: string;
  inspectionPlanValue?: number;
  inspectionPlaceId?: string;
  inspectionPlaceName?: string;
  inspectionPlaceAddress?: string;
  inspectionPlacePhones?: string[];
  inspectionPlaceEmails?: string[];
  inspectionDate?: Date;
  inspectionPeriod?: string;
  plate?: string;
  renavam?: string;
  searchZipCode?: string;
}

export class CreateInspectionRequest {
  id: number | null;
  partnerId: number | null;
  kbbId: number | null;
  passwordless: string | null;
  vehicleName: string | null;
  inspectionPlanId: string | null;
  inspectionPlanName: string | null;
  inspectionPlanValue: number | null;
  inspectionPlaceId: string | null;
  inspectionPlaceName: string | null;
  inspectionPlaceAddress: string | null;
  inspectionPlacePhones: string[] | null;
  inspectionPlaceEmails: string[] | null;
  inspectionDate: Date | null;
  inspectionPeriod: number | null;
  plate: string | null;
  renavam: string | null;
  searchZipCode: string | null;

  constructor({
    id,
    partnerId,
    kbbId,
    passwordless,
    vehicleName,
    inspectionPlanId,
    inspectionPlanName,
    inspectionPlanValue,
    inspectionPlaceId,
    inspectionPlaceName,
    inspectionPlaceAddress,
    inspectionPlacePhones,
    inspectionPlaceEmails,
    inspectionDate,
    inspectionPeriod,
    plate,
    renavam,
    searchZipCode,
  }: ICreateInspectionRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.partnerId = TypesHelper.toNumberNull(partnerId);
    this.kbbId = TypesHelper.toNumberNull(kbbId);
    this.passwordless = TypesHelper.toStringNull(passwordless);
    this.vehicleName = TypesHelper.toStringNull(vehicleName);
    this.inspectionPlanId = TypesHelper.toStringNull(inspectionPlanId);
    this.inspectionPlanName = TypesHelper.toStringNull(inspectionPlanName);
    this.inspectionPlanValue = TypesHelper.toNumberNull(inspectionPlanValue);
    this.inspectionPlaceId = TypesHelper.toStringNull(inspectionPlaceId);
    this.inspectionPlaceName = TypesHelper.toStringNull(inspectionPlaceName);
    this.inspectionPlaceAddress = TypesHelper.toStringNull(
      inspectionPlaceAddress
    );
    this.inspectionPlacePhones = inspectionPlacePhones ?? null;
    this.inspectionPlaceEmails = inspectionPlaceEmails ?? null;
    this.inspectionDate = TypesHelper.toDateNull(inspectionDate);
    this.inspectionPeriod = TypesHelper.toNumberNull(inspectionPeriod);
    this.plate = TypesHelper.toStringNull(plate);
    this.renavam = TypesHelper.toStringOnlyNumbersNull(renavam);
    this.searchZipCode = TypesHelper.toStringOnlyNumbersNull(searchZipCode);
  }
}
