import {
  CreditcardCompany,
  CreditcardCompanyEnum,
  NumberHelper,
  PaymentCreditCard,
  PaymentCreditCardForm,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  useDebtsContext,
  useProductsDebts,
  useSessionContext,
  useZipcodeAddress,
} from "../../../../hooks";
import { IPasswordlessParams } from "../../../../models";
import {
  CetOnCombo,
  DivLoadingPaymentPage,
} from "./debt-payment.organism.style";

export const DebtPaymentOrganism: React.FC = () => {
  const { handleEventGTM } = useSessionContext();

  const {
    paymentContext,
    setPaymentContext,
    plotsContext,
    setPlotsContext,
    debtsContext,
    vehicleDebtsContext,
    setVehicleDebtsContext,
  } = useDebtsContext();

  const { t } = useTranslation();
  const { getInstallments, getVehicleDebits } = useProductsDebts();

  const [plotSelected, setPlotSelected] = useState(
    paymentContext?.parcelable ?? debtsContext?.installmentPlan
  );

  const history = useHistory();
  const { id, typeJorney } = useParams<IPasswordlessParams>();

  const { getAddressByZipcode } = useZipcodeAddress();

  const handleZipcode = useCallback(
    async (zipcode: string) => {
      const address = await getAddressByZipcode(zipcode);
      return address;
    },
    [getAddressByZipcode]
  );

  const submit = useCallback(
    (values: PaymentCreditCard) => {
      setPaymentContext({
        ...values,
        parcelable: plotSelected,
        flag: paymentContext?.flag,
        name: paymentContext?.name,
      });
      history.push(paths.debtsConfirmation(id, typeJorney));
    },
    [
      history,
      id,
      paymentContext?.flag,
      paymentContext?.name,
      plotSelected,
      setPaymentContext,
      typeJorney,
    ]
  );

  const loadDebits = useCallback(() => {
    getVehicleDebits(Number(id))
      .then((t) => {
        setVehicleDebtsContext(t);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getVehicleDebits, id, setVehicleDebtsContext]);

  const handleChange = useCallback(
    (creditcardCompany: CreditcardCompanyEnum) => {
      if (paymentContext)
        setPaymentContext({ ...paymentContext, flag: creditcardCompany });
    },
    [paymentContext, setPaymentContext]
  );

  const excelRate = useCallback(
    (
      periods: number,
      payment: number,
      present: number,
      future?: number,
      type?: number,
      guess?: number
    ): number => {
      guess = guess === undefined ? 0.01 : guess;
      future = future === undefined ? 0 : future;
      type = type === undefined ? 0 : type;

      // Set maximum epsilon for end of iteration
      var epsMax = 1e-10;

      // Set maximum number of iterations
      var iterMax = 10;

      // Implement Newton's method
      var y,
        y0,
        y1,
        x0,
        x1 = 0,
        f = 0,
        i = 0;
      var rate = guess;
      if (Math.abs(rate) < epsMax) {
        y =
          present * (1 + periods * rate) +
          payment * (1 + rate * type) * periods +
          future;
      } else {
        f = Math.exp(periods * Math.log(1 + rate));
        y = present * f + payment * (1 / rate + type) * (f - 1) + future;
      }
      y0 = present + payment * periods + future;
      y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
      i = x0 = 0;
      x1 = rate;
      while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
        rate = (y1 * x0 - y0 * x1) / (y1 - y0);
        x0 = x1;
        x1 = rate;
        if (Math.abs(rate) < epsMax) {
          y =
            present * (1 + periods * rate) +
            payment * (1 + rate * type) * periods +
            future;
        } else {
          f = Math.exp(periods * Math.log(1 + rate));
          y = present * f + payment * (1 / rate + type) * (f - 1) + future;
        }
        y0 = y1;
        y1 = y;
        ++i;
      }
      return rate;
    },
    []
  );

  const cetCalc = useCallback(
    (plot?: number): string => {
      const installment = plotsContext?.find((f) => f.installments === plot);

      if (installment) {
        const cet =
          excelRate(
            installment.installments,
            installment.amount,
            (debtsContext?.totalDebts ?? 0) * -1
          ) * 100;
        return NumberHelper.toFormatString(cet, 2, "--");
      } else return "--";
    },
    [debtsContext?.totalDebts, excelRate, plotsContext]
  );

  const totalAmount = useCallback(
    (plot?: number): string => {
      const installment = plotsContext?.find((f) => f.installments === plot);
      return NumberHelper.toFormatString(installment?.totalAmount, 2, "--");
    },
    [plotsContext]
  );

  const addPayment = useCallback(() => {
    handleEventGTM({
      event: "add_payment_info",
      ecommerce: {
        value: `${debtsContext?.totalDebtsInstallments}`,
        currency: "BRL",
        payment_type: "Cartão de Crédito",
        items: {
          item_name: debtsContext?.vehicleDebtDetails.map((item) => item.title),
          item_id: debtsContext?.vehicleDebtDetails.map((item) => item.debtId),
          price: debtsContext?.vehicleDebtDetails.map((item) => item.value),
          currency: "BRL",
          affiliation: "Débitos",
          item_category: "Documentação",
          item_list_name: "Pagamento",
          quantity: 1,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtsContext?.totalDebtsInstallments, debtsContext?.vehicleDebtDetails]);

  useEffect(() => {
    if (!plotSelected && debtsContext?.installmentPlan)
      setPlotSelected(debtsContext?.installmentPlan);
  }, [debtsContext?.installmentPlan, plotSelected]);

  useEffect(() => {
    if (!plotSelected && paymentContext?.parcelable)
      setPlotSelected(paymentContext?.parcelable);
  }, [paymentContext?.parcelable, plotSelected]);

  useEffect(() => {
    if (!debtsContext) history.replace(paths.debtsPenalties(id, typeJorney));
    // eslint-disable-next-line
  }, [debtsContext]);

  useEffect(() => {
    if (!plotsContext && debtsContext) {
      getInstallments(
        debtsContext.debitsProtocol,
        debtsContext.vehicleDebtDetails.map((m) => m.debtId)
      )
        .then((t) => setPlotsContext(t))
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotsContext, debtsContext]);

  useEffect(() => {
    if (!vehicleDebtsContext) loadDebits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDebtsContext]);

  useEffect(() => {
    const submitButton = document.querySelector('button[type="submit"]');
    if (!vehicleDebtsContext) submitButton?.setAttribute("disabled", "true");
    else submitButton?.removeAttribute("disabled");
  }, [vehicleDebtsContext]);

  useEffect(() => {
    addPayment();
  }, [addPayment]);

  return (
    <>
      {debtsContext && (
        <>
          <Row>
            <Col>
              {!vehicleDebtsContext && (
                <DivLoadingPaymentPage>
                  <SpinnerCenteredAtom />
                  <div className={"title"}>{t("Wait")}</div>
                  <div className={"description"}>
                    {t("WarningWaitingForDetranPaymentPage")}
                  </div>
                </DivLoadingPaymentPage>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <PaymentCreditCardForm
                getAddress={handleZipcode}
                values={paymentContext}
                hidePlot={true}
                submit={submit}
                translate={t}
              >
                <>
                  <Row className={"form-default"}>
                    <Col lg={6}>
                      <label className="form-label">
                        {t("Number Parcelables")}
                      </label>
                      <CetOnCombo>
                        <Form.Control
                          as={"select"}
                          placeholder={t("Tab here")}
                          onChange={(e: any) =>
                            setPlotSelected(Number(e.target.value))
                          }
                          value={plotSelected}
                        >
                          {plotsContext?.map((m, i) => (
                            <option key={i} value={m.installments}>{`${
                              m.installments
                            }x ${t("$")} ${NumberHelper.toFormatString(
                              m.amount,
                              2
                            )}`}</option>
                          ))}
                        </Form.Control>
                        <span>
                          {!plotsContext ? (
                            <SpinnerCenteredAtom size="sm" />
                          ) : (
                            `${t("CET")} ${cetCalc(plotSelected)}% ${t(
                              "By month"
                            )} ${t("$")} ${totalAmount(plotSelected)}`
                          )}
                        </span>
                      </CetOnCombo>
                    </Col>
                  </Row>
                  <Row className={"form-default my-3"}>
                    <Col md={6}>
                      <label className="form-label">
                        {t("Creditcard flag")}
                      </label>
                      <CreditcardCompany onChange={handleChange} />
                    </Col>
                  </Row>
                </>
              </PaymentCreditCardForm>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
