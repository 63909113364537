import {
  FormikControlAtom,
  MaskHelper,
  ZipcodeAddress,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  ContentGroupBox,
  TitleContentBox,
} from "./order-data-form.molecule.style";
import { OrderDataFormMoleculeValidation } from "./order-data-form.molecule.validation";
import { FormikControlIturanAtom } from "../../../atoms";
import { IOrderDataFormMoleculeProps } from "../../../../models";

interface IProps {
  onSubmit: (values: IOrderDataFormMoleculeProps, errors: any) => Promise<void>;
  startSubmit: boolean;
  setIsSubmiting: (submit: boolean) => void;
  initialValues?: IOrderDataFormMoleculeProps;
  getAddress?: (zipcode: string) => Promise<ZipcodeAddress>;
}

export const OrderDataFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { initialValues, getAddress } = props;

  const formik = useFormik<IOrderDataFormMoleculeProps>({
    initialValues: initialValues ?? ({} as IOrderDataFormMoleculeProps),
    onSubmit: async (values, { setErrors }) => {
      try {
        await props.onSubmit(values, setErrors);
      } catch (error) {
        console.log("Error.OrderDataFormMolecule", error);
      }
    },
    validationSchema: OrderDataFormMoleculeValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const [addressZipcode, setAddressZipcode] = useState<ZipcodeAddress>();

  const getAddressByZipcode = useCallback(
    async (cep: string) => {
      if (getAddress) {
        try {
          const address = await getAddress(cep);
          setAddressZipcode(address);

          if (!(address as any)?.erro && address?.uf) {
            formik.setFieldValue("city", address.localidade);
            formik.setFieldValue("state", address.uf);
            formik.setFieldValue("street", address.logradouro);
            formik.setFieldValue("district", address.bairro);
            if (
              (addressZipcode === undefined &&
                initialValues?.zipCode &&
                initialValues?.zipCode !== cep) ||
              (addressZipcode?.cep && addressZipcode?.cep !== cep)
            ) {
              formik.setFieldValue("number", "");
              formik.setFieldValue("complement", "");
            }
          } else {
            throw new Error("Address not found");
          }
        } catch (e) {
          setAddressZipcode(undefined);
        }
      }
    },

    [addressZipcode, formik, getAddress, initialValues?.zipCode]
  );

  useEffect(() => {
    if (
      formik?.values?.zipCode &&
      formik.values.zipCode.replace(/[^\d]/g, "").length === 8
    ) {
      getAddressByZipcode(formik.values.zipCode);
    } else {
      setAddressZipcode(undefined);
      formik.setFieldValue("street", "");
      formik.setFieldValue("number", "");
      formik.setFieldValue("complement", "");
      formik.setFieldValue("district", "");
      formik.setFieldValue("city", "");
      formik.setFieldValue("state", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.zipCode]);

  useEffect(() => {
    if (props.startSubmit) {
      formik.submitForm();
    }
    // eslint-disable-next-line
  }, [props.startSubmit]);

  useEffect(
    () => props.setIsSubmiting(formik.isSubmitting),
    // eslint-disable-next-line
    [formik.isSubmitting]
  );

  useEffect(
    () => {
      if (props.initialValues) formik.setValues(props.initialValues);
    },
    // eslint-disable-next-line
    [props.initialValues]
  );

  return (
    <Form
      className={"form-default"}
      onSubmit={(e: any) => formik.handleSubmit(e)}
    >
      <ContentGroupBox>
        <TitleContentBox>{t("Address data")}</TitleContentBox>

        <Row>
          <FormikControlIturanAtom
            md={3}
            mask={MaskHelper.Cep}
            formik={formik}
            property={"zipCode"}
            label={t("Zip Code")}
          />
          <FormikControlIturanAtom
            md={4}
            formik={formik}
            property={"street"}
            label={t("Street")}
            disabled={!!addressZipcode?.logradouro}
          />
          <FormikControlIturanAtom
            md={2}
            formik={formik}
            property={"number"}
            label={t("Number")}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={" "}
          />
          <FormikControlAtom
            md={4}
            formik={formik}
            property={"complement"}
            translate={t}
            label={t("Complement")}
          />
          <FormikControlIturanAtom
            md={3}
            formik={formik}
            property={"district"}
            label={t("Neighborhood")}
            disabled={!!addressZipcode?.bairro}
          />
          <FormikControlIturanAtom
            md={3}
            formik={formik}
            property={"city"}
            label={t("City")}
            disabled={!!addressZipcode?.localidade}
          />
          <FormikControlIturanAtom
            md={2}
            formik={formik}
            property={"state"}
            label={t("State")}
            disabled={!!addressZipcode?.uf}
          />
        </Row>
      </ContentGroupBox>

      <ContentGroupBox>
        <TitleContentBox>{t("Emergency contact")}</TitleContentBox>
        <Row>
          <FormikControlIturanAtom
            md={4}
            formik={formik}
            property={"name"}
            label={t("Name")}
          />
          <FormikControlIturanAtom
            mask={MaskHelper.Phone}
            md={3}
            formik={formik}
            property={"phone"}
            label={t("Phone")}
          />
        </Row>
      </ContentGroupBox>

      <div className={"hide"}>
        <input type={"submit"} />
      </div>
    </Form>
  );
};
