import React from "react";
import { C4uStepperMolecule } from "c4u-web-components";

interface IProps {
  currentStep: number;
  items: string[];
}

export const MenuHorizontalStepsMolecule: React.FC<IProps> = (props) => {
  return <C4uStepperMolecule steps={props.items} currentStep={props.currentStep} />;
};
