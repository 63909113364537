import { Row } from "react-bootstrap";
import styled from "styled-components";

const WrapperBoxPlots = styled.div`
  background: ${(props) => props.theme.colors.BlueHigh};
  border-radius: 10px;
  padding: 2px;
`;
const RowWrapperDebit = styled(Row)`
  margin-bottom: 20px;
`;
const HeaderBoxPlots = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.white};
  justify-content: center;
  min-height: 50px;
  align-items: center;
  div {
    margin: 0 10px;
    white-space: nowrap;
  }
  span {
    margin: 0 5px;
  }
`;
const Plate = styled.div``;
const Renavam = styled.div``;
const BodyBoxPlots = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  padding: 0 20px;
  font-size: 12px;
  line-height: 20px;
`;
const LinePlot = styled.div`
  display: flex;
  padding: 12px 0;
  height: 44px;
  label {
    margin-top: 2px;

    font-size: 12px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.Dark};
    white-space: nowrap;
  }
  span {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    font-size: 10px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.DarkGray};
    width: 100%;
    text-align: right;
  }
`;

const LinePlotMsg = styled.div`
  display: flex;
  padding: 12px 0;
  label {
    font-size: 12px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.Dark};
  }
  span {
    font-size: 10px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.DarkGray};
    width: 100%;
    text-align: right;
  }
`;

export {
  WrapperBoxPlots,
  RowWrapperDebit,
  Renavam,
  Plate,
  HeaderBoxPlots,
  BodyBoxPlots,
  LinePlot,
  LinePlotMsg,
};
