import { parseISO } from "date-fns";
import React, { FunctionComponent, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import {
  useInspectionContext,
  useKbb,
  useProductsTracker,
  useTrackerContext,
} from "../../../hooks";
import {
  IPasswordlessParams,
  ISaveTrackerScheduling,
  ITrackerData,
} from "../../../models";

export const TrackerBaseTemplate: FunctionComponent = (props) => {
  const history = useHistory();

  const {
    trackerDataContext,
    setTrackerDataContext,
    setOriginInsuranceContext,
  } = useTrackerContext();
  const { setVehicleContext } = useInspectionContext();
  const { setVehicleCategoryContext } = useTrackerContext();

  const { getTrackerDetail } = useProductsTracker();
  const { GetVehicle } = useKbb();

  const { id, typeJorney } = useParams<IPasswordlessParams>();

  const getTracker = useCallback(async () => {
    if (paths.trackerOrder(id, typeJorney) === history.location.pathname) {
      history.replace(paths.trackerQuotes(id, typeJorney));
      return;
    }
    if (paths.trackerCheckout(id, typeJorney) === history.location.pathname) {
      history.replace(paths.trackerScheduleInstall(id, typeJorney));
      return;
    }

    const data = await getTrackerDetail(Number(id));
    setVehicleCategoryContext(data.vehicleCategory);
    if (data?.kbbId || data.molicarId) {
      GetVehicle(data.kbbId, data.molicarId, data.vehicleModelYear).then(
        setVehicleContext
      );

      const tracker = trackerDataContext ?? ({} as ITrackerData);
      tracker.driver = {
        vehicleUse: data.vehicleUse ?? "",

        name: data.name ?? "",
        email: data.email ?? "",
        cellphone: data.cellphone ?? "",
        document: data.document ?? "",
        birthdate: data.birthdate ?? "",
        gender: data.gender ?? "",
        maritalStatus: data.maritalStatus ?? "",
        legalVehicleOwn: data.legalVehicleOwn ?? "",
        driversBetween18and26: data.driversBetween18and26 ?? "",
        vehicleUseToWork: data.vehicleUseToWork ?? "",
        storedClosedGarage: data.storedClosedGarage ?? "",
        storedClosedGarageAtHome: data.storedClosedGarageAtHome ?? "",
        storedClosedGarageAtWork: data.storedClosedGarageAtWork ?? "",
        storedClosedGarageAtSchool: data.storedClosedGarageAtSchool ?? "",
        mainDriver: data.mainDriver ?? "",

        driverPersonalDocument: data.driverPersonalDocument ?? "",
        driverName: data.driverName ?? "",
        driverBirthdate: data.driverBirthdate ?? "",
        driverGender: data.driverGender ?? "",
        driverMaritalStatus: data.driverMaritalStatus ?? "",
        driverLink: data.driverLink ?? "",
        licenseTime: data.licenseTime ?? "",
      };
      tracker.order = {
        name: data.emergencyContacts?.length
          ? data.emergencyContacts[0].name
          : "",
        phone: data.emergencyContacts?.length
          ? `${data.emergencyContacts[0].areaCode}${data.emergencyContacts[0].phoneNumber}`
          : "",

        zipCode: data.zipCode ?? "",
        street: data.street ?? "",
        number: data.number ?? "",
        complement: data.complement ?? "",
        district: data.neighborhood ?? "",
        city: data.city ?? "",
        state: data.state ?? "",
      } as any;
      tracker.trackerId = data.id;
      tracker.vehicle = {
        plate: data.plate ?? "",
        vin: data.vin ?? "",
        dealershipExit: data.dealershipExit ?? "",
        gasKit: data.gasKit ?? "0",
        vehicleUse: data.vehicleUse ?? "",
        taxFree: data.taxFree ? "1" : "0",
        taxFreeReason: data.taxFreeReason ?? "",
        isTunning: data.isTunning ? "1" : "0",
        isZeroKm: data.isZeroKm ? "1" : "0",
        overnightZipCode: data.overnightZipCode ?? "",
        isInsuranceOrigin: data.isInsuranceOrigin ?? "",
      } as any;
      tracker.quote = {
        partnerQuoteItemId: data.partnerQuoteItemId ?? "",
        value: data.previousValue,
        date:
          data.quoteDate === undefined || data.quoteDate === null
            ? undefined
            : parseISO(data.quoteDate.toString()),
        name: data.productName ?? "",
      } as any;
      tracker.vehicleName = data.vehicleName;
      tracker.vehicleModelYear = data.vehicleModelYear;
      tracker.step = data.step;
      tracker.scheduling = {
        trackerId: data.id,
        shopId: data.partnerShopId,
        schedulingDate: data.schedulingDate?.toString(),
        periodNumber: data.partnerPeriodNumber,
        zipCode: data.homeScheduleAddresses?.zipCode,
        isHomeSchedule: data.isHomeSchedule,
        homeScheduleAddresses: data.homeScheduleAddresses,
        displacementValue: data.displacementValue,
        vehicleCategory: data.vehicleCategory ?? "",
      } as ISaveTrackerScheduling;

      setTrackerDataContext(tracker);
      setOriginInsuranceContext(tracker.vehicle?.isInsuranceOrigin);
    }
  }, [
    id,
    typeJorney,
    history,
    getTrackerDetail,
    setVehicleCategoryContext,
    GetVehicle,
    setVehicleContext,
    trackerDataContext,
    setTrackerDataContext,
    setOriginInsuranceContext,
  ]);

  useEffect(() => {
    if (!trackerDataContext && id && id !== "0") getTracker();
    // eslint-disable-next-line
  }, [trackerDataContext, id]);

  return <>{props.children}</>;
};
