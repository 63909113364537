import styled from "styled-components";

export const WrapperQuoteItem = styled.div`
  text-align: center;
`;

export const WrapperQuoteOkNokItem = styled(WrapperQuoteItem)`
  display: grid;
  height: 100%;
  align-items: center;
`;

export const WrapperQuoteItemKeys = styled(WrapperQuoteItem)`
  align-items: flex-start;
  height: 100%;
`;

export const ContentGroupBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  margin-left: 11px;
  gap: 5px;

  path {
    fill: rgb(255, 212, 120);
  }
`;

export const Title = styled.div<IProps>`
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: ${(p) => (p.size === "small" ? 12 : 16)}px;
  line-height: 20px;
  color: ${(p) => p.theme.colors.MediumGray};
  text-transform: uppercase;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 10px 0 4px 0;
`;

export const Price = styled.div`
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${(p) => p.theme.colors.BlueNavy};
  text-transform: uppercase;

  &.reduce-font {
    font-size: 16px;
  }
`;

export const PlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-size: 24px;
  color: ${(p) => p.theme.colors.BlueNavy};
  cursor: pointer;
`;

export const Description = styled(Price)`
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
`;

export const SpotlightPlot = styled.div`
  width: 62px;
  height: 14px;
  margin-top: 6px !important;
`;

export const Spotlight = styled(SpotlightPlot)`
  background: #f3bc2a;
  border-radius: 2px;

  font-family: ${(p) => p.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: bold;
  font-size: 6px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #1f3e74;

  text-align: center;
  margin: 0 auto;
  justify-content: center;
`;

export const Franchise = styled.div`
  font-family: ${(p) => p.theme.fontFamily.OpenSans};
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
`;
interface IProps {
  size?: "small" | "big";
}

export const SubTitle = styled.div<IProps>`
  font-family: ${(p) => p.theme.fontFamily.OpenSans};
  font-weight: 600;
  font-size: ${(p) => (p.size === "small" ? "10px" : "11px")};
  line-height: 16px;
  color: ${(p) => p.theme.colors.MediumGray};

  &.total-loss {
    max-width: 90px;
    text-align: start;
    line-height: 14px;
  }
`;

export const TotalLossContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TotalLossItemDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const DeniedIconFixedWidth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 25px;
  height: 25px;
`;

export const ChooseCommisionWrapper = styled.div`
  padding: 0 15px;
`;

export const CommissionOptionInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  gap: 6px;

  input,
  label {
    cursor: pointer;
    margin: 0;
  }

  label {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
`;
