import styled from "styled-components";

export const WrapperDataControl = styled.div`
  max-width: 400px;
  margin: auto;
  height: 100%;
  display: grid;
  align-items: center;
  button {
    text-transform: uppercase;
  }
`;
export const IconCircle = styled.div`
  margin-top: 6px;
  border: 1px solid ${(props) => props.theme.colors.DarkBlue};
  box-sizing: border-box;
  width: 31px;
  border-radius: 31px;
  text-align: center;
  height: 31px;
  vertical-align: middle;
  line-height: 27px;
  background-color: ${(props) => props.theme.colors.white};
  svg {
    width: 27px;
    margin: -3px 0 0 0;
    path {
      fill: #003468;
    }
  }
`;

export const TitleInfo = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
`;
export const DescriptionInfo = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
`;
