import styled from "styled-components";

export const CardHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 767px) {
    gap: 12px;
  }
`;

export const VehicleInformationContent = styled.div`
  img {
    width: 125px;
    border-radius: 10px;
  }
`;

export const HeaderTitleCard = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;

  text-align: center;
  letter-spacing: -0.15px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const CardBodyWrapperButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
`;

export const ContentPlateAndAcessButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 590px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
`;
