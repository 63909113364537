import React, { useEffect, useState } from "react";

import {
  CardBodySubtitle,
  CardDate,
  WrapperCardBodyRenave,
} from "./vehicle-delivery-card-body.atom.style";
import { Col, Row } from "react-bootstrap";

import { DateHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";

interface IProps {
  reinspectionDate: string;
  reinspectionPeriod: number;
}

export const VehicleDeliveryCardBodyAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [timeLeftUntilDelivery, setTimeLeftUntilDelivery] = useState("");

  const calculateRestTime = () => {
    const mydate = DateHelper.calculateTimeLeft(
      new Date(props.reinspectionDate)
    );

    if (mydate.days) {
      if (mydate.days === 1) {
        setTimeLeftUntilDelivery(
          t("{{day}}day {{hours}}h {{minutes}}min", {
            day: mydate.days,
            hours: mydate.hours,
            minutes: mydate.minutes,
          })
        );
        return;
      }

      setTimeLeftUntilDelivery(
        t("{{days}}days {{hours}}h {{minutes}}min", {
          days: mydate.days,
          hours: mydate.hours,
          minutes: mydate.minutes,
        })
      );
      return;
    }

    if (mydate.hours) {
      setTimeLeftUntilDelivery(
        t("{{hours}}h {{minutes}}min", {
          hours: mydate.hours,
          minutes: mydate.minutes,
        })
      );
      return;
    }

    if (mydate.minutes === 1) {
      setTimeLeftUntilDelivery(
        t("{{minute}} minute", {
          minute: mydate.minutes,
        })
      );

      return;
    }

    setTimeLeftUntilDelivery(
      t("{{minutes}} minutes", {
        minutes: mydate.minutes,
      })
    );
  };

  useEffect(() => {
    calculateRestTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperCardBodyRenave>
      <Row>
        <Col md={12}>
          <CardDate className="mr-3">
            {t("DeliveryDate {{date}}", {
              date: DateHelper.dateHourCustomFormat(
                props.reinspectionDate,
                `dd/MM/yyyy 'às' HH':'mm`
              ),
            })}
          </CardDate>
        </Col>
        <Col>
          <CardBodySubtitle>
            {t("TimeUntilDelivery {{time}}", {
              time: timeLeftUntilDelivery,
            })}
          </CardBodySubtitle>
        </Col>
      </Row>
    </WrapperCardBodyRenave>
  );
};
