import styled from "styled-components";

export const Wrapper = styled.div`
  .highlight-empty .rbt-input-main,
  .highlight-empty .form-check,
  .highlight-empty input,
  .highlight-empty select {
    border: 1px solid green;
    border-radius: 4px;
  }

  .highlight-empty-no .rbt-input-main,
  .highlight-empty-no .form-check,
  .highlight-empty-no input,
  .highlight-empty-no select {
    border: 1px solid red;
    border-radius: 4px;
  }
`;
