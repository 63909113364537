import { PasswordlessPageEnum } from "c4u-web-components";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { PasswordlessRegisterOrganism } from "../../organisms";
import { DebtsBasePage } from "./base/debts-base.page";

export const DebtsPasswordlessRegisterPage: React.FC = () => {
  const { t } = useTranslation();

  const { typeJorney } = useParams<IPasswordlessParams>();

  const [page, setPage] = useState<PasswordlessPageEnum>();

  const header = useMemo(() => {
    return {
      Title: t("TitleVehicleRegister"),
      Description: t("DescriptionRegisterPawordless"),
      HandleClickBack:
        page === PasswordlessPageEnum.CodeValidation
          ? () => window.location.reload()
          : undefined,
      otherPage: true,
    } as IHeaderMainTemplate;
  }, [t, page]);

  return (
    <DebtsBasePage step={0} header={header} hideAuxiliarButton={true}>
      <PasswordlessRegisterOrganism
        route={paths.debtsVehicle(typeJorney)}
        setPage={setPage}
      />
    </DebtsBasePage>
  );
};
