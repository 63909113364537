import styled from "styled-components";

export const ContentGroupBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 36px 26px;
  margin-bottom: 44px;
`;

export const TitleContentBox = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #1f3e74;
  margin: 0 0 28px 0;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: baseline;
  div {
    margin-left: 4px;
  }
`;
