export class ManheimRequestError {
  errors: any;

  constructor(apiErrors: any) {
    this.errors = {};

    Object.keys(apiErrors).forEach((prop) => {
      const propHandle = prop.trim().replace(/^x?\[\d\]\./i, "");
      const newProp =
        propHandle.substr(0, 1).toLowerCase() + propHandle.substr(1);
      const value = apiErrors[prop];
      if (value && value.length > 0) {
        const [message] = value;
        var regEx = new RegExp(`'${newProp}'`, "ig");
        this.errors[newProp] = { text: message.replace(regEx, "") };
      }
    });
  }
}

export interface ManheimRequestError {
  message?: string;
  code?: string;
}

export class ManheimGeneralRequestMessageError {
  code?: string;
  message?: string;

  constructor(data?: ManheimRequestError) {
    this.message = data?.message;
    this.code = data?.code;
  }
}

export class ManheimGeneralRequestError {
  generalError: string;
  stack?: string;

  constructor(stack?: string) {
    this.stack = stack;
    this.generalError = "api_error";
  }
}
