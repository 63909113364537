import { ICoxUser, ICreatePasswordlessRequest } from "c4u-web-components";
import React, { createContext, useState } from "react";
import { SessionProviderState } from "../hooks/contexts/use-session-context.hook";
import { ConflictRequestError, IHeaderMainTemplate } from "../models";

const SessionContext = createContext({} as any);

declare const window: Window &
  typeof globalThis & {
    registration: any[];
  };

const SessionProvider = (props: any) => {
  const [genericSuccessModal, setShowGenericSuccessModal] = useState(false);
  const [genericErrorModal, setShowGenericErrorModal] = useState(false);
  const [genericWarningModal, setShowGenericWarningModal] = useState(false);
  const [genericModalMsg, setGenericModalMsg] = useState<string | null>(null);
  const [riskifiedIdContext, setRiskifiedIdContext] = useState<string>();
  const [usernamePawordlessContext, setUsernamePawordlessContext] =
    useState<ICreatePasswordlessRequest>({} as ICreatePasswordlessRequest);
  const [coxUserContext, setCoxUserContext] = useState<ICoxUser>();

  const [genericEventClose, setGenericEventClose] = useState<
    (() => void) | null
  >(null);

  const showGenericErrorModal = (
    text: string | null = null,
    onClose: (() => void) | null = null
  ) => {
    setGenericModalMsg(text);
    setShowGenericErrorModal(true);
    if (onClose) setGenericEventClose(() => onClose);
  };
  const hideGenericErrorModal = () => {
    setGenericModalMsg(null);
    setShowGenericErrorModal(false);

    if (genericEventClose) {
      genericEventClose();
      setGenericEventClose(null);
    }
  };

  const showGenericSuccessModal = (
    text: string | null = null,
    onClose: (() => void) | null = null
  ) => {
    setGenericModalMsg(text);
    setShowGenericSuccessModal(true);
    if (onClose) setGenericEventClose(() => onClose);
  };
  const hideGenericSuccessModal = () => {
    setGenericModalMsg(null);
    setShowGenericSuccessModal(false);

    if (genericEventClose) {
      genericEventClose();
      setGenericEventClose(null);
    }
  };
  const showGenericWarningModal = (
    text: string | null = null,
    onClose: (() => void) | null = null
  ) => {
    setGenericModalMsg(text);
    setShowGenericWarningModal(true);
    if (onClose) setGenericEventClose(() => onClose);
  };
  const hideGenericWarningModal = () => {
    setGenericModalMsg(null);
    setShowGenericWarningModal(false);
    if (genericEventClose) {
      genericEventClose();
      setGenericEventClose(null);
    }
  };

  const handleFormikError = (
    exception: any,
    setErrors?: any,
    mapper: (param: any) => any = (param) => param,
    showErrorModal = true
  ) => {
    if (exception?.errors) {
      showGenericWarningModal();
      if (setErrors) setErrors(mapper(exception.errors));
    } else if (exception instanceof ConflictRequestError)
      return exception?.message;
    else setShowGenericErrorModal(showErrorModal);
  };

  const handleFormikException = (
    exception: any,
    setErrors?: any,
    mapper: (param: any) => any = (param) => param
  ) => {
    const message = handleFormikError(exception, setErrors, mapper);
    if (message) showGenericWarningModal(message);
  };

  const [headerMainTemplate, setHeaderMainTemplate] =
    useState<IHeaderMainTemplate>();

  const handleEventGTM = (eventData: object) => {
    try {
      console.log(eventData);
      window.registration.push(eventData);
    } catch (e) {
      if (process.env.REACT_APP_GTMID)
        console.log("Error when trying to send data to GTM", eventData);
    }
  };

  return (
    <SessionContext.Provider
      value={
        {
          usernamePawordlessContext,
          setUsernamePawordlessContext,
          genericModalMsg,
          genericErrorModal,
          showGenericErrorModal,
          hideGenericErrorModal,
          genericSuccessModal,
          showGenericSuccessModal,
          hideGenericSuccessModal,
          genericWarningModal,
          showGenericWarningModal,
          hideGenericWarningModal,
          headerMainTemplate,
          setHeaderMainTemplate,
          handleFormikError,
          handleFormikException,
          riskifiedIdContext,
          setRiskifiedIdContext,
          coxUserContext,
          setCoxUserContext,
          handleEventGTM,
        } as SessionProviderState
      }
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
