import { TypesHelper } from "c4u-web-components";

export interface ISaveTrackerOrderRequestEmergencyContact {
  name?: string;
  phone?: string;
}

export class SaveTrackerOrderRequestEmergencyContact {
  name: string | null;
  areaCode: string | null;
  phoneNumber: string | null;
  constructor({ name, phone, areaCode, phoneNumber }: any) {
    this.name = TypesHelper.toStringNull(name);
    const phoneParts = phone?.split(")");
    this.areaCode =
      phoneParts?.length === 2
        ? TypesHelper.toStringOnlyNumbersNull(phoneParts[0])
        : null;
    this.phoneNumber =
      phoneParts?.length === 2
        ? TypesHelper.toStringOnlyNumbersNull(phoneParts[1])
        : null;
  }
}

export interface ISaveTrackerOrderRequest {
  trackerId?: number | string;
  partnerQuoteGuid?: string;
  partnerQuoteItemId?: number;
  commission?: number;
  street?: string;
  complement?: string;
  neighborhood?: string;
  zipCode?: string;
  number?: string;
  emergencyContacts?: ISaveTrackerOrderRequestEmergencyContact[];
}

export class SaveTrackerOrderRequest {
  trackerId: number | null;
  partnerQuoteGuid: string | null;
  partnerQuoteItemId: number | null;
  commission: number | null;
  street: string | null;
  complement: string | null;
  neighborhood: string | null;
  zipCode: string | null;
  number: string | null;
  emergencyContacts: SaveTrackerOrderRequestEmergencyContact[] | null;
  constructor({
    trackerId,
    partnerQuoteGuid,
    partnerQuoteItemId,
    commission,
    street,
    complement,
    neighborhood,
    zipCode,
    number,
    emergencyContacts,
  }: ISaveTrackerOrderRequest) {
    this.trackerId = TypesHelper.toNumberNull(trackerId) || null;
    this.partnerQuoteGuid = TypesHelper.toStringNull(partnerQuoteGuid);
    this.partnerQuoteItemId = TypesHelper.toNumberNull(partnerQuoteItemId);
    this.commission = TypesHelper.toNumberNull(commission);
    this.street = TypesHelper.toStringNull(street);
    this.complement = TypesHelper.toStringNull(complement);
    this.neighborhood = TypesHelper.toStringNull(neighborhood);
    this.zipCode = TypesHelper.toStringOnlyNumbersNull(zipCode);
    this.number = TypesHelper.toStringNull(number);
    this.emergencyContacts =
      emergencyContacts?.map(
        (m) => new SaveTrackerOrderRequestEmergencyContact(m)
      ) ?? null;
  }
}
