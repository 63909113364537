import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export const ColContent = styled(Col)`
  background: #ffffff;
  min-height: 410px;
  display: grid;

  @media (max-width: 576px) {
    background-color: ${(props) => props.theme.colors.DarkBlue};
    div {
      color: ${(props) => props.theme.colors.white} !important;
    }
  }
`;

export const ColData = styled(Col)`
  background: #ffffff;
  min-height: 410px;
  display: grid;
`;

export const ColBlue = styled(Col)`
  background: ${(p) => p.theme.colors.DarkBlue};
`;

export const RowContent = styled(Row)`
  margin: 0;
  overflow: hidden;
  border: 1px solid rgba(168, 170, 172, 0.35);
  box-sizing: border-box;
  border-radius: 10px;
  @media (min-width: 576px) {
    .borderLeft {
      border-right: 1px solid rgba(168, 170, 172, 0.35);
      box-sizing: border-box;
    }
  }
`;
