import { TransferToUserModalAtom } from "c4u-web-components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import {
  useC4u,
  useInspectionContext,
  useProductsInspection,
  useSessionContext,
} from "../../../hooks";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { FeedbackModal } from "../../molecules";
import { InspectionPayment } from "../../organisms";
import { InspectionBasePage } from "./base/inspection-base.page";

export const InspectionPaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { typeJorney } = useParams<IPasswordlessParams>();

  const { getListMyUsers } = useC4u();
  const { putTransferInspection } = useProductsInspection();
  const { coxUserContext } = useSessionContext();
  const { inspectionContext, vehicleContext, idInspectionContext } =
    useInspectionContext();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [showChooseUserModal, setShowChooseUserModal] = useState(false);

  const header = useMemo(() => {
    return {
      Title: t("TitleInspectionRegisterPage"),
      BackTo: paths.inspectionVehicle(typeJorney),
      otherPage: true,
    } as IHeaderMainTemplate;
  }, [typeJorney, t]);

  useEffect(() => {
    if (coxUserContext?.isCorporate) setShowChooseUserModal(true);
  }, [coxUserContext?.isCorporate, inspectionContext, vehicleContext]);

  useEffect(() => {
    if (showErrorModal || showSuccessModal) setShowChooseUserModal(false);
  }, [showErrorModal, showSuccessModal]);

  return (
    <>
      <InspectionBasePage step={3} header={header}>
        <InspectionPayment />
      </InspectionBasePage>

      <TransferToUserModalAtom
        entryId={idInspectionContext ?? 0}
        show={showChooseUserModal}
        onSubmitCallback={putTransferInspection}
        getUsersPagedListCallback={getListMyUsers}
        onSucessCallBack={() => setShowSuccessModal(true)}
        setShowErrorModal={setShowErrorModal}
        setErrorMsg={setErrorMsg}
        translate={t}
      />
      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => history.push(paths.myInspections(typeJorney))}
        title={t("A problem found")}
        content={errorMsg ?? t("GenericErrorMsg")}
      />
      <FeedbackModal
        type={"success"}
        show={showSuccessModal}
        onHide={() => history.push(paths.myInspections(typeJorney))}
        title={t("Everything all right")}
        content={t("Inspection schedule request forwarded successfully")}
      />
    </>
  );
};
