import styled from "styled-components";

const CardBodyIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

const BodyIconDiv = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.MediumGray};
  cursor: ${(props) => (props.onClick !== undefined ? "pointer" : "default")};
`;

export { BodyIconDiv, CardBodyIcons };
