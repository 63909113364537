import { PasswordlessSendByEnum } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../../constants";
import {
  useDebtsContext,
  useProductsDebts,
  useSessionContext,
} from "../../../../hooks";
import {
  DebtsVehicleModel,
  DebtsVehicleRegisterRequest,
  IDebtsVehicleRegisterRequest,
  IPasswordlessParams,
} from "../../../../models";
import { FormDebtsVehicleRegisterMolecule } from "../../../molecules";
import { IFormDebtsVehicleRegister } from "../../../molecules/debts/form-debts-vehicle-register/form-debts-vehicle-register.molecule";

export const DebtsVehicleRegisterOrganism: React.FC = () => {
  const { debtsContext, setDebtsContext } = useDebtsContext();
  const {
    handleFormikError,
    showGenericWarningModal,
    usernamePawordlessContext,
    setRiskifiedIdContext,
  } = useSessionContext();
  const { saveDebtVehicle } = useProductsDebts();
  const [submit, setSubmit] = useState<boolean>(false);

  const { typeJorney } = useParams<IPasswordlessParams>();

  const history = useHistory();

  const handleSubmit = async (
    props: IFormDebtsVehicleRegister,
    setErrors: any
  ) => {
    try {
      const response = await saveDebtVehicle(
        new DebtsVehicleRegisterRequest({
          ...props,
          passwordless:
            usernamePawordlessContext.sendBy === PasswordlessSendByEnum.Sms
              ? usernamePawordlessContext.cellphone
              : usernamePawordlessContext.email,
        } as IDebtsVehicleRegisterRequest)
      );

      setDebtsContext({
        id: response.id,
        detranUf: response.detranUf,
        plate: response.plate,
        renavam: response.renavam,
        customer: {
          document: props.document,
          email:
            typeJorney === Definition.Passwordless
              ? response.customer.email
              : "",
        },
      } as DebtsVehicleModel);

      setRiskifiedIdContext(undefined);

      return true;
    } catch (e) {
      const msg = handleFormikError(e, setErrors);
      if (msg) showGenericWarningModal(msg);
      return false;
    }
  };

  useEffect(() => {
    if (debtsContext?.id && submit) {
      setSubmit(false);
      history.push(
        paths.debtsPenalties(debtsContext.id.toString(), typeJorney)
      );
    }
    // eslint-disable-next-line
  }, [debtsContext, submit]);

  return (
    <>
      <FormDebtsVehicleRegisterMolecule
        onSubmit={handleSubmit}
        onSuccessSubmit={() => setSubmit(true)}
        initialValues={{
          detranUf: debtsContext?.detranUf ?? "",
          plate: debtsContext?.plate ?? "",
          renavam: debtsContext?.renavam ?? "",
          document: debtsContext?.customer.document ?? "",
        }}
      />
    </>
  );
};
