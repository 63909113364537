import { PaymentCreditCard } from "c4u-web-components";
import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useState,
} from "react";
import { IInspectionProviderProps } from "../hooks/contexts/use-inspection.hook";
import { Inspection, InspectionVehicleData, Vehicle } from "../models";

const InspectionContext = createContext({} as any);

const InspectionProvider: FunctionComponent = (props) => {
  const [idInspectionContext, setIdInspectionContext] = useState<number>();
  const [vehicleDataContext, setVehicleDataContext] =
    useState<InspectionVehicleData>();
  const [vehicleContext, setVehicleContext] = useState<Vehicle>();

  const [inspectionContext, setInspectionContext] = useState<Inspection>();
  const [paymentContext, setPaymentContext] = useState<PaymentCreditCard>();

  const clearInspectionContexts = useCallback(() => {
    setIdInspectionContext(undefined);
    setInspectionContext(undefined);
    setPaymentContext(undefined);
    setVehicleContext(undefined);
    setVehicleDataContext(undefined);
  }, []);

  return (
    <InspectionContext.Provider
      value={
        {
          idInspectionContext,
          setIdInspectionContext,
          inspectionContext,
          setInspectionContext,
          vehicleContext,
          setVehicleContext,
          vehicleDataContext,
          setVehicleDataContext,
          paymentContext,
          setPaymentContext,
          clearInspectionContexts,
        } as IInspectionProviderProps
      }
    >
      {props.children}
    </InspectionContext.Provider>
  );
};

export { InspectionContext, InspectionProvider };
