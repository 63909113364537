import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IHeaderMainTemplate } from "../../../models";
import { ScheduleTrackerInstalationOrganism } from "../../organisms";
import { TrackerBasePage } from "./base/tracker-base.page";

export const TrackerSchedulePage: React.FC = () => {
  const { t } = useTranslation();

  const header = useMemo(() => {
    return {
      Title: t("Schedule installation"),
    } as IHeaderMainTemplate;
  }, [t]);

  return (
    <TrackerBasePage step={5} header={header}>
      <ScheduleTrackerInstalationOrganism />
    </TrackerBasePage>
  );
};
