import { TypesHelper } from "c4u-web-components";

export interface ISaveTrackerQuoteRequest {
  trackerId?: number;
  partnerQuoteGuid?: string;
  partnerQuoteItemId?: number;
  commission?: number;
  name?: string;
  value?: number;
  date?: Date | string;
  displacementValue?: number;
}

export class SaveTrackerQuoteRequest {
  trackerId: number | null;
  partnerQuoteGuid: string | null;
  partnerQuoteItemId: number | null;
  commission: number | null;
  name: string | null;
  value: number | null;
  date: Date | null;
  displacementValue: number | null;
  constructor({
    trackerId,
    partnerQuoteGuid,
    partnerQuoteItemId,
    commission,
    name,
    value,
    date,
    displacementValue,
  }: ISaveTrackerQuoteRequest) {
    this.trackerId = TypesHelper.toNumberNull(trackerId);
    this.partnerQuoteGuid = TypesHelper.toStringNull(partnerQuoteGuid);
    this.partnerQuoteItemId = TypesHelper.toNumberNull(partnerQuoteItemId);
    this.commission = TypesHelper.toNumberNull(commission);
    this.name = TypesHelper.toStringNull(name);
    this.value = TypesHelper.toNumberNull(value);
    this.date = TypesHelper.toDateTimeNull(date);
    this.displacementValue = TypesHelper.toNumberNull(displacementValue);
  }
}
