import { Form } from "react-bootstrap";
import styled from "styled-components";

export const FlexForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const ContentGroupBox = styled.div`
  padding: 28px 48px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(168, 170, 172, 0.35);
  border-radius: 10px;
`;

export const TitleContentBox = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #1f3e74;
  margin: 0 0 28px 0;
`;
