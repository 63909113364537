import { ButtonSecondary, Img, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BoxImg,
  BoxVehicleDetail,
  LabelPlate,
  VehicleName,
  VehiclePlate,
  WrapperVechicleDetails,
} from "./card-vehicle-details.molecute.style";

interface IProps {
  photo: string;
  title: string;
  plate: string;
  onClickEditVehicle?: () => void;
}
export const CardVehicleDetails: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);

  useEffect(() => setProps(parameters), [parameters]);

  return (
    <BoxVehicleDetail>
      <Row>
        <Col lg={4}>
          {props?.photo ? (
            <BoxImg>
              <Img src={props.photo} />
            </BoxImg>
          ) : (
            <SpinnerCenteredAtom />
          )}
        </Col>
        <Col lg={4}>
          <WrapperVechicleDetails>
            <VehicleName>{props.title}</VehicleName>
            <div className={"d-flex align-items-center"}>
              <div>
                <LabelPlate>{t("Plate")}</LabelPlate>
                <VehiclePlate>{props.plate}</VehiclePlate>
              </div>
              <div className={"ml-4"}>
                <ButtonSecondary
                  sizex={"md"}
                  sizey={"thin"}
                  onClick={props.onClickEditVehicle}
                >
                  {t("Edit vehicle")}
                </ButtonSecondary>
              </div>
            </div>
          </WrapperVechicleDetails>
        </Col>
      </Row>
    </BoxVehicleDetail>
  );
};
