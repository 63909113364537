import { Img } from "c4u-web-components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CommingSoonB3 } from "../../assets";
import { CommingSoonAdvice, Wrapper } from "./b3-home.page.style";

export const B3Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Row>
        <Col className={"text-right position-relative"}>
          <CommingSoonAdvice>
            {t("News comming")}
          </CommingSoonAdvice>

          <Img src={CommingSoonB3} className={"background-img"} />
        </Col>
      </Row>
    </Wrapper>
  );
};
