import { ButtonPrimary } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  ModalHeader,
  ModalBody,
  Title,
  Content,
  BackButton,
} from "./modal-payment-proof-share.molecule.style";
import { ModalPaymentProofShareMoleculeValidation } from "./modal-payment-proof-share.molecule.validation";

interface IProps {
  show: boolean;
  onHide: () => void;
  onSubmit?: (email: string) => Promise<void>;
  onBackButton?: () => void;
}
export interface IForm {
  email: string;
}

export const ModalPaymentProofShareMolecule: React.FC<IProps> = (
  parameters
) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => setProps(parameters), [parameters]);

  const formik = useFormik<IForm>({
    initialValues: {} as IForm,
    onSubmit: async (values) => {
      if (props.onSubmit) await props.onSubmit(values.email);
      formik.setSubmitting(false);
    },
    validationSchema: ModalPaymentProofShareMoleculeValidation(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const handleBack = () => {
    if (props.onBackButton) props.onBackButton();
    props.onHide();
  };

  return (
    <Modal show={props.show} centered onHide={props.onHide}>
      <ModalHeader closeButton>{t("To share proof")}</ModalHeader>
      <ModalBody>
        <Content>
          <Title>{t("TitleModalEmailShare")}</Title>
          <Form
            onSubmit={(e: any) => formik.handleSubmit(e)}
            className={"form-default"}
          >
            <Form.Row className={"input"}>
              <Form.Group as={Col} sm controlId="email">
                <Form.Control
                  isInvalid={!!formik.errors?.email}
                  placeholder={t("Tab here")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors?.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} sm>
                <Row>
                  <Col>
                    <BackButton onClick={handleBack}>{t("Back")}</BackButton>
                  </Col>
                  <Col className={"text-right"}>
                    <ButtonPrimary
                      sizex={"md"}
                      type={"submit"}
                      loading={formik.isSubmitting}
                    >
                      {t("Send")}
                    </ButtonPrimary>
                  </Col>
                </Row>
              </Form.Group>
            </Form.Row>
          </Form>
        </Content>
      </ModalBody>
    </Modal>
  );
};
