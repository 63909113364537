import { Routers } from "../models";
import {
  B3Home,
  DebtsPasswordlessRegisterPage,
  DebtsProofPaymentPage,
  DebtsVehicleRegisterPage,
  FenautoHome,
  InspectionConfirmationPage,
  InspectionListPage,
  InspectionPartnerPage,
  InspectionPasswordlessRegisterPage,
  InspectionPaymentPage,
  InspectionVehiclePage,
  InsuranceHome,
  ManagementHome,
  ManheimBuyerInformationPage,
  MyNumbersHome,
  PerformanceHome,
  TrackerCheckoutPage,
  TrackerDriverPage,
  TrackerMyQuotesPage,
  TrackerOrderPage,
  TrackerPasswordlessRegisterPage,
  TrackerQuotesPage,
  TrackerSchedulePage,
  TrackerVehiclePage,
  VehicleDebtsConfirmPage,
  VehicleDebtsListPage,
  VehicleDebtsPage,
  VehicleDebtsPaymentPage,
} from "../ui/pages";
import {
  B3Template,
  DebtsTemplate,
  EmptyTemplate,
  FenautoTemplate,
  InspectionTemplate,
  InsuranceTemplate,
  ManheimTemplate,
  MyNumbersTemplate,
  PasswordlessTemplate,
  TrackerPasswordlessTemplate,
  TrackerTemplate,
} from "../ui/templates";
import { PerformanceTemplate } from "../ui/templates/performance/performance.template";

const paths = {
  inspectionRegister: (param?: string) =>
    `/${Definition.ProductInspection}/register/${param ?? ""}`,
  inspectionPartner: (param?: string) =>
    `/${Definition.ProductInspection}/partner/${param ?? ""}`,
  inspectionVehicle: (param?: string) =>
    `/${Definition.ProductInspection}/vehicle/${param ?? ""}`,
  inspectionPayment: (param?: string) =>
    `/${Definition.ProductInspection}/payment/${param ?? ""}`,
  inspectionConfirmation: (param?: string) =>
    `/${Definition.ProductInspection}/confirmation/${param ?? ""}`,
  myInspections: (param?: string) =>
    `/${Definition.ProductInspection}/list/${param ?? ""}`,
  insurance: () => "/insurance",
  debtsRegister: (param?: string) =>
    `/${Definition.ProductDebts}/register/${param ?? ""}`,
  debtsVehicle: (param?: string) =>
    `/${Definition.ProductDebts}/vehicle/${param ?? ""}`,
  debtsPenalties: (param1: string = ":id", param2?: string) =>
    `/${Definition.ProductDebts}/penalties/${param1}/${param2 ?? ""}`,
  debtsPayment: (param1: string | number = ":id", param2?: string) =>
    `/${Definition.ProductDebts}/payment/${param1}/${param2 ?? ""}`,
  debtsConfirmation: (param1: string = ":id", param2?: string) =>
    `/${Definition.ProductDebts}/confirmation/${param1}/${param2 ?? ""}`,
  debtsList: (param?: string) =>
    `/${Definition.ProductDebts}/list/${param ?? ""}`,
  debtsProof: (guid: string = ":guid", print: string = ":print") =>
    `/${Definition.ProductDebts}/proof/${guid}/${print}`,
  management: () => "/managementData",
  myNumbers: () => "/myNumbers",
  fenauto: () => "/fenauto",
  performance: () => "/performance",
  b3: () => "/management",
  trackerRegister: (param?: string) =>
    `/${Definition.ProductTracker}/register/${param ?? ""}`,
  trackerDriverData: (id?: string | number, param?: string | number) =>
    `/${Definition.ProductTracker}/driver/${id ?? ":id"}/${param ?? ""}`,
  trackerVehicleSelection: (id?: string | number, param?: string | number) =>
    `/${Definition.ProductTracker}/vehicle/${id ?? ":id"}/${param ?? ""}`,
  trackerScheduleInstall: (id?: string | number, param?: string | number) =>
    `/${Definition.ProductTracker}/schedule/${id ?? ":id"}/${param ?? ""}`,
  trackerQuotes: (id?: string | number, param?: string | number) =>
    `/${Definition.ProductTracker}/quotes/${id ?? ":id"}/${param ?? ""}`,
  trackerOrder: (id?: string | number, param?: string | number) =>
    `/${Definition.ProductTracker}/order/${id ?? ":id"}/${param ?? ""}`,
  trackerCheckout: (id?: string | number, param?: string | number) =>
    `/${Definition.ProductTracker}/checkout/${id ?? ":id"}/${param ?? ""}`,
  trackerMyQuotes: (param?: string | number) =>
    `/${Definition.ProductTracker}/my-quotes/${param ?? ""}`,
  manheimBuyerInformationPage: () => "/manheim-buyer-information",
};

const Definition = {
  Passwordless: "passwordless",
  TypeJorneyParam: ":typeJorney",
  JorneyPasswordless: "passwordless-jorney",
  JorneyInspection: "inspection",
  JorneyDebts: "debts",
  JorneyInsurance: "insurance",
  JorneyB3: "b3",
  ProductInspection: "inspection",
  ProductDebts: "debts",
  ProductTracker: "tracker",
};

const routes = [
  //#region Debts
  {
    path: paths.debtsRegister(Definition.TypeJorneyParam),
    component: DebtsPasswordlessRegisterPage,
    template: PasswordlessTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsVehicle(),
    component: DebtsVehicleRegisterPage,
    template: DebtsTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsVehicle(Definition.TypeJorneyParam),
    component: DebtsVehicleRegisterPage,
    template: PasswordlessTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsPenalties(),
    component: VehicleDebtsPage,
    template: DebtsTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsPenalties(":id", Definition.TypeJorneyParam),
    component: VehicleDebtsPage,
    template: PasswordlessTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsPayment(),
    component: VehicleDebtsPaymentPage,
    template: DebtsTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsPayment(":id", Definition.TypeJorneyParam),
    component: VehicleDebtsPaymentPage,
    template: PasswordlessTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsConfirmation(),
    component: VehicleDebtsConfirmPage,
    template: DebtsTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsConfirmation(":id", Definition.TypeJorneyParam),
    component: VehicleDebtsConfirmPage,
    template: PasswordlessTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsList(),
    component: VehicleDebtsListPage,
    template: DebtsTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsList(Definition.TypeJorneyParam),
    component: VehicleDebtsListPage,
    template: PasswordlessTemplate,
    title: "Debts",
  },
  {
    path: paths.debtsProof(),
    component: DebtsProofPaymentPage,
    template: EmptyTemplate,
    title: "Debts",
  },
  //#endregion

  //#region b3
  {
    path: paths.b3(),
    component: B3Home,
    template: B3Template,
    title: "b3",
  },
  {
    path: paths.management(),
    component: ManagementHome,
    template: B3Template,
    title: "management",
  },
  {
    path: paths.myNumbers(),
    component: MyNumbersHome,
    template: MyNumbersTemplate,
    title: "my numbers",
  },
  {
    path: paths.fenauto(),
    component: FenautoHome,
    template: FenautoTemplate,
    title: "fenauto",
  },
  {
    path: paths.performance(),
    component: PerformanceHome,
    template: PerformanceTemplate,
    title: "performance",
  },
  //#endregion

  //#region insurance
  {
    path: paths.insurance(),
    component: InsuranceHome,
    template: InsuranceTemplate,
    title: "insurance",
  },
  //#endregion

  //#region tracker
  {
    path: paths.trackerRegister(Definition.TypeJorneyParam),
    component: TrackerPasswordlessRegisterPage,
    template: TrackerPasswordlessTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerDriverData(),
    component: TrackerDriverPage,
    template: TrackerTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerDriverData(":id", Definition.TypeJorneyParam),
    component: TrackerDriverPage,
    template: TrackerPasswordlessTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerVehicleSelection(),
    component: TrackerVehiclePage,
    template: TrackerTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerVehicleSelection(":id", Definition.TypeJorneyParam),
    component: TrackerVehiclePage,
    template: TrackerPasswordlessTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerScheduleInstall(),
    component: TrackerSchedulePage,
    template: TrackerTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerScheduleInstall(":id", Definition.TypeJorneyParam),
    component: TrackerSchedulePage,
    template: TrackerPasswordlessTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerQuotes(),
    component: TrackerQuotesPage,
    template: TrackerTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerQuotes(":id", Definition.TypeJorneyParam),
    component: TrackerQuotesPage,
    template: TrackerPasswordlessTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerOrder(),
    component: TrackerOrderPage,
    template: TrackerTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerOrder(":id", Definition.TypeJorneyParam),
    component: TrackerOrderPage,
    template: TrackerPasswordlessTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerCheckout(),
    component: TrackerCheckoutPage,
    template: TrackerTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerCheckout(":id", Definition.TypeJorneyParam),
    component: TrackerCheckoutPage,
    template: TrackerPasswordlessTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerMyQuotes(),
    component: TrackerMyQuotesPage,
    template: TrackerTemplate,
    title: "Tracker",
  },
  {
    path: paths.trackerMyQuotes(Definition.TypeJorneyParam),
    component: TrackerMyQuotesPage,
    template: TrackerPasswordlessTemplate,
    title: "Tracker",
  },
  //#endregion

  //#region Inspections
  {
    path: paths.inspectionRegister(Definition.TypeJorneyParam),
    component: InspectionPasswordlessRegisterPage,
    template: PasswordlessTemplate,
    title: "Inspection",
  },
  {
    path: paths.inspectionPartner(),
    component: InspectionPartnerPage,
    template: InspectionTemplate,
    title: "Inspection",
  },
  {
    path: paths.inspectionPartner(Definition.TypeJorneyParam),
    component: InspectionPartnerPage,
    template: PasswordlessTemplate,
    title: "Inspection",
  },
  {
    path: paths.inspectionVehicle(),
    component: InspectionVehiclePage,
    template: InspectionTemplate,
    title: "Inspection",
  },
  {
    path: paths.inspectionVehicle(Definition.TypeJorneyParam),
    component: InspectionVehiclePage,
    template: PasswordlessTemplate,
    title: "Inspection",
  },
  {
    path: paths.inspectionPayment(),
    component: InspectionPaymentPage,
    template: InspectionTemplate,
    title: "Inspection",
  },
  {
    path: paths.inspectionPayment(Definition.TypeJorneyParam),
    component: InspectionPaymentPage,
    template: PasswordlessTemplate,
    title: "Inspection",
  },
  {
    path: paths.inspectionConfirmation(),
    component: InspectionConfirmationPage,
    template: InspectionTemplate,
    title: "Inspection",
  },
  {
    path: paths.inspectionConfirmation(Definition.TypeJorneyParam),
    component: InspectionConfirmationPage,
    template: PasswordlessTemplate,
    title: "Inspection",
  },
  {
    path: paths.myInspections(),
    component: InspectionListPage,
    template: InspectionTemplate,
    title: "TitleMyInspectionsPage",
  },
  {
    path: paths.myInspections(Definition.TypeJorneyParam),
    component: InspectionListPage,
    template: PasswordlessTemplate,
    title: "TitleMyInspectionsPage",
  },
  //#endregion

  //#region manheim

  {
    path: paths.manheimBuyerInformationPage(),
    component: ManheimBuyerInformationPage,
    template: ManheimTemplate,
  },
] as Routers[];

export { Definition, routes as default, paths };
