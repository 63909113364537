import {
  CorporateUserDealershipLogRequest,
  HttpHelper,
  IAuth0User,
  ICoxUser,
  IListDealershipsPagedResponse,
  IListUsersPagedResponse,
  ListDealershipsPagedParams,
  ListUsersPagedParams,
} from "c4u-web-components";

import { c4uHttp, useRequest } from "./config";

export const useC4u = () => {
  const { get, post } = useRequest(c4uHttp, "");

  const GetUser = async (): Promise<ICoxUser> => {
    const { data } = await get(`Admin/User`);
    return data;
  };

  const GetAuth0User = async (): Promise<IAuth0User> => {
    const { data } = await get(`Admin/Auth0User`);
    return data;
  };

  const postSelectDealership = async (
    request: CorporateUserDealershipLogRequest
  ): Promise<void> => {
    await post("LoginLog/createCorporateUserDealershipLog", request);
  };

  const getListMyDealerships = async (
    params: ListDealershipsPagedParams
  ): Promise<IListDealershipsPagedResponse> => {
    const { data } = await get(
      `Admin/listMyDealerships${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  const getListMyUsers = async (
    params: ListUsersPagedParams
  ): Promise<IListUsersPagedResponse> => {
    const { data } = await get(
      `Admin/listMyUsers${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  return {
    GetAuth0User,
    GetUser,
    postSelectDealership,
    getListMyDealerships,
    getListMyUsers,
  };
};
