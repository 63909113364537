import { ICoxUser, ICreatePasswordlessRequest } from "c4u-web-components";
import { FormikErrors } from "formik";
import { useContext } from "react";
import { SessionContext } from "../../contexts";
import { IHeaderMainTemplate } from "../../models";

export interface SessionProviderState {
  genericModalMsg: string | null;
  genericSuccessModal: boolean;
  showGenericSuccessModal: (
    text?: string | null,
    onClose?: (() => void) | null
  ) => void;
  hideGenericSuccessModal: () => void;
  genericErrorModal: boolean;
  showGenericErrorModal: (
    text?: string | null,
    onClose?: (() => void) | null
  ) => void;
  hideGenericErrorModal: () => void;
  genericWarningModal: boolean;
  showGenericWarningModal: (
    text?: string | null,
    onClose?: (() => void) | null
  ) => void;
  hideGenericWarningModal: () => void;
  headerMainTemplate: IHeaderMainTemplate;
  setHeaderMainTemplate: React.Dispatch<IHeaderMainTemplate>;
  usernamePawordlessContext: ICreatePasswordlessRequest;
  setUsernamePawordlessContext: React.Dispatch<ICreatePasswordlessRequest>;
  riskifiedIdContext: string;
  setRiskifiedIdContext: React.Dispatch<string | undefined>;
  handleFormikError: <Values>(
    exception: any,
    setErrors?: FormikErrors<Values>,
    mapper?: (param: any) => any,
    showErrorModal?: boolean
  ) => string | undefined;
  handleFormikException: <Values>(
    exception: any,
    setErrors?: FormikErrors<Values>,
    mapper?: (param: any) => any
  ) => void;
  coxUserContext: ICoxUser;
  setCoxUserContext: React.Dispatch<React.SetStateAction<ICoxUser>>;
  handleEventGTM: (eventData: object) => void;
}

export const useSessionContext = () => {
  const context = useContext<SessionProviderState>(SessionContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
