import styled from "styled-components";

export const WrapperQuotes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 30px 0;
  @media (max-width: 575px) {
    justify-content: flex-start;
    width: 100%;
    .scroll {
      width: 1280px;
      overflow-x: scroll;
    }
  }
`;
