import { Combo } from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../../constants";
import {
  useInspectionContext,
  useProductsTracker,
  useSessionContext,
  useTrackerContext,
} from "../../../../hooks";
import {
  IPasswordlessParams,
  ISaveTrackerVehicleRequest,
  ITrackerData,
  SaveTrackerVehicleRequest,
} from "../../../../models";
import { FooterSubmitButtonAtom } from "../../../atoms";
import {
  VehicleInformation,
  VehicleSelectionFormMolecule,
} from "../../../molecules";
import { IVehicleSelectionFormMoleculeProps } from "../../../molecules/tracker/vehicle-selection-form/vehicle-selection-form.molecule";
import { ContentVehicleSelection } from "./vehicle-selection.organism.style";

interface IProps {
  isPasswordless?: boolean;
}

export const VehicleSelectionOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, typeJorney } = useParams<IPasswordlessParams>();

  const { handleFormikException } = useSessionContext();
  const {
    trackerDataContext,
    setTrackerDataContext,
    vehicleCategoryContext,
    setVehicleCategoryContext,
  } = useTrackerContext();

  const { vehicleContext, setVehicleContext } = useInspectionContext();
  const informationCarMolicarCondition = vehicleContext?.id === 0;

  const { saveTrackerVehicle, getVehicleByPlate } = useProductsTracker();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [goSubmit, setGoSubmit] = useState(false);

  //#region Combos
  const genders = useMemo(
    () => [
      new Combo({ title: t("Female"), value: "feminino" }),
      new Combo({ title: t("Male"), value: "masculino" }),
    ],
    [t]
  );

  const maritalStatus = useMemo(
    () => [
      new Combo({ title: t("Solteiro"), value: "solteiro" }),
      new Combo({
        title: t("Casado ou residente com companheiro(a)"),
        value: "casado",
      }),
      new Combo({ title: t("Viúvo"), value: "viuvo" }),
      new Combo({ title: t("Divorciado"), value: "divorciado" }),
    ],
    [t]
  );
  const yesNo = useMemo(
    () => [
      new Combo({ title: t("Yes"), value: "1" }),
      new Combo({ title: t("No"), value: "0" }),
    ],
    [t]
  );

  const vehicleUse = useMemo(
    () => [
      new Combo({ title: t("Private"), value: "particular" }),
      new Combo({ title: t("App driver"), value: "motorista_app" }),
      new Combo({ title: t("Comercial"), value: "comercial" }),
      new Combo({ title: t("Taxi"), value: "taxi" }),
    ],
    [t]
  );

  const taxFreeReason = useMemo(
    () => [
      new Combo({ title: t("Taxi"), value: "taxi" }),
      new Combo({ title: t("PCD"), value: "pcd" }),
      new Combo({ title: t("Others"), value: "outros" }),
    ],
    [t]
  );
  //#endregion

  const isPasswordless = useMemo(
    () => typeJorney === Definition.Passwordless,
    [typeJorney]
  );

  const vehicleValid = useMemo((): boolean => {
    return !!vehicleContext;
  }, [vehicleContext]);

  const vehicleNameFormat = `${vehicleContext?.brandName} ${vehicleContext?.modelName} ${vehicleContext?.versionName} ${vehicleContext?.hp} Cv - ${vehicleContext?.year}`;

  const mapper = (
    src: IVehicleSelectionFormMoleculeProps
  ): ISaveTrackerVehicleRequest => {
    return {
      trackerId: id,
      plate: src.plate,
      vin: src.vin,
      dealershipExit: src.dealershipExit,
      gasKit: src.gasKit,
      vehicleUse: src.vehicleUse,
      taxFree: src.taxFree,
      taxFreeReason: src.taxFreeReason,
      isTunning: src.isTunning,
      isZeroKm: src.isZeroKm,
      kbbId: vehicleContext.id,
      molicarId: vehicleContext.molicarID,
      vehicleName: vehicleNameFormat,
      overnightZipCode: src.overnightZipCode,
      passwordless: src.passwordless,
      modelYear: vehicleContext.year,
    } as ISaveTrackerVehicleRequest;
  };
  const handleSave = async (
    values: IVehicleSelectionFormMoleculeProps,
    errors: any
  ) => {
    try {
      const response = await saveTrackerVehicle(
        new SaveTrackerVehicleRequest(mapper(values))
      );
      //await new Promise((resolve) => setTimeout(resolve, 1000));
      setTrackerDataContext({
        ...trackerDataContext,
        vehicle: values,
        trackerId: response.trackerId,
        vehicleName: vehicleNameFormat,
      } as ITrackerData);
      history.push(paths.trackerDriverData(response.trackerId, typeJorney));
    } catch (e) {
      handleFormikException(e, errors, mapper);
    }
  };
  const searchVehicleCarDados = useCallback(
    (
      plate: string,
      setIsLoading: (v: boolean) => void,
      thenCallback: (data: any) => void,
      catchCallback: (v: any) => void
    ) => {
      setIsLoading(true);
      getVehicleByPlate(plate, id, vehicleCategoryContext)
        .then((data) => thenCallback(data))
        .catch((err) => catchCallback(err))
        .finally(() => setIsLoading(false));
    },
    [getVehicleByPlate, id, vehicleCategoryContext]
  );

  useEffect(() => {
    if (!isSubmiting && goSubmit) setGoSubmit(false);
    // eslint-disable-next-line
  }, [isSubmiting]);

  useEffect(() => {
    if (!id || id === "0") {
      setTrackerDataContext(undefined);
      setVehicleContext(undefined);
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <ContentVehicleSelection>
        <Row>
          <Col lg={6}>
            <div className={"mt-1"}>
              <h2>{t("Vehicle Data")}</h2>
              <VehicleSelectionFormMolecule
                vehicleCategory={vehicleCategoryContext}
                setVehicleCategory={setVehicleCategoryContext}
                initialValues={trackerDataContext?.vehicle}
                searchVehicleCarDados={searchVehicleCarDados}
                combo={{
                  genders: genders,
                  maritalStatus: maritalStatus,
                  yesNo: yesNo,
                  vehicleUse: vehicleUse,
                  taxFreeReason: taxFreeReason,
                }}
                defaults={{
                  yes: "1",
                  no: "0",
                  taxi: "taxi",
                }}
                onSubmit={handleSave}
                startSubmit={goSubmit}
                isSubmiting={(submiting) => setIsSubmiting(submiting)}
                isPasswordless={isPasswordless}
              />
            </div>
          </Col>
          <Col lg={6}>
            <VehicleInformation
              reduced={false}
              vehicleCategory={vehicleCategoryContext}
              informationCarMolicar={informationCarMolicarCondition}
            />
          </Col>
        </Row>
      </ContentVehicleSelection>
      {vehicleValid && vehicleContext.year ? (
        <FooterSubmitButtonAtom
          onClick={() => setGoSubmit(true)}
          loading={isSubmiting}
        >
          {t("Continue")}
        </FooterSubmitButtonAtom>
      ) : (
        <></>
      )}
    </>
  );
};
