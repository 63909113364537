import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../../constants";
import { useSessionContext } from "../../../../hooks";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../../models";
import { MenuStepper } from "../../../molecules";
import { Wrapper } from "./debts-base.page.style";

interface IProps {
  step: number;
  header: IHeaderMainTemplate;
  hideAuxiliarButton?: boolean;
}

export const DebtsBasePage: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { setHeaderMainTemplate, setRiskifiedIdContext, riskifiedIdContext } =
    useSessionContext();

  const history = useHistory();

  const { typeJorney, id } = useParams<IPasswordlessParams>();
  document.title = t("TitlePageDebts");

  const itemsMenu = useMemo(() => {
    if (typeJorney === Definition.Passwordless)
      return [
        t("Authorize"),
        t("View"),
        t("Debts"),
        t("Payment"),
        t("Confirmation"),
      ];
    return [t("View"), t("Debts"), t("Payment"), t("Confirmation")];
  }, [t, typeJorney]);

  useLayoutEffect(() => {
    if (props?.header)
      setHeaderMainTemplate({
        ...props.header,
        NavigationButtonAction: props.hideAuxiliarButton
          ? undefined
          : () => history.push(paths.debtsList(typeJorney)),
        NavigationButtonText: props.hideAuxiliarButton
          ? undefined
          : t("MyRequests"),
      });
    // eslint-disable-next-line
  }, [props?.header]);

  const step = () => {
    if (typeJorney === Definition.Passwordless) {
      return props.step === 0 ? 1 : props.step + 1;
    } else {
      return props.step;
    }
  };

  const riskifiedBeaconLoad = () => {
    var store_domain = "usezapay.com.br";
    var session_id = riskifiedIdContext;
    var url =
      ("https:" === document.location.protocol ? "https://" : "http://") +
      "beacon.riskified.com?shop=" +
      store_domain +
      "&sid=" +
      session_id;
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = url;
    var x = document.getElementsByTagName("script")[0];
    x?.parentNode?.insertBefore(s, x);
    window.addEventListener("load", riskifiedBeaconLoad, false);
  };

  useEffect(() => {
    if (id) {
      const guid = riskifiedIdContext ?? `${id}_${new Date().toISOString()}`;
      setRiskifiedIdContext(guid);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (riskifiedIdContext) {
      riskifiedBeaconLoad();
    }
    // eslint-disable-next-line
  }, [riskifiedIdContext]);

  return (
    <Row className={"mb-5"}>
      <Col md={"auto"} className={"mr-5"}>
        <MenuStepper currentStep={step()} items={itemsMenu} />
      </Col>
      <Col md>
        <Wrapper>{props.children}</Wrapper>
      </Col>
    </Row>
  );
};
