import { ButtonTertiary, ModalDefault, NumberHelper } from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FixedCommission } from "../../../models/apis/products/tracker/get-tracker-quotes.model";
import {
  ChooseCommisionWrapper,
  CommissionOptionInputWrapper,
  Description,
  PlusButton,
  Price,
  PriceWrapper,
  Spotlight,
  SpotlightPlot,
  Title,
  WrapperQuoteOkNokItem,
} from "./quote-item.atom.style";

interface IProps {
  planId: string;
  chosenPlanId?: string;
  title: string;
  value: number;
  currency: string;
  description: string;
  spotlight?: string;
  showDealer?: boolean;
  commissions?: FixedCommission[];
  setChosenCommission: (v: FixedCommission) => void;
}

export const QuoteItemPriceAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [index, setIndex] = useState("0");
  const [selectedIndex, setSelectedIndex] = useState("0");
  const [monthlyPayment, setMonthlyPayment] = useState(() => {
    let max = 0;
    props.commissions?.forEach((f, i) => {
      if (f.monthlyPayment > max) {
        max = f.monthlyPayment;
        setIndex(i.toString());
        setSelectedIndex(i.toString());
      }
    });
    return max;
  });

  const formatLabel = useCallback(
    (percentage, commission, monthlyPayment) => {
      return (
        <span>
          <b>{NumberHelper.toFormatString(percentage, 1, "0,0", 4)}%</b>
          {" - "}
          {t("$")} {NumberHelper.toFormatString(commission, 2, "0,00")} (
          {t("Installment of")} {t("$")}{" "}
          {NumberHelper.toFormatString(monthlyPayment, 2, "0,00")})
        </span>
      );
    },
    [t]
  );

  useEffect(() => {
    if (
      props.chosenPlanId &&
      props.chosenPlanId === props.planId &&
      props.commissions
    ) {
      props.setChosenCommission(props.commissions[Number(index)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chosenPlanId, index]);

  const htmlName = useMemo(
    () => props.title.replace(/\W/g, "-"),
    [props.title]
  );

  return (
    <>
      <WrapperQuoteOkNokItem>
        <Title size={"small"}>{props.title}</Title>
        <PriceWrapper>
          <Price>
            {props.currency}{" "}
            {NumberHelper.toFormatString(
              monthlyPayment ? monthlyPayment : props.value,
              2
            )}
          </Price>
          {!!props.showDealer && (
            <PlusButton id="plus-button" onClick={() => setShowModal(true)}>
              {t("Plus")}
            </PlusButton>
          )}
        </PriceWrapper>
        <Description>{props.description}</Description>
        {props.spotlight ? (
          <Spotlight>{props.spotlight}</Spotlight>
        ) : (
          <SpotlightPlot></SpotlightPlot>
        )}
      </WrapperQuoteOkNokItem>

      <ModalDefault
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedIndex(index);
        }}
        title={t("Select Commissions")}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        footer={
          <ButtonTertiary
            sizex="md"
            onClick={() => {
              const n = Number(selectedIndex);
              if (props.commissions && props.commissions.length > 0) {
                setMonthlyPayment(props.commissions[n].monthlyPayment);
                setIndex(selectedIndex);
              }
              setShowModal(false);
            }}
          >
            {t("Ok")}
          </ButtonTertiary>
        }
      >
        <ChooseCommisionWrapper>
          {props.commissions?.map((m, i) => (
            <CommissionOptionInputWrapper key={`${htmlName}-commision-${i}`}>
              <input
                type="radio"
                id={`${htmlName}-commision-${i}`}
                onChange={(v) => setSelectedIndex(v.target.value)}
                value={i.toString()}
                checked={i.toString() === selectedIndex}
              />
              <label htmlFor={`${htmlName}-commision-${i}`}>
                {formatLabel(
                  m.commissionPercentage,
                  m.commission,
                  m.monthlyPayment
                )}
              </label>
            </CommissionOptionInputWrapper>
          ))}
        </ChooseCommisionWrapper>
      </ModalDefault>
    </>
  );
};
