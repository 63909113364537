import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  min-height: 695px;
  background: rgba(168, 170, 172, 0.35);
  border-radius: 20px;
  display: grid;
  align-items: center;
  padding: 60px;
  margin-bottom: 25px;
`;

const CommingSoonAdvice = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.BlueNavy};
`;
const CommingSoonAdviceDescription = styled(CommingSoonAdvice)`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  margin-top: 30px;
`;

export { CommingSoonAdvice, Wrapper, CommingSoonAdviceDescription };
