import axios, { CancelToken } from "axios";
import {
  Combo,
  FilterAtom,
  FormikControlAtom,
  TrackerStepEnum,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { componentUnmountedMsgKey } from "../../../../constants";
import { useSessionContext } from "../../../../hooks";
import {
  IGetTrackerListParams,
  IGetTrackerPagedResponseModel,
} from "../../../../models";
import {
  HeaderSearchFilterWrapper,
  StyledForm,
} from "./header-search-filter.atom.style";

interface IProps {
  getTrackerList: (
    params: IGetTrackerListParams,
    cancelToken?: CancelToken | undefined
  ) => Promise<IGetTrackerPagedResponseModel>;
  setTrakerHistoryData: (
    data: IGetTrackerPagedResponseModel | undefined
  ) => void;
  disableFormSubmit?: boolean;
  pageNumber?: number;
}

interface IFormData {
  search?: string;
  steps?: string[];
  page: number;
  pageSize: number;
}

export const HeaderSearchFilterAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { handleFormikException } = useSessionContext();

  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false);
  const source = useMemo(
    () => axios.CancelToken.source(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.pageNumber]
  );

  const validations = Yup.object<IFormData>({
    search: Yup.string().optional(),
    steps: Yup.array().of(Yup.string()),
    page: Yup.number().required(),
    pageSize: Yup.number().required(),
  });

  const formik = useFormik<IFormData>({
    initialValues: {
      search: "",
      steps: [],
      page: 1,
      pageSize: 10,
    } as IFormData,
    onSubmit: async (values, { setErrors }) => {
      try {
        const params: IGetTrackerListParams = {
          ...values,
          steps: values.steps?.map((m) => parseInt(m)),
        };
        props.setTrakerHistoryData(undefined);
        const data = await props.getTrackerList(params, source?.token);
        props.setTrakerHistoryData(data);
      } catch (e: any) {
        if (e?.stack?.message === componentUnmountedMsgKey) return;
        handleFormikException(e, setErrors);
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const stepOptions = useMemo(() => {
    return [
      new Combo({ title: t("Vehicle"), value: TrackerStepEnum.Vehicle }),
      new Combo({ title: t("Driver"), value: TrackerStepEnum.Driver }),
      new Combo({ title: t("Quote"), value: TrackerStepEnum.Quote }),
      new Combo({ title: t("Order"), value: TrackerStepEnum.Order }),
      new Combo({ title: t("Scheduled"), value: TrackerStepEnum.Scheduled }),
    ];
  }, [t]);

  useEffect(() => {
    if (props.pageNumber) formik.setFieldValue("page", props.pageNumber);
    formik.submitForm();

    return () => {
      source?.cancel(componentUnmountedMsgKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageNumber]);

  useEffect(
    () => {
      if (formik.values.page !== 1) {
        formik.setFieldValue("page", 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.search, formik.values.steps]
  );

  return (
    <HeaderSearchFilterWrapper>
      <Row>
        <Col xs={8}>
          <StyledForm onSubmit={(e: any) => formik.handleSubmit(e)}>
            <Form.Row>
              <FormikControlAtom
                type={"search-submit"}
                formik={formik}
                md={12}
                property={"search"}
                disabled={props.disableFormSubmit}
                translate={t}
              />
            </Form.Row>
          </StyledForm>
        </Col>
        <Col xs={"auto"}>
          <FilterAtom
            filterText={t("Filter")}
            filterIsOpen={filterIsOpen}
            setFilterIsOpen={setFilterIsOpen}
          />
        </Col>
      </Row>

      {filterIsOpen && (
        <Row className="my-3 mx-auto">
          <Col xs="auto">
            <StyledForm onInput={(e: any) => formik.handleSubmit(e)}>
              <Form.Row>
                <FormikControlAtom
                  type={"checkbox"}
                  data={stepOptions}
                  formik={formik}
                  md={12}
                  property={"steps"}
                  disabled={props.disableFormSubmit}
                  translate={t}
                />
              </Form.Row>
            </StyledForm>
          </Col>
        </Row>
      )}
    </HeaderSearchFilterWrapper>
  );
};
