import { useRequest, productsHttp } from "./config";
import { ManagementDataModel } from "../../models";

export const useProductsManagement = () => {
  const { get } = useRequest(productsHttp, "managementData");

  const getManagementData = async (): Promise<ManagementDataModel> => {
    const { data } = await get("");
    return data;
  };

  const getMyNumbers = async (): Promise<ManagementDataModel> => {
    const { data } = await get("myNumbers");
    return data;
  };

  const getPerformance = async (): Promise<ManagementDataModel> => {
    const { data } = await get("performance");
    return data;
  };

  const getFenauto = async (): Promise<ManagementDataModel> => {
    const { data } = await get("fenauto");
    return data;
  };

  const getKeepPowerBi = async (): Promise<any> => {
    const { data } = await get("keepPowerBi");
    return data;
  };

  return {
    getFenauto,
    getManagementData,
    getMyNumbers,
    getPerformance,
    getKeepPowerBi,
  };
};
