import { TypesHelper } from "c4u-web-components";
import { IHomeInstallationAddress } from "../../..";

export interface ISaveTrackerScheduling {
  trackerId?: number;
  shopId?: number;
  schedulingDate?: string;
  periodNumber?: number;
  shopName?: string;
  shopAddress?: string;
  zipCode?: string;
  isHomeSchedule?: boolean;
  homeScheduleAddresses?: IHomeInstallationAddress;
  displacementValue?: number;
}

class InstallationAddress {
  zipCode: string | null;
  street: string | null;
  neighborhood: string | null;
  number: string | null;
  complement: string | null;
  referencePoint: string | null;
  contactName: string | null;
  contactPhone: string | null;

  constructor({
    zipCode,
    street,
    district,
    number,
    complement,
    referencePoint,
    name,
    phone,
  }: IHomeInstallationAddress) {
    this.zipCode = TypesHelper.toStringNull(zipCode);
    this.street = TypesHelper.toStringNull(street);
    this.neighborhood = TypesHelper.toStringNull(district);
    this.number = TypesHelper.toStringNull(number);
    this.complement = TypesHelper.toStringNull(complement);
    this.referencePoint = TypesHelper.toStringNull(referencePoint);
    this.contactName = TypesHelper.toStringNull(name);
    this.contactPhone = TypesHelper.toStringNull(phone);
  }
}

export class SaveTrackerScheduling {
  trackerId: number | null;
  shopId: number | null;
  schedulingDate: string | null;
  periodNumber: number | null;
  shopName: string | null;
  shopAddress: string | null;
  zipCode: string | null;
  isHomeSchedule: boolean | null;
  homeScheduleAddresses?: InstallationAddress | null;
  constructor({
    trackerId,
    shopId,
    schedulingDate,
    periodNumber,
    shopName,
    shopAddress,
    zipCode,
    isHomeSchedule,
    homeScheduleAddresses,
  }: ISaveTrackerScheduling) {
    this.trackerId = TypesHelper.toNumberNull(trackerId);
    this.shopId = TypesHelper.toNumberNull(shopId);
    this.schedulingDate = TypesHelper.toStringNull(schedulingDate);
    this.periodNumber = TypesHelper.toNumberNull(periodNumber);
    this.shopName = TypesHelper.toStringNull(shopName);
    this.shopAddress = TypesHelper.toStringNull(shopAddress);
    this.zipCode = TypesHelper.toStringNull(zipCode);
    this.isHomeSchedule = TypesHelper.toBooleanNull(isHomeSchedule);
    this.homeScheduleAddresses = !!homeScheduleAddresses
      ? new InstallationAddress(homeScheduleAddresses)
      : null;
  }
}
