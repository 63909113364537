import styled from "styled-components";

const TitlePage = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  color: ${(props) => props.theme.colors.Dark};
  margin-bottom: 15px;
`;

const DescriptionPage = styled.div`
  color: ${(props) => props.theme.colors.Dark};
  margin-bottom: 20px;
`;
const DivLoading = styled.div`
  color: ${(props) => props.theme.colors.BlueNavy};
  margin-top: 30px;
  .title {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: ${(props) => props.theme.colors.BlueNavy};
    margin: 10px auto;
  }
  .description {
    color: ${(props) => props.theme.colors.Dark};
    max-width: 400px;
    margin: auto;
    text-align: center;
  }
`;
const TotalDebitsSelected = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: ${(props) => props.theme.colors.BlueNavy};
`;

export { TitlePage, DescriptionPage, TotalDebitsSelected, DivLoading };
