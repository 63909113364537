import { CoxIcon } from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Definition } from "../../../../constants";
import { IPasswordlessParams } from "../../../../models";
import { FixedCommission } from "../../../../models/apis/products/tracker/get-tracker-quotes.model";
import {
  QuoteItemCoverageAtom,
  QuoteItemFranchiseAtom,
  QuoteItemKeysAtom,
  QuoteItemPriceAtom,
  QuoteItemTotalLossAtom,
} from "../../../atoms";
import {
  ButtonChoosePlan,
  ColButtons,
  ColQuotes,
  ContentMobileQuoteButtons,
  DealershipSectionWrapper,
  NoCotation,
  QuotesItem,
  RowButtons,
  RowQuotes,
  DealershipSectionTitle,
  TitleHeader,
  WrapperQuote,
} from "./tracker-quote.molecule.style";

export interface IQuotesModule {
  title: string;
  withInsurance: boolean;
  quotes: IQuotesModuleItem[];
}
interface IQuotesModuleItem {
  planId: string;
  spotlights: IQuotesModuleSpotlightsDetail;
  regulars: IQuotesModuleRegularsDetail[];
  totalLoss?: IQuotesModuleTotalLossDetail;
  franchise?: IQuotesModuleFranchiseDetail;
  listItems: IQuotesModuleDetailItems[];
  commissions: FixedCommission[];
}
interface IQuotesModuleDetail {
  title: string;
  description: string;
}
interface IQuotesModuleSpotlightsDetail extends IQuotesModuleDetail {
  price: number;
  recommended: boolean;
}
interface IQuotesModuleTotalLossDetail {
  title: string;
  descriptionTheft: string;
  hasTheft: boolean;
  descriptionCollision: string;
  hasCollision: boolean;
}
interface IQuotesModuleFranchiseDetail extends IQuotesModuleDetail {
  price: string;
}
interface IQuotesModuleRegularsDetail extends IQuotesModuleDetail {
  has: boolean;
}
interface IQuotesModuleDetailItems
  extends Omit<IQuotesModuleDetail, "description"> {
  list: string[];
}

interface IProps {
  quotesModules?: IQuotesModule[];
  onChoose?: (planId: string) => void;
  chosenPlanId?: string;
  setChosenCommission: (v: FixedCommission) => void;
}

export const TrackerQuoteMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { typeJorney } = useParams<IPasswordlessParams>();

  const [showDealer, setShowDealer] = useState(false);

  const handleChoose = (planId: string) => {
    if (props.onChoose) props.onChoose(planId);
  };

  const maxListItems = useMemo(
    () =>
      props.quotesModules
        ?.flatMap((f) => f.quotes)
        .map((m) => m?.listItems)
        ?.map((m) => m?.length)
        ?.reduce((a, b) => Math.max(a, b)) ?? 0,
    [props]
  );

  const getSpotlight = useCallback(
    (quotes?: IQuotesModuleItem[]) => {
      return (
        <RowQuotes className={"RowQuotes"}>
          {quotes?.map((quoteItem, x) => {
            return (
              <ColQuotes
                key={`spotlights${x}`}
                className={`ColQuotes ${x % 2 === 0 ? "bgGray" : ""}`}
                active={!!quoteItem.spotlights.recommended}
              >
                {quoteItem.spotlights ? (
                  <QuoteItemPriceAtom
                    planId={quoteItem.planId}
                    chosenPlanId={props.chosenPlanId}
                    title={quoteItem.spotlights.title}
                    value={quoteItem.spotlights.price}
                    currency={t("$")}
                    description={quoteItem.spotlights.description}
                    spotlight={
                      quoteItem.spotlights.recommended
                        ? "Recomendado"
                        : undefined
                    }
                    showDealer={showDealer}
                    commissions={quoteItem.commissions}
                    setChosenCommission={props.setChosenCommission}
                  />
                ) : (
                  "--"
                )}
              </ColQuotes>
            );
          })}
        </RowQuotes>
      );
    },
    [props.chosenPlanId, props.setChosenCommission, showDealer, t]
  );

  const getTotalLoss = useCallback((quotes?: IQuotesModuleItem[]) => {
    return (
      <RowQuotes className={"RowQuotes"}>
        {quotes?.map((quoteItem, x) => {
          return (
            quoteItem.totalLoss && (
              <ColQuotes
                key={`franchise${x}`}
                className={`ColQuotes ${x % 2 === 0 ? "bgGray" : ""}`}
                active={!!quoteItem.spotlights.recommended}
              >
                <QuoteItemTotalLossAtom {...quoteItem.totalLoss} />
              </ColQuotes>
            )
          );
        })}
      </RowQuotes>
    );
  }, []);

  const getFranchise = useCallback((quotes?: IQuotesModuleItem[]) => {
    return (
      <RowQuotes className={"RowQuotes"}>
        {quotes?.map((quoteItem, x) => {
          return (
            quoteItem.franchise && (
              <ColQuotes
                key={`franchise${x}`}
                className={`ColQuotes ${x % 2 === 0 ? "bgGray" : ""}`}
                active={!!quoteItem.spotlights.recommended}
              >
                <QuoteItemFranchiseAtom
                  title={quoteItem.franchise.title}
                  price={quoteItem.franchise.price}
                  description={quoteItem.franchise.description}
                />
              </ColQuotes>
            )
          );
        })}
      </RowQuotes>
    );
  }, []);

  const getRegulars = useCallback((quotes: IQuotesModuleItem[]) => {
    let nodes = [];

    const lenRegulars =
      quotes
        .map((m) => m?.regulars)
        ?.map((m) => m?.length)
        ?.reduce((a, b) => Math.max(a, b)) ?? 0;

    for (let i = 0; i < lenRegulars; i++) {
      nodes.push(
        <RowQuotes key={`RowRegulars${i}`} className={"RowQuotes"}>
          {quotes?.map((quoteItem, x) => {
            return (
              !!quoteItem.regulars[i] && (
                <ColQuotes
                  key={`regulars${x}`}
                  className={`ColQuotes ${x % 2 === 0 ? "bgGray" : ""}`}
                  active={!!quoteItem.spotlights?.recommended}
                >
                  <QuoteItemCoverageAtom
                    title={quoteItem.regulars[i].title}
                    has={quoteItem.regulars[i].has}
                    description={quoteItem.regulars[i].description.toString()}
                  />
                </ColQuotes>
              )
            );
          })}
        </RowQuotes>
      );
    }
    return nodes.map((m) => m);
  }, []);

  const getListItems = useCallback(
    (quotes?: IQuotesModuleItem[]) => {
      const nodes = [];

      const ituranWithInsuranceThirdParties = t(
        "ituranWithInsuranceThirdParties"
      );
      const ituranWithComboInsurance = t("ituranWithComboInsurance");
      const ituranWithCompleteInsurance = t("ituranWithCompleteInsurance");

      for (let i = 0; i < maxListItems; i++) {
        nodes.push(
          <RowQuotes key={`RowListItem${i}`} className={"RowQuotes"}>
            {quotes?.map((quoteItem, x) => (
              <ColQuotes
                key={`listItems${x}`}
                className={`ColQuotes ${x % 2 === 0 ? "bgGray" : ""}`}
                active={!!quoteItem.spotlights.recommended}
              >
                {(quoteItem.listItems[i] &&
                  quoteItem.spotlights.title ===
                    ituranWithInsuranceThirdParties) ||
                quoteItem.spotlights.title === ituranWithComboInsurance ? (
                  <QuoteItemKeysAtom
                    title={quoteItem.listItems[i].title}
                    items={quoteItem.listItems[i].list}
                    trackerInsurance={true}
                    message={t("theDamageDataIsOnlyForThirdParties")}
                  />
                ) : quoteItem.listItems[i] &&
                  quoteItem.spotlights.title === ituranWithCompleteInsurance ? (
                  <QuoteItemKeysAtom
                    title={quoteItem.listItems[i].title}
                    items={quoteItem.listItems[i].list}
                    trackerInsurance={true}
                    message={t(
                      "dataReferringToDamageIncludeTheInsuredAndThirdParties"
                    )}
                  />
                ) : quoteItem.listItems[i] ||
                  quoteItem.spotlights.recommended === true ? (
                  <QuoteItemKeysAtom
                    title={quoteItem.listItems[i].title}
                    items={quoteItem.listItems[i].list}
                  />
                ) : (
                  "--"
                )}
              </ColQuotes>
            ))}
          </RowQuotes>
        );
      }

      return nodes.map((m) => m);
    },
    [maxListItems, t]
  );

  const [chosenQuoteModule, setChosenQuoteModule] = useState<string>();

  const plusButton = useCallback(() => {
    setShowDealer(!showDealer);
  }, [showDealer]);

  useEffect((quotes?: IQuotesModuleItem[]) => {
    quotes?.map((t) => console.log("Teste" + t.listItems[0].title));
  }, []);

  useEffect(() => {
    if (props.quotesModules) {
      const chosenGroupIndex = props.chosenPlanId
        ? props.quotesModules?.findIndex((f) =>
            f.quotes.find((f) => f.planId === props.chosenPlanId)
          ) ?? 0
        : 0;
      setChosenQuoteModule(props.quotesModules[chosenGroupIndex]?.title);
    }
  }, [props.quotesModules, props.chosenPlanId]);

  useEffect(() => {
    let maxHeight = 0;

    const differentials = Array.from(
      document.getElementsByClassName("Differentials")
    );

    differentials.forEach((f) => {
      if (f.clientHeight > maxHeight) maxHeight = f.clientHeight;
    });

    if (maxHeight) {
      differentials.forEach((f) => {
        f.setAttribute("style", `height=${maxHeight}`);
      });
    }
  }, [props.quotesModules]);

  return (
    <>
      {typeJorney !== Definition.Passwordless && (
        <DealershipSectionWrapper onClick={plusButton}>
          <DealershipSectionTitle>
            {t("Dealership Section")}
          </DealershipSectionTitle>
          <CoxIcon
            isEnabled={showDealer}
            name={showDealer ? "visibility" : "visibility-off"}
            width={15}
            height={15}
            id="section-dealership"
          />
        </DealershipSectionWrapper>
      )}

      <ContentMobileQuoteButtons className={`d-none d-sm-flex d-xl-none`}>
        <Row>
          {props.quotesModules?.map((quoteModule, i) => (
            <Col sm={"auto"} key={`colMobileQuotesBt${i}`}>
              <ButtonChoosePlan
                active={chosenQuoteModule === quoteModule.title}
                onClick={() => setChosenQuoteModule(quoteModule.title)}
              >
                {quoteModule.withInsurance ? (
                  <CoxIcon name={"security"} />
                ) : (
                  <CoxIcon name={"target"} />
                )}
                {quoteModule.title}
              </ButtonChoosePlan>
            </Col>
          ))}
        </Row>
      </ContentMobileQuoteButtons>

      <QuotesItem>
        <Row>
          {props.quotesModules?.map((quoteModule, i) => (
            <Col
              key={`RowColQuote${i}`}
              lg={"auto"}
              md
              sm
              xs={props.quotesModules?.length ?? 12 / 6}
              className={`quote d-lg-none d-md-none d-sm-none ${
                chosenQuoteModule === quoteModule.title
                  ? "d-lg-flex d-md-flex d-sm-flex"
                  : "d-xl-flex"
              }`}
            >
              <WrapperQuote>
                <TitleHeader>
                  {quoteModule.withInsurance ? (
                    <CoxIcon name={"security"} />
                  ) : (
                    <CoxIcon name={"target"} />
                  )}
                  {quoteModule.title}
                </TitleHeader>
                {getSpotlight(quoteModule.quotes)}
                {getRegulars(quoteModule.quotes)}
                {quoteModule.withInsurance && getTotalLoss(quoteModule.quotes)}
                {quoteModule.withInsurance && getFranchise(quoteModule.quotes)}
                {getListItems(quoteModule.quotes)}

                <RowButtons>
                  {quoteModule.quotes?.map((m, x) => (
                    <ColButtons
                      id={`button-chooseThis${x}`}
                      key={`ColButtons${x}`}
                      active={m.planId === props.chosenPlanId}
                      onClick={() => handleChoose(m.planId)}
                    >
                      {m.planId === props.chosenPlanId
                        ? t("Selected")
                        : t("Choose this")}
                    </ColButtons>
                  ))}
                </RowButtons>
                {(!props.quotesModules || props.quotesModules.length === 0) && (
                  <NoCotation>
                    {t("No cotation from this insurance company")}
                  </NoCotation>
                )}
              </WrapperQuote>
            </Col>
          ))}
        </Row>
      </QuotesItem>
    </>
  );
};
