import { CoxIcon, NumberHelper } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BodyBoxPlots,
  HeaderBoxPlots,
  LinePlot,
  LinePlotMsg,
  Plate,
  Renavam,
  RowWrapperDebit,
  WrapperBoxPlots,
} from "./debit-plots.molecule.style";

interface IProps {
  plate: string;
  renavam: string;
  plotSelected?: number;
  onCheck?: (event: React.FormEvent<HTMLInputElement>) => void;
  plots?: {
    plot: number;
    value: number;
    total: number;
  }[];
}

export const DebitPlots: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => setProps(parameters), [parameters]);

  return (
    <RowWrapperDebit>
      <Col>
        <WrapperBoxPlots>
          <HeaderBoxPlots>
            <Plate>
              <CoxIcon name={"car-front-v2"} />
              <span>{props.plate}</span>
            </Plate>
            <Renavam>
              <CoxIcon name={"document"} />
              <span>{props.renavam}</span>
            </Renavam>
          </HeaderBoxPlots>

          <BodyBoxPlots>
            {props.plots && props.plots?.length > 0 &&
              props.plots?.map((m, i) => (
                <LinePlot key={`LinePlot${i}`}>
                  <Form.Check
                    value={m.plot}
                    checked={m.plot === props.plotSelected}
                    onChange={parameters.onCheck}
                    id={m.plot.toString()}
                  />
                  <Form.Label>{`${m.plot}x ${NumberHelper.toFormatString(
                    m.value,
                    2
                  )}`}</Form.Label>
                  <span>{`${t("$")} ${NumberHelper.toFormatString(
                    m.total,
                    2
                  )}`}</span>
                </LinePlot>
              ))}
            {props.plots?.length === 0 && (
              <LinePlotMsg>
                <Form.Label>{t("No debit selected")}</Form.Label>
              </LinePlotMsg>
            )}
            {!props.plots && (
              <LinePlotMsg>
                <Form.Label>{t("No valid debit selected")}</Form.Label>
              </LinePlotMsg>
            )}
          </BodyBoxPlots>
        </WrapperBoxPlots>
      </Col>
    </RowWrapperDebit>
  );
};
