import React from "react";
import {
  ContentGroupBox,
  SubTitle,
  Title,
  WrapperQuoteItemKeys
} from "./quote-item.atom.style";

interface IProps {
  title: string;
  items: string[];
  trackerInsurance?: boolean;
  message?: string;
}

export const QuoteItemKeysAtom: React.FC<IProps> = (props) => {
  return (
    <WrapperQuoteItemKeys className="Differentials">
      <ContentGroupBox>
        <Title className={"mb-3"}>{props.title}</Title>
      </ContentGroupBox>
      {props.items.map((m, i) => (
        <SubTitle size="small" className={"mb-1"} key={`SubTitle${i}`}>
          {m}
        </SubTitle>
      ))}
      {props.trackerInsurance && (
        <>
          <hr />
          <SubTitle size="small" className={"mb-1"}>
            {props.message}
          </SubTitle>
        </>
      )}
    </WrapperQuoteItemKeys>
  );
};
