import { NumberHelper, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  useProductsTracker,
  useSessionContext,
  useTrackerContext,
} from "../../../../hooks";
import { IPasswordlessParams, SaveTrackerScheduling } from "../../../../models";
import { FooterSubmitButtonAtom } from "../../../atoms";
import {
  CheckoutInfoMolecule,
  CheckoutScheduleMolecule,
  CheckoutVehicleInfoMolecule,
  FeedbackModal,
} from "../../../molecules";
import {
  ColBlue,
  ColContent,
  ColData,
  RowContent,
} from "./tracker-checkout.organism.style";

export const TrackerCheckoutOrganism: React.FC = () => {
  const { t } = useTranslation();

  const atHomeInstallationFee = 60;

  const { showGenericErrorModal, handleEventGTM } = useSessionContext();

  const { trackerDataContext, setTrackerDataContext } = useTrackerContext();
  const { saveTrackerScheduling } = useProductsTracker();
  const history = useHistory();
  const params = useParams<IPasswordlessParams>();

  const [confirmModal, setConfirmModal] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const beginCheckout = useCallback(() => {
    handleEventGTM({
      event: "begin_checkout",
      ecommerce: {
        value: `${trackerDataContext.quote?.value}`,
        currency: "BRL",
        items: {
          item_name: trackerDataContext.quote?.name,
          item_id: trackerDataContext.quote?.partnerQuoteItemId,
          price: trackerDataContext.quote?.value,
          currency: "BRL",
          affiliation: "Ituran",
          item_category: "Rastreadores",
          item_list_name: "Agendar instalação",
          quantity: 1,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = useCallback(async () => {
    try {
      if (trackerDataContext?.scheduling) {
        await saveTrackerScheduling(
          new SaveTrackerScheduling(trackerDataContext?.scheduling)
        );
        handleEventGTM({
          event: "purchase",
          ecommerce: {
            transaction_id: `${trackerDataContext.trackerId}`,
            value: `${trackerDataContext.quote?.value}`,
            currency: "BRL",
            items: {
              item_name: trackerDataContext.quote?.name,
              item_id: trackerDataContext.quote?.partnerQuoteItemId,
              price: trackerDataContext.quote?.value,
              currency: "BRL",
              affiliation: "Ituran",
              item_category: "Rastreadores",
              item_list_name: "Agendar instalação",
              quantity: 1,
            },
          },
        });
        setConfirmModal(true);
      } else showGenericErrorModal();
    } catch (error) {
      showGenericErrorModal();
    } finally {
      setIsSubmiting(false);
    }
  }, [
    handleEventGTM,
    saveTrackerScheduling,
    showGenericErrorModal,
    trackerDataContext,
  ]);

  useEffect(() => {
    if (isSubmiting) save();
    // eslint-disable-next-line
  }, [isSubmiting]);

  useEffect(() => {
    beginCheckout();
  }, [beginCheckout]);

  const changeSchedule = () => {
    history.replace(paths.trackerScheduleInstall(params.id, params.typeJorney));
  };
  const changeShop = () => {
    setTrackerDataContext({
      ...trackerDataContext,
      scheduling: {
        ...trackerDataContext.scheduling,
        shopId: undefined,
      },
    });
    changeSchedule();
  };

  const checkoutInfoSteps = useMemo(() => {
    return trackerDataContext?.scheduling?.isHomeSchedule
      ? [
          <>
            {t("StepHomeInstallInfoCheckout1p1", {
              fee: NumberHelper.toFormatString(atHomeInstallationFee, 2),
            })}
            <strong>
              {t("StepHomeInstallInfoCheckout1p2", {
                value: NumberHelper.toFormatString(
                  trackerDataContext?.quote?.value
                    ? trackerDataContext?.quote?.value + atHomeInstallationFee
                    : 0,
                  2
                ),
              })}
            </strong>
          </>,
          t("StepHomeInstallInfoCheckout2"),
        ]
      : [t("StepInfoCheckout1"), t("StepInfoCheckout2")];
  }, [
    t,
    trackerDataContext?.quote?.value,
    trackerDataContext?.scheduling?.isHomeSchedule,
  ]);

  return (
    <>
      <Row className={"pb-4"}>
        <Col>{t("SubtitleCheckout")}</Col>
      </Row>

      {trackerDataContext === undefined ? (
        <SpinnerCenteredAtom />
      ) : (
        <>
          <RowContent className={"my-4"}>
            <ColContent sm={6} className={"borderLeft"}>
              <Row>
                <Col>
                  <CheckoutVehicleInfoMolecule
                    carName={trackerDataContext?.vehicleName ?? ""}
                    plate={
                      trackerDataContext?.vehicle?.plate?.toUpperCase() ?? ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <ColBlue>
                  {trackerDataContext?.scheduling?.schedulingDate && (
                    <CheckoutScheduleMolecule
                      inspectionDate={trackerDataContext.scheduling.schedulingDate.replace(
                        "Z",
                        ""
                      )}
                      inspectionPlace={
                        trackerDataContext.scheduling.shopName ?? ""
                      }
                      inspectionAddress={
                        trackerDataContext.scheduling.shopAddress ?? ""
                      }
                      onChangeSchedule={changeSchedule}
                      onChangeShop={changeShop}
                    />
                  )}
                </ColBlue>
              </Row>
            </ColContent>
            <ColData sm={6}>
              <CheckoutInfoMolecule
                steps={checkoutInfoSteps}
                planName={trackerDataContext?.quote?.name ?? ""}
                planValue={trackerDataContext?.quote?.value ?? 0}
                quoteDate={trackerDataContext?.quote?.date ?? undefined}
                quoteNumber={
                  trackerDataContext?.quote?.partnerQuoteItemId?.toString() ??
                  ""
                }
              />
            </ColData>
          </RowContent>
          <FooterSubmitButtonAtom
            onClick={() => setIsSubmiting(true)}
            loading={isSubmiting}
          >
            {t("Continue")}
          </FooterSubmitButtonAtom>
        </>
      )}
      <FeedbackModal
        type={"success"}
        show={confirmModal}
        onHide={() => history.push(paths.trackerMyQuotes(params.typeJorney))}
        title={t("IturanSuccessSchedulingMsg")}
        content={t("IturanSucessAdviceMsg")}
      />
    </>
  );
};
