import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { VehicleDebtsOrganism } from "../../organisms";
import { DebtsBasePage } from "./base/debts-base.page";

export const VehicleDebtsPage: React.FC = () => {
  const { t } = useTranslation();

  const { typeJorney } = useParams<IPasswordlessParams>();

  const header = useMemo(() => {
    return {
      Title: t("TitleVehicleRegister"),
      BackTo: paths.debtsVehicle(typeJorney),
      otherPage: true,
    } as IHeaderMainTemplate;
    // eslint-disable-next-line
  }, [typeJorney]);

  return (
    <DebtsBasePage step={2} header={header}>
      <VehicleDebtsOrganism />
    </DebtsBasePage>
  );
};
