import { CoxIcon } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  ContactDetailsWrapper,
  ContactDetailsDiv,
} from "./contact-details.atom.style";

export const ContactDetailsAtom: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContactDetailsWrapper>
      <ContactDetailsDiv>
        {t("ituranQueries")}:
        <CoxIcon name="phone" /> {t("ituranPhone")}
      </ContactDetailsDiv>
      <ContactDetailsDiv>
        {t("CAP")}: <CoxIcon name="phone" />
        {t("capPhone")}
      </ContactDetailsDiv>
    </ContactDetailsWrapper>
  );
};
