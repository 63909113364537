import React, { useLayoutEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ManagementReport } from "../../organisms";
import { useSessionContext } from "../../../hooks";

export const ManagementHome: React.FC = () => {
  const { t } = useTranslation();
  const { setHeaderMainTemplate } = useSessionContext();

  useLayoutEffect(() => {
    setHeaderMainTemplate({
      Title: t("TitleB3Report"),
      hideAndAlignLeft: true,
      otherPage: true,
    });
  }, [setHeaderMainTemplate, t]);

  return (
    <Row>
      <Col className={"text-right position-relative"}>
        <ManagementReport />
      </Col>
    </Row>
  );
};
