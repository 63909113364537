import React, { FunctionComponent } from "react";
import { PasswordlessTemplate } from "..";
import { TrackerBaseTemplate } from "./tracker-base.template";

export const TrackerPasswordlessTemplate: FunctionComponent = (props) => {
  return (
    <PasswordlessTemplate>
      <TrackerBaseTemplate>{props.children}</TrackerBaseTemplate>
    </PasswordlessTemplate>
  );
};
