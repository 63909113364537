import styled from "styled-components";

const FilterLabel = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  color: ${(props) => props.theme.colors.BlueNavy};
  text-transform: uppercase;
  cursor: pointer;
`;

const DebtsListOrganismWrapper = styled.div`
  padding-bottom: 30px;
`;
export { FilterLabel, DebtsListOrganismWrapper };
