import { CoxIcon } from "c4u-web-components";
import React, { useCallback } from "react";
import {
  SubTitle,
  Title,
  TotalLossContentWrapper,
  TotalLossItemDiv,
  WrapperQuoteOkNokItem,
  DeniedIconFixedWidth,
} from "./quote-item.atom.style";

interface IProps {
  title: string;
  descriptionTheft: string;
  hasTheft: boolean;
  descriptionCollision: string;
  hasCollision: boolean;
}

export const QuoteItemTotalLossAtom: React.FC<IProps> = (props) => {
  const getItemIcon = useCallback((has: boolean) => {
    return has ? (
      <CoxIcon name="checked" width={25} height={25} />
    ) : (
      <DeniedIconFixedWidth>
        <CoxIcon name="denied" width={18} height={18} />
      </DeniedIconFixedWidth>
    );
  }, []);

  return (
    <WrapperQuoteOkNokItem>
      <Title>{props.title}</Title>
      <TotalLossContentWrapper>
        <TotalLossItemDiv>
          {getItemIcon(props.hasTheft)}
          <SubTitle className="total-loss">{props.descriptionTheft}</SubTitle>
        </TotalLossItemDiv>
        <TotalLossItemDiv>
          {getItemIcon(props.hasCollision)}
          <SubTitle className="total-loss">
            {props.descriptionCollision}
          </SubTitle>
        </TotalLossItemDiv>
      </TotalLossContentWrapper>
    </WrapperQuoteOkNokItem>
  );
};
