import { TypesHelper } from "c4u-web-components";

export interface ISaveTrackerDriverRequest {
  trackerId?: number;
  vehicleUse?: string;
  name?: string;
  email?: string;
  cellphone?: string;
  document?: string;
  birthdate?: string;
  gender?: string;
  maritalStatus?: string;
  legalVehicleOwn?: string;
  driversBetween18and26?: string;
  vehicleUseToWork?: string;
  storedClosedGarage?: string;
  storedClosedGarageAtHome?: string;
  storedClosedGarageAtWork?: string;
  storedClosedGarageAtSchool?: string;
  mainDriver?: string;
  driverPersonalDocument?: string;
  driverName?: string;
  driverBirthdate?: string;
  driverGender?: string;
  driverMaritalStatus?: string;
  driverLink?: string;
  licenseTime?: string;
}

export class SaveTrackerDriverRequest {
  trackerId: number | null;
  vehicleUse: string | null;
  name: string | null;
  email: string | null;
  cellphone: string | null;
  document: string | null;
  birthdate: string | null;
  gender: string | null;
  maritalStatus: string | null;
  legalVehicleOwn: string | null;
  driversBetween18and26: string | null;
  vehicleUseToWork: string | null;
  storedClosedGarage: string | null;
  storedClosedGarageAtHome: string | null;
  storedClosedGarageAtWork: string | null;
  storedClosedGarageAtSchool: string | null;
  mainDriver: string | null;
  driverPersonalDocument: string | null;
  driverName: string | null;
  driverBirthdate: string | null;
  driverGender: string | null;
  driverMaritalStatus: string | null;
  driverLink: string | null;
  licenseTime: string | null;
  constructor({
    trackerId,
    vehicleUse,
    name,
    email,
    cellphone,
    document,
    birthdate,
    gender,
    maritalStatus,
    legalVehicleOwn,
    driversBetween18and26,
    vehicleUseToWork,
    storedClosedGarage,
    storedClosedGarageAtHome,
    storedClosedGarageAtWork,
    storedClosedGarageAtSchool,
    mainDriver,
    driverPersonalDocument,
    driverName,
    driverBirthdate,
    driverGender,
    driverMaritalStatus,
    driverLink,
    licenseTime,
  }: ISaveTrackerDriverRequest) {
    this.trackerId = TypesHelper.toNumberNull(trackerId);
    this.vehicleUse = TypesHelper.toStringNull(vehicleUse);
    this.name = TypesHelper.toStringNull(name);
    this.email = TypesHelper.toStringNull(email);
    this.cellphone = TypesHelper.toStringOnlyNumbersNull(cellphone);
    this.document = TypesHelper.toStringOnlyNumbersNull(document);
    this.birthdate = TypesHelper.toStringNull(birthdate);
    this.gender = TypesHelper.toStringNull(gender);
    this.maritalStatus = TypesHelper.toStringNull(maritalStatus);
    this.legalVehicleOwn = TypesHelper.toStringNull(legalVehicleOwn);
    this.driversBetween18and26 = TypesHelper.toStringNull(
      driversBetween18and26
    );
    this.vehicleUseToWork = TypesHelper.toStringNull(vehicleUseToWork);
    this.storedClosedGarage = TypesHelper.toStringNull(storedClosedGarage);
    this.storedClosedGarageAtHome = TypesHelper.toStringNull(
      storedClosedGarageAtHome
    );
    this.storedClosedGarageAtWork = TypesHelper.toStringNull(
      storedClosedGarageAtWork
    );
    this.storedClosedGarageAtSchool = TypesHelper.toStringNull(
      storedClosedGarageAtSchool
    );
    this.mainDriver = TypesHelper.toStringNull(mainDriver);
    this.driverPersonalDocument = TypesHelper.toStringNull(
      driverPersonalDocument
    );
    this.driverName = TypesHelper.toStringNull(driverName);
    this.driverBirthdate = TypesHelper.toStringNull(driverBirthdate);
    this.driverGender = TypesHelper.toStringNull(driverGender);
    this.driverMaritalStatus = TypesHelper.toStringNull(driverMaritalStatus);
    this.driverLink = TypesHelper.toStringNull(driverLink);
    this.licenseTime = TypesHelper.toStringNull(licenseTime);
  }
}
