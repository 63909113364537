import styled from "styled-components";

const CenterControl = styled.div`
  margin: 26px 0 0 0;
  span {
    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.theme.colors.BlueNavy} !important;
    cursor: pointer;
  }
`;

export { CenterControl };
