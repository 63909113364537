import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Definition } from "../../constants";

export const useQuery = () => {
  const location = useLocation();

  const quey = useMemo(() => {
    const queryString =
      location.search.trim().length > 0
        ? location.search
        : location.pathname.replace("/", "?");
    return new URLSearchParams(queryString);
  }, [location.search, location.pathname]);

  const get = (name: string) => quey.get(name);

  const IsDebtProduct = () =>
    location.pathname.startsWith(`/${Definition.ProductDebts}`);

  const IsInspectionProduct = () =>
    location.pathname.startsWith(`/${Definition.ProductInspection}`);

  const IsTrackerProduct = () =>
    location.pathname.startsWith(`/${Definition.ProductTracker}`);

  return { get, IsDebtProduct, IsInspectionProduct, IsTrackerProduct };
};
