import styled from "styled-components";

interface IPropsStatusColor {
  color: string;
}

const VehicleDeliveryCardWrapper = styled.div`
  max-width: 648px;
  width: 100%;
`;

const VehicleDeliveryCardDiv = styled.div<IPropsStatusColor>`
  background: #f9f9f9;
  padding: 21px 30px 21px 45px;
  box-shadow: 0px 8px 10px 0px #00000014;
  border-radius: 10px;
  border-left: 11px solid ${(props) => props.color};
`;

export { VehicleDeliveryCardDiv, VehicleDeliveryCardWrapper };
