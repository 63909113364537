import styled from "styled-components";

const Wrapper = styled.div`
  .background-img {
    width: 85%;
  }
`;

const CommingSoonAdvice = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */
  display: grid;

  align-items: center;
  letter-spacing: -1px;
  color: ${(props) => props.theme.colors.BlueNavy};

  text-align: left;

  .logo-img {
    margin-top: 20px;
  }

  position: absolute;
  width: 180px;
  top: 40%;

  @media (max-width: 992px) {
    position: relative;
    width: 100%;
    top: 0;
    align-items: center;
    justify-content: center;
    .logo-img {
      margin: 20px auto;
    }
  }
`;

export { CommingSoonAdvice, Wrapper };
