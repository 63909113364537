import {
  ButtonPrimary,
  FormikControlAtom,
  MaskHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FeedbackModal } from "../..";
import { FormDebtsVehicleRegisterValidation } from "./form-debts-vehicle-register.validation";

interface IProps {
  onSubmit: (props: IFormDebtsVehicleRegister, errors: any) => Promise<boolean>;
  onSuccessSubmit?: () => void;
  initialValues?: IFormDebtsVehicleRegister;
}

export interface IFormDebtsVehicleRegister {
  detranUf: string;
  plate: string;
  renavam: string;
  document: string;
}

export const FormDebtsVehicleRegisterMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<IFormDebtsVehicleRegister>({
    initialValues: props.initialValues ?? ({} as IFormDebtsVehicleRegister),
    onSubmit: async (values, { setErrors }) => {
      if (await props.onSubmit(values, setErrors)) {
        formik.setSubmitting(false);
        if (props.onSuccessSubmit) props.onSuccessSubmit();
      }
    },
    validationSchema: FormDebtsVehicleRegisterValidation(),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Form
      onSubmit={(e: any) => formik.handleSubmit(e)}
      className={"form-default"}
    >
      <Form.Row>
        <FormikControlAtom
          as="select"
          md={6}
          formik={formik}
          property="detranUf"
          label={t("Detran")}
          translate={t}
        >
          <option value="">{t("Select")}</option>
          <option value="0AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="0AP">Amapá</option>
          <option value="0AM">Amazonas</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espírito Santo</option>
          <option value="GO">Goiás</option>
          <option value="0MA">Maranhão</option>
          <option value="MG">Minas Gerais</option>
          <option value="MT">Mato Grosso</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="0PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="PR">Paraná</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="0RO">Rondônia</option>
          <option value="0RR">Roraima</option>
          <option value="SC">Santa Catarina</option>
          <option value="SP">São Paulo</option>
          <option value="0SE">Sergipe</option>
          <option value="0TO">Tocantins</option>
        </FormikControlAtom>
      </Form.Row>

      <Form.Row>
        <FormikControlAtom
          md={4}
          formik={formik}
          property="plate"
          label={t("Vehicle Plate")}
          mask={MaskHelper.PlateBr}
          func={(v: string) => v.toUpperCase()}
          translate={t}
        />

        <FormikControlAtom
          md={4}
          formik={formik}
          property="renavam"
          label={t("Renavam")}
          mask={MaskHelper.Renavam}
          placeholderChar={"\u2000"}
          translate={t}
        />

        <FormikControlAtom
          md={4}
          formik={formik}
          property="document"
          label={t("Personal Document / Legal company document")}
          mask={MaskHelper.CpfCnpj}
          translate={t}
        />
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} md={12} className={"text-right"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"lg"}
            loading={formik.isSubmitting}
          >
            {t("Continue")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>

      <FeedbackModal
        show={formik.values?.detranUf?.slice(0, 1) === "0"}
        onHide={() => formik.setFieldValue("detranUf", "")}
        title={t("Region not allowed")}
        type={"warning"}
        content={t("AdviceModalRegionNotAllowed")}
        textButton={"OK"}
      />
    </Form>
  );
};
