import { HttpHelper, TransferToUserRequest } from "c4u-web-components";
import {
  DebtsCheckOrderModel,
  DebtsPaymentModel,
  DebtsPaymentRequest,
  DebtsRegisterRequest,
  DebtsVehicleModel,
  DebtsVehicleRegisterRequest,
  InstallmentsModel,
  ShareProofRequest,
  VehicleAllDebtsModel,
} from "../../models";
import { IGetDebtsPagedResponseModel } from "../../models/apis/products/debts-vehicle.model";
import { productsHttp, useRequest } from "./config";

export const useProductsDebts = () => {
  const { get, post, put } = useRequest(productsHttp, "Debts");

  const getVehicleDebits = async (
    id: number
  ): Promise<VehicleAllDebtsModel> => {
    const { data } = await get(`GetVehicleDebts/${id}`);
    return data;
  };

  const getDebit = async (id: number): Promise<DebtsVehicleModel> => {
    const { data } = await get(`GetRegisteredDebt/${id}`);
    return data;
  };

  const getAllDebits = async (
    page: number,
    pageSize: number = 10
  ): Promise<IGetDebtsPagedResponseModel> => {
    const { data } = await get(
      `GetAllRegisteredDebts${HttpHelper.BuildQueryString({ page, pageSize })}`
    );
    return data;
  };

  const getInstallments = async (
    protocol: string,
    debtsIds: string[]
  ): Promise<InstallmentsModel[]> => {
    const { data } = await post(`GetInstallments`, {
      protocol: protocol,
      debts: debtsIds,
    });
    return data;
  };

  const saveDebtVehicle = async (
    request: DebtsVehicleRegisterRequest
  ): Promise<DebtsVehicleModel> => {
    const { data } = await post(`RegisterVehicle`, request);
    return data;
  };

  const saveDebts = async (
    request: DebtsRegisterRequest
  ): Promise<DebtsVehicleModel> => {
    const { data } = await put(`RegisterDebts`, request);
    return data;
  };

  const payDebts = async (
    request: DebtsPaymentRequest
  ): Promise<DebtsPaymentModel> => {
    const { data } = await post(`Payment`, request);
    return data;
  };

  const checkOrder = async (id: number): Promise<DebtsCheckOrderModel> => {
    const { data } = await get(`CheckOrder/${id}`);
    return data;
  };

  const getDebtByToken = async (token: string): Promise<DebtsVehicleModel> => {
    const { data } = await get(`proof/${token}`);
    return data;
  };

  const shareProof = async (request: ShareProofRequest): Promise<void> => {
    const { data } = await post(`ShareProof`, request);
    return data;
  };

  const putTransferDebts = async (
    request: TransferToUserRequest
  ): Promise<void> => {
    await put(`TransferDebts`, request);
  };

  return {
    shareProof,
    getDebtByToken,
    checkOrder,
    payDebts,
    saveDebts,
    getDebit,
    getAllDebits,
    saveDebtVehicle,
    getInstallments,
    getVehicleDebits,
    putTransferDebts,
  };
};
