import { ButtonTertiary } from "c4u-web-components";
import { Row } from "react-bootstrap";
import styled from "styled-components";

const Title = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.DarkBlue};
  line-height: 30px;
  margin: 0;
`;

const SubTitle = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  color: ${(props) => props.theme.colors.DarkGray};
  line-height: 20px;
  margin: 0;
`;

const AllCapsButtonTertiary = styled(ButtonTertiary)`
  text-transform: uppercase;
`;

const DivText = styled.div`
  margin: 0;
`;

const WrapperHeaderMainTemplate = styled(Row)`
  display: flex;
  align-items: center;
  min-height: 98px;

  hr {
    margin: 0 0 20px 0;
  }

  .bt-nav button {
    margin: 0 0 0 auto;
  }

  .center-align-vertical {
    button {
      margin: unset;
    }
    display: flex;
    align-items: center;
  }
`;

export {
  AllCapsButtonTertiary,
  SubTitle,
  Title,
  DivText,
  WrapperHeaderMainTemplate,
};
