import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { InspectionConfirmation } from "../../organisms";
import { InspectionBasePage } from "./base/inspection-base.page";

export const InspectionConfirmationPage: React.FC = () => {
  const { t } = useTranslation();

  const params = useParams<IPasswordlessParams>();

  const header = useMemo(() => {
    return {
      Title: t("TitleInspectionRegisterPage"),
      BackTo: paths.inspectionPayment(params.typeJorney),
      otherPage: true,
    } as IHeaderMainTemplate;
  }, [params, t]);

  return (
    <InspectionBasePage step={4} header={header}>
      <InspectionConfirmation />
    </InspectionBasePage>
  );
};
