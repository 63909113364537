import { Modal, Row } from "react-bootstrap";
import styled, { DefaultTheme } from "styled-components";
import { DebtsStatusPaymentEnum } from "../../../../models";

const minHeightBox = "236px";

interface DatailsProps {
  close: boolean;
}
interface StatusPaymentProps {
  status: DebtsStatusPaymentEnum;
}

const BoxCardInspection = styled.div`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
  box-sizing: border-box;
  border-radius: 4px 4px 0px 4px;
  min-height: ${minHeightBox};
  display: grid;
  position: relative;
  margin-bottom: 30px;
`;

const StatusBarLateral = styled.div`
  background: ${(props) => props.theme.colors.BlueManhein};
  border-radius: 4px 0px 0px 4px;
  width: 14px;
  height: 100%;
`;

const Content = styled.div`
  padding: 45px 15px 0 15px;
  height: 100%;
  display: grid;
  hr {
    margin: 0;
  }
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.MediumGray};
`;

const WrapperReportDid = styled.div`
  display: flex;
  div {
    margin-right: 4px;
    font-weight: bold;
  }
`;

const Value = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.DarkBlue};
`;
const CarCirle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: ${(props) => props.theme.colors.white};
  border: 0.928571px solid ${(props) => props.theme.colors.DarkBlue};
  box-sizing: border-box;
  border-radius: 64px;
  margin: auto;
`;
const WrapperInspection = styled.div`
  margin: 0 0 0 0;
  max-width: 100px;
  img {
    max-width: 100px;
    margin: 23px auto 0 auto;
  }
  @media (max-width: 767px) {
    margin-top: 50px;
  }
`;

const getColorStatus = (
  status: DebtsStatusPaymentEnum,
  theme: DefaultTheme
) => {
  switch (status) {
    case DebtsStatusPaymentEnum.Refused:
      return theme.colors.Error;
    case DebtsStatusPaymentEnum.Pending:
      return theme.colors.Yellow;
    case DebtsStatusPaymentEnum.Created:
      return theme.colors.Warning;
    case DebtsStatusPaymentEnum.Settled:
    case DebtsStatusPaymentEnum.Paied:
      return theme.colors.ConfirmActive;

    default:
      return "";
  }
};

const StatusFlyer = styled.div<StatusPaymentProps>`
  width: 256px;
  height: 40px;
  background: ${(props) => getColorStatus(props.status, props.theme)};
  position: absolute;
  top: 42px;
  right: -8px;
  color: ${(props) => props.theme.colors.white};
  padding: 11px 18px;
  b {
    text-transform: uppercase;
  }
  svg {
    width: 16px;
  }
  div {
    margin: 0px 0 0 226px;
  }
  @media (max-width: 1200px) {
    top: 0;
  }
`;
const Row1 = styled(Row)`
  margin-top: 35px;
`;
const Row2 = styled(Row)`
  margin-top: 30px;
`;
const Details = styled.div<DatailsProps>`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) =>
    props.close ? props.theme.colors.Grey74 : props.theme.colors.DarkBlue};
  text-transform: uppercase;
  margin: 0 8px 0 0;
`;

const WrapperDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
  margin: 29px 0 0 0;
  position: relative;
  div:first-child {
    cursor: pointer;
  }
`;
const ShareItem = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
`;
const DivContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 25px;
`;
const DivStatus = styled.div`
  height: 100%;
`;
const FooterInfoPayment = styled.div`
  margin-top: 30px;
`;

const InfoReport = styled.div`
  max-width: 176px;
  margin: 0 0 0 auto;
  text-align: center;
  @media (max-width: 992px) {
    margin: auto;
  }
`;
const InfoReportStatus = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.BlueManhein};
  margin-top: 6px;
  margin-bottom: 10px;
`;

const WrapperDetail = styled.div`
  span {
    font-size: 14px;
    line-height: 24px;
    color: #808285;
  }
  p {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #1f3e74;
  }
`;

const AdvicePaymentDebts = styled.div`
  min-height: 24px;
  background: ${(props) => props.theme.colors.Yellow74};
  border-radius: 15px;
  display: flex;
  margin-bottom: 20px;
`;
const DivIconCox = styled.div`
  width: 24px;
  height: 24px;
  margin: auto 5px auto 0;
  svg {
    background: white;
    border-radius: 24px;
    width: 24px;
    height: 24px;
  }
`;
const AdvicePaymentDebtsColor = styled.div`
  color: ${(props) => props.theme.colors.BlueNavy};
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  margin: auto 0;
`;
const DebtLine = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
  min-height: 42px;
  display: grid;
  align-items: center;
`;

const DebtLineMore = styled.div`
  min-height: 38px;
  background: rgba(168, 170, 172, 0.35);
  border-radius: 10px;
  display: grid;
  align-items: center;
  padding: 0 25px;
  margin-top: 12px;
`;

const TitleDebt = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.MediumGray};
`;
const TitleDebtMore = styled(TitleDebt)`
  color: #3a5693;
`;
const ValueDebt = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  text-align: right;
  color: ${(props) => props.theme.colors.MediumGray};
  align-items: center;
  display: grid;
  height: 100%;
  white-space: nowrap;
`;
const ValueDebtMore = styled(ValueDebt)`
  color: ${(props) => props.theme.colors.BlueHigh};
`;

const HeaderTicket = styled.div`
  background: ${(props) => props.theme.colors.DTBlue};
  border-radius: 0px 0px 8px 8px;
  min-height: 130px;
  padding: 20px 40px 20px 40px;
  margin-top: -7px;
  color: ${(props) => props.theme.colors.white};
  h5 {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-weight: 700;
    size: 16px;
    line-height: 22px;
    padding: 0;
    margin: 0;
  }
  h6 {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-weight: 400;
    size: 14px;
    padding: 0;
    margin: 0;
  }
  h4 {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-weight: 700;
    size: 24px;
    padding: 0;
    margin: 0;
  }
`;

const ModalHeader = styled(Modal.Header)`
  border: none;
  border-radius: 0px 0px 8px 8px;
  background: ${(props) => props.theme.colors.BlueNavy};
  z-index: 2;
  .auto {
    margin: auto;
  }
  span {
    font-size: 30px;
    font-weight: normal;
    color: ${(props) => props.theme.colors.white};
  }
  button {
    padding: 0;
    margin: 0;
    opacity: 1;
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;
  .col {
    margin: auto 0;
  }
`;

const LinePart = styled.div`
  width: 1px;
  height: 23px;
  border: 1px solid ${(props) => props.theme.colors.white};
`;

const ContentProof = styled.div`
  padding: 20px 40px;
`;

const LineTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  hr {
    border: 1px solid ${(props) => props.theme.colors.DTBlue};
    background-color: ${(props) => props.theme.colors.DTBlue};
    margin: 0;
  }
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.DTBlue};
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
`;

const TotalValueTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.DTBlue};
`;

const LabelTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.MediumGray};
`;

const LabelNormal = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.MediumGray};
`;

const LabelNormalValue = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.Dark};
`;

const TagCreditCardInfo = styled.div`
  background: ${(props) => props.theme.colors.Background};
  height: 92px;
  display: grid;
  padding: 20px 40px;
  .col {
    display: flex;
  }
`;

const LabelTitleBlue = styled.div`
  color: ${(props) => props.theme.colors.DTBlue};
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const WarnningContentPayment = styled.div`
  color: ${(props) => props.theme.colors.MediumGray};
  font-size: 10px;
  line-height: 16px;
  padding: 20px 40px;
`;

const FooterButtons = styled.div`
  padding: 20px 40px;
`;
const LabelPrint = styled.div`
  color: ${(props) => props.theme.colors.LapisLazul};
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  cursor: pointer;
  path {
    fill: ${(props) => props.theme.colors.LapisLazul};
  }
`;

export {
  LabelPrint,
  FooterButtons,
  WarnningContentPayment,
  LabelTitleBlue,
  TagCreditCardInfo,
  LabelNormalValue,
  LabelNormal,
  LabelTitle,
  TotalValueTitle,
  LineTitle,
  ContentProof,
  HeaderTicket,
  LinePart,
  ModalBody,
  ModalHeader,
  FooterInfoPayment,
  ValueDebt,
  ValueDebtMore,
  TitleDebt,
  TitleDebtMore,
  DebtLine,
  DebtLineMore,
  AdvicePaymentDebts,
  WrapperDetail,
  ShareItem,
  Row1,
  Row2,
  StatusFlyer,
  BoxCardInspection,
  WrapperInspection,
  StatusBarLateral,
  Content,
  Label,
  Value,
  CarCirle,
  Details,
  WrapperDetails,
  WrapperReportDid,
  Wrapper,
  DivContent,
  DivStatus,
  InfoReport,
  InfoReportStatus,
  AdvicePaymentDebtsColor,
  DivIconCox,
};
