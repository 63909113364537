import { Auth0Provider } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { paths } from "../../../constants";
import { useDebtsContext, useSessionContext } from "../../../hooks";
import { FeedbackModal, TopMainTemplate } from "../../molecules";
import { AppContainerWithMenu } from "../base.template.style";
import { DebtsAuth } from "./debts.auth";

export const DebtsTemplate: FunctionComponent = (props) => {
  const { t } = useTranslation();

  const {
    genericModalMsg,
    genericErrorModal,
    hideGenericErrorModal,
    genericSuccessModal,
    hideGenericSuccessModal,
    genericWarningModal,
    hideGenericWarningModal,
  } = useSessionContext();
  const { clearDebtsContexts } = useDebtsContext();

  const redirectUri = `${window.location.origin}/#${paths.debtsVehicle()}`;

  return (
    <>
      <AppContainerWithMenu>
        <Container>
          <TopMainTemplate />
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ""}
            scope={process.env.REACT_APP_AUTH0_SCOPE ?? ""}
            redirectUri={redirectUri}
          >
            <DebtsAuth
              redirectUri={redirectUri}
              onSelectDealershipSuccess={clearDebtsContexts}
            >
              {props.children}
            </DebtsAuth>
          </Auth0Provider>
        </Container>
        <FeedbackModal
          type={"success"}
          show={genericSuccessModal}
          onHide={hideGenericSuccessModal}
          title={t("Success")}
          content={genericModalMsg ?? t("GenericSuccessMsg")}
        />
        <FeedbackModal
          type={"error"}
          show={genericErrorModal}
          onHide={hideGenericErrorModal}
          title={t("Error")}
          content={genericModalMsg ?? t("GenericErrorMsg")}
        />
        <FeedbackModal
          type={"warning"}
          show={genericWarningModal}
          onHide={hideGenericWarningModal}
          title={t("Warning")}
          content={genericModalMsg ?? t("GenericWarningMsg")}
        />
      </AppContainerWithMenu>
    </>
  );
};
