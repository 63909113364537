import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { DebtConfirmationOrganism } from "../../organisms";
import { DebtsBasePage } from "./base/debts-base.page";

export const VehicleDebtsConfirmPage: React.FC = () => {
  const { t } = useTranslation();

  const { id, typeJorney } = useParams<IPasswordlessParams>();

  const header = useMemo(() => {
    if (id)
      return {
        Title: t("TitleVehicleRegister"),
        BackTo: paths.debtsPayment(id, typeJorney),
        otherPage: true,
      } as IHeaderMainTemplate;
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {header && (
        <DebtsBasePage step={4} header={header}>
          <DebtConfirmationOrganism />
        </DebtsBasePage>
      )}
    </>
  );
};
