import { Auth0Provider } from "@auth0/auth0-react";
import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { paths } from "../../../constants";
import { useInspectionContext, useSessionContext } from "../../../hooks";
import { FeedbackModal, TopMainTemplate } from "../../molecules";
import { AppContainerWithMenu } from "../base.template.style";
import { InspectionAuth } from "./inspection.auth";

export const InspectionTemplate: FunctionComponent = (props) => {
  const { t } = useTranslation();

  const {
    genericModalMsg,
    genericErrorModal,
    hideGenericErrorModal,
    genericWarningModal,
    hideGenericWarningModal,
  } = useSessionContext();
  const { clearInspectionContexts } = useInspectionContext();

  const redirectUri = `${window.location.origin}/#${paths.inspectionPartner()}`;

  return (
    <>
      <AppContainerWithMenu>
        <Container>
          <TopMainTemplate />
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ""}
            scope={process.env.REACT_APP_AUTH0_SCOPE ?? ""}
            redirectUri={redirectUri}
          >
            <InspectionAuth
              redirectUri={redirectUri}
              onSelectDealershipSuccess={clearInspectionContexts}
            >
              {props.children}
            </InspectionAuth>
          </Auth0Provider>
        </Container>
        <FeedbackModal
          type={"error"}
          show={genericErrorModal}
          onHide={hideGenericErrorModal}
          title={t("Error")}
          content={genericModalMsg ?? t("GenericErrorMsg")}
        />
        <FeedbackModal
          type={"warning"}
          show={genericWarningModal}
          onHide={hideGenericWarningModal}
          title={t("Warning")}
          content={genericModalMsg ?? t("GenericWarningMsg")}
        />
      </AppContainerWithMenu>
    </>
  );
};
