import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { TrackerCheckoutOrganism } from "../../organisms";
import { TrackerBasePage } from "./base/tracker-base.page";

export const TrackerCheckoutPage: React.FC = () => {
  const { t } = useTranslation();

  const params = useParams<IPasswordlessParams>();

  const header = useMemo(() => {
    return {
      Title: t("TitleTrackerRegisterPage"),
      BackTo: paths.trackerScheduleInstall(params.id, params.typeJorney),
    } as IHeaderMainTemplate;
  }, [params, t]);

  return (
    <TrackerBasePage step={5} header={header}>
      <TrackerCheckoutOrganism />
    </TrackerBasePage>
  );
};
