import {
  ButtonSecondary,
  ITypeAheadFilterBy,
  IUser,
  PasswordlessSendByEnum,
} from "c4u-web-components";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import {
  useInspectionContext,
  useProductsInspection,
  useSessionContext,
  useTrackerContext,
} from "../../../../hooks";
import {
  ConflictRequestError,
  CreateInspectionRequest,
  IPasswordlessParams,
} from "../../../../models";
import {
  VehicleData,
  VehicleInformation,
  VehicleOptions,
} from "../../../molecules";
import {
  DivButtonAdvance,
  VehicleChooseCol,
} from "./vehicle-choose.organism.style";

interface IProps {
  filterBy?: ITypeAheadFilterBy;
  user?: IUser;
  show?: boolean;
}

export const VehicleChoose: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { typeJorney } = useParams<IPasswordlessParams>();

  const {
    showGenericErrorModal,
    showGenericWarningModal,
    usernamePawordlessContext,
  } = useSessionContext();
  const {
    vehicleContext,
    vehicleDataContext,
    inspectionContext,
    setIdInspectionContext,
    idInspectionContext,
  } = useInspectionContext();

  const informationCarMolicarCondition = vehicleContext?.id === 0;

  const vehicleNameFormat = `${vehicleContext?.brandName} ${vehicleContext?.modelName} ${vehicleContext?.versionName} ${vehicleContext?.hp} Cv - ${vehicleContext?.year}`;

  const { saveInspection } = useProductsInspection();

  const { vehicleCategoryContext, setVehicleCategoryContext } =
    useTrackerContext();

  const [submiting, setSubmiting] = useState(false);

  const vehicleValid = useMemo((): boolean => {
    return !!vehicleContext;
  }, [vehicleContext]);

  const buttonValid = useMemo((): boolean => {
    if (!vehicleContext) return false;

    return (
      !!vehicleDataContext?.plate &&
      !!vehicleDataContext?.renavam &&
      vehicleDataContext.renavam.replace(/[^\d]/g, "").length > 0
    );
  }, [vehicleContext, vehicleDataContext]);

  const handleSave = async () => {
    vehicleDataContext.renavam = vehicleDataContext.renavam
      ? vehicleDataContext.renavam.padStart(11, "0")
      : vehicleDataContext.renavam;
    setSubmiting(true);
  };

  const save = async () => {
    try {
      const request = new CreateInspectionRequest({
        id: idInspectionContext,
        partnerId: inspectionContext?.inspectionCompany,
        kbbId: vehicleContext.id,
        vehicleName: vehicleNameFormat,
        passwordless:
          usernamePawordlessContext.sendBy === PasswordlessSendByEnum.Sms
            ? usernamePawordlessContext.cellphone
            : usernamePawordlessContext.email,

        inspectionDate: inspectionContext?.dateSchedule,
        inspectionPeriod: inspectionContext?.hourSchedule,
        inspectionPlaceAddress: inspectionContext?.unitSchedule?.address,
        inspectionPlaceEmails: inspectionContext?.unitSchedule?.emails,
        inspectionPlaceId: inspectionContext?.unitSchedule?.idUnit,
        inspectionPlaceName: inspectionContext?.unitSchedule?.unitName,
        inspectionPlacePhones: inspectionContext?.unitSchedule?.phones,
        inspectionPlanId: inspectionContext?.inspectionPlan?.id,
        inspectionPlanName: inspectionContext?.inspectionPlan?.name,
        inspectionPlanValue: inspectionContext?.inspectionPlan?.value,
        plate: vehicleDataContext?.plate,
        renavam: vehicleDataContext?.renavam,
        searchZipCode: inspectionContext?.zipcodePlans,
      });

      const { id } = await saveInspection(request);
      setIdInspectionContext(id);
      history.push(paths.inspectionPayment(typeJorney));
    } catch (error) {
      if (error instanceof ConflictRequestError && error?.message)
        showGenericWarningModal(error?.message, () =>
          history.push(paths.inspectionPartner(typeJorney))
        );
      else
        showGenericErrorModal(undefined, () =>
          history.push(paths.inspectionPartner(typeJorney))
        );
    } finally {
      setSubmiting(false);
    }
  };

  useEffect(() => {
    if (submiting) {
      save();
    }
  }, [submiting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!inspectionContext)
      history.replace(paths.inspectionPartner(typeJorney));
    // eslint-disable-next-line
  }, [inspectionContext]);

  return (
    <>
      <Row>
        <VehicleChooseCol lg={6}>
          <VehicleOptions
            vehicleCategory={vehicleCategoryContext}
            setVehicleCategory={setVehicleCategoryContext}
            vehicleKbb={
              vehicleContext
                ? {
                    idBrand: vehicleContext.brandID,
                    idKbb: vehicleContext.id,
                    idModel: vehicleContext.modelID,
                    year: vehicleContext.year,
                    molicarID: vehicleContext.molicarID,
                  }
                : undefined
            }
          />
          <VehicleData
            data={
              !!vehicleDataContext
                ? {
                    plate: vehicleDataContext?.plate?.toString() ?? "",
                    renavam: vehicleDataContext?.renavam?.toString() ?? "",
                  }
                : undefined
            }
          />
        </VehicleChooseCol>
        <Col lg={6}>
          <VehicleInformation
            reduced={false}
            vehicleCategory={vehicleCategoryContext}
            informationCarMolicar={informationCarMolicarCondition}
          />
        </Col>
      </Row>
      <>
        {vehicleValid && (
          <Row>
            <Col md={6}></Col>
            <Col md={6} className={"text-right"}>
              <DivButtonAdvance>
                <ButtonSecondary
                  onClick={handleSave}
                  disabled={!buttonValid}
                  loading={submiting}
                >
                  {t("Schedule the inspection for this vehicle")}
                </ButtonSecondary>
              </DivButtonAdvance>
            </Col>
          </Row>
        )}
      </>
    </>
  );
};
