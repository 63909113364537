import { ButtonSecondary, CoxIcon, DateHelper } from "c4u-web-components";
import { ptBR } from "date-fns/locale";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  DescriptionInfo,
  IconCircle,
  TitleInfo,
  WrapperDataControl,
} from "./checkout-schedule.molecule.style";

interface IProps {
  inspectionDate: string;
  inspectionPlace: string;
  inspectionAddress: string;
  onChangeShop: () => void;
  onChangeSchedule: () => void;
}

export const CheckoutScheduleMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <WrapperDataControl>
        <Row>
          <Col xs={"auto"} className={"pr-0"}>
            <IconCircle>
              <CoxIcon
                name={"calendar"}
                fillColor={"#003468"}
                width={15}
                height={15}
              />
            </IconCircle>
          </Col>
          <Col xs>
            <Row>
              <Col>
                <TitleInfo>{t("Instalation schedule for")}</TitleInfo>
              </Col>
            </Row>
            <Row>
              <Col>
                <DescriptionInfo>
                  {DateHelper.dateHourCustomFormat(
                    props.inspectionDate,
                    `dd '${t("of")}' MMMM 'às' HH'h'mm`,
                    { locale: ptBR }
                  )}
                </DescriptionInfo>
              </Col>
            </Row>
            <Row className={"mb-3"}>
              <Col>
                <ButtonSecondary
                  sizex={"md"}
                  sizey={"thin"}
                  onClick={props.onChangeSchedule}
                  data-cyid="changedate"
                >
                  {t("Change date")}
                </ButtonSecondary>
              </Col>
            </Row>
            <Row>
              <Col>
                <TitleInfo>{props.inspectionPlace}</TitleInfo>
              </Col>
            </Row>
            <Row>
              <Col>
                <DescriptionInfo>{props.inspectionAddress}</DescriptionInfo>
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonSecondary
                  sizex={"md"}
                  sizey={"thin"}
                  onClick={props.onChangeShop}
                  data-cyid="changeinspection"
                >
                  {t("Change inspection")}
                </ButtonSecondary>
              </Col>
            </Row>
          </Col>
        </Row>
      </WrapperDataControl>
    </>
  );
};
