import { TypesHelper } from "c4u-web-components";

interface BillingAddress {
  zipCode?: string;
  address?: string;
  neighborhood?: string;
  city?: string;
  number?: string;
}

interface ICard {
  number?: string;
  brand?: string;
  holder?: string;
  expirationDate?: string;
  cvv?: string;
  billingAddress?: BillingAddress;
}

interface Payment {
  protocol?: string;
  state?: string;
  installmentPlan?: number;
  ids?: string[];
  card?: ICard;
  clientDetails?: IClientDetails;
}

export interface IDebtsPaymentRequest {
  id?: number;
  document?: string;
  name?: string;
  phone?: string;
  email?: string;
  payment?: Payment;
  installmentValue?: number;
}
export interface IClientDetails {
  cartToken?: string;
}

export class DebtsPaymentRequest {
  id: number | null;
  name: string | null;
  document: string | null;
  phone: string | null;
  email: string | null;
  installmentValue: number | null;
  payment: Payment | null;

  constructor({
    id,
    document,
    phone,
    email,
    name,
    payment,
    installmentValue,
  }: IDebtsPaymentRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.name = TypesHelper.toStringNull(name);
    this.phone = TypesHelper.toStringOnlyNumbersNull(phone);
    this.document = TypesHelper.toStringOnlyNumbersNull(document);
    this.email = TypesHelper.toStringNull(email);
    this.installmentValue = TypesHelper.toNumberNull(installmentValue, 2);
    this.payment = {
      protocol: TypesHelper.toStringNull(payment?.protocol) ?? undefined,
      state: TypesHelper.toStringNull(payment?.state) ?? undefined,
      installmentPlan:
        TypesHelper.toNumberNull(payment?.installmentPlan) ?? undefined,
      ids: payment?.ids,
      clientDetails: payment?.clientDetails,
      card: {
        brand: TypesHelper.toStringNull(payment?.card?.brand) ?? undefined,
        cvv: TypesHelper.toStringNull(payment?.card?.cvv) ?? undefined,
        expirationDate:
          TypesHelper.toStringNull(payment?.card?.expirationDate) ?? undefined,
        holder: TypesHelper.toStringNull(payment?.card?.holder) ?? undefined,
        number: TypesHelper.toStringNull(payment?.card?.number) ?? undefined,
        billingAddress: {
          address:
            TypesHelper.toStringNull(payment?.card?.billingAddress?.address) ??
            undefined,
          city:
            TypesHelper.toStringNull(payment?.card?.billingAddress?.city) ??
            undefined,
          neighborhood:
            TypesHelper.toStringNull(
              payment?.card?.billingAddress?.neighborhood
            ) ?? undefined,
          number:
            TypesHelper.toStringNull(payment?.card?.billingAddress?.number) ??
            undefined,
          zipCode:
            TypesHelper.toStringNull(payment?.card?.billingAddress?.zipCode) ??
            undefined,
        },
      },
    };
  }
}
