import { useRequest, kbbHttp } from "./config";
import {
  VehicleModel,
  VehicleVersion,
  Years,
  VehicleBrand,
  Vehicle,
  VehicleBrandModels,
} from "../../models";
import { useTranslation } from "react-i18next";
import {
  HttpHelper,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
} from "c4u-web-components";

export const useKbb = () => {
  const { get } = useRequest(kbbHttp, "Kbb");

  const { t } = useTranslation();

  const GetAllVehicleBrand = async (
    vehicleCategory?: VehicleCategoriesEnum,
    sourcePartner?: SourcePartnerEnum
  ): Promise<VehicleBrand[]> => {
    const { data } = await get(
      `GetAllVehicleBrand${HttpHelper.BuildQueryString({
        vehicleCategory,
        sourcePartner,
      })}`
    );
    return data;
  };

  const GetAllVehicleModelByBrand = async (
    brandId: number,
    year: number | null = null,
    vehicleCategory?: VehicleCategoriesEnum,
    sourcePartner?: SourcePartnerEnum
  ): Promise<VehicleModel[]> => {
    const { data } = await get(
      `GetAllVehicleModelByBrand/${brandId}` +
        `${HttpHelper.BuildQueryString({
          vehicleCategory,
          year,
          sourcePartner,
        })}`
    );
    return data;
  };

  const GetAllVehicleModels = async (
    vehicleCategory?: VehicleCategoriesEnum,
    sourcePartner?: SourcePartnerEnum
  ): Promise<VehicleBrandModels[]> => {
    const { data } = await get(
      `GetAllVehicleModels${HttpHelper.BuildQueryString({
        vehicleCategory,
        sourcePartner,
      })}`
    );
    return data;
  };

  const GetAllVehicleVersion = async (
    modelId: number,
    year: number,
    sourcePartner?: SourcePartnerEnum
  ): Promise<VehicleVersion[]> => {
    const { data } = await get(
      `GetAllVehicleVersion/${year}/${modelId}${HttpHelper.BuildQueryString({
        sourcePartner,
      })}`
    );
    return data;
  };

  const GetAllYears = async (
    sourcePartner?: SourcePartnerEnum
  ): Promise<Years[]> => {
    const { data } = await get(
      `GetAllYears${HttpHelper.BuildQueryString({ sourcePartner })}`
    );
    return data;
  };

  const GetVehicle = async (
    id: number | null,
    molicarID: string | null,
    year: number
  ): Promise<Vehicle> => {
    const { data } = await get(
      `GetVehicle/${id}${HttpHelper.BuildQueryString({ molicarID, year })}`
    );
    if (data && !data?.name) {
      const vehicle = data as Vehicle;
      data.name = `${vehicle?.brandName} ${vehicle?.modelName} ${
        vehicle?.versionName
      } ${vehicle?.hp} ${t("Hp")} - ${vehicle?.year}`;
    }
    return data;
  };

  return {
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetAllYears,
    GetAllVehicleBrand,
    GetVehicle,
    GetAllVehicleModels,
  };
};
