import { MsgValidation, YupHelper } from "c4u-web-components";
import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  IDefaultsProps,
  IVehicleSelectionFormMoleculeProps,
} from "./vehicle-selection-form.molecule";

export const VehicleSelectionFormMoleculeValidation = (
  props: IDefaultsProps
) => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDatePtBrValid",
    YupHelper.isDatePtBrValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "betweenDatesPtBr",
    YupHelper.betweenDatesPtBr
  );

  return Yup.object<IVehicleSelectionFormMoleculeProps>({
    overnightZipCode: Yup.string().required(t(MsgValidation.Required)),
    vin: Yup.string().when("isZeroKm", {
      is: (v) => v === props.yes,
      then: Yup.string()
        .nullable()
        .length(17, t(MsgValidation.Invalid))
        .required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
    plate: Yup.string().when("isZeroKm", {
      is: (v) => v === props.no,
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
    gasKit: Yup.string().required(t(MsgValidation.Required)),
    isZeroKm: Yup.string().required(t(MsgValidation.Required)),
    isZeroKmToggle: Yup.string(),
    dealershipExit: Yup.string().when("isZeroKm", {
      is: (v) => v === props.yes,
      then: Yup.string()
        .nullable()
        .required(t(MsgValidation.Required))
        .betweenDatesPtBr(
          addDays(new Date(), -20),
          new Date(),
          t(MsgValidation.Invalid),
          t("Must be greater then 20 days ago"),
          t("Must be smaller then today")
        ),
      otherwise: Yup.string().notRequired(),
    }),
    vehicleUse: Yup.string().required(t(MsgValidation.Required)),
    taxFree: Yup.string().when("vehicleUse", {
      is: (v) => v !== props.taxi,
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
    isTunning: Yup.string().required(t(MsgValidation.Required)),
    taxFreeReason: Yup.string().when("taxFree", {
      is: (v) => v === props.yes,
      then: Yup.string().nullable().required(t(MsgValidation.Required)),
      otherwise: Yup.string().notRequired(),
    }),
  });
};
