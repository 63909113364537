import { Auth0Provider } from "@auth0/auth0-react";
import { ActiveItemTypeConstant } from "c4u-web-components";
import React, { FunctionComponent } from "react";

import { ManheimAuthC4U } from "./manheim.authC4U";
import { paths } from "../../../constants";

export const ManheimTemplate: FunctionComponent = (props) => {
  const redirectUri = `${
    window.location.origin
  }/#${paths.manheimBuyerInformationPage()}`;

  return (
    <>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ""}
        scope={process.env.REACT_APP_AUTH0_SCOPE ?? ""}
      >
        <ManheimAuthC4U
          redirectUri={redirectUri}
          activeMenu={ActiveItemTypeConstant.MANHEIM}
        >
          {props.children}
        </ManheimAuthC4U>
      </Auth0Provider>
    </>
  );
};
