import styled from "styled-components";

const BoxVehicleDetail = styled.div`
  width: 100%;
  min-height: 160px;
  border: 1px solid ${(props) => props.theme.colors.Platinum};
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.white};
  .row {
    min-height: 160px;
  }
  .col {
    min-height: 160px;
  }
`;

const WrapperVechicleDetails = styled.div`
  padding: 30px 0;
  max-width: 400px;
  display: grid;
  height: 100%;
`;

const BoxImg = styled.div`
  text-align: center;
  overflow: hidden;
  max-width: 286px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  img {
    height: 160px;
    width: auto;
  }
`;

const VehicleName = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* Dark Blue Manheim */
  color: ${(props) => props.theme.colors.DarkBlue};
  margin: 0 0 23px 0;
`;

const VehiclePlate = styled(VehicleName)`
  margin: 0 0 23px 0;
`;

const LabelPlate = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.MediumGray};
`;

export {
  BoxImg,
  BoxVehicleDetail,
  LabelPlate,
  VehicleName,
  VehiclePlate,
  WrapperVechicleDetails,
};
