import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IHeaderMainTemplate } from "../../../models";
import { InspectionRegister } from "../../organisms";
import { InspectionBasePage } from "./base/inspection-base.page";

export const InspectionPartnerPage: React.FC = () => {
  const { t } = useTranslation();

  const header = useMemo(() => {
    return {
      Title: t("TitleInspectionRegisterPage"),
      hideReturnButton: true,
      otherPage: true,
    } as IHeaderMainTemplate;
  }, [t]);

  return (
    <InspectionBasePage step={1} header={header}>
      <InspectionRegister />
    </InspectionBasePage>
  );
};
