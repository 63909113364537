import styled from "styled-components";
import { Row } from "react-bootstrap";

const ComboSort = styled.div`
  text-align: center;
  padding: 15px 0;
  div {
    width: 140px;
    margin: 0 0 0 auto;
  }
`;

const ReportListing = styled.div`
  margin: 18px 0 18px 0;
`;

const ReportListingRow = styled(Row)`
  margin: 12px 0 12px 0;
`;

export { ComboSort, ReportListing, ReportListingRow };
