import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useState,
} from "react";
import { IDebtsContextProps } from "../hooks/contexts/use-debits.hook";
import {
  DebtsVehicleModel,
  InstallmentsModel,
  PaymentCreditCardInfoModel,
  VehicleAllDebtsModel,
} from "../models";

const DebtsContext = createContext({} as any);

const DebtsProvider: FunctionComponent = (props) => {
  const [debtShareContext, setDebtShareContext] = useState<{
    id: number;
    token: string;
    close: () => void;
  }>();
  const [debtsContext, setDebtsContext] = useState<DebtsVehicleModel | null>();
  const [plotsContext, setPlotsContext] = useState<InstallmentsModel[]>();
  const [paymentContext, setPaymentContext] =
    useState<PaymentCreditCardInfoModel>();
  const [vehicleDebtsContext, setVehicleDebtsContext] =
    useState<VehicleAllDebtsModel>();

  const clearDebtsContexts = useCallback(() => {
    setDebtsContext(undefined);
    setPaymentContext(undefined);
    setVehicleDebtsContext(undefined);
    setPlotsContext(undefined);
  }, []);

  return (
    <DebtsContext.Provider
      value={
        {
          debtShareContext,
          setDebtShareContext,
          debtsContext,
          setDebtsContext,
          paymentContext,
          setPaymentContext,
          plotsContext,
          setPlotsContext,
          vehicleDebtsContext,
          setVehicleDebtsContext,
          clearDebtsContexts,
        } as IDebtsContextProps
      }
    >
      {props.children}
    </DebtsContext.Provider>
  );
};

export { DebtsContext, DebtsProvider };
