import {
  ButtonPrimary,
  Combo,
  CoxIcon,
  CustomModalAtom,
  MaskHelper,
  VehicleCategoriesEnum,
  OptionsSelectionVehicleMolecule,
  PasswordlessSendByEnum,
  SourcePartnerEnum,
  SpinnerCenteredAtom,
  Tooltip,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StaticVehicleOptions, VehicleOptions } from "../..";
import {
  useInspectionContext,
  useKbb,
  useSessionContext,
  useTrackerContext,
  useZipcodeAddress,
} from "../../../../hooks";
import {
  IFindByPlateResponse,
  IFoundVehicles,
  Vehicle,
} from "../../../../models";

import { FormikControlIturanAtom, ToggleInputAtom } from "../../../atoms";

import {
  ButtonStyled,
  ContentLoader,
  SelectVehicleDescription,
  SelectVehicleDiv,
  TooltipWrapper,
  VehicleVersionsDiv,
} from "./vehicle-selection-form.molecule.style";
import { VehicleSelectionFormMoleculeValidation } from "./vehicle-selection-form.molecule.validation";

interface IComboProps {
  genders: Combo[];
  maritalStatus: Combo[];
  yesNo: Combo[];
  vehicleUse: Combo[];
  taxFreeReason: Combo[];
}

export interface IDefaultsProps {
  yes: string;
  no: string;
  taxi: string;
}

export interface IVehicleSelectionFormMoleculeProps {
  plate?: string;
  vin?: string;
  dealershipExit?: string;
  gasKit: string;
  vehicleUse: string;
  taxFree?: string;
  taxFreeReason?: string;
  isTunning: string;
  isZeroKm: string;
  isZeroKmToggle: string;
  overnightZipCode: string;
  isInsuranceOrigin?: boolean;
  passwordless?: string | null;
  modelYear?: number;
}

interface ISelectVehicleProps {
  id?: any;
  molicarId?: string;
  description?: any;
  error?: boolean;
}

interface IProps {
  initialValues?: IVehicleSelectionFormMoleculeProps;
  searchVehicleCarDados: (
    plate: string,
    setIsLoading: (v: boolean) => void,
    thenCallback: (data: IFindByPlateResponse) => void,
    catchCallback: (data: ISelectVehicleProps) => void
  ) => void;
  combo: IComboProps;
  defaults: IDefaultsProps;
  onSubmit: (
    values: IVehicleSelectionFormMoleculeProps,
    errors: any
  ) => Promise<void>;
  startSubmit: boolean;
  isSubmiting: (submit: boolean) => void;
  isPasswordless?: boolean;
  vehicleCategory: VehicleCategoriesEnum;
  setVehicleCategory: (vehicleCategory: VehicleCategoriesEnum) => void;
}

const formatVehicleName = (
  brand: string,
  model: string,
  year: number,
  version: string
) => {
  return `${brand} ${model} - ${version}`;
};

export const VehicleSelectionFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { GetVehicle } = useKbb();
  const { getAddressByZipcode } = useZipcodeAddress();

  const { usernamePawordlessContext, showGenericWarningModal } =
    useSessionContext();

  const { vehicleContext, setVehicleContext } = useInspectionContext();
  const {
    trackerDataContext,
    setTrackerDataContext,
    originInsuranceContext,
    vehicleCategoryContext,
    setVehicleCategoryContext,
    sourcePartnerContext,
    setSourcePartnerContext,
    yearsCombo,
    vehicleYearSelected,
    setVehicleYearSelected,
    setYearsCombo,
  } = useTrackerContext();

  const [vehicleData, setVehicleData] = useState<IFindByPlateResponse>();
  const [showModal, setShowModal] = useState(false);
  const [vehicleSelect, setVehicleSelect] = useState<ISelectVehicleProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [markerClassName, setMarkerClassName] = useState<string>();
  const [cardadosSearchFailed, setCardadosSearchFailed] = useState<boolean>();
  const [isVinAndDateFilledOut, setIsVinAndDateFilledOut] = useState(false);
  const [messageErrorOvernightZipCode, setMessageErrorOvernightZipCode] =
    useState(false);

  const isSourceKbb = useMemo(
    () => sourcePartnerContext === SourcePartnerEnum.Kbb,
    [sourcePartnerContext]
  );

  const isCarCategory = useMemo(
    () => vehicleCategoryContext === VehicleCategoriesEnum.Car,
    [vehicleCategoryContext]
  );

  const formik = useFormik<IVehicleSelectionFormMoleculeProps>({
    initialValues: props.initialValues ?? {
      plate: "",
      isZeroKm: props.defaults.no,
      gasKit: props.defaults.no,
      isTunning: props.defaults.no,
      vin: "",
      dealershipExit: "",
      vehicleUse: "",
      overnightZipCode: "",
      isZeroKmToggle: "",
    },
    onSubmit: async (values, { setErrors }) => {
      try {
        if (messageErrorOvernightZipCode === true) {
          formik.setFieldError("overnightZipCode", "CEP Inválido");
          return;
        }
        await props.onSubmit(
          {
            ...values,
            passwordless:
              usernamePawordlessContext.sendBy === PasswordlessSendByEnum.Sms
                ? usernamePawordlessContext.cellphone
                : usernamePawordlessContext.email,
          },
          setErrors
        );
      } catch (error) {
        console.log("Error.VehicleSelectionFormMolecule", error);
      }
    },
    validationSchema: VehicleSelectionFormMoleculeValidation(props.defaults),
    validateOnBlur: true,
    validateOnChange: false,
    // isInitialValid: true,
  });

  const onBlurCep = useCallback(
    (ev: any) => {
      const { value } = ev.target;
      const cep = value?.replace(/[^0-9]/g, "");

      if (cep.length !== 8) {
        return;
      }

      getAddressByZipcode(cep).then((response) => {
        if (response.erro !== true) {
          setMessageErrorOvernightZipCode(false);
          formik.setFieldError("overnightZipCode", "");
        } else {
          setMessageErrorOvernightZipCode(true);
          formik.setFieldError("overnightZipCode", "CEP Inválido");
        }
      });
    },
    [formik, getAddressByZipcode]
  );

  const cleanVehicle = useCallback(() => {
    const clearContext = { ...trackerDataContext };
    if (clearContext?.vehicle?.plate) {
      clearContext.vehicle.plate = undefined;
      setTrackerDataContext(clearContext);
    }
    setVehicleData(undefined);
    setVehicleSelect(undefined);
    setVehicleContext(undefined);
    setCardadosSearchFailed(undefined);
    setVehicleYearSelected(undefined);
    setYearsCombo(undefined);
  }, [
    trackerDataContext,
    setVehicleContext,
    setVehicleYearSelected,
    setYearsCombo,
    setTrackerDataContext,
  ]);

  const settingDataVehicle = useCallback(
    (vehicle: IFoundVehicles) => {
      setVehicleSelect({
        id: vehicle.kbbId,
        molicarId: vehicle.molicarId,
        description: formatVehicleName(
          vehicle.brand,
          vehicle.model,
          vehicle.modelYear,
          vehicle.version
        ),
      });

      var context = {} as Vehicle;
      context.id = vehicle.kbbId;
      context.brandName = vehicle.brand;
      context.year = vehicle.modelYear;
      context.versionName = vehicle.version;
      context.modelName = vehicle.model;
      context.molicarID = vehicle.molicarId;

      setVehicleContext(context);
    },
    [setVehicleContext]
  );

  const isPasswordless = useMemo(
    () => !!props.isPasswordless,
    [props.isPasswordless]
  );

  const isZeroKm = useMemo(
    () => formik.values.isZeroKm === props.defaults.yes,
    [formik.values.isZeroKm, props.defaults.yes]
  );

  useEffect(() => {
    if (props.startSubmit) {
      formik.submitForm();
    }
    // eslint-disable-next-line
  }, [props.startSubmit]);

  useEffect(
    () => props.isSubmiting(formik.isSubmitting),
    // eslint-disable-next-line
    [formik.isSubmitting]
  );

  useEffect(
    () => {
      if (props.initialValues) formik.setValues(props.initialValues);
    },
    // eslint-disable-next-line
    [props.initialValues]
  );

  useEffect(() => {
    if (formik.values.isZeroKmToggle !== undefined) {
      formik.setFieldValue(
        "isZeroKm",
        formik.values.isZeroKmToggle.length.toString()
      );
    }
  }, [formik.values.isZeroKmToggle]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (originInsuranceContext) {
      if (vehicleSelect?.id) {
        setMarkerClassName("highlight-empty");
      }
    }
  }, [originInsuranceContext, vehicleSelect]);

  const getVehicleAsync = useCallback(
    async (
      id: number,
      molicarID: string | null,
      year: number
    ): Promise<void> => {
      const vehicle = await GetVehicle(id, molicarID, year);
      setVehicleContext(vehicle);
    },
    [GetVehicle, setVehicleContext]
  );

  const getVehicleData = async ({ id, molicarId, year }: any) => {
    getVehicleAsync(Number(id), molicarId, year);
  };

  const handleCarDadosSearchResponse = (data: IFindByPlateResponse) => {
    setVehicleData(undefined);
    setCardadosSearchFailed(false);
    if (data?.vehicles.length === 1) {
      const vehicle = data.vehicles[0];
      settingDataVehicle(vehicle);
      setVehicleSelect({
        id: isSourceKbb ? vehicle.kbbId : vehicle.molicarId,
        description: formatVehicleName(
          vehicle.brand,
          vehicle.model,
          Number(vehicleYearSelected?.value),
          vehicle.version
        ),
      });
      getVehicleData({
        id: vehicle.kbbId,
        molicarId: vehicle.molicarId,
        year: vehicle.modelYear,
      });
    } else {
      setVehicleData(data);
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (vehicleYearSelected?.value) {
      getVehicleData({
        id: vehicleContext?.id,
        molicarId: vehicleContext?.molicarID,
        year: vehicleYearSelected?.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleYearSelected?.value]);

  const handleSearchVehicleError = (err: any) => {
    cleanVehicle();

    setCardadosSearchFailed(true);
    setVehicleSelect({
      description: t("Error Service CarDados"),
      error: true,
    });

    console.log(err);
  };

  useEffect(() => {
    if (!isZeroKm && !isPasswordless) {
      if (formik.values.plate?.length !== 7) {
        cleanVehicle();
        return;
      }

      if (
        formik.values.plate?.length === 7 &&
        !trackerDataContext?.vehicle?.plate
      ) {
        props.searchVehicleCarDados(
          formik.values.plate,
          setIsLoading,
          handleCarDadosSearchResponse,
          handleSearchVehicleError
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.plate]);

  useEffect(() => {
    cleanVehicle();
    formik.setFieldValue("plate", "");
    formik.setFieldValue("vin", "");
    formik.setFieldValue("dealershipExit", "");
    formik.setFieldValue("gasKit", props.defaults.no);
    formik.setFieldValue("vehicleUse", "");
    formik.setFieldValue("taxFree", "");
    formik.setFieldValue("taxFreeReason", "");
    formik.setFieldValue("isTunning", props.defaults.no);
    formik.setFieldValue("overnightZipCode", "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZeroKm]);

  const handleVehicleSelect = (vehicle: IFoundVehicles) => {
    settingDataVehicle(vehicle);
    getVehicleData({
      id: vehicle.kbbId,
      molicarId: vehicle.molicarId,
      year: vehicleYearSelected?.value ?? vehicle.modelYear,
    });
  };

  useEffect(() => {
    if (cardadosSearchFailed) {
      showGenericWarningModal(t("VehicleNotAvailableErrorMessage"));
      setCardadosSearchFailed(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardadosSearchFailed]);

  useEffect(() => {
    if (isZeroKm && !isVinAndDateFilledOut)
      setIsVinAndDateFilledOut(
        formik.values.vin?.length === 17 &&
          formik.values.dealershipExit?.replace(/\D/g, "").length === 8
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.dealershipExit, formik.values.vin, isZeroKm]);

  useEffect(() => {
    cleanVehicle();
    formik.setFieldValue("plate", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleCategoryContext, isZeroKm]);

  return (
    <>
      <Form
        className={"form-default"}
        onSubmit={(e: any) => formik.handleSubmit(e)}
      >
        <Row>
          <ToggleInputAtom
            id={"isZeroKmToggle"}
            title={t("Vehicle0KmToggle")}
            form={formik}
            checked={isZeroKm}
          />
        </Row>
        <OptionsSelectionVehicleMolecule
          vehicleCategory={vehicleCategoryContext}
          setVehicleCategory={setVehicleCategoryContext}
          translate={t}
        />
        &nbsp;&nbsp;
        {!isPasswordless && (
          <Row>
            <FormikControlIturanAtom
              md={6}
              formik={formik}
              property={"plate"}
              label={t("Plate")}
              maxLength={7}
              func={(v: string) => v.toUpperCase()}
            />
          </Row>
        )}
        <SelectVehicleDiv>
          {isLoading ? (
            <ContentLoader>
              <SpinnerCenteredAtom />
              <SelectVehicleDescription>
                {t("SearchingPlate")}
              </SelectVehicleDescription>
            </ContentLoader>
          ) : (
            cardadosSearchFailed === false && (
              <>
                <SelectVehicleDescription>
                  {vehicleSelect?.description
                    ? vehicleSelect.description
                    : t("Select vehicle")}
                </SelectVehicleDescription>
                {vehicleData?.vehicles.length && (
                  <ButtonStyled
                    type={"button"}
                    onClick={() => setShowModal(true)}
                  >
                    {t("Edit")}
                  </ButtonStyled>
                )}
              </>
            )
          )}
        </SelectVehicleDiv>
        {isZeroKm || isPasswordless ? (
          <VehicleOptions
            vehicleCategory={vehicleCategoryContext}
            setVehicleCategory={setVehicleCategoryContext}
            colorMarker={{
              brandColor: originInsuranceContext
                ? vehicleContext?.brandID
                  ? "highlight-empty"
                  : "highlight-empty-no"
                : "",
              modelColor: originInsuranceContext
                ? vehicleContext?.modelID
                  ? "highlight-empty"
                  : "highlight-empty-no"
                : "",
              versinColor: originInsuranceContext
                ? vehicleContext?.versionID
                  ? "highlight-empty"
                  : "highlight-empty-no"
                : "",
            }}
            vehicleKbb={
              vehicleContext
                ? {
                    idBrand: vehicleContext.brandID,
                    idKbb: vehicleContext.id,
                    idModel: vehicleContext.modelID,
                    year: vehicleContext.year,
                    molicarID: vehicleContext.molicarID,
                  }
                : undefined
            }
          />
        ) : (
          <StaticVehicleOptions
            vehicleKbbId={vehicleSelect?.id}
            vehicleMolicarId={vehicleSelect?.molicarId}
            className={markerClassName}
            vehicleCategory={vehicleCategoryContext}
            setVehicleCategory={setVehicleCategoryContext}
            sourcePartner={sourcePartnerContext}
            setSourcePartner={setSourcePartnerContext}
            userYearOfManuFacture={yearsCombo}
          />
        )}
        {(vehicleContext?.id || vehicleContext?.molicarID) && (
          <Row className="align-items-baseline mt-4">
            {isZeroKm && (
              <>
                <FormikControlIturanAtom
                  md={6}
                  formik={formik}
                  property={"vin"}
                  label={t("Chassi")}
                  maxLength={17}
                />
                <FormikControlIturanAtom
                  mask={MaskHelper.Date}
                  md={6}
                  formik={formik}
                  property={"dealershipExit"}
                  label={t("Dealership exit")}
                />
              </>
            )}

            {(!isZeroKm || isVinAndDateFilledOut) && (
              <>
                <FormikControlIturanAtom
                  md={6}
                  mask={MaskHelper.Cep}
                  formik={formik}
                  property={"overnightZipCode"}
                  label={t("Overnight Zip Code")}
                  onBlur={onBlurCep}
                />

                {isPasswordless && (
                  <>
                    <FormikControlIturanAtom
                      md={6}
                      formik={formik}
                      property={"plate"}
                      label={t("Plate")}
                      maxLength={7}
                      func={(v: string) => v.toUpperCase()}
                    />
                  </>
                )}

                <FormikControlIturanAtom
                  type={"select"}
                  hideSelectOption
                  data={props.combo.yesNo}
                  md={6}
                  formik={formik}
                  property={"gasKit"}
                  disabled={!isCarCategory ? true : false}
                  label={
                    <TooltipWrapper className={"form-label"}>
                      {t("Does the vehicle have a gas kit?")}
                      <Tooltip content={t("GasKitAdvice")}>
                        <CoxIcon name={"information"} />
                      </Tooltip>
                    </TooltipWrapper>
                  }
                />

                <FormikControlIturanAtom
                  type={"select"}
                  data={props.combo.vehicleUse}
                  md={6}
                  formik={formik}
                  property={"vehicleUse"}
                  label={t("Vehicle use")}
                />

                {formik.values.vehicleUse &&
                  formik.values.vehicleUse !== props.defaults.taxi && (
                    <FormikControlIturanAtom
                      type={"select"}
                      data={props.combo.yesNo}
                      md={6}
                      formik={formik}
                      property={"taxFree"}
                      label={
                        <TooltipWrapper className={"form-label"}>
                          {t("Tax free")}
                          <Tooltip content={t("TaxFreeAdvice")}>
                            <CoxIcon name={"information"} />
                          </Tooltip>
                        </TooltipWrapper>
                      }
                    />
                  )}

                {formik.values.taxFree === props.defaults.yes && (
                  <FormikControlIturanAtom
                    type={"select"}
                    data={props.combo.taxFreeReason}
                    md={6}
                    formik={formik}
                    property={"taxFreeReason"}
                    label={t("Tax free reason")}
                  />
                )}

                <FormikControlIturanAtom
                  type={"select"}
                  hideSelectOption
                  data={props.combo.yesNo}
                  md={6}
                  formik={formik}
                  property={"isTunning"}
                  disabled={!isCarCategory ? true : false}
                  label={t("Is tunning")}
                />
              </>
            )}
          </Row>
        )}
        <div className={"hide"}>
          <input type={"submit"} />
        </div>
      </Form>

      <CustomModalAtom
        show={showModal}
        onHide={() => setShowModal(false)}
        title={t("Select the correct vehicle")}
        footer={
          <ButtonPrimary
            className="float-right"
            onClick={() => setShowModal(false)}
            sizex="md"
          >
            {t("Ok")}
          </ButtonPrimary>
        }
      >
        {vehicleData?.vehicles.map((m, i) => (
          <VehicleVersionsDiv key={`vehicle-version-${i}`}>
            <input
              title={formatVehicleName(
                m.brand,
                m.model,
                m.modelYear,
                m.version
              )}
              type="radio"
              id={isCarCategory ? m.kbbId.toString() : m.molicarId}
              name={isCarCategory ? m.kbbId.toString() : m.molicarId}
              value={isCarCategory ? m.kbbId.toString() : m.molicarId}
              onChange={(e) => handleVehicleSelect(m)}
              checked={
                isSourceKbb
                  ? vehicleSelect?.id === m.kbbId
                  : vehicleSelect?.molicarId === m.molicarId
              }
            />
            <label className="pl-2" htmlFor={m.kbbId.toString()}>
              {formatVehicleName(m.brand, m.model, m.modelYear, m.version)}
            </label>
          </VehicleVersionsDiv>
        ))}
      </CustomModalAtom>
    </>
  );
};
