import { ActiveItemTypeConstant } from "c4u-web-components";
import React from "react";
import { AuthTemplate } from "..";
import { paths } from "../../../constants";

export const InsuranceAuth: React.FC = (props) => {
  return (
    <AuthTemplate
      redirectUri={`${window.location.origin}/#${paths.insurance()}`}
      activeMenu={ActiveItemTypeConstant.INSURANCE}
    >
      {props.children}
    </AuthTemplate>
  );
};
