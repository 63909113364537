import { Col } from "react-bootstrap";
import styled from "styled-components";

const DivButtonAdvance = styled.div`
  text-align: right;
  margin: 32px 0;
`;

const VehicleChooseCol = styled(Col)`
  .text-vehicle-brand {
    margin: 0 0 12px;
  }
`;

export { DivButtonAdvance, VehicleChooseCol };
