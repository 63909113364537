import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useInspectionContext } from "../../../hooks";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { ListInspection } from "../../organisms";
import { InspectionBasePage } from "./base/inspection-base.page";

export const InspectionListPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<IPasswordlessParams>();
  const { clearInspectionContexts } = useInspectionContext();

  const header = useMemo(() => {
    return {
      Title: t("TitleMyInspectionsPage"),
      Description: t("DescriptionMyInspectionsPage"),
      NavigationButtonAction: () => {
        clearInspectionContexts();
        history.push(paths.inspectionPartner(params.typeJorney));
      },
      NavigationButtonText: t("New Inspection"),
      hideAndAlignLeft: true,
      otherPage: true
    } as IHeaderMainTemplate;
  }, [t, clearInspectionContexts, history, params.typeJorney]);

  return (
    <InspectionBasePage header={header}>
      <ListInspection />
    </InspectionBasePage>
  );
};
