import styled from "styled-components";

const BackgroudBreadcrumb = styled.div`
  background-color: ${(props) => props.theme.colors.Background};
  height: 42px;
`;

const AppContainerWithMenu = styled.div`
  .form-default {
    .form-label {
      font-size: 14px;
    }
  }

  padding-left: 82px;
  padding-top: 80px;
  @media (max-width: 576px) {
    padding-left: 0px;
  }
`;

export { AppContainerWithMenu, BackgroudBreadcrumb };
