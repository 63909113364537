import { TypesHelper } from "c4u-web-components";

export interface IGetTrackerShopRequest {
  trackerId?: number;
  schedulingZipCode?: string;
  isHomeSchedule?: boolean;
}

export class GetTrackerShopRequest {
  trackerId: number | null;
  schedulingZipCode: string | null;
  isHomeSchedule: boolean | null;
  constructor({ trackerId, schedulingZipCode, isHomeSchedule }: IGetTrackerShopRequest) {
    this.trackerId = TypesHelper.toNumberNull(trackerId);
    this.schedulingZipCode =
      TypesHelper.toStringOnlyNumbersNull(schedulingZipCode);
    this.isHomeSchedule = TypesHelper.toBooleanNull(isHomeSchedule);
  }
}
