import { ButtonPrimary } from "c4u-web-components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DivButtonAdvance } from "./footer-submit-button.atom.style";

interface IProps {
  disabled?: boolean;
  loading: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const FooterSubmitButtonAtom: React.FC<IProps> = (props) => {
  return (
    <Row>
      <Col md={6}></Col>
      <Col md={6} className={"text-right"}>
        <DivButtonAdvance>
          <ButtonPrimary
            sizex={"md"}
            onClick={props.onClick}
            loading={props.loading}
            disabled={props.disabled}
            id="button-continue"
          >
            {props.children}
          </ButtonPrimary>
        </DivButtonAdvance>
      </Col>
    </Row>
  );
};
