import { ActiveItemTypeConstant } from "c4u-web-components";
import React from "react";
import { AuthTemplate } from "..";

interface IProps {
  redirectUri: string;
  onSelectDealershipSuccess?: () => void;
}

export const InspectionAuth: React.FC<IProps> = (props) => {
  return (
    <AuthTemplate {...props} activeMenu={ActiveItemTypeConstant.REPORT}>
      {props.children}
    </AuthTemplate>
  );
};
