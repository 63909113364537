import {
  Combo,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
  VehicleSearchOptions,
  VehicleSearchOptionsProps,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useInspectionContext,
  useKbb,
  useTrackerContext,
} from "../../../../hooks";

interface IProps {
  vehicleKbbId?: number;
  vehicleMolicarId?: string;
  className?: string;
  vehicleCategory: VehicleCategoriesEnum;
  setVehicleCategory: (vehicleCategory: VehicleCategoriesEnum) => void;
  sourcePartner: SourcePartnerEnum;
  setSourcePartner: (sourcePartner: SourcePartnerEnum) => void;
  userYearOfManuFacture: Combo[];
}

export const StaticVehicleOptions: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => {
    setProps(parameters);
  }, [parameters]);

  const { vehicleContext, setVehicleContext } = useInspectionContext();
  const {
    vehicleCategoryContext,
    sourcePartnerContext,
    setSourcePartnerContext,
    yearsCombo,
    setYearsCombo,
    vehicleYearSelected,
    setVehicleYearSelected,
  } = useTrackerContext();

  const {
    GetAllVehicleBrand,
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetAllYears,
    GetVehicle,
  } = useKbb();

  const [vehicleBrandCombo, setVehicleBrandCombo] = useState<Combo[]>();
  const [vehicleModelCombo, setVehicleModelCombo] = useState<Combo[]>();
  const [vehicleVersionCombo, setVehicleVersionCombo] = useState<Combo[]>();

  const [vehicleBrandSelected, setVehicleBrandSelected] = useState<Combo>();
  const [vehicleModelSelected, setVehicleModelSelected] = useState<Combo>();
  const [vehicleVersionSelected, setVehicleVersionSelected] = useState<Combo>();
  const [haveYearsSelected, setHaveYearsSelected] = useState(false);

  const isSourceKbb = useMemo(
    () => sourcePartnerContext === SourcePartnerEnum.Kbb,
    [sourcePartnerContext]
  );

  const getVehiclesBrandAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllVehicleBrand(
      vehicleContext?.vehicleTypeID,
      sourcePartnerContext
    );
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.name,
          value: m.id,
        })
    );
    setVehicleBrandCombo(itemsCombo);
  }, [GetAllVehicleBrand, sourcePartnerContext, vehicleContext?.vehicleTypeID]);

  const getVehicleVersionAsync = useCallback(
    async (modelId: number, year: number): Promise<void> => {
      if (modelId) {
        const items = await GetAllVehicleVersion(
          modelId,
          year,
          sourcePartnerContext
        );
        const itemsCombo = items?.map(
          (m) =>
            new Combo({
              title: m.name,
              value: isSourceKbb ? m.kbbid : m.molicarID,
            })
        );
        setVehicleVersionCombo(itemsCombo);
      }
    },
    [GetAllVehicleVersion, isSourceKbb, sourcePartnerContext]
  );

  const getVehiclesModelAsync = useCallback(
    async (brandId: number, year: number | null = null): Promise<void> => {
      if (brandId) {
        const items = await GetAllVehicleModelByBrand(
          brandId,
          year,
          vehicleContext?.vehicleTypeID,
          sourcePartnerContext
        );
        const itemsCombo = items?.map(
          (m) =>
            new Combo({
              title: m.name,
              value: m.id,
            })
        );
        setVehicleModelCombo(itemsCombo);
      }
    },
    [
      GetAllVehicleModelByBrand,
      sourcePartnerContext,
      vehicleContext?.vehicleTypeID,
    ]
  );

  const GetAllYearsAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllYears(sourcePartnerContext);
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.year,
          value: m.year,
        })
    );
    setYearsCombo(itemsCombo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetAllYears, sourcePartnerContext]);

  const getVehicleAsync = useCallback(
    async (
      id: number,
      molicarID: string | null,
      year: number
    ): Promise<void> => {
      const vehicle = await GetVehicle(id, molicarID, year);
      setVehicleContext(vehicle);
    },
    [GetVehicle, setVehicleContext]
  );

  useEffect(() => {
    if (vehicleContext?.id === 0) {
      setSourcePartnerContext(SourcePartnerEnum.Molicar);
    } else {
      setSourcePartnerContext(SourcePartnerEnum.Kbb);
    }
  }, [setSourcePartnerContext, sourcePartnerContext, vehicleContext?.id]);

  useEffect(() => {
    if (vehicleContext !== undefined && !vehicleVersionCombo) {
      getVehicleAsync(
        Number(vehicleContext.id),
        vehicleContext.molicarID,
        vehicleContext.year
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleVersionSelected, vehicleYearSelected?.value]);

  useEffect(() => {
    getVehiclesBrandAsync();
    GetAllYearsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vehicleCategoryContext,
    sourcePartnerContext,
    vehicleContext?.vehicleTypeID,
  ]);

  useEffect(() => {
    if (vehicleContext && vehicleBrandCombo) {
      const brand = vehicleBrandCombo?.find(
        (f) => f.value === vehicleContext.brandID
      );
      setVehicleBrandSelected(brand);
    }
  }, [vehicleContext, vehicleBrandCombo]);

  useEffect(() => {
    if (vehicleContext && yearsCombo) {
      const year = yearsCombo?.find((f) => f.value === vehicleContext?.year);
      setVehicleYearSelected(year);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleContext, yearsCombo]);

  useEffect(() => {
    if (vehicleContext?.brandName) {
      getVehiclesModelAsync(vehicleContext?.brandID, vehicleContext?.year);
    }
  }, [vehicleContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleContext && vehicleModelCombo) {
      const model = vehicleModelCombo?.find(
        (f) => f.value === vehicleContext.modelID
      );
      setVehicleModelSelected(model);
    }
  }, [vehicleContext, vehicleModelCombo]);

  useEffect(() => {
    if (vehicleContext && vehicleVersionCombo) {
      let version: Combo | undefined;
      if (isSourceKbb) {
        version = vehicleVersionCombo?.find(
          (f) => f.value === vehicleContext.id
        );
      } else {
        version = vehicleVersionCombo?.find(
          (f) => f.value === vehicleContext.molicarID
        );
      }

      setVehicleVersionSelected(version);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleContext, vehicleVersionCombo]);

  useEffect(() => {
    if (vehicleContext) {
      getVehicleVersionAsync(vehicleContext?.modelID, vehicleContext?.year);
    }
  }, [vehicleContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleContext === undefined) {
      setVehicleBrandSelected(undefined);
      setVehicleYearSelected(undefined);
      setVehicleModelSelected(undefined);
      setVehicleVersionSelected(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleContext]);

  useEffect(() => {
    if (vehicleContext?.year === 0) {
      setHaveYearsSelected(false);
    } else {
      setHaveYearsSelected(true);
    }
  }, [vehicleContext?.year, vehicleYearSelected]);

  const propsVehicleSearchOptions = useMemo((): VehicleSearchOptionsProps => {
    return {
      Dropdowns: {
        showAll: true,
        haveYearsSelected: haveYearsSelected,
        Brand: {
          placeholder: t("Select") + "...",
          Options: vehicleBrandCombo,
          Value: vehicleBrandSelected,
          disabled: true,
          className: props.className,
        },
        Model: {
          placeholder: t("Select") + "...",
          Options: vehicleModelCombo,
          Value: vehicleModelSelected,
          disabled: true,
          className: props.className,
        },
        Version: {
          placeholder: t("Select") + "...",
          Options: vehicleVersionCombo,
          Value: vehicleVersionSelected,
          disabled: true,
          className: props.className,
        },
        Year: {
          placeholder: t("Select") + "...",
          Options: yearsCombo,
          Value: vehicleYearSelected,
          disabled: haveYearsSelected ? false : true,
          onChange: setVehicleYearSelected,
        },
      },
      Texts: {
        Brand: t("Brand"),
        ClearFilter: t("Clean Filters"),
        Loading: t("Loading"),
        Model: t("Model"),
        NoMatchesFound: t("No Matches Found"),
        Others: t("Others"),
        TitleYears: t("Model Year"),
        Version: t("Version"),
      },
    };

    // eslint-disable-next-line
  }, [
    vehicleBrandCombo,
    yearsCombo,
    vehicleModelCombo,
    vehicleVersionCombo,
    vehicleBrandSelected,
    vehicleYearSelected,
    vehicleModelSelected,
    vehicleVersionSelected,
    haveYearsSelected,
    t,
  ]);

  return (
    <Row>
      <Col>
        <VehicleSearchOptions {...propsVehicleSearchOptions} />
      </Col>
    </Row>
  );
};
