import { PaymentCreditCard } from "c4u-web-components";
import { useContext } from "react";
import { InspectionContext } from "../../contexts";
import { Inspection, InspectionVehicleData, Vehicle } from "../../models";

export interface IInspectionProviderProps {
  idInspectionContext: number | undefined;
  setIdInspectionContext: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  inspectionContext: Inspection;
  setInspectionContext: React.Dispatch<
    React.SetStateAction<Inspection | undefined>
  >;
  vehicleContext: Vehicle;
  setVehicleContext: React.Dispatch<React.SetStateAction<Vehicle | undefined>>;
  vehicleDataContext: InspectionVehicleData;
  setVehicleDataContext: React.Dispatch<
    React.SetStateAction<InspectionVehicleData | undefined>
  >;
  paymentContext: PaymentCreditCard;
  setPaymentContext: React.Dispatch<
    React.SetStateAction<PaymentCreditCard | undefined>
  >;
  clearInspectionContexts: () => void;
}

export const useInspectionContext = () => {
  const context = useContext<IInspectionProviderProps>(InspectionContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
