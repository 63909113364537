import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerCenteredAtom } from "c4u-web-components";

import React, { useEffect } from "react";

import { ContainerManheimStyled } from "./auth.template-manheim.style";

export const AuthManheimTemplate: React.FC = (props) => {
  const {
    isLoading,
    isAuthenticated: isAuthenticatedInManheim,
    loginWithPopup,
  } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticatedInManheim) {
      loginWithPopup({
        connection: process.env.REACT_APP_AUTH0_MANHEIM_CONNECTION ?? "",
        domain: process.env.REACT_APP_AUTH0_MANHEIM_DOMAIN ?? "",
        clientId: process.env.REACT_APP_AUTH0_MANHEIM_CLIENTID ?? "",
        audience: process.env.REACT_APP_AUTH0_MANHEIM_AUDIENCE ?? "",
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticatedInManheim]);

  return isAuthenticatedInManheim ? (
    <ContainerManheimStyled>{props.children}</ContainerManheimStyled>
  ) : (
    <SpinnerCenteredAtom />
  );
};
