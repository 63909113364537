import React, { useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useDebtsContext, useSessionContext } from "../../../hooks";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { DebtsListOrganism } from "../../organisms";

export const VehicleDebtsListPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { typeJorney } = useParams<IPasswordlessParams>();

  const { clearDebtsContexts } = useDebtsContext();
  const { setHeaderMainTemplate } = useSessionContext();

  const header = useMemo(() => {
    return {
      Title: t("TitleVehicleList"),
      BackTo: paths.debtsVehicle(typeJorney),
      NavigationButtonAction: () => {
        clearDebtsContexts();
        history.push(paths.debtsVehicle(typeJorney));
      },
      NavigationButtonText: t("New view"),
      hideAndAlignLeft: true,
      otherPage: true,
    } as IHeaderMainTemplate;

    // eslint-disable-next-line
  }, [typeJorney]);

  useLayoutEffect(() => {
    if (header) setHeaderMainTemplate(header);
    // eslint-disable-next-line
  }, [header]);

  return <DebtsListOrganism />;
};
