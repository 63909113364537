import {
  Combo,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
} from "c4u-web-components";
import React, { FunctionComponent, createContext, useState } from "react";
import { ITrackerContextProps } from "../hooks/contexts/use-tracker.hook";
import { ITrackerData } from "../models";

const TrackerContext = createContext({} as any);

const TrackerProvider: FunctionComponent = (props) => {
  const [trackerDataContext, setTrackerDataContext] = useState<ITrackerData>();
  const [originInsuranceContext, setOriginInsuranceContext] =
    useState<boolean>();
  const [vehicleCategoryContext, setVehicleCategoryContext] =
    useState<VehicleCategoriesEnum>(VehicleCategoriesEnum.Car);
  const [sourcePartnerContext, setSourcePartnerContext] =
    useState<SourcePartnerEnum>(SourcePartnerEnum.Kbb);
  const [yearsCombo, setYearsCombo] = useState<Combo[]>();
  const [vehicleYearSelected, setVehicleYearSelected] = useState<Combo>();

  return (
    <TrackerContext.Provider
      value={
        {
          trackerDataContext,
          setTrackerDataContext,
          originInsuranceContext,
          setOriginInsuranceContext,
          vehicleCategoryContext,
          setVehicleCategoryContext,
          sourcePartnerContext,
          setSourcePartnerContext,
          yearsCombo,
          setYearsCombo,
          vehicleYearSelected,
          setVehicleYearSelected,
        } as ITrackerContextProps
      }
    >
      {props.children}
    </TrackerContext.Provider>
  );
};

export { TrackerContext, TrackerProvider };
