import { ButtonPrimary, CoxIcon, NumberHelper } from "c4u-web-components";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BoxBackShoopResume,
  BoxListValues,
  BoxShoopResume,
  BoxValueDescription,
  PaymentTaxe,
  PaymentTerms,
  PaymentValue,
  PaymentWay,
  TitlePayment,
  WhiteCircle,
  WrapperBoxConfirmation,
} from "./debit-confirmation-payment.molecule.style";

interface IProps {
  plate: string;
  renavam: string;
  debts: {
    description: string;
    value: number;
  }[];
  totalDebts: number;
  date: Date;
  plot: number;
  plotValue: number;
  zapayTaxe: number;
  totalValue: number;
  onFinishing?: () => void;
  loading?: boolean;
}

export const DebitConfirmationPaymentMolecule: React.FC<IProps> = (
  parameters
) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => setProps(parameters), [parameters]);

  const numberFormatMoney = (value: number) => {
    return t("$") + " " + NumberHelper.toFormatString(value, 2, "--");
  };

  return (
    <WrapperBoxConfirmation>
      <Row>
        <Col className={"pr-0"}>
          <BoxListValues>
            <Row>
              <Col xs={"auto"}>
                <WhiteCircle>
                  <CoxIcon name={"car-cash"} />
                </WhiteCircle>
              </Col>
              <Col
                xs={"auto"}
                className={"d-flex align-items-center justify-content-center"}
              >
                {t("TitleDebitConfirmationPayment")}
              </Col>
            </Row>
            <Row className={"my-2"}>
              <Col xs={"auto"}>
                <CoxIcon name={"car-front-v2"} />
                <span className={"ml-1"}>{props.plate}</span>
              </Col>
              <Col xs={"auto"}>
                <CoxIcon name={"document"} />
                <span className={"ml-1"}>{props.renavam}</span>
              </Col>
            </Row>
            <Row className={"mt-4"}>
              <Col>
                {props.debts?.map((m, i) => (
                  <BoxValueDescription
                    key={`debts-summary-description-${i}`}
                    className={"mb-3 "}
                  >
                    <Row className={"h-100"}>
                      <Col sm={"8"} className={"description"}>
                        {m.description}
                      </Col>
                      <Col sm={"4"} className={"value"}>
                        {numberFormatMoney(m.value)}
                      </Col>
                    </Row>
                  </BoxValueDescription>
                ))}
              </Col>
            </Row>
            <Row>
              <Col className={"text-right"}>
                <label>{t("Total debts")}</label>{" "}
                {numberFormatMoney(props.totalDebts)}
              </Col>
            </Row>
          </BoxListValues>
        </Col>
        <Col className={"pl-0"}>
          <BoxBackShoopResume>
            <BoxShoopResume>
              <TitlePayment>{t("Shop resume")}</TitlePayment>
              <PaymentWay>
                <Row>
                  <Col>{t("Payment date")}</Col>
                  <Col>{format(props.date, "dd/MM/yyyy")}</Col>
                </Row>
              </PaymentWay>
              <PaymentWay>
                <Row>
                  <Col>{t("Payment way")}</Col>
                  <Col>{`${props.plot}x ${numberFormatMoney(
                    props.plotValue
                  )}`}</Col>
                </Row>
              </PaymentWay>
              <PaymentTaxe>
                <Row>
                  <Col>{t("Zapay payment service")}</Col>
                  <Col>{numberFormatMoney(props.zapayTaxe)}</Col>
                </Row>
              </PaymentTaxe>
              <PaymentValue>
                <Row>
                  <Col>{t("Total value")}</Col>
                  <Col>{numberFormatMoney(props.totalValue)}</Col>
                </Row>
              </PaymentValue>
              <PaymentTerms>
                <div>
                  {t("TermsDebitConfirmationPayment")}{" "}
                  <b>
                    <a
                      href={"https://usezapay.com.br/termos/cox"}
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      {t("Terms")}
                    </a>
                  </b>{" "}
                  {t("TermsDebitConfirmationPaymentPart2")}
                </div>
              </PaymentTerms>
              <div className={"text-center"}>
                <ButtonPrimary
                  sizex={"lg"}
                  disabled={!props.totalValue}
                  onClick={parameters.onFinishing}
                  loading={props.loading}
                >
                  {t("Finish purchase")}
                </ButtonPrimary>
              </div>
            </BoxShoopResume>
          </BoxBackShoopResume>
        </Col>
      </Row>
    </WrapperBoxConfirmation>
  );
};
