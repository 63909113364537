import styled from "styled-components";

export const ContactDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  margin: 2px 0 0;
`;

export const ContactDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  color: ${(props) => props.theme.colors.Dark};
`;
