import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";
import { Auth0Provider } from "@auth0/auth0-react";

import { AppContainerWithMenu } from "../base.template.style";
import { TopMainTemplate } from "../../molecules";
import { paths } from "../../../constants";
import { PerformanceAuth } from "./performance.auth";

export const PerformanceTemplate: FunctionComponent = (props) => {
  const redirectUri = `${window.location.origin}/#${paths.performance()}`;

  return (
    <>
      <AppContainerWithMenu>
        <Container>
          <TopMainTemplate />
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ""}
            scope={process.env.REACT_APP_AUTH0_SCOPE ?? ""}
            redirectUri={redirectUri}
          >
            <PerformanceAuth redirectUri={redirectUri}>
              {props.children}
            </PerformanceAuth>
          </Auth0Provider>
        </Container>
      </AppContainerWithMenu>
    </>
  );
};
