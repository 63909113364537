import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { CreditcardCompanyEnum, SpinnerCenteredAtom } from "c4u-web-components";
import { useProductsDebts } from "../../../../hooks";
import { DebtsVehicleModel } from "../../../../models";
import { useParams } from "react-router-dom";
import { PrintProofPaymentMolecute } from "../../../molecules";
import { parseISO } from "date-fns";

export const DebtsProofPaymentOrganism: React.FC = () => {
  const { guid, print } = useParams<any>();

  const { getDebtByToken } = useProductsDebts();

  const [debtRegistered, setDebtRegistered] = useState<DebtsVehicleModel>();

  useEffect(() => {
    getDebtByToken(guid)
      .then((t) => setDebtRegistered(t))
      .catch((c) => setDebtRegistered({} as DebtsVehicleModel));
    // eslint-disable-next-line
  }, [guid]);

  return (
    <>
      {debtRegistered === undefined ? (
        <SpinnerCenteredAtom />
      ) : (
        <Container>
          <PrintProofPaymentMolecute
            id={debtRegistered.id}
            protocol={debtRegistered.debitsProtocol}
            plate={debtRegistered.plate}
            debtsValue={debtRegistered.totalDebts}
            total={debtRegistered.totalDebtsInstallments}
            debts={debtRegistered.vehicleDebtDetails}
            date={parseISO(
              (
                debtRegistered.paymentDate ?? debtRegistered.insertDate
              )?.toString()
            )}
            creditcard={debtRegistered.finalCreditCard}
            creditcardFlag={
              debtRegistered.flagCreditCard as CreditcardCompanyEnum
            }
            installmentPlan={debtRegistered.installmentPlan}
            installmentValue={debtRegistered.installmentValue}
            print={print === "true"}
          />
        </Container>
      )}
    </>
  );
};
