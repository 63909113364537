import styled from "styled-components";

export const WrapperInfoControl = styled.div`
  padding: 34px 0;
  max-width: 400px;
  margin: auto;
  height: 100%;
  display: grid;
  align-items: center;
  button {
    text-transform: uppercase;
  }
`;

export const StepTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #4a4a4a;
`;

export const DataValue = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #808285;
`;

export const GroupPriceBox = styled.div`
  max-width: 253px;
  min-height: 62px;
  background: #1f3e74;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  display: grid;
  padding: 10px;
  margin: 10px auto 0 auto;
`;

export const TitleSuperior = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
`;

export const DescriptionGroup = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

export const DescriptionGroupContent = styled.div`
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
`;

export const DescriptionGroupAuxiliar = styled.div`
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-transform: lowercase;
  margin-left: 5px;
`;

export const NumberStep = styled.div`
  width: 24px;
  height: 24px;

  background: #edc46c;
  border: 1px solid #edc46c;
  box-sizing: border-box;
  border-radius: 24px;

  font-weight: bold;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #1f3e74;
`;
