import React from "react";
import {
  VehicleDeliveryCardDiv,
  VehicleDeliveryCardWrapper,
} from "./vehicle-delivery-card.molecule.style";
import { VehicleDeliveryCardBodyAtom, VehicleDeliveryCardHeaderAtom } from "../../../atoms";

interface IProps {
  plate: string;
  reinspectionDate: string;
  reinspectionPeriod: number;
  buyerId: string;
  adMainVehiclePicture: string;
  redirectTo: string;
}

export const VehicleDeliveryCardMolecule: React.FC<IProps> = (props) => {
  return (
    <VehicleDeliveryCardWrapper>
      <VehicleDeliveryCardDiv color={"#003468"}>
        <VehicleDeliveryCardHeaderAtom
          image={props.adMainVehiclePicture}
          plate={props.plate}
          urlRedirect={props.redirectTo}
        />
        <hr />
        <VehicleDeliveryCardBodyAtom
          reinspectionPeriod={props.reinspectionPeriod}
          reinspectionDate={props.reinspectionDate}
        />
      </VehicleDeliveryCardDiv>
    </VehicleDeliveryCardWrapper>
  );
};
