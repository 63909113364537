import React, { useLayoutEffect, useMemo } from "react";
import { Wrapper } from "./tracker-base.page.style";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../../constants";
import { useSessionContext } from "../../../../hooks";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../../models";
import { MenuHorizontalStepsMolecule } from "../../../molecules";

interface IProps {
  step?: number;
  header: IHeaderMainTemplate;
  hideAuxiliarButton?: boolean;
}

export const TrackerBasePage: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { setHeaderMainTemplate } = useSessionContext();

  const history = useHistory();

  document.title = t("TitlePageTracker");

  const { typeJorney } = useParams<IPasswordlessParams>();

  const itemsMenu = useMemo(() => {
    if (typeJorney === Definition.Passwordless)
      return [
        t("Authorize"),
        t("Vehicle data"),
        t("Driver data"),
        t("Quote"),
        t("Request data"),
        t("Schedule installation"),
      ];
    return [
      t("Vehicle data"),
      t("Driver data"),
      t("Quote"),
      t("Request data"),
      t("Schedule installation"),
    ];
  }, [t, typeJorney]);

  useLayoutEffect(() => {
    if (props?.header)
      setHeaderMainTemplate({
        NavigationButtonAction: props.hideAuxiliarButton
          ? undefined
          : () => history.push(paths.trackerMyQuotes(typeJorney)),
        NavigationButtonText: props.hideAuxiliarButton
          ? undefined
          : t("TrackerMyQuotes"),
        ...props.header,
      });
    // eslint-disable-next-line
  }, [props?.header]);

  const step = () => {
    if (typeJorney === Definition.Passwordless && props.step !== undefined) {
      return props.step + 1;
    } else {
      return props.step;
    }
  };

  return (
    <Wrapper>
      {props.step !== undefined && (
        <Row className="mb-5">
          <Col>
            <MenuHorizontalStepsMolecule
              currentStep={step() ?? 0}
              items={itemsMenu}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>{props.children}</Col>
      </Row>
    </Wrapper>
  );
};
