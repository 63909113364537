import { MsgValidation } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IOrderDataFormMoleculeProps } from "../../../../models";

export const OrderDataFormMoleculeValidation = () => {
  const { t } = useTranslation();

  return Yup.object<IOrderDataFormMoleculeProps>({
    name: Yup.string().required(t(MsgValidation.Required)),
    phone: Yup.string().required(t(MsgValidation.Required)),
    zipCode: Yup.string().required(t(MsgValidation.Required)),
    street: Yup.string().required(t(MsgValidation.Required)),
    number: Yup.string().required(t(MsgValidation.Required)),
    district: Yup.string().required(t(MsgValidation.Required)),
    city: Yup.string().required(t(MsgValidation.Required)),
    state: Yup.string().required(t(MsgValidation.Required)),
  });
};
