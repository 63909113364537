import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../hooks";
import { IHeaderMainTemplate } from "../../../models";
import { VehicleSelectionOrganism } from "../../organisms";
import { TrackerBasePage } from "./base/tracker-base.page";

export const TrackerVehiclePage: React.FC = () => {
  const { t } = useTranslation();

  const { get } = useQuery();

  useEffect(() => {
    const passwordlessToken = get("passwordless_token");
    const passwordlessExpire = get("passwordless_expire");
    const passwordlessUser = get("passwordless_email_cellphone");

    if (!!passwordlessToken && !!passwordlessExpire && !!passwordlessUser) {
      sessionStorage.setItem("passwordless_token", String(passwordlessToken));
      sessionStorage.setItem("passwordless_expire", String(passwordlessExpire));
      sessionStorage.setItem(
        "passwordless_email_cellphone",
        String(passwordlessUser)
      );
    }
  }, [get]);

  const header = useMemo(() => {
    return {
      Title: t("TitleTrackerRegisterPage"),
      hideReturnButton: true,
      hideAndAlignLeft: true,
    } as IHeaderMainTemplate;
  }, [t]);

  return (
    <TrackerBasePage step={1} header={header}>
      <VehicleSelectionOrganism />
    </TrackerBasePage>
  );
};
