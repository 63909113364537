import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ButtonSecondary } from "c4u-web-components";
import {
  AllCapsButtonTertiary,
  SubTitle,
  Title,
  DivText,
  WrapperHeaderMainTemplate,
} from "./top-main-template.molecule.style";
import { useSessionContext } from "../../../../hooks";
import { useHistory } from "react-router-dom";
import { ContactDetailsAtom } from "../../../atoms";

interface IProps {
  hideBottomRule?: boolean;
}

export const TopMainTemplate: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { headerMainTemplate: header } = useSessionContext();

  let history = useHistory();

  const handleClickBack = () => {
    header.HandleClickBack
      ? header.HandleClickBack()
      : header?.BackTo
      ? history.push(header.BackTo)
      : history.goBack();
  };

  const titleSection = useMemo(
    () => (
      <DivText>
        <Title>{header?.Title}</Title>
        <SubTitle>{header?.Description}</SubTitle>
      </DivText>
    ),
    [header]
  );

  return (
    <WrapperHeaderMainTemplate>
      {header?.Element ? (
        header.Element
      ) : (
        <>
          <Container>
            <Row className="my-3">
              {!header?.hideAndAlignLeft && (
                <Col xs={6} md={1} className="center-align-vertical">
                  {!header?.hideReturnButton && (
                    <ButtonSecondary
                      sizex={"sm"}
                      sizey={"thin"}
                      onClick={handleClickBack}
                      disabled={
                        header?.BackTo === undefined &&
                        header?.HandleClickBack === undefined
                      }
                    >
                      {t("Back")}
                    </ButtonSecondary>
                  )}
                </Col>
              )}

              <Col
                md={header?.hideAndAlignLeft ? 8 : 6}
                lg={header?.hideAndAlignLeft ? 9 : 7}
                className="d-none d-md-block"
              >
                {titleSection}
                {!header?.homeList && !header?.otherPage && (
                  <ContactDetailsAtom />
                 )} 
              </Col>

              {header?.NavigationButtonAction && (
                <Col className="center-align-vertical d-flex justify-content-end">
                  <AllCapsButtonTertiary
                    sizex={"md"}
                    sizey={"thin"}
                    onClick={header.NavigationButtonAction}
                  >
                    {header.NavigationButtonText}
                  </AllCapsButtonTertiary>
                </Col>
              )}
            </Row>

            <Row className="my-3">
              <Col className="d-md-none">{titleSection}</Col>
            </Row>
          </Container>
          {!props.hideBottomRule && <hr />}
        </>
      )}
    </WrapperHeaderMainTemplate>
  );
};
