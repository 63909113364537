import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../models";
import { DriverOrderOrganism } from "../../organisms";
import { TrackerBasePage } from "./base/tracker-base.page";

export const TrackerOrderPage: React.FC = () => {
  const { t } = useTranslation();

  const params = useParams<IPasswordlessParams>();

  const header = useMemo(() => {
    return {
      Title: t("Request data"),
      BackTo: paths.trackerQuotes(params.id, params.typeJorney),
    } as IHeaderMainTemplate;
  }, [params, t]);

  return (
    <TrackerBasePage step={4} header={header}>
      <DriverOrderOrganism />
    </TrackerBasePage>
  );
};
