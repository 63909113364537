import React, { useLayoutEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../../constants";
import { useSessionContext } from "../../../../hooks";
import { IHeaderMainTemplate, IPasswordlessParams } from "../../../../models";
import { MenuStepper } from "../../../molecules";

interface IProps {
  step?: number;
  header: IHeaderMainTemplate;
  hideAuxiliarButton?: boolean;
}

export const InspectionBasePage: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { setHeaderMainTemplate } = useSessionContext();

  const history = useHistory();

  document.title = t("TitlePageInspection");

  const { typeJorney } = useParams<IPasswordlessParams>();

  const itemsMenu = useMemo(() => {
    if (typeJorney === Definition.Passwordless)
      return [
        t("Authorize"),
        t("Partner"),
        t("Vehicle"),
        t("Payment"),
        t("Confirmation"),
      ];
    return [t("Partner"), t("Vehicle"), t("Payment"), t("Confirmation")];
  }, [t, typeJorney]);

  useLayoutEffect(() => {
    if (props?.header)
      setHeaderMainTemplate({
        NavigationButtonAction: props.hideAuxiliarButton
          ? undefined
          : () => history.push(paths.myInspections(typeJorney)),
        NavigationButtonText: props.hideAuxiliarButton
          ? undefined
          : t("MyInspections"),
        ...props.header,
      });
    // eslint-disable-next-line
  }, [props?.header]);

  const step = () => {
    if (typeJorney === Definition.Passwordless && props.step !== undefined) {
      return props.step === 0 ? 1 : props.step + 1;
    } else {
      return props.step;
    }
  };

  return (
    <Row className={"mb-5"}>
      {props.step !== undefined && (
        <Col md={"auto"} className={"mr-5"}>
          <MenuStepper currentStep={step() ?? 0} items={itemsMenu} />
        </Col>
      )}
      <Col md>{props.children}</Col>
    </Row>
  );
};
