import {
  IPagedResponseBaseModel,
  PaginationControlMolecule,
  SpinnerCenteredAtom,
  TrackerStepEnum,
} from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import * as Scroll from "react-scroll";
import { paths } from "../../../../constants";
import { useInspectionContext, useTrackerContext } from "../../../../hooks";
import { IGetTrackerResponse, IPasswordlessParams } from "../../../../models";
import { TrackerQuoteCardMolecule } from "../../../molecules";
import { MyQuotesWrapper, NoQuotesFoundDiv } from "./my-quotes.organism.style";

interface IProps {
  quotesTraker?: IGetTrackerResponse[];
  paginationData?: IPagedResponseBaseModel;
  setPageNumber: (v: number) => void;
}

export const TrackerMyQuotesOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { typeJorney } = useParams<IPasswordlessParams>();

  const { setVehicleContext } = useInspectionContext();
  const { setTrackerDataContext } = useTrackerContext();

  // const getTrackerListCallback = useCallback(
  //   async (params: IGetTrackerListParams, cancelToken: CancelToken) => {
  //     getTrackerList(params, cancelToken)
  //       .then(({ data }) => {
  //         setTrackerQuotesHistory(data);
  //         getUpdateTrackerList(params, cancelToken)
  //           .then(({ data }) => {
  //             if (data) setTrackerQuotesHistory(data);
  //           })
  //           .catch((error) => {
  //             if ((error as any)?.stack?.message === componentUnmountedMsgKey)
  //               return;
  //             console.log("tracker list update failed");
  //           });
  //       })
  //       .catch((error) => {
  //         if ((error as any)?.stack?.message === componentUnmountedMsgKey)
  //           return;
  //         console.log("tracker list loading failed");
  //         setTrackerQuotesHistory([]);
  //       });
  //   },
  //   [getTrackerList, getUpdateTrackerList, setTrackerQuotesHistory]
  // );

  // useEffect(() => {
  //   setTrackerQuotesHistory({}, );
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTrackerPath = (
    id: string | number,
    step: TrackerStepEnum
  ): string => {
    let path;
    switch (step) {
      case TrackerStepEnum.Scheduling:
        path = paths.trackerScheduleInstall;
        break;
      case TrackerStepEnum.Order:
      case TrackerStepEnum.Quote:
      case TrackerStepEnum.Driver:
        path = paths.trackerQuotes;
        break;
      case TrackerStepEnum.Vehicle:
        path = paths.trackerDriverData;
        break;
      case TrackerStepEnum.CreatedFromInsurance:
        path = paths.trackerVehicleSelection;
        break;
      default:
        return paths.trackerMyQuotes(typeJorney);
    }
    return path(id, typeJorney);
  };

  const scroll = Scroll.animateScroll;
  const propsScroll = {
    duration: 10,
    delay: 0,
  };

  return (
    <MyQuotesWrapper>
      <hr />
      {props.quotesTraker === undefined ? (
        <SpinnerCenteredAtom />
      ) : props.quotesTraker.length === 0 ? (
        <NoQuotesFoundDiv
          className={
            "h-100 mt-5 d-flex align-items-center justify-content-center"
          }
        >
          {t("NoQuotesFoundMatchingFilters")}
        </NoQuotesFoundDiv>
      ) : (
        <>
          {props.quotesTraker.map(
            (m, i) =>
              m.step >= TrackerStepEnum.Vehicle && (
                <TrackerQuoteCardMolecule
                  data={m}
                  key={`quote-${i}`}
                  viewTrackerOnClick={
                    [
                      TrackerStepEnum.Scheduled,
                      TrackerStepEnum.Canceled,
                      TrackerStepEnum.Finished,
                    ].includes(m.step)
                      ? undefined
                      : () => {
                          scroll.scrollToTop(propsScroll);
                          setTrackerDataContext(undefined);
                          setVehicleContext(undefined);
                          history.push(getTrackerPath(m.id, m.step));
                        }
                  }
                />
              )
          )}
          {props.paginationData && props.paginationData.totalPages > 1 && (
            <div className="default-align">
              <PaginationControlMolecule
                translator={t}
                paginationData={props.paginationData}
                setPageNumber={props.setPageNumber}
              />
            </div>
          )}
        </>
      )}
    </MyQuotesWrapper>
  );
};
