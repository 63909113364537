import { ButtonTertiary } from "c4u-web-components";
import React from "react";
import {
  CardBodyWrapperButtons,
  CardHeaderTitleWrapper,
  ContentPlateAndAcessButton,
  HeaderTitleCard,
  VehicleInformationContent,
} from "./vehicle-delivery-card-header.atom.style";
import { useTranslation } from "react-i18next";

interface IProps {
  plate: string;
  image: string;
  urlRedirect: string;
}

export const VehicleDeliveryCardHeaderAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <CardHeaderTitleWrapper>
      <VehicleInformationContent>
        <img src={props.image} alt="" />
      </VehicleInformationContent>

      <ContentPlateAndAcessButton>
        <VehicleInformationContent>
          <HeaderTitleCard>
            {t("Plate {{plate}}", {
              plate: props.plate,
            })}
          </HeaderTitleCard>
        </VehicleInformationContent>

        <CardBodyWrapperButtons>
          <ButtonTertiary
            sizex={"sm"}
            sizey={"thin"}
            onClick={() => {
              window.location.href = props.urlRedirect;
            }}
          >
            {t("Acess")}
          </ButtonTertiary>
        </CardBodyWrapperButtons>
      </ContentPlateAndAcessButton>
    </CardHeaderTitleWrapper>
  );
};
